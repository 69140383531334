import {
  getInventoryProductsClient,
  getProductsAlerts
} from "../../../client/utilities.client";
import {buildInventoryProductsWithAlertInfo} from "../../../utils/product";
import {isObject} from "../../../internal-types/IsObject";
import {InventoryProductWithAlertInfo} from "../../fast-traslation/scan-product/ScanProduct.navigator";
import {alertComparator} from "../../../utils/alerts.utils";
import { getLocationOrContainerFromScan } from "client/scanner/scanner.client";
import { ScannedContainer, ScannedLocation } from "client/scanner/scanner.types";

export type StorageContainerDetail = {
  inventoryProductsWithAlertInfo: InventoryProductWithAlertInfo[]
  locationAndContainer: Required<ScannedLocation | ScannedContainer>
}

export const isCointainerDetail = (o: unknown): o is StorageContainerDetail => {
  return isObject(o) && '_from' in o && o._from === 'containerDetailLoader';
}

export const containerDetailLoader = async ({params}) => {
  const containerName = String(params.containerName);
  const locationAndContainer = await getLocationOrContainerFromScan(containerName)

  const inventoryProducts = await getInventoryProductsClient(
    locationAndContainer.location.legacyId,
    locationAndContainer.container?.legacyId
  )

  const {data: alerts} = await getProductsAlerts(
    inventoryProducts.map(product => product.productId)
  )

  const response = buildInventoryProductsWithAlertInfo(inventoryProducts, alerts)
  const sortedInventoryProductsWithAlertInfo = response.sort(alertComparator)
  return {
    _from: 'containerDetailLoader',
    locationAndContainer,
    inventoryProductsWithAlertInfo: sortedInventoryProductsWithAlertInfo
  }
}