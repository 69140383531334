import { useAtom } from 'jotai'
import { fastTraslationOrigin, fastTraslationProducts } from '../FastTraslation.state'
import { useRequiredParams } from '../../../hooks/params.hook'
import { useEffect, useState } from 'react'
import { useFastTraslationScanDestinationNavigator } from '../scan-destination/FastTraslationScanDestination.navigator'
import { getRulesFromProductClient, getTotalStockInWarehouse } from '../../../client/utilities.client'
import { useNavigate } from 'react-router-dom'

export const useFastTraslationQuantity = () => {
  const [quantity, setQuantity] = useState<number>(0)
  const [inventoryProducts] = useAtom(fastTraslationProducts)
  const [currentLocation] = useAtom(fastTraslationOrigin)
  const scanDestination = useFastTraslationScanDestinationNavigator()
  const { ean } = useRequiredParams('ean')
  const navigate = useNavigate()
  const [totalStock, setTotalStock] = useState<number | null>(null)

  const currentInventoryProduct = inventoryProducts.find(e => e.productEan === ean)
  const canConfirm = currentInventoryProduct && quantity > 0 && quantity <= currentInventoryProduct.stock

  useEffect(() => {
    if (currentInventoryProduct && currentInventoryProduct.productId) {
      getRulesFromProductClient(currentInventoryProduct.productId)
        .catch(() => {
          console.error('Prefetching failed, just ignore')
        })
    }
    if (currentInventoryProduct && currentInventoryProduct.productEan) {
      getTotalStockInWarehouse(currentInventoryProduct.productEan)
        .then((data) => {
          setTotalStock(data.stock)
        })
        .catch(() => {
          setTotalStock(-1)
          console.error('Prefetching failed, just ignore')
        })
    }
  }, [currentInventoryProduct])

  return {
    currentLocation,
    currentInventoryProduct,
    setQuantity,
    canConfirm,
    next: () => {
      scanDestination.go(ean, quantity)
    },
    back: () => {
      navigate(-1)
    },
    totalStock
  }
}
