import {getLocationsAndContainersInPreStorage} from "./Storage.client";
import {isLocationAndContainer, LocationAndContainer} from "../../types/model";
import {isObject} from "../../internal-types/IsObject";
import {isArray} from "lodash";

type HasLocationAndContainer = {
  locationAndContainers: LocationAndContainer[]
}

export const hasLocationAndContainers = (data: unknown): data is HasLocationAndContainer => {
  return isObject(data)
    && 'locationAndContainers' in data
    && isArray(data.locationAndContainers)
    && data.locationAndContainers.every(isLocationAndContainer)
}

export const prestoragePendingLoader = async () => {
  return new Promise((resolve, reject) => setTimeout(async () => {
      try {
        const value = await getLocationsAndContainersInPreStorage()
        resolve({
          locationAndContainers: value
        })
      } catch (e) {
        reject(e)
      }
    }, 200) // Check with Elpidia
  )
}
