import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTraslateScanOrigin } from '../scan-origin/ScanOrigin.navigator'
import { useInventoryProducts } from '../../../hooks/inventoryProducts.hooks'
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import { type InventoryProduct, type LocationOrContainer } from '../../../types/model'
import { traslationDestinationInfoAtom, traslationOriginInfoReader } from '../Traslation.state'
import { useAtom } from 'jotai'
import { moveContainerClient, moveProducts } from '../../../client/move.client'
import { isErrorResponse } from '../../../internal-types/HasError'
import { manageError } from '../../../utils/errors/error.handler'
import { Analytics } from "../../../analytics/analytics.events";

interface MoveInventoryProductsProps {
  inventoryProducts: InventoryProduct[]
  origin: LocationOrContainer
  destination: LocationOrContainer
}

export const useMoveAll = () => {
  const data = useLoaderData() as any
  const navigate = useNavigate()
  const traslateScanOrigin = useTraslateScanOrigin()
  const inventoryProducts = useInventoryProducts(data)
  const [origin] = useAtom(traslationOriginInfoReader)
  const [destination] = useAtom(traslationDestinationInfoAtom)
  const moveContainer = data.moveContainer
  const [loading, setLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const hasLooseProducts = searchParams.get('looseProducts')

  if (isErrorResponse(inventoryProducts)) {
    throw new Error(inventoryProducts.error.message) // TODO: improve this
  }

  return {
    origin,
    destination,
    inventoryProducts,
    actions: {
      moveInventoryProducts: ({ inventoryProducts, origin, destination }: MoveInventoryProductsProps) => {
        const tmpOrigin = {
          locationId: origin.location.legacyId,
          locationName: origin.location.name,
          locationType: origin.location.type,
          containerId: origin.container?.legacyId,
          containerName: origin.container?.name,
          containersInLocation: origin.totalContainers ?? 0,
        }
        const tmpDestination = {
          locationId: destination.location.legacyId,
          locationName: destination.location.name,
          locationType: destination.location.type,
          containerId: destination.container?.legacyId,
          containerName: destination.container?.name,
          containersInLocation: destination.totalContainers ?? 0,
        }
        setLoading(true)
        if(hasLooseProducts != null && hasLooseProducts === 'true'){
          moveProducts({ inventoryProducts, origin: tmpOrigin, destination: tmpDestination, hasLooseProducts: true })
            .then(() => {
              Analytics.traslation.moveLooseProducts({
                inventoryProducts,
                origin: tmpOrigin,
                destination: tmpDestination
              })

              if (inventoryProducts.length === 1) {
                toast.success(`Movido 1 SKU. Origen: ${origin.location.name}.Destino: ${destination.location.name}`)
              } else {
                toast.success(`Movidos ${inventoryProducts.length} productos. Origen: ${origin.location.name}. Destino: ${destination.location.name}`)
              }
              setLoading(false)
              traslateScanOrigin.go()
            })
            .catch(manageError('Error al mover productos'))
        }
        else{
          if (moveContainer) {
            moveContainerClient({ origin: tmpOrigin, destination: tmpDestination })
              .then(() => {
                Analytics.traslation.moveContainer({
                  origin: tmpOrigin,
                  destination: tmpDestination,
                })
                toast.success('Movido el contenedor ' + origin.container?.name + ' con éxito')
                setLoading(false)
                traslateScanOrigin.go()
              })
              .catch(manageError('Error al mover todos los productos'))
          } else {
            moveProducts({ inventoryProducts, origin: tmpOrigin, destination: tmpDestination, hasLooseProducts: false})
              .then(() => {
                Analytics.traslation.moveProducts({
                  inventoryProducts,
                  origin: tmpOrigin,
                  destination: tmpDestination
                })
  
                if (inventoryProducts.length === 1) {
                  toast.success(`Movido 1 SKU. Origen: ${origin.location.name}.Destino: ${destination.location.name}`)
                } else {
                  toast.success(`Movidos ${inventoryProducts.length} productos. Origen: ${origin.location.name}. Destino: ${destination.location.name}`)
                }
                setLoading(false)
                traslateScanOrigin.go()
              })
              .catch(manageError('Error al mover productos'))
          }
        }
      },
      cancel: () => {
        toast.success('Movimiento cancelado con éxito')
        setLoading(false)
        traslateScanOrigin.go()
      },
      back: () => {
        navigate(-1)
      }
    },
    loading
  }
}
