import { type InventoryProduct } from '../../types/model'
import { InventoryProductCountMolecule } from './InventoryProductCount.molecule'
import { CardComponent, CardRow, TextBoxMolecule } from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'

interface ProductFixQuantityComponentProps {
  inventoryProduct: InventoryProduct
  location: string
  onChange: (value: string) => void
  label: string
}

export const InventoryProductFixQuantityComponent = ({
  label,
  inventoryProduct,
  onChange,
  location
}: ProductFixQuantityComponentProps) => {
  const { t } = useTranslation('global')

  return <CardComponent>
        <InventoryProductCountMolecule
          isWeighable={inventoryProduct.byWeight}
          stock={inventoryProduct.stock}
          quantity={inventoryProduct.stock}
          count={inventoryProduct.stock}
          subtitle={inventoryProduct.byWeight ? undefined : t('uom.units')}
          location={location} />
        <CardRow>
            <TextBoxMolecule
                type='number'
                label={label}
                onChange={onChange}
            />
        </CardRow>
    </CardComponent>
}
