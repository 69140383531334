import { useProductContainersListNavigator } from '../product-containers-list/ProductContainersList.navigator'
import { useEffect, useState } from 'react'
import { useScanner } from '../../../hooks/scanner.hook'

export const useSearchProduct = (onError: (err: string) => void) => {
  const productContainersList = useProductContainersListNavigator(onError)
  const [loading, setLoading] = useState<boolean>(false)
  const { getScan } = useScanner()

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      if (/^[0-9]+$/.test(scanned)) {
        setLoading(true)
        productContainersList.go(scanned)
          .then(() => { setLoading(false) })
          .catch(e => { onError(e.message as string) })
      } else {
        onError('El codigo de barras ' + scanned + ' no pertenece a un producto válido')
      }
    }
  }, [getScan])

  return {
    loading,
    actions: {
      goEAN: (scanned: string) => {
        if (/^[0-9]+$/.test(scanned)) {
          setLoading(true)
          productContainersList.go(scanned)
            .then(() => { setLoading(false) })
            .catch(e => { onError(e.message as string) })
        } else {
          onError('El codigo de barras ' + scanned + ' no pertenece a un producto válido')
        }
      }
    }
  }
}
