import { useNavigate } from 'react-router-dom'

export const useGoToInventoryTransfer = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(INVENTORY_TRANSFER_URL)
    }
  }
}

export const INVENTORY_TRANSFER_URL = '/inventory-transfer/'
