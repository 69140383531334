import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasListAtom,
  Filler,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useLowerContainer } from './LowerContainer.hook'
import { onErrorToast } from '../../../onErrorToast.util'
import { MoveOrganism } from '../../../components/organisms/v2/Move.organism'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { useTranslation } from 'react-i18next'

export const LowerContainerPage = () => {
  const { origin, suggestedLocation, actions, isLoading } = useLowerContainer(onErrorToast)
  const { t } = useTranslation('global')

  if (!origin || !suggestedLocation || isLoading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return (
    <>
      <ToolbarMolecule
        title={t('lower-container.toolbar-title')}
        actionIcon={Icons.CloseIcon}
        onAction={actions.goBack}
      />
      <PageContentAtom>
        <AtlasBodySubtitle>{t("reverse-logistics.register.instructions")}</AtlasBodySubtitle>
        <AtlasListAtom>
          <li><AtlasBodyMain display='contents'>{t('lower-container.scan')}</AtlasBodyMain></li>
        </AtlasListAtom>
        <MoveOrganism
          origin={origin}
          originText={t('lower-container.origin-text')}
          destination={suggestedLocation}
          destinationText={t('lower-container.destination-text')}
          showAlerts={false}
        />
        <Filler />
      </PageContentAtom>
    </>
  )
}
