import { FormikProvider, Form } from 'formik'

import {
  AtlasBodySubtitle,
  BottomGroupMolecule,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useTemperature } from './Temperature.hooks'
import { FormikTextFieldOrganism } from 'components/organisms/FormikTextField.organism'
import { CheckboxMolecule } from 'components/molecules/Checkbox.molecule'
import LoaderAtom from 'components/atoms/Loader.atom'

const TemperaturePage = () => {
  const { t, formConfig, loading, actions } = useTemperature()

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}

      <ToolbarMolecule
        title={t('receipt-temperature.title')}
        actionIcon={Icons.CloseIcon}
        onAction={actions.onClosePage}
      />

      <PageContentAtom $gap={24}>
        <AtlasBodySubtitle>
          {t('receipt-temperature.capture-temp')}
        </AtlasBodySubtitle>

        <FormikProvider value={formConfig}>
          <Form
            onSubmit={formConfig.handleSubmit}
            style={{ gap: '16px', display: 'flex', flexDirection: 'column' }}
          >
            <FormikTextFieldOrganism
              type={'number'}
              name={'temperature'}
              label={t('receipt-temperature.temp-label')}
              formConfig={formConfig}
            />

            <CheckboxMolecule
              name={'outOfRangeApproved'}
              value={formConfig.values.outOfRangeApproved.toString()}
              checked={formConfig.values.outOfRangeApproved}
              label={t('receipt-temperature.out-of-range')}
              onChange={formConfig.handleChange}
            />

            <BottomGroupMolecule>
              <PrimaryButtonAtom type="submit" disabled={!formConfig.isValid}>
                {t('commons.continue')}
              </PrimaryButtonAtom>
            </BottomGroupMolecule>
          </Form>
        </FormikProvider>
      </PageContentAtom>
    </>
  )
}

export default TemperaturePage
