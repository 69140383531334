import { ScanResponse } from "client/scanner/scanner.types";
import { CommonLocationInfo } from "client/types";

export function toCommonLocationInfoFromScanResponse(scanResponse: ScanResponse): CommonLocationInfo {
  return {
    locationId: scanResponse.location?.legacyId ?? "",
    locationName: scanResponse.location?.name ?? "",
    locationType: scanResponse.location?.type ?? "",
    containerId: scanResponse.container?.legacyId,
    containerName: scanResponse.container?.name,
    containersInLocation: Number(scanResponse.totalContainers)
  };
}