import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { ReverseLogisticsPage } from "./ReverseLogistics.page";
import { ScanSuppliesPage } from "./v2/scan-supplies/ScanSupplies.page";

const ReverseLogisticWrapper = () => {
  const featureFlag = useFeatureIsOn("op_utilities_front_reverse_logistics_v2");
  
  return featureFlag ?  <ScanSuppliesPage/> : <ReverseLogisticsPage/>
}

export default ReverseLogisticWrapper;