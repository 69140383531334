import { useNavigate } from 'react-router-dom'

export const ALERTS_REGISTER_RESTOCK_URL = '/alerts/:source/:ean'

export const useAlertsRegisterRestockNavigator = () => {
  const navigate = useNavigate()
  return {
    go: (source: string, ean: string) => {
      navigate(ALERTS_REGISTER_RESTOCK_URL
        .replace(':source', source)
        .replace(':ean', ean))
    }
  }
}
