import { AtlasBodyMain, AtlasColor, CardComponent } from "@justomx/atlas-components"
import { FlexColumnAtom } from "components/atoms/FlexColumn.atom"
import { FlexRowAtom } from "components/atoms/FlexRow.atom"
import TareIcon from '../../../assets/images/Utilities/Illustration_tara.svg'
import { useTranslation } from "react-i18next"

interface HistoryListProps {
  onClick: () => void
}

const HistoryList = ({onClick}: HistoryListProps) => {
  const { t } = useTranslation('global')
  return <CardComponent $variant='highlight' style={{ marginBottom: '12px'}} onClick={onClick}>
  <FlexRowAtom style={{ alignItems: 'center', gap: 8}}>
    <img src={TareIcon} />
    <FlexColumnAtom $gap={4}>
      <AtlasBodyMain color={AtlasColor.Neutral00}>
        {t('reverse-logistics.scan-supplies.view-last-list.title')}
      </AtlasBodyMain>
      <AtlasBodyMain color={AtlasColor.Neutral00}>
        {t('reverse-logistics.scan-supplies.view-last-list.subtitle')}
      </AtlasBodyMain>
    </FlexColumnAtom>
  </FlexRowAtom>
</CardComponent>
}

export default HistoryList