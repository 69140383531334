import { type ReverseLogisticProductInfo } from 'types/model'

function validateStock (product: ReverseLogisticProductInfo, value: number) {
  return value > product.pendingQuantity 
}

export function validateRegister(scanned: string, quantity: number, selectedProduct: ReverseLogisticProductInfo, suggestedLocations: string[], t: any) {
  
  if (!quantity || quantity === 0) {
    throw new Error(t('reverse-logistics.register.no-quantity'));
  }

  if (validateStock(selectedProduct, quantity)) {
    throw new Error(t('reverse-logistics.register.stock-error'));
  }

  if (!suggestedLocations.includes(scanned)) {
    throw new Error(t('reverse-logistics.register.invalid-location'));
  }

  return
}