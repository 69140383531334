import { useNavigate } from 'react-router-dom'

export const useGoToScanOrder = () => {
  const navigate = useNavigate()

  return {
    go: (receiptType: string) => {
      navigate(SCAN_ORDER_URL.replace(':receiptType', receiptType))
    }
  }
}

export const SCAN_ORDER_URL = '/receipt/scan-order/:receiptType'
