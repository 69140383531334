import { useNavigate } from 'react-router-dom'
import { useRequiredParams } from 'hooks/params.hook'

export const useGoToProductLots = () => {
  const navigate = useNavigate()
  const { receiptType } = useRequiredParams('receiptType')

  return {
    go: (
      receiptId: string,
      orderId: string,
      productId: string,
      editionMode = false
    ) => {
      navigate(
        `${PRODUCT_LOTS_URL.replace(':receiptType', receiptType)
          .replace(':receiptId', receiptId)
          .replace(':orderId', orderId)
          .replace(':productId', productId)}?editionMode=${editionMode}`
      )
    }
  }
}

export const PRODUCT_LOTS_URL =
  '/receipt/:receiptType/:receiptId/order/:orderId/lots/:productId'
