import { useTranslation } from 'react-i18next'
import {
  BottomGroupMolecule,
  Filler,
  Icons,
  PageContentAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { ScannerMolecule } from 'components/molecules/Scanner.molecule'
import LoaderAtom from 'components/atoms/Loader.atom'
import { useScanOrder } from './ScanOrder.hooks'

const ScanOrderPage = () => {
  const { t } = useTranslation('global')
  const { actions, loading, type } = useScanOrder()

  if (loading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return (
    <>
      <ToolbarMolecule
        title={t('receipt-scan-order.title')}
        actionIcon={Icons.CloseIcon}
        onAction={actions.goBack}
      />

      <Filler flexGrow={2} />
      <PageContentAtom>
        <ScannerMolecule
          label={t('receipt-scan-order.scan-label', {
            type: t(`receipt-type.${type}`).toLocaleLowerCase()
          })}
          onScan={actions.getOrderInfo}
        />
      </PageContentAtom>
      <Filler />

      <BottomGroupMolecule>
        <TextBoxMolecule
          type="number"
          label={t('receipt-scan-order.textbox')}
          onNewValue={actions.getOrderInfo}
        />
      </BottomGroupMolecule>
    </>
  )
}

export default ScanOrderPage
