import { useTranslation } from 'react-i18next'
import { useScanDestination } from './ScanDestination.hook'
import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon, Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import LoaderAtom from 'components/atoms/Loader.atom'

export const ScanDestinationPage = () => {
  const { actions, loading } = useScanDestination()
  const { t } = useTranslation('global')

  if (loading) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return <>
    <ToolbarMolecule
      title={t('inventory-waste.scan-destination.toolbar-title')}
      actionIcon={Icons.ArrowBack}
      onAction={actions.goBack}
    />
    <PageContentAtom $centered>
      <Filler/>
      <Icon src={Icons.ScanIcon}/>
      <AtlasBodyTitle>{t('inventory-waste.scan-destination.scan')}</AtlasBodyTitle>
      <AtlasH1 style={{ textAlign: 'center' }}>{t('inventory-waste.scan-destination.location')}</AtlasH1>
      <AtlasBodyTitle>{t('inventory-waste.scan-destination.use-your-scanner')}</AtlasBodyTitle>
      <Filler/>
    </PageContentAtom>
  </>
}
