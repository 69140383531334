import { AtlasBodyMain, AtlasColor, AtlasH1, CardComponent } from '@justomx/atlas-components'
import { formatNumber, formatWeight } from '../../utils/product'
import { useTranslation } from 'react-i18next'

interface Props {
  isWeighable: boolean
  expectedStock: number
  stock: number
}

export function ExpectedRestockMolecule ({ isWeighable, expectedStock, stock }: Props) {
  const { t } = useTranslation('global')

  return (
    <CardComponent style={{
      backgroundColor: '#686868',
      textAlign: 'center'
    }}>
      <AtlasBodyMain style={{ color: AtlasColor.Neutral20 }}>{t('alerts.register.toolbar-title')}</AtlasBodyMain>
      <AtlasH1>{isWeighable ? formatWeight(expectedStock, 2) : `${expectedStock} uds`}</AtlasH1>
      <AtlasBodyMain style={{ color: AtlasColor.Neutral20 }}>{t('alerts.register.stock-in-location', {
        stock: formatNumber(stock),
        uom: isWeighable ? 'g' : 'uds'
      })}</AtlasBodyMain>
  </CardComponent>
  )
}
