import {ContainerResponse, LocationResponse} from "../../../client/scanner/scanner.types";
import {useEffect, useState} from "react";
import {getInventoryProductsClient} from "../../../client/utilities.client";
import {onErrorToast} from "../../../onErrorToast.util";
import { LocationAndContainerUniversalInfo } from "../universal-search.types";
import { useAtom } from "jotai";
import { universalSearchContainerAtomReader, universalSearchLocationAtomReader } from "../universal-search.state";
import { useNavigate } from "react-router-dom";

export function useUniversalSearchContainer() {
  const [location] = useAtom(universalSearchLocationAtomReader)
  const [container] = useAtom(universalSearchContainerAtomReader)
  const [fulfilledLocation, setFulfilledLocation] = useState<LocationAndContainerUniversalInfo>({
    inventoryProducts: [],
    loading: false,
    error: null
  })
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    getInventoryProductsClient(location.legacyId, container.legacyId, false)
    .then(e => setFulfilledLocation(x => ({...x, inventoryProducts: e})))
    .catch(e => {
      onErrorToast("Error al obtener productos en contenedor: " + e.message)
      setFulfilledLocation(x => ({...x, error: e}))
    })
    .finally(() => {
      setFulfilledLocation(x => ({...x, loading: false}))
      setLoading(false)
    })
  }, [])
  
  return { 
    container,
    fulfilledLocation,
    loading,
    goBack: () => navigate(-1)
  }
}
