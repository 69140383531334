import { useNavigate } from 'react-router-dom'

export const ALERTS_HOME_PAGE = '/alerts'

export const useAlertsPageNavigator = () => {
  const navigate = useNavigate()
  return {
    go: () => {
      navigate(ALERTS_HOME_PAGE)
    }
  }
}
