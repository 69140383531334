import { atom } from 'jotai'
import { createReader } from '../../utils/atomReader'
import { ScannedResponse, ScanResponse, type ContainerResponse, type LocationResponse, type ProductResponse } from 'client/scanner/scanner.types'

export const universalSearchScanAtom = atom<ScanResponse | null>(null)
export const universalSearchScanAtomReader = createReader(universalSearchScanAtom)

export const universalSearchProductAtom = atom<ProductResponse | null>(null)
export const universalSearchProductAtomReader = createReader(universalSearchProductAtom)

export const universalSearchLocationAtom = atom<LocationResponse | null>(null)
export const universalSearchLocationAtomReader = createReader(universalSearchLocationAtom)

export const universalSearchContainerAtom = atom<ContainerResponse | null>(null)
export const universalSearchContainerAtomReader = createReader(universalSearchContainerAtom)
