import { type RouteObject } from 'react-router-dom'
import { FAST_TRASLATION_ROUTE } from './FastTraslation.navigator'
import { FastTraslationPage } from './FastTraslation.page'
import { SCAN_PRODUCT_NAVIGATOR_ROUTE } from './scan-product/ScanProduct.navigator'
import { ScanProductPage } from './scan-product/ScanProduct.page'
import { FAST_TRASLATION_SET_QUANTITY } from './set-quantity/FastTraslationQuantity.navigator'
import { FastTraslationQuantityPage } from './set-quantity/FastTraslationQuantity.page'
import { FastTraslationScanDestinationPage } from './scan-destination/FastTraslationScanDestination.page'
import { FAST_TRASLATION_SCAN_DESTINATION } from './scan-destination/FastTraslationScanDestination.navigator'
import { FAST_TRANSFER_SET_WEIGHT } from './set-weight/FastTraslationWeight.navigator'
import { FastTransferWeightPage } from './set-weight/FastTraslationWeight.page'

export const fastTraslationRoutes: RouteObject[] = [
  {
    id: '(fast-traslation) Main Page - Scan LPN',
    path: FAST_TRASLATION_ROUTE,
    element: <FastTraslationPage/>
  },
  {
    id: '(fast-traslation) Scan Product',
    path: SCAN_PRODUCT_NAVIGATOR_ROUTE,
    element: <ScanProductPage/>
  },
  {
    id: '(fast-traslation) Traslate product',
    path: FAST_TRASLATION_SET_QUANTITY,
    element: <FastTraslationQuantityPage/>
  },
  {
    id: '(fast-traslation) Traslate product weighable',
    path: FAST_TRANSFER_SET_WEIGHT,
    element: <FastTransferWeightPage/>
  },
  {
    id: '(fast-traslation) Scan destination & move product',
    path: FAST_TRASLATION_SCAN_DESTINATION,
    element: <FastTraslationScanDestinationPage/>
  }
]
