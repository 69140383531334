import { atomWithStorage } from 'jotai/utils'
import { atom } from 'jotai'
import { InventoryProduct, type LocationOrContainer } from '../../types/model'
import { createReader } from '../../utils/atomReader'

export const traslationOriginInfoAtom = atomWithStorage<LocationOrContainer | null>(
  'traslationOriginLocationInfo',
  null
)

export const traslationOriginInfoReader =
  createReader(traslationOriginInfoAtom)

export const traslationDestinationInfoAtom = atomWithStorage<LocationOrContainer | null>(
  'traslationDestinationLocationInfo',
  null
)

export const traslationDestinationInfoReader =
  createReader(traslationDestinationInfoAtom)


export const selectedInventoryProductsAtom = atom<InventoryProduct[]>([])
export const selectedInventoryProductsReader = createReader(selectedInventoryProductsAtom)

