import { useNavigate } from 'react-router-dom'

export const REVERSE_LOGISTICS_SCAN_SUPPLIES_URL = '/reverse-logistics/scan-supplies'

export const useScanSuppliesNavigator = () => {
  const navigate = useNavigate()
  return {
    go: () => {
      navigate(REVERSE_LOGISTICS_SCAN_SUPPLIES_URL)
    }
  }
}
