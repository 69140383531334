import { useLoaderData } from 'react-router-dom'
import { isInventoryProduct, type InventoryProduct } from '../types/model'
import {isObject} from "../internal-types/IsObject";


type HasInventoryProduct = {
  inventoryProduct: InventoryProduct
}
const hasInventoryProduct = (data: unknown): data is HasInventoryProduct => {
  return isObject(data) && 'inventoryProduct' in data && isInventoryProduct(data.inventoryProduct);
}

export function useInventoryProduct (): InventoryProduct {
  const data = useLoaderData()
  if (hasInventoryProduct(data)) {
    return data.inventoryProduct;
  }

  throw new Error('Received data is not of type InventoryProduct');
}
