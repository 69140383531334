import { useCreateProduct } from './CreateProduct.hooks'
import { ProductCardMolecule } from '../../../components/molecules/ProductCard.molecule'
import {
  CreateInventoryProductOrganism,
  type InventoryAttributes
} from '../../../components/organisms/CreateInventoryProduct.organism'
import { Filler, Icons, PageContentAtom, PrimaryButtonAtom, ToolbarMolecule } from '@justomx/atlas-components'
import { Product } from 'types/model'

export const CreateProductPage = () => {
  const {
    actions,
    value,
    locationAndContainer,
    canConfirm,
    loading
  } = useCreateProduct()

  const onChange = (attribute: InventoryAttributes, value: string) => {
    switch (attribute) {
      case 'quantity':
      { actions.setQuantity(Number(value)); return }
      case 'lot':
      { actions.setLot(value.trim()); return }
      case 'expiration':
      { actions.setExpiration(value.trim()) }
    }
  }

  const tmpProduct: Product = {
    productId: value.legacyId,
    productName: value.name,
    productSku: value.sku,
    productEan: value.ean,
    productImage: value.image,
    productZone: value.zone as string,
    stock: value.stock
  }

  return <>
    <ToolbarMolecule title='Productos'
                     actionIcon={Icons.ArrowBack}
                     onAction={actions.goBack}/>
    <PageContentAtom>
      <ProductCardMolecule product={tmpProduct}/>
      <CreateInventoryProductOrganism
        location={locationAndContainer.location?.name as string}
        onChange={onChange}
      />
      <Filler/>
      <PrimaryButtonAtom
          disabled={loading || canConfirm.disabled }
          onClick={actions.confirm}
      >
        Confirmar
      </PrimaryButtonAtom>
    </PageContentAtom>
  </>
}
