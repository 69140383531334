import { useEffect, useState } from 'react'
import { type IsLoadable, NoneObject, LoadedObject } from 'utils/async-types'
import _ from 'lodash'
import { getContainers } from 'client/utilities.client'
import { LocationAndContainer, LocationOrContainer } from 'types/model'
import { useAtom } from 'jotai'
import { traslationOriginInfoAtom } from '../Traslation.state'
import { useGoToMoveOptions } from '../move-options/MoveOptions.navigator'
import { useLoaderData, useNavigate} from 'react-router-dom'
import { useInventoryProducts } from 'hooks/inventoryProducts.hooks'
import { useScanner } from 'hooks/scanner.hook'
import { MOVE_PRODUCT_LIST_URL } from '../move-product-list/MoveInventoryProductList.navigator'
import { getContainerFromScan } from 'client/scanner/scanner.client'
import { manageError } from 'utils/errors/error.handler'
import { useTranslation } from 'react-i18next'

export const useContainers = (onError: (str: string) => void) => {
  const [isLoadingPage, setLoading] = useState<boolean>(false)
  const [origin, setOrigin] = useAtom(traslationOriginInfoAtom)
  const moveOptions = useGoToMoveOptions()
  const navigate = useNavigate()
  const [containers, setContainers] = useState<IsLoadable<Array<Required<LocationAndContainer>>>>(NoneObject)
  const data = useLoaderData() as any
  const looseProducts = useInventoryProducts(data)
  const { t } = useTranslation('global')
  
  const { keys, getScan } = useScanner()

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setLoading(true)
      const scannedContainer = containers.value.filter(e => e.containerName === scanned)
      if (_.isEmpty(scannedContainer)) {
        onError(t('traslation.choose-container.not-found'))
        setLoading(false)
        return
      }
      else{
        getContainerFromScan(scanned)
        .then(origin => {
          setOrigin(origin as LocationOrContainer)
          moveOptions.go(origin as LocationOrContainer)
          setLoading(false)
        })
        .catch(manageError(`ScanOrigin.hooks.ts#useEffect ${scanned}`))
      }
    }
  }, [keys])

  useEffect(() => {
    setLoading(true)
    getContainers(origin?.location.legacyId as string)
      .then(e => LoadedObject(e))
      .then(setContainers)
      .catch(e => {
        console.error(e)
        onError(e.message as string)
      })
      setLoading(false)
  }, [])

  return {
    origin,
    containers,
    looseProducts,
    isLoadingPage,
    actions: {
      back: () => { navigate(-1) },
      goToMoveProducts: () => {
        navigate((MOVE_PRODUCT_LIST_URL + '?looseProducts=true').replace(':origin', origin?.location.legacyId as string).replace(':container', origin?.container?.legacyId as string))
      }
    }
  }
}
