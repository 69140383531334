import {useTranslation} from "react-i18next";
import {AtlasCaption, PageContentAtom, ToolbarMolecule, Icons, AtlasBodySubtitle, AtlasH3, Divider, CardComponent} from "@justomx/atlas-components";
import {ProductCardMolecule} from "../../../components/molecules/ProductCard.molecule";
import LoaderAtom from "../../../components/atoms/Loader.atom";
import { LocationAndContainerWithStock } from "../components/location-and-container-with-stock.component";
import { useUniversalSearchProduct } from "./universal-search-product.hook";
import { ProductLocationDetail } from "client/products/products.types";
import { AlertLocation } from "client/alerts/alerts.types";
import ProductInfo from "../components/product-info.component";
import _ from "lodash";

interface LocationSectionProps {
  title: string;
  items: ProductLocationDetail[] | AlertLocation[];
  showContainer: boolean;
  emptyMessage: string;
}

const LocationSection: React.FC<LocationSectionProps> = ({ title, items, showContainer, emptyMessage }) => {
  return (
    <>
      <AtlasH3>{title}</AtlasH3>
      {items.length > 0 ? (
        items.map((item) => (
          <LocationAndContainerWithStock item={item} showContainer={showContainer} key={item.locationId + '-' + item.containerId} />
        ))
      ) : (
        <AtlasCaption>{emptyMessage}</AtlasCaption>
      )}
    </>
  );
};


export const UniversalSearchProductPage = () => {
  const {product, fulfilledProduct, productRules, loading, goBack} = useUniversalSearchProduct()
  const {t} = useTranslation('global')

  const rulesLocations = productRules?.data.flatMap(e => 
    e.locations.map(location => ({
      ...location,
      stock: location.capacity,
    }))
  );

  const locationTypesMap = {
    Storage: t('universal-search.storage'),
    PreStorage: t('universal-search.prestorage'),
    Picking: t('universal-search.picking'),
  }

  return <>
    {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
    <ToolbarMolecule 
      title={t('universal-search.product.toolbar-title')}
      actionIcon={Icons.ArrowBack}
      onAction={goBack}
    />
    <PageContentAtom>
    <AtlasBodySubtitle>{t('universal-search.product.scanned-product')}</AtlasBodySubtitle>
    <CardComponent>
      <ProductInfo product={product}/>
    </CardComponent>
    <LocationSection
      title={t('universal-search.restocking-rules')}
      items={rulesLocations || []}
      showContainer={false}
      emptyMessage={t('universal-search.product.no-rules')}
    />
    <Divider/>
    <LocationSection
      title={_.capitalize(t('universal-search.picking'))}
      items={fulfilledProduct?.picking || []}
      showContainer={false}
      emptyMessage={t('universal-search.product.no-inventory', {type: locationTypesMap.Picking})}
    />
    <Divider />
    <LocationSection
      title={_.capitalize(t('universal-search.prestorage'))}
      items={fulfilledProduct?.preStorage || []}
      showContainer={true}
      emptyMessage={t('universal-search.product.no-inventory', {type: locationTypesMap.PreStorage})}
    />
    <Divider />
    <LocationSection
      title={_.capitalize(t('universal-search.storage'))}
      items={fulfilledProduct?.storage || []}
      showContainer={true}
      emptyMessage={t('universal-search.product.no-inventory', {type: locationTypesMap.Storage})}
    />
  </PageContentAtom>
  </>
}
