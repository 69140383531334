import { onErrorToast } from '../../onErrorToast.util'
import { useContainerAdministration } from './ContainerAdministration.hook'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasBodyTitle,
  AtlasListAtom,
  BottomGroupMolecule,
  CardComponent,
  Filler,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  StackAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { Global } from '../../utils/global'
import { LocationInfo } from '../../types/model'
import { LocationAndContainerMolecule } from '../../components/molecules/v2/LocationAndContainer.molecule'
import { isError, isLoading, isNone } from '../../utils/async-types'
import LoaderAtom from '../../components/atoms/Loader.atom'
import { MyFiller } from '../fast-traslation/scan-product/ScanProduct.page'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { containerAdministrationLastDropState } from './ContainerAdministration.state'

interface ContainerCardProps {
  container: Required<LocationInfo>
}

export const ContainerCard = ({ container }: ContainerCardProps) => {
  return (
    <CardComponent>
      <StackAtom>
        <LocationAndContainerMolecule data={container}/>
      </StackAtom>
    </CardComponent>
  )
}
export const ContainerAdministrationPage = () => {
  const { containersInStorage, actions, showLoading } = useContainerAdministration(onErrorToast)
  const [lastDropped] = useAtom(containerAdministrationLastDropState)
  const { t } = useTranslation('global')

  if (showLoading || isLoading(containersInStorage) || isNone(containersInStorage)) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  if (isError(containersInStorage)) {
    return (
      <>
          <ToolbarMolecule
              title={t('container-management.toolbar-title')}
              actionIcon={Icons.IcMenuIcon}
              onAction={Global.WV.openMenu}/>
          <PageContentAtom>
              <AtlasBodyTitle>{t('container-management.default-error')}</AtlasBodyTitle>
              <BottomGroupMolecule>
                  <PrimaryButtonAtom onClick={actions.lift}>
                      {t('container-management.btn-upload-container')}
                  </PrimaryButtonAtom>
              </BottomGroupMolecule>
          </PageContentAtom>
      </>
    )
  }

  const alerts = containersInStorage.value.filter(c => c.container !== lastDropped)

  return (
    <>
        <ToolbarMolecule title={t('container-management.toolbar-title')}
                         actionIcon={Icons.IcMenuIcon}
                         onAction={Global.WV.openMenu}/>
        <PageContentAtom>
          <AtlasBodySubtitle>{t("container-management.instructions")}</AtlasBodySubtitle>
          <AtlasListAtom>
            <li><AtlasBodyMain display='contents'>{t('container-management.lower-instructions')}</AtlasBodyMain></li>
            <li><AtlasBodyMain display='contents'>{t('container-management.lift-instructions')}</AtlasBodyMain></li>
          </AtlasListAtom>
          {alerts.map((e) => (
            <ContainerCard key={e.location}
              container={e} 
            />
          ))}
          <Filler/>
          <MyFiller/>
          <BottomGroupMolecule>
              <PrimaryButtonAtom onClick={actions.lift}>
                  {t('container-management.btn-upload-container')}
              </PrimaryButtonAtom>
          </BottomGroupMolecule>
        </PageContentAtom>
    </>
  )
}
