import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { ReverseLogisticsRegisterPage } from "./register-product-quantity/RegisterProductQuantity.page";
import { ReverseLogisticsRegisterPage as ReverseLogisticsRegisterPageV2} from "./v2/register-product-quantity/RegisterProductQuantity.page";

const RegisterProductQuantityWrapper = () => {
  const featureFlag = useFeatureIsOn("op_utilities_front_reverse_logistics_v2");
  
  return featureFlag ?  <ReverseLogisticsRegisterPageV2/> : <ReverseLogisticsRegisterPage/>
}

export default RegisterProductQuantityWrapper;