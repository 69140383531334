import { useLoaderData } from 'react-router-dom'

import { useGoToPickingList } from './PickingList/PickingList.navigator'
import type { OutputOrderData } from './types/InventoryTransfer.types'

export const useInventoryTransfer = () => {
  const pickingList = useGoToPickingList()
  const loaderData = useLoaderData() as any

  const onSelectOrder = (orderData: OutputOrderData) => {
    pickingList.go(orderData.order, orderData._id)
  }

  return { actions: { onSelectOrder }, loaderData }
}
