import { getCommonHeaders } from 'client/utilities.client-helper'
import { getLogicalContainerStorage, type LocationOrContainer } from '../../../types/model'
import { MOVE_CONTAINER, MOVE_PRODUCT, isTraslationFlow } from '../traslation.flow'

interface ValidateMovementProp {
  origin: LocationOrContainer
  destination: LocationOrContainer
  flow: string | null
  valueScanned: string
}

const isLPN = /^[A-Z]{2,3}-(LPN|11[1-5])-[0-9]{8}$/

function generalValidations (prop: ValidateMovementProp) {
  const {
    flow,
    valueScanned,
    destination,
    origin
  } = prop

  const headers = getCommonHeaders()
  const warehouse = headers['x-justo-warehouse']

  if (destination.location.name.includes('-RE-')) {
    throw Error('O-1: No se puede escanear una ubicación o contenedor en ubicación de recibo')
  }

  if (destination.location.type === 'Picking') {
    if (origin.location.id === destination.location.id) {
      throw Error('O-48: No se puede escanear la misma ubicación de picking de origen')
    }
  } else {
    if (origin.container?.id === destination.container?.id && origin.location.id === destination.location.id) {
      throw Error('O-48: No se puede escanear en destino el mismo contenedor de origen')
    }
  }

  if (flow === MOVE_PRODUCT) {
    if (
      destination.location.type !== 'Picking' &&
      destination.container?.id == null &&
      !getLogicalContainerStorage(warehouse).some(it => destination.location.name.includes(it))
    ) {
      throw Error(`O-31: No se puede mover productos a una ubicación de (${destination.location.type}) sin contenedor.`)
    }

    if (destination.location.type === 'Picking' && destination.container && destination.container.id && destination.container.name) {
      destination.container.id = undefined
      destination.container.name = undefined
    }
  }

  if (flow === MOVE_CONTAINER) {
    if (isLPN.test(valueScanned)) {
      throw new Error('O-42: No se puede trasladar un contenedor adentro de otro contenedor')
    }

    if (destination.location.type === 'Storage') {
      if (getLogicalContainerStorage(warehouse).some(it => destination.location.name.includes(it))) {
        throw Error('O-43: No se puede escanear una ubicación de almacenamiento de contenedores logicos')
      }
      if (destination.totalContainers && destination.totalContainers > 0) {
        throw Error('O-39: No se puede trasladar un contenedor a una ubicación de almacenamiento que ya tiene un contenedor.')
      }
    }

    if (destination.location.type === 'Picking') {
      throw new Error('O-41: No se puede trasladar un contenedor a una ubicación de picking')
    }
  }

  return prop
}

export const validateMovement = (prop: ValidateMovementProp) => {
  const { flow } = prop

  if (!isTraslationFlow(flow)) {
    throw new Error('Flow: ' + flow)
  }

  return generalValidations(prop)
}
