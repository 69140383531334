import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { ScanDestinationPage } from "./scan-destination/ScanDestination.page";
import { ScanDestinationPage as ScanDestinationPageV2} from "./v2/scan-destination/ScanDestination.page";

const ScanDestinationWrapper = () => {
  const featureFlag = useFeatureIsOn("op_utilities_front_reverse_logistics_v2");
  
  return featureFlag ?  <ScanDestinationPageV2/> : <ScanDestinationPage/>
}

export default ScanDestinationWrapper;