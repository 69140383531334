export const localeIncludes = (substring: string) => (string: string) => {
  const str = string.trim().normalize('NFKD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  const substr = substring.trim().normalize('NFKD').replace(/[\u0300-\u036f]/g, '').toLowerCase()

  return str.includes(substr)
}

export const formatString = (template: string, ...args: any[]): string => {
  return template.replace(/{([0-9]+)}/g, function (match, index) {
    return typeof args[index] === 'undefined' ? match : args[index]
  })
}
