import { AtlasBodyMain, AtlasColor, CardComponent, CardRow, Divider, StackAtom } from '@justomx/atlas-components'
import ProductInfo from 'components/atoms/v2/ProductInfo.atom'
import { useTranslation } from 'react-i18next'
import { type ProductInfoCard } from 'types/model'
import { formatNumber } from '../../../utils/product'

interface ProductCardProps {
  product: ProductInfoCard
  showQuantity?: boolean
  quantityTitle?: string
  showPriority?: boolean
  showIcon?: boolean
  showTitle?: boolean
  openDialog?: () => void
}

export function ProductCard ({
  product,
  showQuantity,
  quantityTitle,
  showPriority,
  showIcon,
  showTitle,
  openDialog
}: ProductCardProps) {
  const { t } = useTranslation('global')
  return <CardComponent>
    {showTitle &&
      <AtlasBodyMain style={{ color: AtlasColor.Neutral20 }}>{t('reverse-logistics.scanned-product')}</AtlasBodyMain>}
    <StackAtom style={{
      flexDirection: 'column',
      alignItems: 'baseline'
    }} gap={12}>
      <ProductInfo product={product} showPriority={showPriority} showWasteIcon={showIcon} openDialog={openDialog} />
      {showQuantity &&
        <>
          <Divider />
          <CardRow style={{
            width: '100%',
            justifyContent: 'space-between'
          }}>
            <AtlasBodyMain
              color={AtlasColor.Neutral40}>{quantityTitle || t('universal-search.quantity')}</AtlasBodyMain>
            <AtlasBodyMain>{formatNumber(product.stock)} {product.isWeighable ? t('uom.grams') : t('uom.units')}</AtlasBodyMain>
          </CardRow>
        </>
      }
    </StackAtom>
  </CardComponent>
}
