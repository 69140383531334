import { getOrder } from '../client/InventoryTransfer.client'
import type { OutputOrderData } from '../types/InventoryTransfer.types'

export const isOutputOrder = (data: unknown): data is OutputOrderData => {
  return (
    !!data &&
    typeof data === 'object' &&
    'order' in data &&
    'status' in data &&
    'updatedAt' in data &&
    'products' in data
  )
}

export const pickingListLoader = async ({ params }) => {
  return await getOrder(`${params.orderId}`)
}
