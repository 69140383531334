import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {toast} from 'react-toastify'
import {adjustInventoryClient} from '../InventoryFix.client'
import {Analytics} from "../../../analytics/analytics.events";
import {useInventoryProduct} from "../../../hooks/inventoryProduct.hook";
import {useCurrentLocationAndContainer} from "../../../hooks/currentLocation.hook";
import {useGoToInventoryProductList} from "../product-list/InventoryProductList.navigator";

function canConfirmFix (quantity: string, currentStock: number) {
  const strQuantity = quantity.toString().trim()
  const numberQuantity = Number(quantity)

  return strQuantity !== '' &&
  numberQuantity !== currentStock &&
  strQuantity === numberQuantity.toString()
    ? {}
    : { disabled: true } // TODO: Move this to a better place
}

export const useInventoryProductFix = () => {
  const navigate = useNavigate()
  const inventoryProduct = useInventoryProduct();
  const locationAndContainer = useCurrentLocationAndContainer();
  const inventoryProductListPage = useGoToInventoryProductList();
  const [quantity, setQuantity] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  return {
    canConfirm: canConfirmFix(quantity, inventoryProduct.stock),
    value: inventoryProduct,
    actions: {
      confirm: async () => { // Mover a servicio?
        try {
          setLoading(true)
          await adjustInventoryClient({
            productId: inventoryProduct.productId,
            locationId: locationAndContainer.location?.legacyId as string,
            containerId: locationAndContainer.container?.legacyId === 'null' || locationAndContainer.container?.legacyId === 'undefined' ? null : locationAndContainer.container?.legacyId,
            stock: Number(quantity)
          })

          Analytics.inventoryFix.adjustInventory({
            location: locationAndContainer.location?.legacyId as string,
            count: Number(quantity),
            ean: inventoryProduct.productEan
          })

          toast.success('Cantidad actualizada del SKU: ' + inventoryProduct.productSku + ' a ' + quantity)
          setTimeout(
            () => {
              setLoading(false)
              inventoryProductListPage.go(locationAndContainer)
            },
            2000
          )
        } catch (e) {
          console.error('On confirm inventory adjustment', e)
          toast.error(`Hubo un problema al ajustar el inventario. "${e.message ?? ''}`)
          setLoading(false)
        }
      },
      goBack: () => {
        navigate(-1)
      },
      setQuantity
    },
    location: locationAndContainer,
    loading
  }
}
