import type { TFunction } from 'i18next'
import * as Yup from 'yup'

export const getValidationSchema = (t: TFunction<'global', undefined>) => {
  return Yup.object({
    quantity: Yup.number()
      .required(t('validations.required'))
      .integer(t('validations.invalid-amount'))
      .min(0, t('validations.invalid-amount'))
  })
}
