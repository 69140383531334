import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import {Location, type LocationAndContainer} from '../../types/model'
import { LocationAndContainerMolecule } from '../molecules/LocationAndContainer.molecule'
import { AtlasBodyMain, AtlasCaption, AtlasColor, CardComponent, Icon, Icons } from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'

interface MoveSuggestionsOrganismProps {
  origin: LocationAndContainer
  originText?: string
  destinations: Location[]
  destinationText?: string
}

export const MoveSuggestionsOrganism = ({ origin, destinations, originText, destinationText }: MoveSuggestionsOrganismProps) => {
  const { t } = useTranslation('global')
  return (
    <CardComponent>
      <CardComponent style={{ gap: 8 }}>
        <AtlasCaption color={AtlasColor.Neutral40}>{originText ?? t('storage-scan-destination.origin')}</AtlasCaption>
        <LocationAndContainerMolecule location={origin} />
      </CardComponent>
      <Icon src={Icons.ArrowForward} size={18} />
      <CardComponent style={{ gap: 8 }} $variant='highlight'>
        <AtlasCaption color={AtlasColor.Neutral40}>{destinationText ?? t('to-storage-destination-location.suggested')}</AtlasCaption>
        {destinations.length > 0 ? destinations.map(
          destination=> <LocationAndContainerMolecule location={destination} />
        )
        :
        <AtlasCaption color={AtlasColor.Neutral40}>
          <FlexRowAtom style={{ gap: 8 }}>
            <Icon src={Icons.IcInformationIcon}/>
            <AtlasBodyMain>{t('storage-scan-destination.no-suggested-locations')}</AtlasBodyMain>
          </FlexRowAtom>
        </AtlasCaption>
        }
      </CardComponent>
    </CardComponent>
  )
}
