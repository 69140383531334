import {RouteObject} from "react-router-dom";
import {UniversalSearchPage} from "./universal-search.page";
import { UNIVERSAL_SEARCH_URL } from "./universal-search.navigator";
import { UNIVERSAL_SEARCH_PRODUCT_URL } from "./product/universal-search-product.navigator";
import { UniversalSearchProductPage } from "./product/universal-search-product.page";
import { UNIVERSAL_SEARCH_LOCATION_URL } from "./location/universal-search-location.navigator";
import { UniversalSearchLocationPage } from "./location/universal-search-location.page";
import { UniversalSearchContainerPage } from "./container/universal-search-container.page";
import { UNIVERSAL_SEARCH_CONTAINER_URL } from "./container/universal-search-container.navigator";

export const universalSearchRoutes: RouteObject[] = [
  {
    id: '(search) Universal Search',
    path: UNIVERSAL_SEARCH_URL,
    element: <UniversalSearchPage/>
  },
  {
    id: '(Product) Universal Search',
    path: UNIVERSAL_SEARCH_PRODUCT_URL,
    element: <UniversalSearchProductPage/>
  },
  {
    id: '(Location) Universal Search',
    path: UNIVERSAL_SEARCH_LOCATION_URL,
    element: <UniversalSearchLocationPage/>
  },
  {
    id: '(Container) Universal Search',
    path: UNIVERSAL_SEARCH_CONTAINER_URL,
    element: <UniversalSearchContainerPage/>
  }
]
