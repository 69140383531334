import { useNavigate } from 'react-router-dom'

export const INVENTORY_WASTE_SCAN_PRODUCT = '/inventory-waste/scan-product'

export const useInventoryWasteScanProduct = () => {
  const navigate = useNavigate()
  return {
    go: () => {
      navigate(INVENTORY_WASTE_SCAN_PRODUCT)
    }
  }
}
