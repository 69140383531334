import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { ReverseLogisticsRegisterWasteOrMissingPage } from "./register-waste-or-missing/RegisterWasteOrMissing.page";
import { ReverseLogisticsRegisterWasteOrMissingPage as ReverseLogisticsRegisterWasteOrMissingPageV2} from "./v2/register-waste-or-missing/RegisterWasteOrMissing.page";

const RegisterWasteOrMissingWrapper = () => {
  const featureFlag = useFeatureIsOn("op_utilities_front_reverse_logistics_v2");
  
  return featureFlag ?  <ReverseLogisticsRegisterWasteOrMissingPageV2/> : <ReverseLogisticsRegisterWasteOrMissingPage/>
}

export default RegisterWasteOrMissingWrapper;