import { useTranslation } from 'react-i18next'
import {
  AtlasBodySubtitle,
  Filler,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useRegisterWasteOrMissing } from './RegisterWasteOrMissing.hook'
import { ProductCard } from '../components/product-card.component'

export const ReverseLogisticsRegisterWasteOrMissingPage = () => {
  const { actions, flow, selectedProduct, quantity, setQuantity } = useRegisterWasteOrMissing()
  const { t } = useTranslation('global')

  return <>
    <ToolbarMolecule
      title={t('reverse-logistics.register-waste-or-missing.toolbar-title', { flow: flow === 'waste' ? 'merma' : 'faltante' })}
      actionIcon={Icons.CloseIcon}
      onAction={actions.goBack}
    />
    <PageContentAtom>
      <AtlasBodySubtitle>{t('reverse-logistics.register-waste-or-missing.write-quantity', { flow: flow === 'waste' ? 'a mermar' : 'faltante' })}</AtlasBodySubtitle>
      <TextBoxMolecule
        label={t('reverse-logistics.register-waste-or-missing.quantity')}
        type='number'
        value={String(quantity)}
        onChange={value => setQuantity(parseInt(value))}
      />
      <Filler/>
      <ProductCard product={selectedProduct} showTitle={true} showStock={true}/>
      <PrimaryButtonAtom onClick={actions.register} disabled={selectedProduct.pendingQuantity === 1 ? false : !quantity}>
        Registrar
      </PrimaryButtonAtom>
    </PageContentAtom>
  </>
}
