import { traslationDestinationInfoAtom, traslationDestinationInfoReader, traslationOriginInfoReader } from '../Traslation.state'
import { useAtom } from 'jotai/index'
import { inventoryProductReader } from '../../inventory-fix/InventoryFix.state'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGoToMoveInventoryItems } from '../move-all/MoveAll.navigator'
import { useGoToScanDestination } from '../scan-destination/ScanDestination.navigator'
import { MOVE_PRODUCT_LIST_URL } from '../move-product-list/MoveInventoryProductList.navigator'
import { MOVE_PRODUCT } from '../traslation.flow'

export const useMoveOneConfiguration = () => {
  const navigate = useNavigate()
  const moveInventoryProducts = useGoToMoveInventoryItems()
  const [origin] = useAtom(traslationOriginInfoReader)
  const [destination] = useAtom(traslationDestinationInfoAtom)
  const [inventoryProduct] = useAtom(inventoryProductReader)
  const [quantity, setQuantity] = useState<string>('')
  const scanDestination = useGoToScanDestination()

  const canConfirmQty = () => {
    const qtyInString = quantity.trim()
    if (/[0-9]+/.test(qtyInString)) {
      const qty = Number(qtyInString)
      return qty > 0 && qty <= inventoryProduct.stock
    }
    return false
  }

  return {
    origin,
    inventoryProduct,
    canConfirm: canConfirmQty(),
    actions: {
      confirm: () => {
        scanDestination.go(origin, MOVE_PRODUCT_LIST_URL, MOVE_PRODUCT, quantity)
      },
      goBack: () => { navigate(-1) },
      setQuantity
    }
  }
}
