import { useNavigate } from 'react-router-dom'

export const INVENTORY_WASTE_SCAN_ORIGIN = '/inventory-waste/scan-origin'

export const useInventoryWasteScanOrigin = () => {
  const navigate = useNavigate()
  return {
    go: () => {
      navigate(INVENTORY_WASTE_SCAN_ORIGIN)
    }
  }
}
