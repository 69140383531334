import {useAtom} from "jotai/index";
import {useEffect, useState} from 'react'
import {toast} from "react-toastify";
import {useScanner} from '../../../../hooks/scanner.hook'
import {validateDestination} from "./ScanDestination.validator";
import { useNavigate } from "react-router-dom";
import { registerWaste } from "../reverse-logistics.client";
import { reverseLogisticsScannedSuppliesAtom, reverseLogisticsSelectedProductAtom } from "../reverse-logistics.state";
import { useQueryParam } from "hooks/params.hook";
import { useReverseLogisticsNavigator } from "../ReverseLogistics.navigator";
import { useTranslation } from "react-i18next";
import { Analytics } from 'analytics/analytics.events'
import { formatWasteOrMissingEvent } from "../register-product-quantity/RegisterProductQuantity.helpers";
import { ReverseLogisticProductInfo, TareOrProduct } from "types/model";
import { useReverseLogisticsScanProduct } from "../scan-product/ScanProduct.navigator";
import { sortSuppliesByPendingQuantity, updateSupplyFromResponse } from "../reverse-logistics.helpers";

export const useScanDestination = () => {
  const { keys, getScan } = useScanner()
  const [selectedProduct, setSelectedProduct] = useAtom(reverseLogisticsSelectedProductAtom)
  const [scannedSupplies, setScannedSupplies] = useAtom(reverseLogisticsScannedSuppliesAtom)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const quantity = useQueryParam('quantity')
  const reverseLogistics = useReverseLogisticsNavigator()
  const scanProduct = useReverseLogisticsScanProduct()
  const { t } = useTranslation('global')

  const handleNavigation = (supplies: TareOrProduct[]) => {
    if (supplies[0].type === 'Product') {
      reverseLogistics.go();
    } else {
      const allProductsWithPendingQuantityZero = supplies.every(supply => 
        supply.products.every(product => product.pendingQuantity === 0)
      ); 
      if (allProductsWithPendingQuantityZero) {
        reverseLogistics.go();
      } else {
        scanProduct.go();
      }
    }
  };
  
  useEffect(() => {
    const scanned = getScan();
    if (scanned && !loading) {
      setLoading(true);
  
      if (validateDestination(scanned)) {
        try{
        let remainingQuantity = Number(quantity);
  
        // Filter supplies with selected product and sort by pending quantity
        const sortedSupplies = sortSuppliesByPendingQuantity(scannedSupplies,selectedProduct);
  
        // Create an array of promises for registering waste
        const promises = sortedSupplies
        .filter((supply) => remainingQuantity > 0 && supply.products.find((p) => p.ean === selectedProduct.ean)) 
        .map((supply) => {
          if (remainingQuantity <= 0) return null;
          const product = supply.products.find(p => p.ean === selectedProduct.ean);
          if (!product) return null;
  
          const pendingQuantity = product.pendingQuantity;
          const wasteQuantity = Math.min(remainingQuantity, pendingQuantity);

          remainingQuantity -= wasteQuantity;

          return registerWaste({
              id: supply.id,
              ean: selectedProduct.ean,
              quantity: wasteQuantity,
            })
            .then((res) => {
            setSelectedProduct(res.products.find(p => p.ean === selectedProduct.ean) as ReverseLogisticProductInfo);
  
            const eventData = formatWasteOrMissingEvent(selectedProduct, supply, quantity);
            Analytics.reverseLogistics.RegisterWaste(eventData);
  
            return res
          })
          .catch((error) => {
            throw new Error(`Error registering waste: ${error.message}`);
          });
        });
  
        // Wait for all registerWaste calls to complete
        Promise.all(promises.filter(Boolean))
          .then((responses) => {
            const updatedSupplies = scannedSupplies.map((supply) => {
              const response = responses.find((res) => res.id === supply.id);
              return response ? updateSupplyFromResponse(supply, response) : supply;
            });
            setScannedSupplies(updatedSupplies);
            handleNavigation(updatedSupplies);

            toast.success(t('reverse-logistics.register-waste-or-missing.success-waste', {
              quantity,
              unit: selectedProduct.isWeighable ? 'g' : Number(quantity) === 1 ? 'unidad' : 'unidades',
              product: selectedProduct.name,
            }));
          })
          .catch((err) => {
            toast.error(err.message);
          })
          .finally(() => {
            setLoading(false);
          });
        } catch (error) {
          toast.error(error.message);
          setLoading(false);
        }  
      } else {
        toast.error('No se puede escanear una ubicación que no sea de merma');
        setLoading(false);
      }
    }
  }, [keys]);
  

  return {
    actions: {
      goBack: () => { navigate(-1) },
    },
    loading
  }
}
