import { MoveOrganism } from '../../../components/organisms/Move.organism'
import { MoveInventoryProductListOrganism } from 'components/organisms/MoveInventoryProductList.organism'
import { useMoveAll } from './MoveAll.hook'
import {
  BottomGroupMolecule,
  BottomGroupSpaceAtom,
  Icons,
  PageContentAtom, PrimaryButtonAtom, SecondaryButtonAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'
import MoveOrganismWrapper from 'components/organisms/moveWrapper.organism'

export const MoveAllPage = () => {
  const {
    inventoryProducts,
    actions,
    origin,
    destination,
    loading
  } = useMoveAll()
  const { t } = useTranslation('global')

  const cancel = () => {
    actions.cancel()
  }

  const end = () => {
    actions.moveInventoryProducts({
      inventoryProducts: inventoryProducts.allInventoryProducts,
      origin,
      destination
    })
  }

  return <>
    <ToolbarMolecule title={t('move-all.toolbar-title')}
                     actionIcon={Icons.ArrowBack}
                     onAction={() => {
                       actions.back()
                     }}/>
    <PageContentAtom>
      <MoveOrganismWrapper origin={origin} destination={destination}/>
      <MoveInventoryProductListOrganism inventoryProducts={inventoryProducts.allInventoryProducts}/>
      <BottomGroupSpaceAtom />
    </PageContentAtom>
    <BottomGroupMolecule>
      <SecondaryButtonAtom onClick={cancel}>Cancelar</SecondaryButtonAtom>
      <PrimaryButtonAtom onClick={end} disabled={loading}>Finalizar</PrimaryButtonAtom>
    </BottomGroupMolecule>
  </>
}
