import { useNavigate } from 'react-router-dom'
import { type LocationOrContainer } from '../../../types/model'

export const useGoToMoveOptions = () => {
  const navigate = useNavigate()

  return {
    go: (origin: LocationOrContainer) => {
      navigate(MOVE_OPTIONS_URL
        .replace(':origin', origin.location.legacyId)
        .replace(':container', origin.container?.legacyId as string)
      )
    }
  }
}

export const MOVE_OPTIONS_URL = '/traslation/:origin/:container/choose'
