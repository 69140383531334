import { useTranslation } from 'react-i18next'
import {
  BottomGroupMolecule,
  Filler,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { ScannerMolecule } from 'components/molecules/Scanner.molecule'
import { useScanLocationData } from './ScanLocation.hooks'
import { ContainerCardMolecule } from 'components/molecules/ContainerCard.molecule'
import LoaderAtom from 'components/atoms/Loader.atom'

const ScanLocationPage = () => {
  const { t } = useTranslation('global')
  const { container, loading, actions } = useScanLocationData()

  if (!container) {
    return null
  }

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}

      <ToolbarMolecule
        title={t('receipt-scan-location.title')}
        actionIcon={Icons.ArrowBack}
        onAction={actions.goBack}
      />

      <Filler flexGrow={2} />
      <PageContentAtom>
        <ScannerMolecule
          label={t('receipt-scan-location.scan-label')}
          onScan={actions.onScan}
        />
      </PageContentAtom>
      <Filler />

      <BottomGroupMolecule>
        <ContainerCardMolecule container={container} />
      </BottomGroupMolecule>
    </>
  )
}

export default ScanLocationPage
