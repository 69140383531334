import { AtlasColor } from '@justomx/atlas-components'
import styled from 'styled-components'

export const DialogAtom = styled.dialog`
    font-family: Roboto, sans-serif;

    background-color: ${AtlasColor.PrimarySurface};
    color: ${AtlasColor.Neutral00};

    width: 90%;
    max-width: 280px;
    padding: 24px;

    border-radius: 16px;
    border: 0;

    position: absolute;
    inset: 0;
    margin: auto;
    animation: open 300ms forwards;

    &::backdrop {
        background-color: rgba(0,0,0,.7);
        height: 100vh;
    }

    &[open]::backdrop {
        animation: open 290ms forwards;
    }

    @keyframes open {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

export const DialogContentAtom = styled.div`
    position: relative;
    display: block;
    margin-bottom: 24px;
`

export const DialogFooterAtom = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
`

export const DialogButtonAtom = styled.button<{ $primary?: boolean }>`
    background: transparent;
    border: 0;
    outline: 0;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    font-weight: 500;
    padding: 8px;
    color: ${props => props.$primary ? AtlasColor.Primary00 : AtlasColor.Neutral00};
    transition: background 300ms ease-in;
    border-radius: .3em;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.$primary ? AtlasColor.Primary00 + '22' : '#FFFFFF11'};
    }
`
