{
  "loader": {
    "message": "Aguarde, por favor..."
  },
  "commons": {
    "continue": "Continuar",
    "cancel": "Cancelar",
    "finish": "Finalizar"
  },
  "validations": {
    "temp-range": "La temperatura no está dentro del rango permitido.",
    "generic-error": "Algo salió mal",
    "required": "Campo requerido",
    "invalid-amount": "Ingrese una cantidad válida",
    "greater-total-units": "La cantidad es mayor a la solicitada, escribe la cantidad correcta.",
    "expired-date": "La fecha ingresada está expirada",
    "alphanum": "Ingrese un valor alfanumérico",
    "single-select": "Seleccione una opción",
    "multi-select": "Seleccione al menos una opción",
    "invalid-date": "Formato de fecha inválido"
  },
  "home": {
    "btn-use-es": "Usar español",
    "btn-use-br": "Usar português",
    "link-transfer-inventory": "Traslado",
    "link-fix-inventory": "Corregir Inventario",
    "link-fast-transfer": "Traslado rápido",
    "link-container-management": "Administración de contenedores",
    "link-alerts": "Alertas de re-abasto",
    "storage-urls": "Almacenaje",
    "receipt-urls": "Recibo",
    "link-inventory-waste": "Merma de inventario",
    "link-inventory-transfer": "Transferencias de inventario",
    "link-search": "Buscador",
    "link-reverse-logistics": "Logística inversa"
  },
  "alerts": {
    "default-error": "Hubo un error al obtener los contenedores con alertas críticas",
    "toolbar-title": "Alertas de reabasto",
    "scan-product-error": "Hubo un error al obtener el producto:",
    "scan-container-error": "Hubo un error al obtener el contenedor:",
    "container-not-in-list": "Escaneaste una tarima que no está en el listado, ¿Quieres continuar?",
    "v2": {
      "toolbar-title": "Alertas de reabasto",
      "scan":  "Escanea la tarima para iniciar el reabasto:"
    },
    "scan-product": {
      "scan": "Escanea el producto a reabastecer",
      "alerts-in-container": "Alertas de la tarima",
      "toolbar-title": "Tarima {{container}}",
      "empty-list": "Sin alertas",
      "card-quantity-title": "Cantidad a reabastecer:",
      "tabs":{
        "Urgent": "Urgentes ({{counter}})",
        "Critical": "Críticas ({{counter}})",
        "Warning": "Preventivas ({{counter}})"
      },
      "product-not-in-container":{
        "title": "Reporte de producto",
        "body": "Si el producto no se encuentra en la tarima, selecciona Continuar. Recuerda revisar la tarima completa."
      },
      "error-not-in-list": "El producto {{product}}, no está registrado en el inventario de la tarima, agrégalo con Corregir inventario.",
       "error-no-products": "Esta tarima no tiene productos registrados, antes debes ingresar productos en el sistema."
    },
    "register": {
      "toolbar-title": "Reabastece",
      "instructions": "Instrucciones",
      "set-quantity": "Escribe la cantidad reabastecida.",
      "set-quantity-weighable": "Escanea o escribe la cantidad.",
      "scan-destination": "Después escanea la ubicación de picking.",
      "stock-in-location": "Cantidad en tarima: {{stock}} {{uom}}",
      "suggested-location": "Ubicaciones a reabastecer",
      "partial-qty-confirmation": {
        "title": "Resumen de reabasto",
        "body": "Llevas {{quantity}} {{unit}} de {{pendingQuantity}} {{unit}} solicitados. ¿Continuarás con el reabasto de este producto?"
      },
      "success": "Se ha reabastecido {{quantity}} {{uom}} de {{product}}.",
      "error-location-not-suggested": "Ubicación incorrecta. Deposita el producto en algunas de las ubicaciones sugeridas.",
      "stock-error": "La cantidad a reabastecer es mayor de la cantidad de inventario disponible en la tarima.",
      "quantity-error": "Cantidad no válida. Debes escribir una cantidad mayor a cero. ",
      "info-message": "Recuerda escribir una cantidad menor al inventario disponible de la tarima. "
    }
  },
  "container-management": {
    "default-error": "Hubo un error al obtener los contenedores con alertas críticas",
    "toolbar-title": "Montacargas",
    "btn-upload-container": "Subir tarima",
    "instructions": "Instrucciones",
    "lower-instructions": "Para bajar: Escanea la tarima",
    "lift-instructions": "Para subir: Selecciona subir tarima",
    "error-reading-container": "Ocurrió un error al escanear la tarima, inténtalo nuevamente."
  },
  "scan-to-lift": {
    "toolbar-title": "Escanea",
    "scan": "Escanea",
    "container": "Tarima",
    "use-your-scanner": "Usa tu escáner"
  },
  "scan-dest-to-lift": {
    "toolbar-title": "Escanea una ubicación de destino",
    "scan": "Escanea",
    "storage-location": "Ubicación de Almacenamiento",
    "use-your-scanner": "Usa tu escáner",
    "container-to-lift": "Contenedor a subir:",
    "no-container-in-location": "No hay contenedor en la ubicación {0} ({1})",
    "container-not-in-rack": "El contenedor {0} no se puede subir porque está en {1}"
  },
  "confirm-lift": {
    "toolbar-title": "Subir tarima",
    "origin-text": "Tarima a subir",
    "destination-text": "Ubicación de destino sugerida",
    "scan": "Escanea la ubicación de almacenaje",
    "success": "Se ha subido la tarima {{containerName}} a la ubicación {{locationName}}"
  },
  "lower-container": {
    "toolbar-title": "Bajar tarima",
    "origin-text": "Tarima a bajar",
    "destination-text": "Ubicación de destino sugerida",
    "scan": "Escanea la ubicación de destino",
    "success": "Se ha bajado la tarima {{containerName}} a la ubicación {{locationName}}"
  },
  "traslation":{
    "choose-container":{
      "not-found": "El contenedor escaneado no existe en la ubicación seleccionada"
    }
  },
  "fast-traslation": {
    "toolbar-title": "Escanea",
    "scan": "Escanea",
    "use-your-scanner": "Usa tu escáner"
  },
  "fast-scan-product": {
    "toolbar-title": "Re-abasto",
    "title-critical-alerts": "Con alertas críticas",
    "title-warning-alerts": "Con alertas preventivas",
    "title-no-alerts": "Sin alertas",
    "bottom-msg": "Escanea el producto que deseas reabastecer"
  },
  "fast-set-quantity": {
    "toolbar-title": "Escribe cantidad",
    "total-qty": "Cantidad total en inventario: {0}",
    "description": {
      "pre": "Hay",
      "high": "{0} unidades",
      "post": "en el contenedor {0}"
    },
    "label-restocking": "Reabastecer",
    "label-qty": "Cantidad",
    "btn-confirm": "Confirmar"
  },
  "fast-set-weight": {
    "toolbar-title": "Reabastece",
    "total-qty": "Cantidad total en inventario: {0}",
    "description": {
      "pre": "Hay",
      "post": "en la ubicación"
    },
    "label-restocking1": "Reabastecer",
    "label-restocking2": "Puedes reabastecer hasta",
    "label-qty": "Cantidad en gramos",
    "total-restocking": "Llevas escaneado {0}",
    "btn-confirm": "Confirmar",
    "btn-add": "Agregar",
    "error-01": "Error extrayendo el peso del ean {0}, puede ingresarlo de forma manual.",
    "error-02": "El producto {0} no coincide con el seleccionado",
    "dialog-delete": {
      "msg": "¿Quieres eliminar {0} de la cantidad reabastecida?",
      "btn-cancel": "Cancelar",
      "btn-confirm": "Eliminar"
    },
    "dialog-confirm": {
      "msg1": "Has reabastecido {0} de los {1} solicitados, ¿quieres finalizar con el re-abasto de este producto?",
      "msg2": "Quieres reabastecer {0} ¿quieres finalizar con el re-abasto de este producto? ",
      "btn-cancel": "Cancelar",
      "btn-confirm": "Finalizar"
    }
  },
  "fast-scan-dest": {
    "toolbar-title": "Escanea",
    "scan": "Escanea",
    "picking-location": "Ubicación de Picking",
    "use-your-scanner": "Usa tu escáner",
    "suggested-locations": "Ubicaciones sugeridas",
    "current-locations": "Ubicaciones actuales"
  },
  "product-containers": {
    "toolbar-title": "Detalle de producto",
    "no-containers": "No se encontraron existencias de este producto en almacenamiento, pre-almacenamiento ni en estanterías.",
    "no-picking": "No se encontraron existencias de este producto en los lugares de recolección.",
    "in-picking": "En picking",
    "in-storage": "En almacenamiento",
    "in-pre-storage": "En pre-almacenamiento",
    "in-rack": "En rack"
  },
  "search-product": {
    "toolbar-title": "Buscar producto",
    "scan": "Escanea",
    "product": "Producto",
    "use-your-scanner": "Usa tu escáner",
    "enter-ean": "Ingresa EAN"
  },
  "choose-container": {
    "toolbar-title": "Escoge contenedor",
    "containers-in-location": "Contenedores en ubicación:",
    "scan-container": "Escanea el contenedor para iniciar el traslado",
    "see-products": "Da tap para ver los productos del contenedor",
    "location-with-loose-product": "Ubicación con producto suelto",
    "loose-product": {
      "title": "Producto suelto",
      "items": ["Ubicación de picking", "Contenedor en almacenaje", "Contenedor en pre-almacenaje"]
    }
  },
  "move-all":{
    "toolbar-title": "Resumen del movimiento",
    "products-to-move": "Productos a mover",
    "product-qty": "Cantidad"
  },
  "scan-origin":{
    "toolbar-title": "Traslado de productos",
    "scan": "Escanea",
    "location": "Ubicación",
    "container": "Contenedor",
    "use-your-scanner": "Usa tu escáner",
    "insert-last-four-digits": "Ingresa últimos 4 dígitos del contenedor"
  },
  "move-products-selector":{
    "product":{
      "title": "Producto",
      "items": ["Pre-almacenaje", "Almacenaje", "Productos del contenedor a una ubicación de picking"]
    },
    "container":{
      "title": "Contenedor",
      "items": ["Ubicación de picking", "Contenedor en almacenaje", "Contenedor en pre-almacenaje"]
    },
    "loose-product":{
      "title": "Producto suelto",
      "items": ["Pre-almacenaje", "Almacenaje", "Productos del contenedor a una ubicación de picking"]
    }
  },
  "scan-destination":{
    "toolbar-title": "Escanea",
    "title": "Ubicación de destino",
    "scan": "Escanea",
    "use-your-scanner": "Usa tu escáner"
  },
  "product-info-restocking": {
    "total-in-origin": "Cantidad en origen: {0}",
    "to-restock": "Por reabastecer: {0}",
    "units": "unidades"
  },
  "to-storage": {
    "title": "Almacenaje",
    "description": "Escanee el contenedor que desea almacenar",
    "no-containers": "No se encontraron contenedores.",
    "default-error": "Hubo un error al obtener los contenedores."
  },
  "to-storage-container-description": {
    "title-critical-alerts": "Con alertas críticas",
    "title-warning-alerts": "Con alertas preventivas",
    "title-no-alerts": "Sin alertas",
    "continue": "Continuar"
  },
  "to-storage-destination-location": {
    "title": "Escanea",
    "scan": "Escanea",
    "location": "Ubicaciones de destino",
    "action": "Usa tu escáner",
    "suggested": "Ubicación de destino sugerida",
    "ok": "El contenedor se ha almacenado"
  },
  "inventory-product-card": {
    "count": "Cantidad total: {{value}}"
  },
  "inventory-product-count-molecule": {
    "description": {
      "pre": "Hay",
      "high": "{{value}} {{uom}}",
      "post": "en"
    }
  },
  "storage-home": {
    "invalid-scan": "El contenedor o ubicación escaneado no está disponible para almacenar: {{scanned}}"
  },
  "storage-container-detail" : {
    "bad-request":  "Hubo un error inesperado",
    "continue": "Continuar"
  },
  "storage-scan-destination" : {
    "scan":  "Escanea",
    "location":  "Ubicación de destino",
    "use-your-scanner":  "Usa tu scanner",
    "ok": "El contenedor {{container}} se ha almacenado en {{destination}} (origen: {{origin}})",
    "bad-request": "Hubo un error inesperado",
    "destination-no-storage": "La ubicacion {{destination}} no es una ubicación de almacenaje",
    "destination-groceries-validation": "No se pueden mover productos a una ubicación de abarrotes",
    "no-suggested-locations": "El producto no tiene ubicación sugerida porque no tiene ubicación de picking y/o inventario.",
    "origin": "Origen"
  },
  "uom": {
    "units": "Unidades",
    "grams": "Gramos"
  },
  "receipt": {
    "title": "Recibo de proveedores",
    "select-order": "Selecciona una orden:",
    "order-id": "Orden: {{orderId}}",
    "total-sku": "Num. SKU: {{totalSKU}}",
    "type-error": "Se esperaba un arreglo de órdenes en la data del loader",
    "provider": "Proveedor: {{provider}}",
    "sync": "Sincronizar",
    "no-data-title": "No tienes ninguna orden sincronizada",
    "no-data-help": "Por favor da tap al botón \"Sincronizar\""
  },
  "scanner-molecule": {
    "title": "Escanea",
    "use-scanner": "Usa tu escáner"
  },
  "receipt-order-detail": {
    "total-sku": "N.º de sku a recibir",
    "boxes-counter": "Cantidad solicitada: {{received}}/{{total}} unidades",
    "expected-kg": "Cantidad solicitada: {{received}}/{{total}} kilos",
    "end": "Finalizar",
    "pending": "Por recibir ({{counter}})",
    "finished": "Registrados ({{counter}})",
    "type-error": "Se esperaba una orden de compra en la data del loader",
    "scan-product": "Escanea el producto a recibir",
    "invalid-upc": "Escanee un UPC válido. Se escanéo {{upc}}",
    "nonexistent-upc": "El producto escaneado no está en la orden de compra actual",
    "registered-upc": "El producto escaneado ya ha sido registrado",
    "upc": "UPC: {{upc}}",
    "full-missing": "¿Quieres reportar este producto como faltante completo?",
    "undo-full-missing": "¿Quieres que el producto ya no sea un faltante completo?",
    "missing-error": "Error al marcar el producto como faltante total"
  },
  "receipt-scan-order": {
    "title": "Número de orden",
    "scan-label": "Documento de entrada {{type}}",
    "textbox": "Escribe el número de orden de compra",
    "invalid-input": "Escanee un número de orden válido. Se escaneó: {{orderNumber}}",
    "sync-error": "No se ha podido sincronizar la orden: {{orderNumber}}",
    "sync-success": "Orden sincronizada."
  },
  "receipt-scan-container": {
    "title": "Escanea",
    "scan-label": "Contenedor",
    "product": "Producto escaneado",
    "product-undefined": "Para escanear un contenedor primero debe escanear un producto",
    "invalid-input": "Escanee un LPN válido. Se escaneó: {{lpn}}",
    "displaced-container": "Este contenedor no está en posición de pre-almacenaje. Por favor escoja otro contenedor."
  },
  "receipt-product-lots": {
    "measure": "Unidad de medida: {{measure}} unidades por caja",
    "measure-kg": "Unidad de medida: {{measure}} gr por caja",
    "requested-boxes": "Cajas solicitadas",
    "requested-units": "Unidades solicitadas",
    "requested-quantity": "Cantidad solicitada",
    "add-lot": "Agregar lote",
    "end": "Finalizar",
    "type-error": "Se esperaba información de lotes en la data del loader",
    "counter": "{{counter}}/{{total}}",
    "counter-kg": "{{counter}} kg/{{total}} kg",
    "get-order-error": "Ocurrió un error al recuperar el detalle de la orden"
  },
  "receipt-lot-form": {
    "lot-number": "Lote: {{lotNumber}}",
    "lot": "Lote",
    "expiration": "Fecha de caducidad (dd/mm/aaaa)",
    "units": "Cantidad de unidades",
    "boxes": "Cantidad de cajas",
    "grams": "Cantidad en gramos",
    "temperature": "Temperatura (en grados)"
  },
  "receipt-missing-dialog": {
    "title": "Te falta recibir {{missing}} unidades ¿cómo las registrarás?",
    "title-kg": "Te falta recibir {{missing}} kilos ¿cómo los registrarás?",
    "title-box": "Te falta recibir {{missing}} cajas ¿cómo las registrarás?",
    "continue": "Continuar",
    "back": "Regresar",
    "reject": "Rechazo de producto",
    "partial-missing": "Reporte de faltante parcial"
  },
  "receipt-partial-missing": {
    "title": "Registra el faltante",
    "subtitle": "Escribe la cantidad que falta del producto",
    "total-missing": "Cantidad faltante",
    "total-kg": "Cantidad faltante en gramos",
    "missing-boxes": "Cajas faltantes",
    "register": "Registrar",
    "type-error": "Se esperaba información de totales en la data del loader",
    "missing-error": "Error almacenar el faltante parcial"
  },
  "receipt-rejection": {
    "title": "Registra el rechazo",
    "evidence": "Sube la evidencia",
    "max-evidences": "Máximo 6 fotos.",
    "form-title": "Escribe la cantidad, lote y selecciona el motivo",
    "units": "Unidades a rechazar",
    "units-kg": "Cantidad a rechazar en gramos",
    "boxes": "Cajas a rechazar",
    "lot": "Lote",
    "register": "Registrar",
    "upload-error": "Ocurrió un error al cargar la imagen, intente de nuevo",
    "create-error": "Ocurrió un error al tratar de crear el rechazo",
    "type-error": "Se esperaba la información de un rechazo en la data del loader",
    "missing-photo": "Debes tomar al menos una foto del rechazo",
    "reasons": {
      "NEAR_EXPIRATION": "Caducidad corta",
      "PRODUCT_DAMAGED": "Producto roto",
      "WITHOUT_LABELING": "Producto sin etiquedado",
      "MOLD": "Hongo",
      "NO_FUMIGATION_CERT": "Sin certificado de fumigación",
      "NO_APPOINTMENT": "Proveedor sin cita",
      "OUT_OF_HOURS": "Proveedor fuera de horario",
      "HARMFUL_FAUNA": "Fauna nociva",
      "EXTRA_PRODUCT": "Producto de mas",
      "BAD_UPC": "UPC no coincide",
      "PACKAGING_DAMAGE": "Empaque roto",
      "OUT_OF_RANGE_TEMP": "Temperatura fuera de rango",
      "DEHYDRATED": "Deshidratación",
      "DECAY": "Pudrición",
      "RIPENING": "Maduración",
      "MECHANICAL_DAMAGE": "Daño mecánico",
      "OXIDATION": "Oxidación",
      "SOGGINESS": "Acuosidad",
      "OVER_WEIGHT": "Exceso de peso"
    }
  },
  "receipt-containers": {
    "title": "Contenedores",
    "type-error": "Se esperaba un arreglo de contenedores en la data del loader",
    "toMove": "Por trasladar ({{counter}})",
    "moved": "Trasladado ({{counter}})",
    "container": "Contenedor: {{container}}",
    "skus": "Núm. de skus: {{totalSKU}}",
    "scan-label": "Escanea el contenedor a trasladar.",
    "finish-order": "Finalizar orden",
    "invalid-container": "El contenedor escaneado no existe en la orden actual",
    "location": "Ubicación escaneada: {{location}}",
    "finish-error": "Ocurrió un error al finalizar el recibo",
    "finish-success": "Recibo finalizado correctamente"
  },
  "receipt-summary": {
    "title": "Resumen",
    "type-error": "Se esperaba un arreglo con el resumen del recibo del producto en la data del loader",
    "lot": "Lote: {{lot}}",
    "amount": "Cantidad: {{amount}} unidades",
    "amount-kg": "Cantidad: {{amount}} kilos",
    "expiration": "Caducidad: {{expiration}}",
    "container": "Contenedor: {{container}}",
    "reasons": "Motivo: {{reasons}}",
    "temperature": "Temperatura (en grados): {{temperature}}",
    "missing-reason": "Motivo: no llegó",
    "confirm-delete": "¿Está seguro de eliminar el elemento seleccionado?",
    "delete-error": "Ocurrió un error al eliminar el elemento seleccionado",
    "received": "Producto recibido, para ver más ve a Registrados.",
    "received-error": "Ocurrió un error al recibir el producto",
    "status": {
      "received": "Lotes recibidos",
      "rejected": "Rechazados",
      "missing": "Faltante"
    }
  },
  "receipt-scan-location": {
    "title": "Escanea",
    "scan-label": "Ubicación",
    "scanned": "Contenedor trasladado, para ver más ve a trasladados.",
    "move-error": "Ocurrió un error al trasladar el contenedor"
  },
  "receipt-type": {
    "title": "Tipo de recibo",
    "transfers": "Transferencia",
    "providers": "Proveedores",
    "to-receive": "¿Qué vas a recibir?",
    "help": "Da tap en el botón dependiendo de lo que vayas a recibir."
  },
  "receipt-temperature": {
    "title": "Temperatura",
    "temp-label": "Temperatura (en grados)",
    "capture-temp": "Captura temperatura",
    "out-of-range": "Fuera del rango, aprobada por calidad.",
    "update-success": "Temperatura registrada.",
    "update-error": "Ocurrió un error al colocar la temperatura",
    "type-error": "Se esperaba una orden de compra al cargar la pantalla"
  },
  "inventory-waste": {
    "scan-origin": {
      "toolbar-title": "Escanea"
    },
    "scan-product": {
      "toolbar-title": "Escanea"
    },
    "scan-destination": {
      "toolbar-title": "Escanea",
      "scan": "Escanea",
      "location": "Ubicación de merma",
      "use-your-scanner": "Usa tu escáner"
    },
    "register-waste": {
      "title": "Registra",
      "quantity-title": "Escribe la cantidad a mermar",
      "quantity-title-weighable": "Escanea o escribe la cantidad a mermar",
      "units": "Cantidad (unidades)",
      "grams": "Cantidad (gramos)",
      "total-qty": "Llevas registrado {0}",
      "reason-title": "Selecciona el motivo",
      "error": "Ocurrió un error al registrar la merma",
      "stock-error": "La cantidad a mermar es mayor que el inventario disponible del producto.",
      "reasons": {
        "EXPIRATION": "Caducidad",
        "QUALITY": "Calidad",
        "RETURN": "Devolucion",
        "HARMFUL": "Fauna nociva",
        "RECOVERY": "Recuperacion",
        "SINISTER": "Siniestro",
        "APPEARANCE": "Apariencia",
        "WEIGHT": "Calibre y/o peso",
        "DAMAGE":"Daño mecánico",
        "DEFORMITY": "Deformidad",
        "DEHYDRATION": "Deshidratación",
        "FUNGUS": "Hongos",
        "MATURATION": "Maduración",
        "OXIDATION": "Oxidación",
        "DECAY": "Pudrición"
      }
    }
  },
  "inventory-transfer": {
    "home": {
      "title": "Transferencias de inventario",
      "type-error": "Se esperaba un arreglo de ordenes de salida en la respuesta de receipt-api",
      "skus": "Cant. SKU: {{total}}"
    },
    "detail": {
      "type-error": "Se esperaban datos de una orden desde receipt-api",
      "picked-quantity": "Cantidad ingresada:",
      "location": "Ubicación escaneada:",
      "to-pick": "Por Pickear",
      "picked": "Pickeado",
      "upc-sku": "UPC: {{barcode}} | SKU: {{sku}}",
      "requested": "Cantidad solicitada:",
      "not-to-pick": "No hay nada por pickear",
      "not-picked": "No hay nada pickeado aún",
      "scan-product": "Escanea producto",
      "already-picked": "El producto escaneado ya fue pickeado",
      "not-existing-upc": "El product escaneado no existe en la orden actual",
      "missing": "Faltante",
      "missing-alert": "¿Está seguro que desea marcar el producto como faltante?"
    },
    "pick": {
      "quantity": "Cantidad tomada",
      "quantity-gr": "Cantidad tomada en gramos",
      "scan": "Escanea ubicación",
      "success": "Producto pickeado correctamente."
    },
    "container": {
      "alert": "Deberás escanear un contenedor ya que en la ubicación hay múltiples contenedores."
    }
  },
  "universal-search": {
    "scan": "Puedes escanear",
    "type-label": "Escribe contenedor, ubicación o UPC.",
    "type-placeholder": "Contenedor, ubicación o UPC.",
    "scan-anything": "Producto, Contenedor, Ubicación",
    "toolbar-title": "Buscador",
    "quantity": "Cantidad:",
    "storage": "almacenaje",
    "prestorage": "pre-almacenaje",
    "picking": "picking",
    "restocking-rules": "Reglas de reabasto",
    "product":{
      "toolbar-title": "Ubicaciones del producto",
      "scanned-product": "Producto escaneado",
      "no-inventory": "No hay inventario en ubicaciones de {{type}}",
      "no-rules": "No hay reglas de reabasto para este producto"
    },
    "container":{
      "toolbar-title": "Detalle de contenedor",
      "scanned-container": "Contenedor escaneado",
      "products-in-container": "Productos del contenedor"
    },
    "location":{
      "toolbar-title": "Detalle de ubicación de {{type}}",
      "scanned-location": "Ubicación escaneada",
      "products-in-location": "Productos de la ubicación",
      "containers-in-location": "Contenedores en ubicación"
    }
  },
  "reverse-logistics":{
    "toolbar-title": "Logística inversa",
    "scan-tare-or-product": "Escanea la tara o el producto para iniciar el despickeo:",
    "info-message": "Inicia con los productos que se merman con facilidad.",
    "select-report-type": "Selecciona el tipo de reporte para {{product}}",
    "tare-id": "Tara ID:",
    "order": "Orden:",
    "scanned-product": "Producto escaneado",
    "tare-not-found": "La tara no se encuentra en el listado, despickea los productos manualmente.",
    "tare-not-valid": "La tara escaneada no es válida.",
    "product-not-found": "Producto no encontrado en logística inversa.",
    "scan-product": {
      "toolbar-title": "Despickea",
      "scan": "Escanea el producto a despickear:",
      "info-message": "Inicia con los productos que se merman con facilidad.",
      "scanned-tare": "Tara escaneada",
      "error-not-found": "El producto escaneado no está en la tara."
    },
    "scan-supplies":{
      "toolbar-title": "Logística inversa",
      "instructions": "Instrucciones",
      "add": "Escanea las taras que vas a despickear",
      "delete": "Para eliminar una tara, selecciona el tache.",
      "info-message": "Puedes escanear máximo 10 taras.",
      "continue": "Despickear",
      "max-scanned": "No puedes agregar otra tara, haz llegado al límite de 10 taras por despickeo. ",
      "view-last-list":{
        "title": "Ver último listado ",
        "subtitle": "Visualizarás los productos de las últimas taras escaneadas. "
      }
    },
    "register":{
      "toolbar-title": "Registra",
      "stock-error": "La cantidad a registrar es mayor que el inventario disponible del producto.",
      "instructions": "Instrucciones",
      "set-quantity": "Escribe la cantidad a despickear.",
      "set-quantity-weighable": "Escribe o escanea la cantidad a despickear.",
      "scan-destination": "Después escanea la ubicación de destino.",
      "suggested-location": "Ubicación sugerida",
      "no-quantity": "Debes escribir la cantidad a despickear para poder escanear una ubicación de destino.",
      "success": "Se han despickeado {{quantity}} {{uom}} en la ubicación {{location}}.",
      "invalid-location": "La ubicación escaneada es incorrecta, debes escanear alguna de las ubicaciones sugeridas.",
      "different-product": "El producto escaneado no es compatible con el producto que se debe reabastecer.  "
    },
    "register-waste-or-missing":{
      "toolbar-title": "Reporte de {{flow}}",
      "write-quantity": "Escribe la cantidad {{flow}}:",
      "quantity": "Cantidad:",
      "success-missing": "Se han registrado como faltante {{quantity}} {{unit}} de {{product}}.",
      "success-waste": "Se han registrado como merma {{quantity}} {{unit}} de {{product}}.",
      "stock-error": "La cantidad a registrar es mayor que el inventario disponible del producto.",
    }
  }
}
