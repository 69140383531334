import { useFastTraslationQuantity } from './FastTraslationQuantity.hook'
import {
  CardComponent,
  CardRow,
  Filler,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { type InventoryProduct } from '../../../types/model'
import { InventoryProductCountMolecule } from '../../../components/molecules/InventoryProductCount.molecule'
import { InventoryProductCardMolecule } from '../../../components/molecules/InventoryProductCard.molecule'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { type InventoryProductWithAlertInfo } from '../scan-product/ScanProduct.navigator'
import { useTranslation } from 'react-i18next'
import { formatString } from 'utils/string'

interface ChangeInventoryProductQuantityMoleculeProps {
  inventoryProduct: InventoryProduct
  location: string
  onChange: (value: string) => void
  label: string
}

function isWithAlertInfo (a: InventoryProduct): a is InventoryProductWithAlertInfo {
  return 'expectedStock' in a
}

function getCount (a: InventoryProduct | InventoryProductWithAlertInfo) {
  if (isWithAlertInfo(a)) {
    return Math.min(a.expectedStock, a.stock)
  }
  return a.stock
}

export const ChangeInventoryProductQuantityMolecule = ({
  label,
  inventoryProduct,
  onChange,
  location
}: ChangeInventoryProductQuantityMoleculeProps) => {
  const count = getCount(inventoryProduct)

  const { t } = useTranslation('global')
  const overline = t('fast-set-quantity.label-restocking')

  return (
    <CardComponent>
      <InventoryProductCountMolecule
        subtitle={t('uom.units')}
        overline={count ? overline : ''}
        count={count > 0 ? count : inventoryProduct.stock}
        stock={count}
        quantity={inventoryProduct.stock}
        isWeighable={false}
        location={location} />
      <CardRow>
        <TextBoxMolecule type="number" label={label} onChange={onChange} />
      </CardRow>
    </CardComponent>
  )
}

export const FastTraslationQuantityPage = () => {
  const {
    setQuantity,
    next,
    back,
    currentInventoryProduct,
    currentLocation,
    canConfirm,
    totalStock
  } = useFastTraslationQuantity()
  const { t } = useTranslation('global')

  if (!currentInventoryProduct || !currentLocation) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return (
    <>
      <ToolbarMolecule title={t('fast-set-quantity.toolbar-title')}
                       actionIcon={Icons.ArrowBack}
                       onAction={back} />
      <PageContentAtom>
        <InventoryProductCardMolecule
          inventoryProduct={currentInventoryProduct}
          subtitle={formatString(t('fast-set-quantity.total-qty'), (totalStock && totalStock < 0 ? 'ERROR' : totalStock) ?? 'Cargando...')} />

        <ChangeInventoryProductQuantityMolecule
          inventoryProduct={currentInventoryProduct}
          onChange={(number) => {
            setQuantity(Number(number))
          }}
          location={currentLocation.containerName || currentLocation.locationName}
          label={t('fast-set-quantity.label-qty')}
        />

        <Filler />

        <PrimaryButtonAtom disabled={!canConfirm} onClick={next}>
          {t('fast-set-quantity.btn-confirm')}
        </PrimaryButtonAtom>
      </PageContentAtom>
    </>
  )
}
