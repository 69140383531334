import { atom, type Atom } from 'jotai'
import { type Getter } from 'jotai/vanilla'

export function createReader<T> (a: Atom<T>) {
  return atom(function (get: Getter) {
    const value = get(a)
    if (!value) throw new Error('Location atom not found')
    return value
  })
}
