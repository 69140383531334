import styled from 'styled-components'

const colors = {
  urgent: '#D33641',
  critical: '#FF8B47',
  warning: '#FCE69C'
}

export const AlertIndicatorAtom = styled.div<{ type: 'urgent' | 'critical' | 'warning' }>`
  background-color:  ${props => colors[props.type]};
  color: #000;
  border-radius: 8px;
  padding: 4px;
`
