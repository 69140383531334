import { type AxiosError } from 'axios/index'

export interface ServerError {
  title?: string
  detail?: string
  status?: number
}

export const manageAxiosError = (error: AxiosError<ServerError>) => {
  const response = error.response?.data
  const detail = response?.detail
  const title = response?.title
  const status = response?.status

  return (detail ?? title ?? 'Error al ejecutar la operación. Status code:' + status)
}
