import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { type FormikInventoryWasteValues, isSameEan, normalizeEanForCapture, WasteReason } from '../../../types/model'
import { useAtom } from 'jotai'
import { inventoryWasteReportAtom, selectedProductReader } from '../inventory-waste.state'
import { useFormik } from 'formik'
import { getValidationSchema } from './RegisterWaste.helpers'
import { useTranslation } from 'react-i18next'
import { useInventoryWasteScanDestination } from '../scan-destination/ScanDestination.navigator'
import { useScanner } from 'hooks/scanner.hook'
import { extractWeight } from 'utils/product'
import { formatString } from 'utils/string'
import { useRequiredParams } from 'hooks/params.hook'
import { getWasteReasons } from '../Inventory-waste.client'

export const useInventoryWasteRegister = (onError: (err: string) => void) => {
  const navigate = useNavigate()
  const [product] = useAtom(selectedProductReader)
  const { t } = useTranslation('global')
  const [loading, setLoading] = useState(false)
  const scanDestination = useInventoryWasteScanDestination()
  const [, setInventoryWasteReport] = useAtom(inventoryWasteReportAtom)
  const {
    keys,
    getScan
  } = useScanner()
  const normalizedEan = normalizeEanForCapture(product.productEan)
  const { ean } = useRequiredParams('ean')
  const firstWeight = extractWeight(ean)
  const [weights, setWeights] = useState<number[]>(firstWeight != null ? [firstWeight] : [])
  const totalWeights = weights.reduce((prev, it) => prev + it, 0)
  const [reasons, setReasons] = useState<WasteReason[]>([])

  const formConfig = useFormik<FormikInventoryWasteValues>({
    initialValues: {
      amount: '',
      reason: []
    },
    validationSchema: getValidationSchema(t, product),
    onSubmit: async (values) => {
      setLoading(true)
      try {
        setInventoryWasteReport({
          quantity: parseInt(values.amount),
          reason: values.reason
        })
        scanDestination.go()
      } catch (error) {
        console.error(error)
        throw new Error(t('inventory-waste.register-waste.error'))
      } finally {
        setLoading(false)
      }
    }
  })

  const getReasons = async () => { 
    const reasons = await getWasteReasons()
    setReasons(reasons)
  }

  useEffect(() => {
    getReasons()
  }, [])

  useEffect(() => {
    formConfig.setFieldValue('amount', totalWeights.toString())
    const scanned = getScan()

    if (!scanned) {
      return
    }

    if (isSameEan(normalizedEan, scanned)) {
      const weight = extractWeight(scanned)

      if (weight == null) {
        onError(formatString(t('fast-set-weight.error-01'), scanned))
        return
      }

      addItem(weight)
    } else {
      onError(formatString(t('fast-set-weight.error-02'), scanned))
    }
  }, [keys])

  const addItem = (item: number) => {
    if (item > 0) {
      const newWeights = [...weights, item]
      setWeights(newWeights)
      const newTotalWeight = newWeights.reduce((acc, curr) => acc + curr, 0)
      formConfig.setFieldValue('amount', newTotalWeight.toString())
      setTimeout(() => {
        formConfig.setFieldTouched('amount', true)
        formConfig.validateField('amount')
      }, 0)
    }
  }

  const deleteItem = (item: number) => {
    const index = weights.indexOf(item)
    if (index >= 0) {
      const temp = [...weights]
      temp.splice(index, 1)
      setWeights(temp)
      const newTotalWeight = temp.reduce((acc, curr) => acc + curr, 0)
      formConfig.setFieldValue('amount', newTotalWeight.toString())
    }
  }

  return {
    product,
    formConfig,
    loading,
    weights,
    totalWeights,
    reasons,
    actions: {
      goBack: () => {
        navigate(-1)
      },
      addItem,
      deleteItem
    }
  }
}
