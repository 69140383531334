import {useNavigate} from 'react-router-dom'
import { type ScanResponse } from 'client/scanner/scanner.types'

interface Go {
  locationAndContainer: ScanResponse
  ean: string
}

export const useGoToInventoryProductFix = () => {
  const navigate = useNavigate()

  return {
    go: ({ locationAndContainer, ean }: Go) => {
      navigate(
        INVENTORY_PRODUCT_FIX_URL
          .replace(':location', locationAndContainer.location?.name as string)
          .replace(':container', locationAndContainer.container?.name || 'null')
          .replace(':ean', ean)
      )
    }
  }
}

export const INVENTORY_PRODUCT_FIX_URL = '/inventory-fix/:location/:container/product/:ean/'
