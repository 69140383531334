import { useNavigate } from 'react-router-dom'

export const INVENTORY_WASTE_SCAN_DESTINATION = '/inventory-waste/scan-destination'

export const useInventoryWasteScanDestination = () => {
  const navigate = useNavigate()
  return {
    go: () => { navigate(INVENTORY_WASTE_SCAN_DESTINATION) }
  }
}
