import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useAtomValue } from 'jotai'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import type { FormikRejectValues } from 'types/model'
import { Analytics } from 'analytics/analytics.events'
import { useQueryParam, useRequiredParams } from 'hooks/params.hook'
import { scannedContainer, selectedProductAtom } from '../Receipt.state'
import { useGoToProductLots } from '../product-lots/ProductLots.navigator'
import { useGoToScanContainer } from '../scan-container/ScanContainer.navigator'
import { uploadFile, upsertRejection } from '../client/Receipt.client'
import {
  generateFileName,
  getRejectLimit,
  getRejectionData,
  getValidationSchema,
  mapRejFormValues,
  maxPhotos,
  renameFile
} from './Rejection.helpers'
import { isRejectionSummary } from './Rejection.loader'
import { MEASURE_UNITS } from '../utils/constants'

export const useRejectionData = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const loaderData = useLoaderData()
  const product = useAtomValue(selectedProductAtom)
  const container = useAtomValue(scannedContainer)
  const [fileKeys, setFileKeys] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [validateRanges, setValidateRanges] = useState(false)
  const { orderId, receiptId, productId } = useRequiredParams(
    'orderId',
    'receiptId',
    'productId'
  )
  const rejectionId = useQueryParam('rejectionId')
  const productLots = useGoToProductLots()
  const scanContainer = useGoToScanContainer()
  if (!isRejectionSummary(loaderData)) {
    throw new Error(t('receipt-rejection.type-error'))
  }

  const { units, boxes } = getRejectLimit(product, loaderData)
  const [validationSchema, setValidationSchema] = useState(
    getValidationSchema(
      t,
      units,
      boxes,
      product?.measureUnit || '',
      validateRanges
    )
  )

  const formConfig = useFormik<FormikRejectValues>({
    initialValues: mapRejFormValues(loaderData.rejection),
    validationSchema,
    onSubmit: async (values) => {
      if (!product) {
        return
      }

      setLoading(true)
      try {
        const data = getRejectionData(
          receiptId,
          product,
          values,
          fileKeys,
          rejectionId || undefined
        )
        await upsertRejection(data)
        Analytics.receipt.addRejection(
          {
            orderId,
            receiptType: 'providers',
            images: data?.rejected?.photos,
            units: data?.rejected?.quantity,
            boxes: data?.rejected?.boxes,
            lot: data?.rejected?.lot,
            rejectReasons: data?.rejected?.rejectedReasons
          },
          product
        )

        let fullRejection: boolean
        if (product.measureUnit === MEASURE_UNITS.box && values.boxes) {
          fullRejection = +values.boxes >= boxes
        } else {
          fullRejection = +values.amount >= units
        }
        if (container || fullRejection) {
          productLots.go(receiptId, orderId, productId)
        } else {
          scanContainer.go(receiptId, orderId)
        }
      } catch (error) {
        console.error(error)
        throw new Error(t('receipt-rejection.upload-error'))
      } finally {
        setLoading(false)
      }
    }
  })

  useEffect(() => {
    if (loaderData.rejection?.photos.length) {
      setFileKeys(loaderData.rejection.photos)
    }
  }, [loaderData])

  useEffect(() => {
    const validateRanges = !formConfig.values.reason.includes('OVER_WEIGHT')
    setValidateRanges(validateRanges)
  }, [formConfig.values])

  useEffect(() => {
    formConfig
      .validateForm()
      .then(() => {})
      .catch(() => {})
  }, [validationSchema])

  useEffect(() => {
    const newValidationSchema = getValidationSchema(
      t,
      units,
      boxes,
      product?.measureUnit || '',
      validateRanges
    )
    setValidationSchema(newValidationSchema)
    formConfig.setFormikState((prevState) => ({
      ...prevState,
      validationSchema: newValidationSchema
    }))
  }, [validateRanges])

  const addPhoto = async (file: File | null) => {
    if (fileKeys.length < maxPhotos) {
      setLoading(true)
      try {
        if (!file) {
          return
        }
        const fileName = generateFileName(file.name, orderId)
        const fileToUpload = renameFile(file, fileName)
        file = null
        await uploadFile(fileToUpload)

        const newFileKeys = [...fileKeys, fileName]
        setFileKeys(newFileKeys)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const removePhoto = (index: number) => {
    const newFileKeys = fileKeys.filter((_, idx) => idx !== index)
    setFileKeys(newFileKeys)
  }

  return {
    formConfig,
    loading,
    fileKeys,
    product,
    actions: {
      addPhoto,
      removePhoto,
      goBack: () => {
        navigate(-1)
      }
    }
  }
}
