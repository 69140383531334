import { axiosInstance } from '../../utils/axios.instance'

interface AdjustInventoryClientParams {
  productId: string
  locationId: string
  containerId: string | null | undefined
  stock: number
}

export const adjustInventoryClient = async ({
  productId,
  locationId,
  containerId,
  stock
}: AdjustInventoryClientParams) => {
  const url = '/inventory-adjustment'
  return await axiosInstance()
    .post(url, {
      productId,
      locationId,
      containerId,
      stock
    })
}

export interface CreateInventoryProductClientProp {
  'productId': string
  'productSku': string
  'productEan': string
  'locationId': string
  'locationName': string
  'containerId': string | undefined
  'containerName': string | undefined
  'quantity': number
  'lot'?: string | null
  'expirationDate'?: string | null
}

export const createInventoryProductClient = async (inventoryProductInfo: CreateInventoryProductClientProp) => {
  const url = '/stock'
  const normalizedExpirationDate = inventoryProductInfo.expirationDate?.substring(0, 10)

  return await axiosInstance()
    .post(url, {
      productId: inventoryProductInfo.productId,
      productSku: inventoryProductInfo.productSku,
      productEan: inventoryProductInfo.productEan,
      locationId: inventoryProductInfo.locationId,
      locationName: inventoryProductInfo.locationName,
      containerId: inventoryProductInfo.containerId,
      containerName: inventoryProductInfo.containerName,
      quantity: inventoryProductInfo.quantity,
      lot: inventoryProductInfo.lot,
      expirationDate: normalizedExpirationDate
    })
}
