import { useLoaderData } from 'react-router-dom'
import {isProduct, isProductTmp, Product, ProductTmp} from '../types/model'
import {isObject} from "../internal-types/IsObject";

type HasProduct = {
  product: ProductTmp
}

const hasProduct = (e: unknown): e is HasProduct => {
  return isObject(e) && 'product' in e && isProductTmp(e.product);
}

export function useProduct() {
  const data = useLoaderData()
  if (hasProduct(data)) {
    return data.product
  }
  throw new Error('Received data is not of type Product: ' + JSON.stringify(data))
}
