import { useNavigate } from 'react-router-dom'

export const useGoToScanContainerLocation = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(SCAN_CONTAINER_LOCATION)
    }
  }
}

export const SCAN_CONTAINER_LOCATION = '/inventory-fix/scan-container-location'
