import { useNavigate } from 'react-router-dom'

export const useGoToUniversalSearchProduct = () => {
  const navigate = useNavigate()

  return {
    go: (productEan) => {
      navigate(UNIVERSAL_SEARCH_PRODUCT_URL.replace(':ean', productEan))
    }
  }
}

export const UNIVERSAL_SEARCH_PRODUCT_URL = '/universal-search/product/:ean'
