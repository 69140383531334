import { useNavigate } from 'react-router-dom'

export const useGoToReceipt = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(RECEIPT_URL)
    }
  }
}

export const RECEIPT_URL = '/receipt/'
