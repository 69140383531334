import { getInventoryProductsClient } from '../../../client/utilities.client'
import { findProductsInfo } from '../../../client/products/products.client'
import { UomKey } from '../../../client/types'

export const moveInventoryProductListLoader = async ({ params, request }) => {
  let {
    origin,
    container
  } = params

  if (container === 'null') {
    container = undefined
  }

  const url = new URL(request.url)
  const searchParams = new URLSearchParams(url.search)

  const looseProducts = searchParams.get('looseProducts') === 'true'

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const products = await getInventoryProductsClient(origin, container, looseProducts)

  const tempProducts = await findProductsInfo(products.map((it) => it.productEan))

  products.map((it) => {
    const product = tempProducts.find((p) => p.ean === it.productEan)
    if (product != null) {
      it.byWeight = product.packagingLevels.some((pl) => pl.uom === UomKey.Weight)
    }
    return it
  })

  return { inventoryProducts: products }
}
