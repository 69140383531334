import { useNavigate } from 'react-router-dom'

export const useSearchProductNavigator = () => {
  const navigate = useNavigate()

  return {
    go: () => { navigate(SEARCH_PRODUCT_NAVIGATOR) }
  }
}

export const SEARCH_PRODUCT_NAVIGATOR = '/alerts/search'
