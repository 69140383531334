import { atomWithStorage } from 'jotai/utils'
import type {
  CreatePickData,
  OutputOrderProduct
} from './types/InventoryTransfer.types'

export const selectedProductAtom = atomWithStorage<
OutputOrderProduct | undefined
>('selectedProductAtom', undefined)

export const pendingPickAtom = atomWithStorage<CreatePickData | undefined>(
  'pendingPick',
  undefined
)
