import { MoveProductsSelectorMolecule } from '../../../components/molecules/MoveProductsSelector.molecule'
import { useMoveOptions } from './MoveOptions.hook'
import { Icons, PageContentAtom, ToolbarMolecule } from '@justomx/atlas-components'

export const MoveOptionsPage = () => {
  const {
    actions,
    looseProducts,
    origin,
  } = useMoveOptions()

  return <>
    <ToolbarMolecule 
      title={origin?.container?.name ?? origin?.location.name as string}
      actionIcon={Icons.ArrowBack}
      onAction={actions.back}
    />
    <PageContentAtom>
      <MoveProductsSelectorMolecule
        type='product'
        onClick={actions.onClickGo('product')}
      />
      {origin?.container?.id != null && <MoveProductsSelectorMolecule
        type='container'
        onClick={actions.onClickGo('container')}
      />}
      {((origin?.location.type === "Storage" && origin?.location.zone === "Groceries") || origin?.location.type === "Pre-storage") && looseProducts.inventoryProducts.length > 0 && <MoveProductsSelectorMolecule
        type='loose-product'
        onClick={actions.onClickGo('loose-product')}
      />}
    </PageContentAtom>
  </>
}
