import { useNavigate } from 'react-router-dom'

export const useGoToPickProduct = () => {
  const navigate = useNavigate()

  return {
    go: (orderId: string, docId: string, grams?: number) => {
      let url = PICK_PRODUCT_URL.replace(':orderId', orderId).replace(
        ':docId',
        docId
      )
      if (grams) {
        url += `?grams=${grams}`
      }
      navigate(url)
    }
  }
}

export const PICK_PRODUCT_URL =
  '/inventory-transfer/:orderId/:docId/pick-product'
