import type { RouteObject } from 'react-router-dom'

import { INVENTORY_TRANSFER_URL } from './InventoryTransfer.navigator'
import { PICKING_LIST_URL } from './PickingList/PickingList.navigator'
import { PICK_PRODUCT_URL } from './PickProduct/PickProduct.navigator'
import { SCAN_CONTAINER_URL } from './ScanContainer/ScanContainer.navigator'

import { inventoryTransferLoader } from './InventoryTransfer.loader'
import { pickingListLoader } from './PickingList/PickingList.loader'

import InventoryTransferPage from './InventoryTransfer.page'
import PickingListPage from './PickingList/PickingList.page'
import PickProductPage from './PickProduct/PickProduct.page'
import ScanContainerPage from './ScanContainer/ScanContainer.page'

export const inventoryTransferRoutes: RouteObject[] = [
  {
    id: '(Inventory Transfer) Orders List',
    path: INVENTORY_TRANSFER_URL,
    element: <InventoryTransferPage />,
    loader: inventoryTransferLoader
  },
  {
    id: '(Inventory Transfer) Picking List',
    path: PICKING_LIST_URL,
    element: <PickingListPage />,
    loader: pickingListLoader
  },
  {
    id: '(Inventory Transfer) Pick Product',
    path: PICK_PRODUCT_URL,
    element: <PickProductPage />
  },
  {
    id: '(Inventory Transfer) Scan Container',
    path: SCAN_CONTAINER_URL,
    element: <ScanContainerPage />
  }
]
