import type { ReceiptProductProcessed } from 'types/model'
import { getProductProcessed } from '../client/Receipt.client'

export const isAReceiptProductProcessed = (
  data: unknown
): data is ReceiptProductProcessed => {
  return (
    !!data &&
    typeof data === 'object' &&
    'ean' in data &&
    'sapProductId' in data &&
    'expectedQuantity' in data
  )
}

export const summaryLoader = async ({ params }) => {
  return await getProductProcessed(`${params.receiptId}`, `${params.productId}`)
}
