import { useNavigate } from 'react-router-dom'
import { useRequiredParams } from 'hooks/params.hook'

export const useGoToPartialMissing = () => {
  const navigate = useNavigate()
  const { receiptType } = useRequiredParams('receiptType')

  return {
    go: (receiptId: string, orderId: string, productId: string) => {
      navigate(
        PARTIAL_MISSING_URL.replace(':receiptType', receiptType)
          .replace(':receiptId', receiptId)
          .replace(':orderId', orderId)
          .replace(':productId', productId)
      )
    }
  }
}

export const PARTIAL_MISSING_URL =
  '/receipt/:receiptType/:receiptId/order/:orderId/lots/:productId/partialmissing'
