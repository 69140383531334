import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { ReverseLogisticsScanProductPage } from "./scan-product/ScanProduct.page";
import { ReverseLogisticsScanProductPage as ReverseLogisticsScanProductPageV2 } from "./v2/scan-product/ScanProduct.page";

const ScanProductWrapper = () => {
  const featureFlag = useFeatureIsOn("op_utilities_front_reverse_logistics_v2");
  
  return featureFlag ?  <ReverseLogisticsScanProductPageV2/> : <ReverseLogisticsScanProductPage/>
}

export default ScanProductWrapper;