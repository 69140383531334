import { reverseLogisticsAxiosInstance } from '../../../utils/op-reverse-logistic.axios.instance'

interface RegisterUnpickProps {
  id: string
  ean: string
  location: string
  quantity: number
}

interface RegisterWasteOrMissingProps {
  id: string
  ean: string
  quantity: number
}

export const getPendings = async () => {
  const url = '/pendings'

  return await reverseLogisticsAxiosInstance()
    .get(url)
    .then(response => response.data)
}

export const getPendingDetails = async (id: string) => {
  const url = '/pendings/'+id

  return await reverseLogisticsAxiosInstance()
    .get(url)
    .then(response => response.data)
}

export const registerUnpick = async ({id, ean, location, quantity}: RegisterUnpickProps) => {
  const url = `/items/${id}/unpick/${ean}`

  return await reverseLogisticsAxiosInstance()
    .post(url,{
      "target": location,
      quantity
    })
    .then(response => response.data)
}

export const registerMissing = async ({id, ean, quantity}: RegisterWasteOrMissingProps) => {
  const url = `/items/${id}/missing/${ean}`

  return await reverseLogisticsAxiosInstance()
    .post(url,{
      quantity
    })
    .then(response => response.data)
}

export const registerWaste = async ({id, ean, quantity}: RegisterWasteOrMissingProps) => {
  const url = `/items/${id}/waste/${ean}`

  return await reverseLogisticsAxiosInstance()
    .post(url,{
      quantity
    })
    .then(response => response.data)
}