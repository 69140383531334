import { axiosInstance } from '../utils/axios.instance'
import { Product, type InventoryProduct, type LocationAndContainer } from '../types/model'
import { restockMetricsAxiosInstance } from 'utils/restock-metrics.axios.instance'

export interface MoveContainerProps {
  origin: LocationAndContainer
  destination: LocationAndContainer
}

export interface MoveProductsProps {
  origin: Omit<LocationAndContainer, 'locationType' | 'containersInLocation'>
  destination: Omit<LocationAndContainer, 'locationType' | 'containersInLocation'>
  inventoryProducts: InventoryProduct[]
  hasLooseProducts?: boolean
}

interface MoveProductsMetricsProps {
  product: InventoryProduct
  origin: LocationAndContainer
  destination: LocationAndContainer
}

export const moveProducts = async ({ origin, destination, inventoryProducts, hasLooseProducts }: MoveProductsProps) => {
  
  const url = hasLooseProducts ? '/stock/move-loose-products' : '/stock/move-products'

  return await axiosInstance()
    .put(url, {
      origin,
      destination,
      products: inventoryProducts.map(e => ({
        productId: e.productId,
        quantity: e.stock
      }))
    })
}

export const moveContainerClient = async ({ origin, destination }: MoveContainerProps) => {
  const url = '/containers/' + origin.containerId
  return await axiosInstance()
    .put(url, {
      fromLocationId: origin.locationId,
      toLocationId: destination.locationId
    })
}

export const moveProductsMetrics = ({product, origin, destination}: MoveProductsMetricsProps) => {
  return restockMetricsAxiosInstance()
    .post('/data-metrics-notifications/restock', {
      ean: product.productEan,
      sku: product.productSku,
      quantity: product.stock,
      sourceLocation: origin.locationName,
      targetLocation: destination.locationName,
      container: destination.containerName
    })
}