import {useContainerDetail} from "./ContainerDetail.hook";
import {onErrorToast} from "../../../onErrorToast.util";
import {
  BottomGroupMolecule,
  CardRow,
  Filler,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  ToolbarMolecule
} from "@justomx/atlas-components";
import {Global} from "../../../utils/global";
import {useTranslation} from "react-i18next";
import {MyFiller} from "../../fast-traslation/scan-product/ScanProduct.page";
import {InventoryProductList} from "../../../design-system/organism/InventoryProductList.organism";
import { SelectAllAtom } from "components/atoms/SelectAll.atom";

export function StorageContainerDetailPage() {
  const {t} = useTranslation('global')
  const {
    data,
    actions,
    selectedInventoryProducts
  } = useContainerDetail(onErrorToast);

  const products = data.inventoryProductsWithAlertInfo;
  const hasSelected = Boolean(selectedInventoryProducts.length)

  return <>
    <ToolbarMolecule
      title={t('to-storage.title')}
      actionIcon={Icons.IcMenuIcon}
      onAction={Global.WV.openMenu}
    />
    <PageContentAtom>
      <CardRow>
        <SelectAllAtom
          hasSelected={hasSelected}
          onAll={actions.selectAllInventoryProducts}
          onNone={actions.clearSelectedInventoryProducts}
        />
      </CardRow>
      <InventoryProductList products={products} selectable={true} selectedInventoryProducts={selectedInventoryProducts} onSelect={actions.toggleInventoryProduct}/>
      <MyFiller/>
    </PageContentAtom>
    <BottomGroupMolecule>
      <PrimaryButtonAtom
        data-test={'continue-btn'}
        onClick={() => actions.continue(selectedInventoryProducts)}
        disabled={!selectedInventoryProducts.length}
      >
        {t('storage-container-detail.continue')}
      </PrimaryButtonAtom>
    </BottomGroupMolecule>
  </>
}