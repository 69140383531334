import { type InventoryProductWithAlertInfo } from '../../pages/fast-traslation/scan-product/ScanProduct.navigator'
import { useTranslation } from 'react-i18next'
import { StackAtom } from '@justomx/atlas-components'
import { GroupOfProduct } from '../molecule/GroupOfProduct.molecule'
import { type InventoryProduct } from 'types/model'

interface InventoryProductListProps {
  products: InventoryProductWithAlertInfo[]
  selectable?: boolean
  selectedInventoryProducts?: InventoryProduct[]
  onSelect?: (p: InventoryProduct) => void
}

export const InventoryProductList = ({
  products = [],
  selectable = false,
  selectedInventoryProducts,
  onSelect
}: InventoryProductListProps) => {
  const { t } = useTranslation('global')

  const criticalProducts = products.filter((p) => p.alert === 'critical')
  const warningProducts = products.filter((p) => p.alert === 'warning')
  const productWithoutAlerts = products.filter((p) => p.alert !== 'critical' && p.alert !== 'warning')

  return (
    <StackAtom>
      <GroupOfProduct
        title={t('fast-scan-product.title-critical-alerts')}
        variant="critical"
        products={criticalProducts}
        selectable={selectable}
        selectedInventoryProducts={selectedInventoryProducts}
        onSelect={onSelect}
      />
      <GroupOfProduct
        title={t('fast-scan-product.title-warning-alerts')}
        variant="warning"
        products={warningProducts}
        selectable={selectable}
        selectedInventoryProducts={selectedInventoryProducts}
        onSelect={onSelect}
      />
      <GroupOfProduct
        title={t('fast-scan-product.title-no-alerts')}
        products={productWithoutAlerts}
        selectable={selectable}
        selectedInventoryProducts={selectedInventoryProducts}
        onSelect={onSelect}
      />
    </StackAtom>
  )
}
