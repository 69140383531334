import {AtlasBodySubtitle, AtlasColor, CardComponent, CardRow, Icon, Icons, StackAtom, Divider, AtlasBodyMain} from "@justomx/atlas-components";
import { ProductLocationDetail } from "client/products/products.types";
import { useTranslation } from "react-i18next";

type LocationAndContainerWithStockProps = {
  item: ProductLocationDetail
  showContainer?: boolean
}

export function LocationAndContainerWithStock({item, showContainer = true}: LocationAndContainerWithStockProps) {
  const {t} = useTranslation('global')
  return <CardComponent key={item.locationId + "-" + item.containerId}>
    <StackAtom style={{flexDirection: 'column', alignItems: 'baseline'}} gap={12}>
      <StackAtom>
        {showContainer && <CardRow>
          <Icon src={Icons.IcPallet} size={18}/>
          <AtlasBodySubtitle>{item.containerName || '[SIN CONTENEDOR]'}</AtlasBodySubtitle>
        </CardRow>}
        <CardRow>
          <Icon src={Icons.IcUbicacionIcon} size={18}/>
          <AtlasBodySubtitle>{item.locationName}</AtlasBodySubtitle>
        </CardRow>
      </StackAtom>
      <Divider/>
      <CardRow style={{ width: '100%', justifyContent: 'space-between'}}>
        <AtlasBodyMain color={AtlasColor.Neutral40}>{t('universal-search.quantity')}</AtlasBodyMain>
        <AtlasBodyMain>{item.stock} {item.isWeighable ? t('uom.grams') : t('uom.units')}</AtlasBodyMain>
      </CardRow>
    </StackAtom>
  </CardComponent>;
}

