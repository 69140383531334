import styled from 'styled-components'
import { Link } from 'react-router-dom'

const style = `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  height: 36px;

  /* Primary/00 */
  border-radius: 8px;
  border: none;
  outline: none;

  /* Atlas/ Button */
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  
  background: #FACE39 center;
  transition: background 0.8s;
  color: #212121;

  &:active {
    background-color: #fc8600;
    background-size: 100%;
    transition: background 0s;
  }
  
  &:disabled {
    background-color: var(--Neutral-60-Disabled, #686868);
  }
`

export const PrimaryLinkAtom = styled(Link)`
  ${style}
`
