import { AtlasBodyTitle, AtlasColor, CardComponent, Icon, Icons } from "@justomx/atlas-components"
import { ContainerResponse, LocationResponse } from "client/scanner/scanner.types"
import { FlexRowAtom } from "components/atoms/FlexRow.atom"

interface ScannedLocationOrContainerProps {
  item: LocationResponse | ContainerResponse
  type: 'location' | 'container'
}

const ScannedLocationOrContainer = ({item, type}: ScannedLocationOrContainerProps) => {
  return (
    <CardComponent $variant='highlight' style={{ marginBottom: '12px'}}>
      <FlexRowAtom style={{ gap: 8}}>
        <Icon src={type === 'location' ? Icons.IcUbicacionIcon : Icons.IcPallet} size={18}/>
        <AtlasBodyTitle 
          color={AtlasColor.Neutral00}
          data-location={item.name}
        >
          {item.name}
        </AtlasBodyTitle>
      </FlexRowAtom>
    </CardComponent>
  )
}

export default ScannedLocationOrContainer