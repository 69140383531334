import { isAxiosError } from 'axios'
import { manageAxiosError, type ServerError } from './axiosError.handler'
import { toast } from 'react-toastify'

export const manageError = (defaultText: string) => (e: Error) => {
  // TODO: chain of responsabilities or map
  console.error('Error', defaultText, e)
  let message: string | undefined
  if (isAxiosError<ServerError>(e)) {
    message = manageAxiosError(e)
  }

  toast.error(message ?? e.message ?? defaultText)
}
