import { type RouteObject } from 'react-router-dom'
import { CONTAINER_ADMINISTRATION } from './ContainerAdministration.navigator'
import { ContainerAdministrationPage } from './ContainerAdministration.page'
import { LowerContainerPage } from './lower-container/LowerContainer.page'
import { LOWER_CONTAINER_ROUTE } from './lower-container/LowerContainer.navigator'
import { SCAN_CONTAINER_TO_LIFT_NAVIGATOR } from './lift-container/scan-to-lift/ScanContainerToLift.navigator'
import { ScanContainerToLiftPage } from './lift-container/scan-to-lift/ScanContainerToLift.page'
import { ConfirmLiftPage } from './lift-container/confirm-lift/ConfirmLift.page'
import { CONFIRM_LIFT } from './lift-container/confirm-lift/ConfirmLift.navigator'

export const containerAdministrationRoutes: RouteObject[] = [
  {
    id: '(container-administration) Main Page - Container list',
    path: CONTAINER_ADMINISTRATION,
    element: <ContainerAdministrationPage/>
  },
  {
    id: '(container-administration) Lower Container confirmation',
    path: LOWER_CONTAINER_ROUTE,
    element: <LowerContainerPage/>
  },
  {
    id: '(container-administration) Lift Container scan',
    path: SCAN_CONTAINER_TO_LIFT_NAVIGATOR,
    element: <ScanContainerToLiftPage/>
  },
  {
    id: '(container-administration) Lift confirmation',
    path: CONFIRM_LIFT,
    element: <ConfirmLiftPage/>
  }

]
