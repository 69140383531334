import { useTranslation } from 'react-i18next'
import { Global } from '../../../utils/global'
import { useScanContainerLocation } from './ScanContainerLocation.hooks'
import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom, TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'

export const ScanContainerLocationPage = () => {
  const { actions } = useScanContainerLocation()
  const { t } = useTranslation('global')

  return (
    <>
      <ToolbarMolecule title="Escanea"
                       actionIcon={Icons.IcMenuIcon}
                       onAction={Global.WV.openMenu}
                       data-test="scan-container-toolbar"
      />
      <PageContentAtom $centered>
        <Filler flexGrow={1} />
        <Icon src={Icons.ScanIcon} />
        <AtlasBodyTitle>Escanea</AtlasBodyTitle>
        <AtlasH1 style={{ textAlign: 'center' }}>Ubicación<br /> ó<br /> Contenedor</AtlasH1>
        <AtlasBodyTitle>Usa tu escáner</AtlasBodyTitle>
        <Filler />
        <TextBoxMolecule label={t('scan-origin.insert-last-four-digits')}
                         onNewValue={actions.goToContainerByLastNumbers} />
      </PageContentAtom>
    </>
  )
}
