import { useNavigate } from 'react-router-dom'
import { ScanResponse } from 'client/scanner/scanner.types';

interface Go {
  locationAndContainer: ScanResponse,
  ean: string
}

export const CREATE_PRODUCT_URL = '/inventory-fix/:location/:container/product/:ean/new'

export const useGoToCreateProduct = () => {
  const navigate = useNavigate()

  return {
    go: ({ locationAndContainer, ean }: Go) => {
      navigate(CREATE_PRODUCT_URL
        .replace(':location', locationAndContainer.location?.name as string)
        .replace(':container', locationAndContainer.container?.name || 'null')
        .replace(':ean', ean))
    }
  }
}
