import { useGoToScanProductInContainer } from '../scan-product-in-container/ScanProductInContainer.navigator'
import { type UseHasInventoryProducts, useInventoryProducts } from '../../../hooks/inventoryProducts.hooks'
import { useLoaderData } from 'react-router-dom'
import { useGoToInventoryProductFix } from '../product-fix/InventoryProductFix.navigator'
import { isErrorResponse } from '../../../internal-types/HasError'
import { useGoToScanContainerLocation } from '../scan-container-location/ScanContainerLocation.navigator'
import { useCurrentLocationAndContainer } from '../../../hooks/currentLocation.hook'
import { type ScanResponse } from 'client/scanner/scanner.types'

interface InventoryProductListHookResponse {
  locationAndContainer: ScanResponse
  inventoryProductResponse: UseHasInventoryProducts
  actions: {
    goToScanProduct: () => void
    goToInventoryProductFix: (ean: string) => void
    back: () => void
  }
}

export const useInventoryProductsList = (): InventoryProductListHookResponse => {
  const data = useLoaderData()
  const scanProduct = useGoToScanProductInContainer()
  const inventoryProductFix = useGoToInventoryProductFix()
  const scanContainerLocation = useGoToScanContainerLocation()

  const inventoryProductResponse = useInventoryProducts(data)
  const locationAndContainer = useCurrentLocationAndContainer()

  if (isErrorResponse(inventoryProductResponse)) {
    throw new Error('Error is not a inventoryProduct array')
  }

  return {
    locationAndContainer,
    inventoryProductResponse,
    actions: {
      goToScanProduct: () => {
        scanProduct.go({
          locationAndContainer
        })
      },
      goToInventoryProductFix: (ean: string) => {
        inventoryProductFix
          .go({
            locationAndContainer,
            ean
          })
      },
      back: () => {
        scanContainerLocation.go()
      }
    }
  }
}
