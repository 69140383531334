import styled from 'styled-components'
import {AtlasBodyMain} from './Typography/Typography.atom'
import {Icon} from './icons/Icon'
import {AtlasColor} from '../foundations/Colors.foundations'
import React from "react";
import {SVGComponent} from "../@types/Svg.component";

interface TagContainerType {
  selected: boolean
}

const TagContainer = styled.div<TagContainerType>`
  display: flex;
  background-color: ${({selected}) => selected ? AtlasColor.Primary10 : AtlasColor.Neutral60};
  color: ${({selected}) => selected ? AtlasColor.Primary00 : AtlasColor.Neutral00};
  gap: 8px;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
`

interface TagAtomType {
  icon?: SVGComponent
  text: string
  selected: boolean
  "data-test"?: string
}

export const TagAtom = (props: TagAtomType) => {
  const {icon, text, selected} = props;
  return <TagContainer selected={selected}
                       data-test={props['data-test']}>
    <AtlasBodyMain data-test={props['data-test'] + '-body'}>{text}</AtlasBodyMain>
    {icon && <Icon src={icon}
                   size={18}
                   style={{color: selected ? 'yellow' : 'white'}}
                   data-test={props['data-test'] + '-icon'}/>}
  </TagContainer>
}
