import { isProduction } from '../utils/environment'

const stgToken = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDIyLCJzZWN1cml0eUNvZGUiOiIkMmEkMDgkMmNyM1VqNVNMMmRzQWF6RUd6dXd0dSIsImlhdCI6MTcxNTc5MjUyOCwiZXhwIjoxNzMxMzQ0NTI4LCJhdWQiOiJodHRwczovL3d3dy5rb3ZpeC5jby8iLCJpc3MiOiJLb3ZpeCIsInN1YiI6Iktvdml4IENsaWVudCJ9.fLIbHMJ77sGkq93etxXCJb5VVbLZyrS6AIh7IqIvjGH6ilYSDuQI6d9IAAHNOWF7rWWPIT2NwBLGO05wWG0FAatJ2hTd4f9C74KveSZWg9EtLBFDfPPScB2di-FZxc7mZ4V9FAwsRTFKniPWespxSnJPv2hBsjUkcPwM8QV_ikocKF1ryp28CIk2GOzZUiQVrxxzpGxkherbLrVCjtoQaSEhRz89ExP6fpOblgPhB2Qpv0Ioyy5h8gHTSOGiqcir4xcO337ntanTOauy4wtZiQo0AlTmqLFzi1qWR2htT45iEF13AHOXPM1A3W7ICgm0R-yVMYCZwYjBLC3iLYqmPZKjUQ0w91SVlJaHQf6-VuMe-DFsRpJYinBM8dJUoVJC9okD8dGuD13p2-D1KyATTjtHJajtYu2QlOon0PuqnvgX0En8kCu6lpw8_9x5GUb-8aTAofuE8z_Rbz9M-3pQedwNXmuZMT98VZaR0czeLu9sx3d8CghIuwE0hD_EcABjRFZIOgIG-Vi_ip1HzIHABx7J_d29gSDvyd4eNgOOp-Qkt1I8ajidexpOSZcDQ9A59eKoW29hyxWyLK_IWOEQGtAFNugFWE-2igRfX-QUu_MhDwj9CzScedg8RUvsWLG7BawyoSxEuINYCHvxkUCdwuyr9G562bfu7UaHh2Bpf7s'

export interface CommonHeaders {
  'x-hardcoded': boolean
  authorization: string
  'x-authorization-wms': string
  'x-authorization-core': string
  'x-justo-country': string
  'x-justo-warehouse'?: string
}

export const getCommonHeaders = (): CommonHeaders => {
  const metadata = (window as any)?.WVMetadata
  if (metadata) {
    return {
      'x-hardcoded': false,
      authorization: metadata['x-authorization-wms'],
      'x-authorization-wms': metadata['x-authorization-wms'],
      'x-authorization-core': metadata['x-authorization-core'],
      'x-justo-country': metadata['x-justo-country']?.toUpperCase(),
      'x-justo-warehouse': metadata['x-justo-warehouse']?.toUpperCase()
    }
  }
  if (isProduction()) {
    console.log('Metadata of WV is undefined, using default values.')
  }
  return {
    'x-hardcoded': true,
    authorization: stgToken,
    'x-authorization-wms': stgToken,
    'x-authorization-core': stgToken,
    'x-justo-country': 'MX',
    'x-justo-warehouse': 'PP'
  }
}

export function getCurrentCountry () {
  const metadata = (window as any)?.WVMetadata
  if (metadata) {
    return metadata['x-justo-country']?.toUpperCase()
  }
  if (isProduction()) {
    console.log('Metadata of WV is undefined, using default values.')
  }
  return 'MX'
}

export function getCurrentWarehouse () {
  const metadata = (window as any)?.WVMetadata
  if (metadata) {
    return metadata['x-justo-warehouse']?.toUpperCase()
  }
  if (isProduction()) {
    console.log('Metadata of WV is undefined, using default values.')
  }
  return 'PP'
}

export function getCurrentUserId () {
  const { authorization } = getCommonHeaders()
  const [_header, payload, _signature] = authorization.split('.')
  const body = JSON.parse(atob(payload))
  return body.id.toString()
}
