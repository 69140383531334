import {axiosInstance} from '../../utils/axios.instance'

export interface CreateInventoryWasteReportClientProp {
    'productEan': string
    'productSku': string
    'productName': string
    'productImage': string
    'lot'?: string | null
    'expirationDate'?: string | null
    'quantity': number
    'locationName': string
    'containerName': string | undefined
    'reason': string[]
}

export const createInventoryWasteReportClient = async (inventoryWasteReport: CreateInventoryWasteReportClientProp) => {
    const url = '/waste/reports/'
    const normalizedExpirationDate = inventoryWasteReport.expirationDate?.substring(0, 10)

    return await axiosInstance()
        .post(url, {
            productEan: inventoryWasteReport.productEan,
            productSku: inventoryWasteReport.productSku,
            productName: inventoryWasteReport.productName,
            productImage: inventoryWasteReport.productImage,
            lot: inventoryWasteReport.lot,
            expirationDate: normalizedExpirationDate,
            quantity: inventoryWasteReport.quantity,
            location: inventoryWasteReport.locationName,
            container: inventoryWasteReport.containerName,
            reason: inventoryWasteReport.reason
        })
}


export const getWasteReasons = async () => {
    const url = `/waste/atlas/reasons` 
    return await axiosInstance()
        .get(url)
        .then((response) => response.data.reasons)
}