import { useTranslation } from 'react-i18next'
import { useGoToReceipt } from '../Receipt.navigator'
import { useGoToScanOrder } from '../scan-order/ScanOrder.navigator'

export const useReceiptType = () => {
  const { t } = useTranslation('global')
  const receipt = useGoToReceipt()
  const scanOrder = useGoToScanOrder()

  const onClosePage = () => {
    receipt.go()
  }

  const onSelectType = (type: string) => {
    scanOrder.go(type)
  }

  return { t, actions: { onClosePage, onSelectType } }
}
