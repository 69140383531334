import { isLocationAndContainer, type LocationAndContainer } from '../types/model'

export class InvalidLocationError extends Error {
  static MESSAGE = 'Error interno: se recibió una ubicación incorrecta'
  constructor (received: unknown) {
    super(InvalidLocationError.MESSAGE)
    console.error(InvalidLocationError.MESSAGE, received)
  }
}
export class LocationWithoutContainerError extends Error {
  constructor (public readonly location: LocationAndContainer) {
    super(`La ubicación ${location.locationName} no posee contenedor y no es ubicación de picking`)
  }
}

export const validateLocationAndContainer = (location: unknown): LocationAndContainer => {
  if (!isLocationAndContainer(location)) {
    throw new InvalidLocationError(location)
  }

  // const isPicking = location.locationType === 'Picking'
  // const hasContainer = Boolean(location.containerId)

  // if (isPicking || hasContainer) { return location }

  // throw new LocationWithoutContainerError(location)

  return location
}
