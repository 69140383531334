import LocationAndContainerMoleculeWrapper from 'components/molecules/LocationAndContainerWrapper.molecule'
import { type LocationOrContainer } from '../../types/model'
import { LocationAndContainerMolecule } from '../molecules/LocationAndContainer.molecule'
import { CardComponent } from '@justomx/atlas-components'

interface SmallMoveOrganismProps {
  origin: LocationOrContainer
}

export const SmallMoveOrganism = ({ origin }: SmallMoveOrganismProps) => {
  return <CardComponent $variant='highlight' style={{ gap: 0 }}>
    <LocationAndContainerMoleculeWrapper origin={origin} />
  </CardComponent>
}
