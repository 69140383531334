import { containerAdministrationDestination, containerAdministrationLastDropState, containerAdministrationState } from '../ContainerAdministration.state'
import { useAtom } from 'jotai'
import { toast } from 'react-toastify'
import { useContainerAdministrationNavigator } from '../ContainerAdministration.navigator'
import { Analytics } from '../../../analytics/analytics.events'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useScanner } from 'hooks/scanner.hook'
import { getLocationFromScan } from 'client/scanner/scanner.client'
import { useTranslation } from 'react-i18next'
import { lowerContainer } from 'client/forklift/forklift.client'
import { validateLocationToDrop } from './LowerContainer.validator'

export const LOCATION_DROP_REGEX = /^[A-Z]{2,3}-ALM-P[0-9]+-R[0-9]+$/

export const useLowerContainer = (onError: (err: string) => void) => {
  const [origin] = useAtom(containerAdministrationState)
  const [suggestedLocation] = useAtom(containerAdministrationDestination)
  const [, setLastDropped] = useAtom(containerAdministrationLastDropState)
  const containerAdministration = useContainerAdministrationNavigator()
  const navigate = useNavigate()
  const { keys, getScan } = useScanner()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation('global')
  
  const confirm = async (destination) => {
    if (origin) {
      try {
        await lowerContainer({
          origin,
          destination: {
            location: destination.name,
            locationType: destination.type,
          }
        })
        setLastDropped(origin.container as string)
        Analytics.forklift.lowerContainer({
          origin: {
            locationName: origin.location,
            locationType: origin.locationType,
            containerName: origin.container
          },
          destination: {
            locationName: destination.name,
            locationType: destination.type,
          }
        })
        toast.success(t(`lower-container.success`, { containerName: origin.container, locationName: destination.name }))
        setIsLoading(false)
        containerAdministration.go()
      } catch (e) {
        setLastDropped('')
        console.error(e)
        onError(e.message as string)
        setIsLoading(false)
      }
    }
  }
  
  useEffect(() => {
    const scanned = getScan();
    if (scanned) {
      setIsLoading(true);
      getLocationFromScan(scanned)
        .then(destination => validateLocationToDrop(destination.location))
        .then(location => confirm(location))
        .catch(e => {
          console.error(e);
          onError(e.message);
          setIsLoading(false)
        });
    }
  }, [keys]);
  

  return {
    origin,
    suggestedLocation,
    isLoading,
    actions: {
      goBack: () => {
        navigate(-1)
      }
    }
  }
}
