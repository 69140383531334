import { useFastTransferWeight } from './FastTraslationWeight.hook'
import {
  AtlasBodySubtitle,
  AtlasColor,
  CardComponent,
  CardRow,
  Filler,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { type InventoryProduct } from '../../../types/model'
import { InventoryProductCountMolecule } from '../../../components/molecules/InventoryProductCount.molecule'
import { InventoryProductCardMolecule } from '../../../components/molecules/InventoryProductCard.molecule'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { type InventoryProductWithAlertInfo } from '../scan-product/ScanProduct.navigator'
import { useTranslation } from 'react-i18next'
import { formatString } from 'utils/string'
import { formatWeight } from 'utils/product'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { DeleteIcon } from 'components/DeleteIcon'
import { onErrorToast } from '../../../onErrorToast.util'
import { useState } from 'react'
import { ConfirmationDialogMolecule, useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'

function isWithAlertInfo (a: InventoryProduct): a is InventoryProductWithAlertInfo {
  return 'expectedStock' in a
}

function getCount (a: InventoryProduct | InventoryProductWithAlertInfo) {
  if (isWithAlertInfo(a)) {
    return Math.min((a.expectedStock - a.currentStock), a.stock)
  }
  return a.stock
}

interface ChangeWeightMoleculeProps {
  inventoryProduct: InventoryProduct
  location: string
  label: string
  onAdd: (value: number) => void
}

const ChangeWeightMolecule = ({
  label,
  inventoryProduct,
  onAdd,
  location
}: ChangeWeightMoleculeProps) => {
  const count = getCount(inventoryProduct)

  const { t } = useTranslation('global')

  const [qty, setQty] = useState(0)

  const addWeightClick = () => {
    // TODO: implementing logic to clear input value
    onAdd(qty)
  }

  return (
    <CardComponent>
      <InventoryProductCountMolecule
        overline={count ? t('fast-set-weight.label-restocking1') : t('fast-set-weight.label-restocking2')}
        count={formatWeight(count > 0 ? count : inventoryProduct.stock)}
        stock={count}
        quantity={inventoryProduct.stock}
        isWeighable={true}
        subtitle={inventoryProduct.byWeight ? '' : t('uom.grams')}
        location={location} />
      <CardRow>
        <FlexRowAtom style={{
          width: '100%',
          alignItems: 'stretch',
          gap: '1em'
        }}>
          <TextBoxMolecule type="number" label={label} onChange={(it) => {
            setQty(Number(it))
          }} />
          <PrimaryButtonAtom style={{
            height: 'auto',
            color: AtlasColor.Primary00,
            backgroundColor: `${AtlasColor.Primary00}22`
          }} onClick={addWeightClick}>
            {t('fast-set-weight.btn-add')}
          </PrimaryButtonAtom>
        </FlexRowAtom>
      </CardRow>
    </CardComponent>
  )
}

interface ListOfWeightsProps {
  detail: string
  items: number[]
  onDelete: (item: number) => void
}

const ListOfWeights = ({
  detail,
  items,
  onDelete
}: ListOfWeightsProps) => {
  return (
    <>
      <AtlasBodySubtitle>{detail}</AtlasBodySubtitle>
      {
        items.map((it, i) => {
          return (
            <CardComponent key={`${i}_${it}`}>
              <CardRow>
                <FlexRowAtom style={{ width: '100vw' }}>
                  <div style={{
                    flex: 1,
                    paddingRight: '1em'
                  }}>{formatWeight(it)}</div>
                  <div style={{
                    color: AtlasColor.Primary00,
                    cursor: 'pointer'
                  }}
                       onClick={() => {
                         onDelete(it)
                       }}>
                    <DeleteIcon />
                  </div>
                </FlexRowAtom>
              </CardRow>
            </CardComponent>
          )
        })
      }
    </>
  )
}

export const FastTransferWeightPage = () => {
  const {
    next,
    back,
    currentInventoryProduct: productSelected,
    currentLocation,
    canConfirm,
    weights,
    weightToRestock,
    addItem,
    deleteItem,
    totalStock
  } = useFastTransferWeight(onErrorToast)

  const {
    dialogRef: continueDialogRef,
    open: openContinueDialog
  } = useConfirmationDialog()

  const hasAlert = productSelected && productSelected.alert && productSelected.alert !== 'none'

  const {
    dialogRef: deleteConfirmationRef,
    open: openDeleteDialog,
    value: valueToDelete
  } = useConfirmationDialog<number>()

  const { t } = useTranslation('global')

  if (!productSelected || !currentLocation) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  const expectedRestock = productSelected.expectedStock - productSelected.currentStock
  const weight = formatWeight(weightToRestock, 2)
  const weightRequest = formatWeight(expectedRestock)

  return (
    <>
      <ToolbarMolecule title={t('fast-set-weight.toolbar-title')}
                       actionIcon={Icons.ArrowBack}
                       onAction={back} />
      <PageContentAtom>
        <InventoryProductCardMolecule
          inventoryProduct={productSelected}
          subtitle={formatString(t('fast-set-weight.total-qty'), totalStock ? (totalStock < 0 ? 'ERROR' : formatWeight(totalStock)) : 'Cargando...')} />

        <ChangeWeightMolecule
          inventoryProduct={productSelected}
          onAdd={(value) => {
            addItem(value)
          }}
          location={currentLocation.containerName ?? currentLocation.locationName}
          label={t('fast-set-weight.label-qty')}
        />

        <ListOfWeights
          detail={formatString(t('fast-set-weight.total-restocking'), weight)}
          items={weights}
          onDelete={openDeleteDialog}
        />

        <Filler />

        <PrimaryButtonAtom disabled={!canConfirm} onClick={openContinueDialog}>
          {t('fast-set-weight.btn-confirm')}
        </PrimaryButtonAtom>
      </PageContentAtom>

      <ConfirmationDialogMolecule dialogRef={continueDialogRef}
                                  confirmText={t('fast-set-weight.dialog-confirm.btn-confirm')}
                                  onConfirm={next}
                                  cancelText={t('fast-set-weight.dialog-confirm.btn-cancel')}>
        {
          hasAlert
            ? formatString(t('fast-set-weight.dialog-confirm.msg1'), weight, weightRequest)
            : formatString(t('fast-set-weight.dialog-confirm.msg2'), weight)
        }
      </ConfirmationDialogMolecule>

      <ConfirmationDialogMolecule dialogRef={deleteConfirmationRef}
                                  value={valueToDelete}
                                  confirmText={t('fast-set-weight.dialog-delete.btn-confirm')}
                                  onConfirm={deleteItem}
                                  cancelText={t('fast-set-weight.dialog-delete.btn-cancel')}>
        {
          valueToDelete
            ? formatString(t('fast-set-weight.dialog-delete.msg'), formatWeight(valueToDelete))
            : ''
        }
      </ConfirmationDialogMolecule>
    </>
  )
}
