import { useMoveInventoryProductList } from './MoveInventoryProductList.hook'
import { SmallMoveOrganism } from '../../../components/organisms/SmallMove.organism'
import { isErrorResponse } from '../../../internal-types/HasError'
import { InventoryProductCardMolecule } from '../../../components/molecules/InventoryProductCard.molecule'
import {
  AtlasBodySubtitle,
  AtlasListAtom,
  BottomGroupMolecule,
  CardRow,
  Filler,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  SpacerAtom,
  StackAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { SelectAllAtom } from 'components/atoms/SelectAll.atom'

const InstructionsMolecule = () => {
  return <>
    <AtlasBodySubtitle>Da tap en el producto para seleccionarlo</AtlasBodySubtitle>
    <AtlasListAtom>
      <li>Para mover por parcialidades, selecciona solo un producto a la vez.</li>
      <li>Para mover la totalidad, selecciona varios o todos los productos</li>
    </AtlasListAtom>
  </>
}

function formatStock (stock: number, isWeighable: boolean): string {
  if (isWeighable) {
    return `${stock} g`
  } else {
    return `${stock} ${stock > 1 ? 'piezas' : 'pieza'}`
  }
}

export const MoveInventoryProductListPage = () => {
  const {
    actions,
    origin,
    inventoryProducts,
    selectedInventoryProducts
  } = useMoveInventoryProductList()

  if (isErrorResponse(inventoryProducts)) {
    throw new Error(inventoryProducts.error.message) // TODO: improve this
  }
  const hasSelected = Boolean(selectedInventoryProducts.length)

  const next = () => {
    actions.moveProducts(selectedInventoryProducts)
  }

  return <>
    <ToolbarMolecule title="Productos"
                     actionIcon={Icons.ArrowBack}
                     onAction={actions.back} />
    <PageContentAtom>
      <SmallMoveOrganism origin={origin} />
      <InstructionsMolecule />
      <CardRow>
        <Filler />
        <SelectAllAtom hasSelected={hasSelected}
                       onAll={actions.selectAllInventoryProducts}
                       onNone={actions.clearSelectedInventoryProducts}
        />
      </CardRow>
      <TextBoxMolecule
        label="Buscar por UPC, SKU o nombre"
        type="search"
        onChange={inventoryProducts.filter.setFilterValue}
      />
      <StackAtom>
        {inventoryProducts.allInventoryProducts.length === 0 ? <InventoryProductListEmpty /> : ''}
        {inventoryProducts.inventoryProducts.map(e =>
          <InventoryProductCardMolecule
            selectable={true}
            key={e.productEan + e.stock}
            inventoryProduct={e}
            onClick={() => {
              actions.toggleInventoryProduct(e)
            }}
            selected={selectedInventoryProducts.some(s => s.productSku === e.productSku)}
            subtitle={`Cantidad total: ${formatStock(e.stock, e.byWeight)}`}
          />
        )}
        <SpacerAtom />
      </StackAtom>
      <BottomGroupMolecule>
        <PrimaryButtonAtom disabled={!selectedInventoryProducts.length}
                           onClick={next}>Continuar</PrimaryButtonAtom>
      </BottomGroupMolecule>
    </PageContentAtom>
  </>
}

const InventoryProductListEmpty = () => {
  return (
    <div className="product-list-empty">
      <svg xmlns="http://www.w3.org/2000/svg"
           className="icon icon-tabler icon-tabler-mood-empty"
           viewBox="0 0 24 24" strokeWidth="1.5"
           stroke="currentColor" fill="none" strokeLinecap="round"
           strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
        <path d="M9 10l.01 0" />
        <path d="M15 10l.01 0" />
        <path d="M9 15l6 0" />
      </svg>
      No hay productos en esta ubicación y/o contenedor
    </div>
  )
}
