import { useAtom } from 'jotai/index'
import { productState } from '../product.state'

export const useProductContainersList = (/* onError: (err: string) => void */) => {
  const [product] = useAtom(productState)

  return {
    product
  }
}
