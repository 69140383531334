import { useScanDestination } from './ScanDestination.hooks'
import { LocationAndContainerMolecule } from '../../../components/molecules/LocationAndContainer.molecule'
import {
  AtlasBodySubtitle,
  AtlasBodyTitle, AtlasColor,
  AtlasH1, CardComponent,
  Filler,
  Icon,
  Icons,
  PageContentAtom, StackAtom, TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { MOVE_PRODUCT } from '../traslation.flow'
import { useTranslation } from 'react-i18next'
import LocationAndContainerMoleculeWrapper from 'components/molecules/LocationAndContainerWrapper.molecule'

export const ScanDestinationPage = () => {
  const { actions, origin, flow } = useScanDestination()
  const { t } = useTranslation('global')
  return <>
    <ToolbarMolecule title='Traslado de productos'
                     actionIcon={Icons.ArrowBack}
                     onAction={actions.back}/>
    <PageContentAtom $centered>
      <Filler/>
      <Icon src={Icons.ScanIcon}/>
      <AtlasBodyTitle>{t('scan-destination.scan')}</AtlasBodyTitle>
      <AtlasH1>{t('scan-destination.title')}</AtlasH1>
      <AtlasBodyTitle>{t('scan-destination.use-your-scanner')}</AtlasBodyTitle>
      <Filler/>
      <CardComponent>
          <AtlasBodySubtitle color={AtlasColor.Neutral40}>Origen: </AtlasBodySubtitle>
          <StackAtom>
            <LocationAndContainerMoleculeWrapper origin={origin} />
          </StackAtom>
      </CardComponent>
      { flow === MOVE_PRODUCT
        ? <TextBoxMolecule
              label={t('scan-origin.insert-last-four-digits')}
              onNewValue={actions.goToContainerByLastNumbers}/>
        : ''}

    </PageContentAtom>
  </>
}
