import { LocationInfo } from 'types/model'
import { LocationAndContainerMolecule } from '../../molecules/v2/LocationAndContainer.molecule'
import { AtlasCaption, AtlasColor, CardComponent, Icon, Icons } from '@justomx/atlas-components'

interface MoveOrganismProps {
  origin: LocationInfo
  originText?: string
  destination: LocationInfo | undefined
  destinationText?: string
  showAlerts?: boolean
}

export const MoveOrganism = ({ origin, destination, originText, destinationText, showAlerts=true }: MoveOrganismProps) => {
  return (
    <CardComponent $variant='highlight' style={{ backgroundColor: '#292929', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <CardComponent $variant='highlight' style={{gap: 8}}>
        <AtlasCaption color={AtlasColor.Neutral40}>{originText ?? 'Origen'}</AtlasCaption>
        <LocationAndContainerMolecule data={origin} showAlerts={showAlerts} />
      </CardComponent>
      {destination &&
        <>
        <Icon src={Icons.ArrowForward} size={18} />
        <CardComponent $variant='highlight' style={{ gap: 8 }}>
          <AtlasCaption color={AtlasColor.Neutral40}>{destinationText ?? 'Ubicación de destino'}</AtlasCaption>
          <LocationAndContainerMolecule data={destination} showAlerts={showAlerts}/>
        </CardComponent>
        </>
      }
    </CardComponent>
  )
}
