import { InventoryProductCountMolecule } from '../molecules/InventoryProductCount.molecule'
import { CardComponent, TextBoxMolecule } from '@justomx/atlas-components'
import {useTranslation} from "react-i18next";

export type InventoryAttributes = 'quantity' | 'lot' | 'expiration'

interface CreateInventoryProductOrganismProps {
  location: string
  onChange: (attribute: InventoryAttributes, value: string) => void
}

export const CreateInventoryProductOrganism = ({ location, onChange }: CreateInventoryProductOrganismProps) => {
  const _onChange = (attribute: InventoryAttributes) => (value: string) => { onChange(attribute, value) }
  const { t } = useTranslation('global')

  return <CardComponent style={{ gap: 20 }}>
    <InventoryProductCountMolecule count={0}
                                   location={location}
                                   subtitle={t('uom.units')}
    />
    <TextBoxMolecule label='Cantidad' type='number' onChange={_onChange('quantity')}/>
    <TextBoxMolecule label='Lote' onChange={_onChange('lot')}/>
    <TextBoxMolecule label='Caducidad' type='date' onChange={_onChange('expiration')}/>
  </CardComponent>
}
