import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useScanProduct } from './ScanProduct.hook'
import { useTranslation } from 'react-i18next'

export const ScanProductPage = () => {
  const { actions } = useScanProduct()
  const { t } = useTranslation('global')

  return <>
    <ToolbarMolecule
      title={t('inventory-waste.scan-product.toolbar-title')}
      actionIcon={Icons.ArrowBack}
      onAction={actions.goBack}
    />
    <PageContentAtom $centered>
      <Filler />
      <Icon src={Icons.ScanIcon} />
      <AtlasBodyTitle>Escanea</AtlasBodyTitle>
      <AtlasH1>Producto</AtlasH1>
      <AtlasBodyTitle>Usa tu escáner</AtlasBodyTitle>
      <Filler />
      <Filler />
    </PageContentAtom>
  </>
}
