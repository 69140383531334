import {
  AtlasColor,
  BottomGroupMolecule,
  Icon,
  Icons,
  PageContentAtom,
  SnackbarInformationPanel,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { Global } from '../../../utils/global'
import { useFastTraslationScanProduct } from './ScanProduct.hook'
import { onErrorToast } from '../../../onErrorToast.util'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { InventoryProductList } from '../../../design-system/organism/InventoryProductList.organism'
import LoaderAtom from '../../../components/atoms/Loader.atom'

export const MyFiller = styled.div`min-height: 100px;`

export const ScanProductPage = () => {
  const {
    origin,
    inventoryProducts,
    isLoading,
    back
  } = useFastTraslationScanProduct(onErrorToast)
  const { t } = useTranslation('global')
  const toolbarTitle = `${t('fast-scan-product.toolbar-title')} ${origin?.containerName ?? ''}`

  if (isLoading) {
    return (
      <LoaderAtom>
        Estamos cargando la información...
      </LoaderAtom>
    )
  }

  return (
    <>
      <ToolbarMolecule title={toolbarTitle} actionIcon={Icons.ArrowBack} onAction={back} />
      <PageContentAtom>
        <InventoryProductList products={inventoryProducts} />
        <MyFiller />
      </PageContentAtom>
      <BottomGroupMolecule>
        <SnackbarInformationPanel style={{
          border: '1px solid',
          backgroundColor: AtlasColor.Primary10,
          borderColor: AtlasColor.Important
        }}>
          <Icon src={Icons.ScanIcon} />
          {t('fast-scan-product.bottom-msg')}
        </SnackbarInformationPanel>
      </BottomGroupMolecule>
    </>
  )
}
