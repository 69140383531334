import { useNavigate } from 'react-router-dom'

export const useGoToUniversalSearchLocation = () => {
  const navigate = useNavigate()

  return {
    go: (locationId) => {
      navigate(UNIVERSAL_SEARCH_LOCATION_URL.replace(':id', locationId))
    }
  }
}

export const UNIVERSAL_SEARCH_LOCATION_URL = '/universal-search/location/:id'
