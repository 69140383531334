import {storageAxiosInstance} from "./Storage.axios.instance";
import {Location, LocationAndContainer} from "../../types/model";
import {validateLocationAndContainer} from "../../utils/locationAndContainer.validator";

export const getLocationsAndContainersInPreStorage = () => {
  const axios = storageAxiosInstance();
  return axios.get<LocationAndContainer[]>('/pre-storage')
  .then(e => e.data)
  .then(e => e.map(obj => ({...obj, locationType: 'PreStorage'})))
  .then(e => e.map(validateLocationAndContainer))
}

export const getSuggestedLocationsInStorage = (containerId: string) => {
  const axios = storageAxiosInstance();
  return axios.get<Location>(`/pre-storage/${containerId}/suggested-storage-location`)
              .then(e => e.data)
}