import { onErrorToast } from '../../../../onErrorToast.util'
import { useConfirmLift } from './ConfirmLift.hook'
import LoaderAtom from '../../../../components/atoms/Loader.atom'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasListAtom,
  Filler,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { MoveOrganism } from '../../../../components/organisms/v2/Move.organism'
import { useTranslation } from 'react-i18next'

export const ConfirmLiftPage = () => {
  const { actions, containerToLift, suggestedDestination, isLoading } =
    useConfirmLift(onErrorToast)
  const { t } = useTranslation('global')

  if (!containerToLift || isLoading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return (
    <>
      <ToolbarMolecule
        title={t('confirm-lift.toolbar-title')}
        actionIcon={Icons.CloseIcon}
        onAction={actions.goBack}
      />
      <PageContentAtom>
        <AtlasBodySubtitle>{t("reverse-logistics.register.instructions")}</AtlasBodySubtitle>
        <AtlasListAtom>
          <li><AtlasBodyMain display='contents'>{t('confirm-lift.scan')}</AtlasBodyMain></li>
        </AtlasListAtom>
        <MoveOrganism
          origin={containerToLift}
          originText={t('confirm-lift.origin-text')}
          destination={suggestedDestination}
          destinationText={t('confirm-lift.destination-text')}
        />
        <Filler />
      </PageContentAtom>
    </>
  )
}
