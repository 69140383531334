import Axios, { type AxiosInstance } from 'axios'
import {isProduction} from "../../utils/environment";
import {CommonHeaders, getCommonHeaders} from "../../client/utilities.client-helper";

let axios

function configureAxios () {
  if (isProduction()) {
    // TODO, move all urls to a config file
    return Axios.create({
      baseURL: 'https://api.justo.cloud/op-storage-manager/'
    })
  }

  return Axios.create({
    baseURL: 'https://stag.justo.cloud/op-storage-manager/'
  })
}

// TODO: Generalize this
function getAxiosInstance (): AxiosInstance {
  if (!axios) {
    axios = configureAxios()

    axios.interceptors.request.use((config) => {
      const headers: CommonHeaders = getCommonHeaders()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(headers).map(e => config.headers.set(e, headers[e]))
      return config
    })
  }

  return axios
}

export const storageAxiosInstance = getAxiosInstance
