import {RouteObject} from "react-router-dom";
import {STORAGE_URL} from "./Storage.navigator";
import {StoragePage} from "./Storage.page";
import {prestoragePendingLoader} from "./Storage.loader";
import {STORAGE_CONTAINER_DETAIL_URL} from "./container-detail/ContainerDetail.navigator";
import {containerDetailLoader} from "./container-detail/ContainerDetail.loader";
import React from "react";
import {StorageContainerDetailPage} from "./container-detail/ContainerDetail.page";
import {STORAGE_SCAN_DESTINATION_URL} from "./scan-destination/StorageScanDestination.navigator";
import {StorageScanDestinationPage} from "./scan-destination/StorageScanDestination.page";
import {storageScanDestinationLoader} from "./scan-destination/StorageScanDestination.loader";

export const storageRoutes: RouteObject[] = [
  {
    id: '(Storage) Storage list',
    path: STORAGE_URL,
    element: <StoragePage/>,
    loader: prestoragePendingLoader
  },
  {
    id: '(Storage) Container detail',
    path: STORAGE_CONTAINER_DETAIL_URL,
    element: <StorageContainerDetailPage/>,
    loader: containerDetailLoader
  },
  {
    id: '(Storage) Scan destination',
    path: STORAGE_SCAN_DESTINATION_URL,
    element: <StorageScanDestinationPage/>,
    loader: storageScanDestinationLoader
  },
]