import { useNavigate } from 'react-router-dom'

export const useGoToUniversalSearchContainer = () => {
  const navigate = useNavigate()

  return {
    go: (containerId) => {
      navigate(UNIVERSAL_SEARCH_CONTAINER_URL.replace(':id', containerId))
    }
  }
}

export const UNIVERSAL_SEARCH_CONTAINER_URL = '/universal-search/container/:id'
