import { useTranslation } from 'react-i18next'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasColor,
  CardComponent,
  Icons,
  ImageThumbnailComponent,
  PageContentAtom,
  ToolbarMolecule,
  StackAtom,
  Divider
} from '@justomx/atlas-components'
import { useScanProduct } from './ScanProduct.hook'
import { ProductCard } from '../../components/product-card.component'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import TareIcon from '../../../../assets/images/Utilities/Illustration_tare.svg'
import LoaderAtom from 'components/atoms/Loader.atom'
import { OptionsDialogOrganism } from 'components/organisms/OptionsDialog.organism'
import { defaultWasteOrMissingOption, wasteOrMissingOptions } from '../ReverseLogistics.page'
import { TextfieldDialogOrganism } from 'components/organisms/TextfieldDialog.organism'
import { InfoMessage } from 'pages/reverse-logistics/components/info-message'
import { zonesMap } from 'utils/zonesMap'

export const ReverseLogisticsScanProductPage = () => {
  const { products, actions, loading, selectedProduct, dialogRef, typeProductDialogRef } = useScanProduct()
  const { t } = useTranslation('global')

  if(loading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return <>
    <ToolbarMolecule
      title={t('reverse-logistics.scan-product.toolbar-title')}
      actionIcon={Icons.CloseIcon}
      onAction={actions.goHome}
      secondaryActionIcon={Icons.IcKeyboardIcon}
      onSecondaryAction={actions.openTypeProductDialog}
    />
    <PageContentAtom style={{flexGrow: 0}}>
      <AtlasBodySubtitle>{t("reverse-logistics.scan-product.scan")}</AtlasBodySubtitle>
      <InfoMessage message={t('reverse-logistics.scan-product.info-message')}/>
      {Object.keys(products).map((zone) => (
        <StackAtom key={zone} gap={12}>
          <AtlasBodySubtitle>{zonesMap[zone]}</AtlasBodySubtitle>
          <StackAtom gap={12} content=''>
            {products[zone].map((product) => (
              <ProductCard
                key={product.ean}
                product={product}
                showPriority={true}
                showStock={true}
                showWasteIcon={true}
                openDialog={()=>actions.openWasteOrMissingDialog(product)}
              />
            ))}
             <Divider style={{height: '2px'}}/>
          </StackAtom>
         
        </StackAtom>
      ))}
       <OptionsDialogOrganism
          dialogRef={dialogRef}
          title={`Selecciona el tipo de reporte para ${selectedProduct.name}`}
          options={wasteOrMissingOptions}
          defaultOption={defaultWasteOrMissingOption}
          confirmAction={actions.goToWasteOrMissingPage}
        />
        <TextfieldDialogOrganism
          dialogRef={typeProductDialogRef}
          title='Escribe el código del producto'
          label='Código'
          confirmText='Continuar'
          cancelText='Cancelar'
          confirmAction={actions.searchProduct}
        />
    </PageContentAtom>
  </>
}
