import { useNavigate } from 'react-router-dom'

export const useGoToTemperature = () => {
  const navigate = useNavigate()

  return {
    go: (receiptId: string, receiptType: string) => {
      navigate(
        TEMPERATURE_URL.replace(':receiptType', receiptType).replace(
          ':receiptId',
          receiptId
        )
      )
    }
  }
}

export const TEMPERATURE_URL = '/receipt/temperature/:receiptType/:receiptId'
