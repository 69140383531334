import styled from 'styled-components'

export const SnackbarInformationPanel = styled.div`
  display: flex;
  padding: 14px 8px 14px 18px;
  border-radius: 4px;
  gap: 10px;
  background: #282828;
  width: 100%;
;
`
