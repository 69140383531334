import styled from 'styled-components'

import { AtlasColor } from '../../foundations/Colors.foundations'

export const AtlasListAtom = styled.ul`
  
  & li {
    list-style-position: inside;
    color: ${AtlasColor.Neutral20};
    margin-left: 6px;
  }
  
  & li::marker {
    color: ${AtlasColor.Neutral20};
  }
`
