import { atomWithStorage } from 'jotai/utils'
import type { ContainerToMove, ProductDetail } from 'types/model'

export const selectedProductAtom = atomWithStorage<ProductDetail | undefined>(
  'selectedProductAtom',
  undefined
)
export const scannedContainer = atomWithStorage<string | undefined>(
  'scannedContainer',
  undefined
)
export const containerToMoveAtom = atomWithStorage<ContainerToMove | undefined>(
  'containerToMove',
  undefined
)
