import {useLoaderData} from "react-router-dom";
import {hasLocationAndContainers} from "./Storage.loader";
import {useScanner} from "../../hooks/scanner.hook";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useGoToContainerDetail} from "./container-detail/ContainerDetail.navigator";
import { LoadedObject } from "utils/async-types";
import { getLocationOrContainerFromScan } from "client/scanner/scanner.client";

export const useStorageHomepage = (onError: (err: string) => void) => {
  const { t } = useTranslation('global')
  const containerDetail = useGoToContainerDetail();
  const { keys, getScan } = useScanner()
  const data = useLoaderData()

  useEffect(() => {
    const scanned = getScan()
    if (scanned && hasLocationAndContainers(data)) {
      // @TODO: find better way of doing this. can we throw errors from containerDetail loader??
      /* const currentLocationAndContainer = data
          .locationAndContainers
          .find(e => e.locationName === scanned || e.containerName === scanned)

      if (currentLocationAndContainer?.containerName) {
        containerDetail.go(currentLocationAndContainer.containerName)
        return;
      }

      onError(t('storage-home.invalid-scan', {scanned}))*/

      getLocationOrContainerFromScan(scanned)
          .then(c => {
              if (c.location.type !== 'PreStorage') {
                  onError(t('storage-home.invalid-scan', {scanned}))
                  return;
              }

              containerDetail.go(scanned);
          })
          .catch(err => {
              onError(t('storage-container-detail.bad-request', {scanned}))
          })
    }
  }, [keys, getScan])

  if (hasLocationAndContainers(data)) {
    return LoadedObject(data.locationAndContainers)
  }

  throw new Error('Expected location and container in loader data')
}