import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom, TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { Global } from '../../../utils/global'
import { useSearchProduct } from './SearchProduct.hook'
import { onErrorToast } from '../../../onErrorToast.util'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { useTranslation } from 'react-i18next'

export const SearchProductPage = () => {
  const { loading, actions } = useSearchProduct(onErrorToast)
  const { t } = useTranslation('global')

  if (loading) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return <>
    <ToolbarMolecule title={t('search-product.toolbar-title')}
                     actionIcon={Icons.IcMenuIcon}
                     onAction={Global.WV.openMenu}/>
    <PageContentAtom $centered>
      <Filler/>
      <Icon src={Icons.ScanIcon}/>
      <AtlasBodyTitle>{t('search-product.scan')}</AtlasBodyTitle>
      <AtlasH1 style={{ textAlign: 'center' }}>{t('search-product.product')}</AtlasH1>
      <AtlasBodyTitle>{t('search-product.use-your-scanner')}</AtlasBodyTitle>
      <Filler/>
      <TextBoxMolecule label={t('search-product.enter-ean')} onNewValue={actions.goEAN}/>
    </PageContentAtom>
  </>
}
