import { LocationAndContainer, LocationOrContainer } from "types/model"
import { MoveOrganism } from "./Move.organism";

interface LocationAndContainerMoleculeProps {
  origin: LocationOrContainer
  originText?: string
  destination: LocationOrContainer
  destinationText?: string
}

const MoveOrganismWrapper = ({origin,destination}: LocationAndContainerMoleculeProps) => {
  const transformedOrigin: LocationAndContainer = {
    locationId: origin.location.id,
    locationName: origin.location.name,
    locationType: origin.location.type,
    containerId: origin.container?.id,
    containerName: origin.container?.name,
    containersInLocation: origin.totalContainers || 0,
  };
  const transformedDestination: LocationAndContainer = {
    locationId: destination.location.id,
    locationName: destination.location.name,
    locationType: destination.location.type,
    containerId: destination.container?.id,
    containerName: destination.container?.name,
    containersInLocation: destination.totalContainers || 0,
  };
  return <MoveOrganism origin={transformedOrigin} destination={transformedDestination} />
}

export default MoveOrganismWrapper