import { useNavigate, useSearchParams } from 'react-router-dom'

export const useFastTransferWeightNavigator = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return {
    go: (ean: string) => {
      const isFromAlertParam = searchParams.get('isFromAlert') === 'true' ? '&isFromAlert=true' : ''

      navigate(
          `${FAST_TRANSFER_SET_WEIGHT.replace(':ean', ean)}?${isFromAlertParam}`
      )
    }
  }
}

export const FAST_TRANSFER_SET_WEIGHT = '/fast-traslation/set-weight/:ean'
