import {useScanProductInContainer} from './ScanProductInContainer.hook'
import {AtlasBodyTitle, AtlasH1, Filler, Icon, Icons, PageContentAtom, ToolbarMolecule} from '@justomx/atlas-components'

export const ScanProductInContainerPages = () => {
  const {actions} = useScanProductInContainer()

  return <>
    <ToolbarMolecule title='Escanea'
                     actionIcon={Icons.ArrowBack}
                     onAction={actions.back}
                     data-test='scan-product-toolbar'/>
    <PageContentAtom $centered>
      <Filler flexGrow={1}/>
      <Icon src={Icons.ScanIcon}/>
      <AtlasBodyTitle>Escanea</AtlasBodyTitle>
      <AtlasH1 style={{textAlign: 'center'}}>Producto</AtlasH1>
      <AtlasBodyTitle>Usa tu escáner</AtlasBodyTitle>
      <Filler/>
    </PageContentAtom>
  </>
}
