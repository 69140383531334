import React from 'react'
import { Await } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  AtlasBodySubtitle,
  AtlasBodyTitle,
  CardComponent,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'

import { Global } from 'utils/global'
import type { OutputOrderData } from './types/InventoryTransfer.types'
import { useInventoryTransfer } from './InventoryTransfer.hooks'
import LoaderAtom from 'components/atoms/Loader.atom'

const InventoryTransferPage = () => {
  const { t } = useTranslation('global')
  const { actions, loaderData } = useInventoryTransfer()

  return (
    <React.Suspense fallback={<LoaderAtom>{t('loader.message')}</LoaderAtom>}>
      <Await
        resolve={loaderData.orders}
        errorElement={<p>Error loading orders data!</p>}
      >
        {(orders: OutputOrderData[]) => (
          <>
            <ToolbarMolecule
              title={t('inventory-transfer.home.title')}
              actionIcon={Icons.HamburgerIcon}
              onAction={Global.WV.openMenu}
            />

            <PageContentAtom>
              {orders.map((order) => (
                <CardComponent
                  key={order._id}
                  onClick={() => {
                    actions.onSelectOrder(order)
                  }}
                >
                  <AtlasBodyTitle>#{order.order}</AtlasBodyTitle>
                  <AtlasBodySubtitle>
                    {t('inventory-transfer.home.skus', {
                      total: order.products.length
                    })}
                  </AtlasBodySubtitle>
                </CardComponent>
              ))}

              {!orders.length && (
                <AtlasBodySubtitle>
                  {t('receipt.no-data-title')}
                </AtlasBodySubtitle>
              )}
            </PageContentAtom>
          </>
        )}
      </Await>
    </React.Suspense>
  )
}

export default InventoryTransferPage
