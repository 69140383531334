import { type Product } from '../../types/model'
import { ProductInfoAtom } from '../atoms/ProductInfo.atom'
import { AtlasColor, CardComponent, CardRow } from '@justomx/atlas-components'

interface ProductCardComponentProps {
  onClick?: () => void
  product: Product
  showStock?: boolean
}

export const ProductCardMolecule = ({ onClick, product, showStock }: ProductCardComponentProps) => {
  return <CardComponent color={AtlasColor.Neutral40}
                        onClick={onClick}
                        data-test={'product-card'}>
    <CardRow $gap={16}>
      <ProductInfoAtom
          product={product}
          showStock={showStock}
          data-test='product-card-info' />
    </CardRow>
  </CardComponent>
}
