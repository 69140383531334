import { useRequiredParams } from 'hooks/params.hook'
import { useNavigate } from 'react-router-dom'

export const useGoToScanLocation = () => {
  const navigate = useNavigate()
  const { receiptType } = useRequiredParams('receiptType')

  return {
    go: (receiptId: string, orderId: string) => {
      navigate(
        SCAN_LOCATION_URL.replace(':receiptType', receiptType)
          .replace(':receiptId', receiptId)
          .replace(':orderId', orderId)
      )
    }
  }
}

export const SCAN_LOCATION_URL =
  '/receipt/:receiptType/:receiptId/order/:orderId/scan-location'
