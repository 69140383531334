import { useLoaderData } from 'react-router-dom'

import type { OrderSummary } from 'types/model'
import { useGoToInventoryTransfer } from 'pages/inventory-transfer/InventoryTransfer.navigator'
import { useGoToOrderDetail } from './order-detail/OrderDetail.navigator'
import { useGoToReceiptType } from './receipt-type/ReceiptType.navigator'
import { useGoToTemperature } from './temperature/Temperature.navigator'
import { RECEIPT_TYPES } from './utils/constants'

export const useReceiptData = () => {
  const data = useLoaderData()
  const orderDetail = useGoToOrderDetail()
  const receiptType = useGoToReceiptType()
  const temperature = useGoToTemperature()
  const invTransfer = useGoToInventoryTransfer()

  const onSelectOrder = (order: OrderSummary) => {
    if (order.frozenOrRefrigerated) {
      temperature.go(order.receiptId, RECEIPT_TYPES[order.documentType])
    } else {
      orderDetail.go(
        RECEIPT_TYPES[order.documentType],
        order.receiptId,
        order.orderId
      )
    }
  }

  const onSelectSync = () => {
    receiptType.go()
  }

  const goToInventoryTransfer = () => {
    invTransfer.go()
  }

  return {
    data,
    actions: { onSelectOrder, onSelectSync, goToInventoryTransfer }
  }
}
