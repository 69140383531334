export enum AtlasColor {
  'Neutral80' = '#282828',
  'Neutral60' = '#686868',
  'Neutral40' = '#9a9a9a',
  'Neutral20' = '#cdcdcd',
  'Neutral10' = '#e6e6e6',
  'Neutral05' = '#f2f2f2',
  'Neutral00' = '#fff',
  'PrimarySurface' = '#424242',
  'Primary10Solid' = '#FEF9E7',
  'Primary10' = 'rgba(250, 206, 57, 0.12)',
  'Primary00' = '#FACE39',
  'Background' = '#212121',
  'Important' = '#FACE39',
  'Error' = '#FF6D76',
  'StateGreen' = '#33856D',
  'BoxShadow' = '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);',
}
