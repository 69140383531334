import type { RouteObject } from 'react-router-dom'

import { RECEIPT_URL } from './Receipt.navigator'
import { ORDER_DETAIL_URL } from './order-detail/OrderDetail.navigator'
import { SCAN_ORDER_URL } from './scan-order/ScanOrder.navigator'
import { SCAN_CONTAINER_URL } from './scan-container/ScanContainer.navigator'
import { PRODUCT_LOTS_URL } from './product-lots/ProductLots.navigator'
import { PARTIAL_MISSING_URL } from './partial-missing/PartialMissing.navigator'
import { REJECTION_URL } from './rejection/Rejection.navigator'
import { MOVE_CONTAINERS_URL } from './move-containers/MoveContainers.navigator'
import { SCAN_LOCATION_URL } from './scan-location/ScanLocation.navigator'
import { RECEIPT_TYPE_URL } from './receipt-type/ReceiptType.navigator'
import { TEMPERATURE_URL } from './temperature/Temperature.navigator'

import { getPurchaseOrdersLoader } from './Receipt.loader'
import { orderDetailLoader } from './order-detail/OrderDetail.loader'
import { moveContainersLoader } from './move-containers/MoveContainers.loader'
import { SUMMARY_URL } from './summary/Summary.navigator'
import { summaryLoader } from './summary/Summary.loader'
import { productLotsLoader } from './product-lots/ProductLots.loader'
import { rejectionLoader } from './rejection/Rejection.loader'
import { partialMissingLoader } from './partial-missing/PartialMissing.loader'
import { temperatureLoader } from './temperature/Temperature.loader'

import Receipt from './Receipt.page'
import OrderDetail from './order-detail/OrderDetail.page'
import ScanOrderPage from './scan-order/ScanOrder.page'
import ScanContainerPage from './scan-container/ScanContainer.page'
import ProductLotsPage from './product-lots/ProductLots.page'
import PartialMissingPage from './partial-missing/PartialMissing.page'
import RejectionPage from './rejection/Rejection.page'
import MoveContainersPage from './move-containers/MoveContainers.page'
import SummaryPage from './summary/Summary.page'
import ScanLocationPage from './scan-location/ScanLocation.page'
import ReceiptTypePage from './receipt-type/ReceiptType.page'
import TemperaturePage from './temperature/Temperature.page'

export const receiptRoutes: RouteObject[] = [
  {
    id: '(Receipt) Purchase orders list',
    path: RECEIPT_URL,
    element: <Receipt />,
    loader: getPurchaseOrdersLoader
  },
  {
    id: '(Receipt) Receipt Type',
    path: RECEIPT_TYPE_URL,
    element: <ReceiptTypePage />
  },
  {
    id: '(Receipt) Temperature',
    path: TEMPERATURE_URL,
    element: <TemperaturePage />,
    loader: temperatureLoader
  },
  {
    id: '(Receipt) Order detail',
    path: ORDER_DETAIL_URL,
    element: <OrderDetail />,
    loader: orderDetailLoader
  },
  {
    id: '(Receipt) Scan order',
    path: SCAN_ORDER_URL,
    element: <ScanOrderPage />
  },
  {
    id: '(Receipt) Scan Container',
    path: SCAN_CONTAINER_URL,
    element: <ScanContainerPage />
  },
  {
    id: '(Receipt) Product Lots',
    path: PRODUCT_LOTS_URL,
    element: <ProductLotsPage />,
    loader: productLotsLoader
  },
  {
    id: '(Receipt) Partial Missing',
    path: PARTIAL_MISSING_URL,
    element: <PartialMissingPage />,
    loader: partialMissingLoader
  },
  {
    id: '(Receipt) Rejection',
    path: REJECTION_URL,
    element: <RejectionPage />,
    loader: rejectionLoader
  },
  {
    id: '(Receipt) Move containers',
    path: MOVE_CONTAINERS_URL,
    element: <MoveContainersPage />,
    loader: moveContainersLoader
  },
  {
    id: '(Receipt) Scan location',
    path: SCAN_LOCATION_URL,
    element: <ScanLocationPage />
  },
  {
    id: '(Receipt) Summary',
    path: SUMMARY_URL,
    element: <SummaryPage />,
    loader: summaryLoader
  }
]
