import styled from 'styled-components'
import { AtlasBodyMain, AtlasColor } from '@justomx/atlas-components'

interface RadioButtonProps {
  id: string
  label: string
  name: string
  value: string
  defaultChecked?: boolean
  onChange?: (value: string) => void
}

const Container = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  height: 24px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ .checkmark {
    background-color: ${AtlasColor.BoxShadow};
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid ${AtlasColor.Primary00};

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${AtlasColor.Primary00};
  }
`

export const RadioButton = (props: RadioButtonProps) => {
  const { label, id, name, value, defaultChecked, onChange } = props

  return (
    <Container>
      <AtlasBodyMain>{label}</AtlasBodyMain>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        onChange={() => {
          onChange?.(value)
        }}
      />
      <Checkmark className="checkmark" />
    </Container>
  )
}
