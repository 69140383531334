import React from 'react';
import styled from 'styled-components'
import {AtlasColor} from "../../foundations/Colors.foundations";

interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  children: any;
}

export const PrimaryButtonAtom = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  height: 36px;

  /* Primary/00 */
  border-radius: 8px;
  border: none;
  outline: none;

  /* Atlas/ Button */
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  
  background: ${AtlasColor.Primary00} center;
  transition: background 0.8s;
  color: ${AtlasColor.Background};

  &:active {
    background-color: #fc8600; // TODO: Move color to Foundations ¿AtlasColor.BackgorundActive?
    background-size: 100%;
    transition: background 0s;
  }
  
  &:disabled {
    background-color: ${AtlasColor.Neutral60}; // TODO: Move color to Foundations ¿AtlasColor.BackgorundActive?
  }
`
