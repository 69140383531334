import { useNavigate } from 'react-router-dom'

export const useGoToPickingList = () => {
  const navigate = useNavigate()

  return {
    go: (orderId: string, docId: string) => {
      navigate(
        PICKING_LIST_URL.replace(':orderId', orderId).replace(':docId', docId)
      )
    }
  }
}

export const PICKING_LIST_URL = '/inventory-transfer/:orderId/:docId'
