import { msInventoryAxiosInstance } from 'utils/ms-inventory.axios.instance'
import { type ProductResponse } from './inventory.types'

export async function getStock (ean: string, containerOrLocation: string): Promise<number> {
  let params: any
  if (containerOrLocation.includes('-LPN-')) {
    params = { container: containerOrLocation }
  } else {
    params = { location: containerOrLocation }
  }
  return await msInventoryAxiosInstance().get<{ stock: number }>(`/v2/stock/${ean}`, { params })
    .then((res) => res.data.stock)
}

export async function getProducts (containerOrLocation: string): Promise<ProductResponse[]> {
  if (containerOrLocation.includes('-LPN-')) {
    return await msInventoryAxiosInstance().get<ProductResponse[]>(`/v2/containers/${containerOrLocation}/grouped`)
      .then(({ data }) => data)
  } else {
    return await msInventoryAxiosInstance().get<ProductResponse[]>(`/v2/locations/${containerOrLocation}/grouped`)
      .then(({ data }) => data)
  }
}
