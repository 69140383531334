import { useState } from 'react'
import { useAtomValue } from 'jotai'
import { useFormik } from 'formik'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import type { UpdateMissingsData } from 'types/model'
import { Analytics } from 'analytics/analytics.events'
import { useRequiredParams } from 'hooks/params.hook'
import { selectedProductAtom } from '../Receipt.state'
import { useGoToProductLots } from '../product-lots/ProductLots.navigator'
import { isTotalsData } from './PartialMissing.loader'
import { getMissingLimit, getValidationSchema } from './PartialMissing.helpers'
import { updateMissings } from '../client/Receipt.client'

export const usePartialMissingData = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const data = useLoaderData()
  const product = useAtomValue(selectedProductAtom)
  const [loading, setLoading] = useState(false)
  const { orderId, receiptId, productId } = useRequiredParams(
    'orderId',
    'receiptId',
    'productId'
  )
  const productLots = useGoToProductLots()
  if (!isTotalsData(data)) {
    throw new Error(t('receipt-partial-missing.type-error'))
  }

  const { totalUnits, boxes } = getMissingLimit(product, data)
  const formConfig = useFormik({
    initialValues: {
      totalMissing: data.totalMissing || '',
      boxes: data.totalMissingBoxes || ''
    },
    validationSchema: getValidationSchema(
      t,
      totalUnits,
      boxes,
      product?.measureUnit || ''
    ),
    onSubmit: async ({ totalMissing, boxes }) => {
      if (!product) {
        return
      }
      try {
        setLoading(true)
        const updateData: UpdateMissingsData = {
          product: {
            id: product.productId,
            expectedQuantity: product.totalUnits,
            barcode: product.barcode
          },
          receiptId,
          isFullMissing: false,
          reportedMissing: +totalMissing,
          reportedMissingBoxes: boxes ? +boxes : undefined
        }
        await updateMissings(updateData)
        Analytics.receipt.addPartialMissing(
          {
            orderId,
            receiptType: 'providers',
            units: updateData?.reportedMissing ?? 0,
            boxes: updateData?.reportedMissingBoxes
          },
          product
        )

        productLots.go(receiptId, orderId, productId)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  })

  return {
    formConfig,
    loading,
    product,
    actions: {
      goBack: () => {
        navigate(-1)
      }
    }
  }
}
