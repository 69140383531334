import { axiosInstance as forkliftInstance } from '../../utils/forklift.instance'
import { MoveForkLiftProps, Pallets } from './forklift.types'

export async function getContainersWithAlerts (): Promise<Pallets[]> {
  return await forkliftInstance()
    .get('/pallets')
    .then(({ data }) => data)
}

export const lowerContainer = async ({ origin, destination }: MoveForkLiftProps) => {
  const url = `/pallets/${origin.container}/drop`
  return await forkliftInstance()
    .post(url, {
      target: destination.location
    })
}

export const liftContainer = async ({ origin, destination }: MoveForkLiftProps) => {
  const url = `/pallets/${origin.container}/lift`
  return await forkliftInstance()
    .post(url, {
      target: destination.location
    })
}