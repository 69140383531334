import { isString } from 'lodash'
import { getInventoryProductsClient } from '../../../client/utilities.client'

export const moveAllLoader = async ({ params, request }) => {
  const {
    origin,
    container
  } = params

  const url = request.url
  if (!isString(url)) { throw new Error('Internal error on react router: request.url is not a string') }
  const containerId = container === 'null' ? null : container
  
  const searchParams = new URL(url).searchParams
  const moveContainer = searchParams.get('moveContainer') === 'true'
  const looseProducts = searchParams.get('looseProducts') === 'true'

  const inventoryProducts = await getInventoryProductsClient(String(origin), String(containerId), looseProducts)

  if (moveContainer) {
    // TODO: Call service to get all items for this origin
    return {
      moveContainer: true,
      inventoryProducts
    }
  }

  const inventoryItems = searchParams.getAll('inventoryItem')
  const quantity = searchParams.get('quantity')

  if (quantity) {
    if (inventoryItems.length !== 1) {
      throw new Error('Cannot receive Quantity parameter if inventoryItems are more than 1')
    }
    const inventoryItemEan = inventoryItems[0]
    const inventoryItem = inventoryProducts.filter(
      e => e.productEan === inventoryItemEan
    ).map(e => ({
      ...e,
      stock: Number(quantity),
      quantity: Number(quantity)
    }))
    return {
      moveContainer: false,
      oneInventoryItem: true,
      inventoryProducts: inventoryItem
    }
  }

  // TODO: Call service to get some items for this origin
  return {
    moveContainer: false,
    inventoryProducts: inventoryProducts.filter(
      e => inventoryItems.some(i => e.productEan === i)
    )
  }
}
