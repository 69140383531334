import { useNavigate } from 'react-router-dom'

export const useConfirmLiftNavigator = () => {
  const navigate = useNavigate()
  return {
    go: (containerName) => {
      navigate(
        CONFIRM_LIFT
          .replace(':containerName', containerName)
      )
    }
  }
}

export const CONFIRM_LIFT = '/forklift/lift-container/:containerName/destination'
