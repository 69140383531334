import { type ScannedLocation } from "client/scanner/scanner.types"

export const validateLocationToLift = (destination: ScannedLocation) => {
  const LOCATION_LIFT_REGEX = /^[A-Z]{2,3}-ALM-P[0-9]+-R[0-9]+-N[0-9]+-U[0-9]+$/
  
  if (!LOCATION_LIFT_REGEX.test(destination.location.name)) 
    throw new Error('La ubicación no es de almacenamiento de altura')

  if(destination.totalContainers > 0)
    throw new Error('La ubicación destino no puede tener contenedores')

  return destination.location
}
