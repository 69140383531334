import { useNavigate } from 'react-router-dom'

export const REVERSE_LOGISTICS_REGISTER_URL = '/reverse-logistics/register/:ean'

export const useReverseLogisticsRegister = () => {
  const navigate = useNavigate()
  return {
    go: (ean: string) => {
      navigate(REVERSE_LOGISTICS_REGISTER_URL.replace(':ean', ean))
    }
  }
}
