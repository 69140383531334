import styled from 'styled-components'
import type { CSSProperties, ReactNode } from 'react'
import { Divider } from '../Divider.atom'
import { AtlasColor } from '../../foundations/Colors.foundations'
import React from "react";

type CardVariants = 'none' | 'highlight' | 'selected'

interface CardLayoutProps {
  $variant: CardVariants
  $centered: boolean
}

const CardLayoutBorders: Record<CardVariants, string> = {
  highlight: 'none',
  none: `1px solid ${AtlasColor.PrimarySurface}`,
  selected: `1px solid ${AtlasColor.Primary00}`
}

export const CardLayout = styled.div<CardLayoutProps>`
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  border: 1px;
  border: ${({ $variant }) => CardLayoutBorders[$variant] || CardLayoutBorders.none};
  color: ${({ $variant }) => $variant === 'highlight' ? AtlasColor.Neutral00 : AtlasColor.Neutral40};
  background-color: ${({ $variant }) => $variant === 'highlight' ? AtlasColor.PrimarySurface : 'transparent'};
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${({ $centered }) => {
    if ($centered) {
      return `
                justify-content: center;
                align-items: center;
            `
    }
    return ''
  }}
`

export const CardDivider = () =>
    <CardRow>
        <Divider/>
    </CardRow>

export const CardRow = styled.div<{ $gap?: number }>`
  align-items: center;
  display: flex;
  gap: ${({ $gap }) => $gap ?? 8}px;
`

interface CardComponentProps {
  children: ReactNode
  $variant?: CardVariants
  $centered?: boolean
  color?: AtlasColor | 'inherit'
  style?: CSSProperties
  onClick?: () => void
  "data-test"?:string
}

export const CardComponent = (props: CardComponentProps) => {
  const {
    children,
    $variant = 'none',
    color = 'inherit',
    $centered = false,
    style,
    onClick,
  } = props;


  return <CardLayout
        $variant={$variant}
        $centered={$centered}
        style={{ ...style, color }}
        onClick={onClick}
        data-test={props["data-test"]}>
        {children}
    </CardLayout>
}
