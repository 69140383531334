import { AtlasBodyMain, AtlasCaption, AtlasColor, AtlasH1, CardComponent, CardRow } from '@justomx/atlas-components'
import { Highlight } from '../atoms/Highlight.atom'
import { useTranslation } from 'react-i18next'
import { formatWeight } from '../../utils/product'

/**
 * MOVE THIS TO THE COMPONENT LIBRARY
 */

interface CounterCardProps {
  title: string | number
  subtitle?: string | number
  overline?: string
}

export const CounterCardMolecule = ({
  title,
  subtitle,
  overline
}: CounterCardProps) => {
  return <CardComponent
    $variant={'highlight'}
    $centered
  >
    {overline ? <AtlasCaption>{overline}</AtlasCaption> : null}
    <AtlasH1>{title}</AtlasH1>
    {subtitle ? <AtlasCaption>{subtitle}</AtlasCaption> : null}
  </CardComponent>
}

/**
 * END MOVE THIS TO THE COMPONENT LIBRARY
 */

interface InventoryProductCountParams {
  stock?: number
  quantity?: number
  isWeighable?: boolean
  overline?: string
  subtitle?: string
  count: string | number
  location: string
}

export const InventoryProductCountMolecule = ({
  overline,
  subtitle,
  location,
  stock,
  quantity,
  isWeighable,
  count
}: InventoryProductCountParams) => {
  const { t } = useTranslation('global')
  const pre = t('inventory-product-count-molecule.description.pre')

  const stockInString = stock ? (isWeighable ? formatWeight(stock) : stock.toString()) : undefined
  const quantityInString = quantity ? (isWeighable ? formatWeight(quantity) : quantity.toString()) : undefined

  const countString = t('inventory-product-count-molecule.description.high', {
    value: quantityInString ?? count,
    uom: subtitle
  })

  const post = t('inventory-product-count-molecule.description.post')

  return (
    <>
      <CardRow style={{ color: AtlasColor.Neutral40 }}>
        <AtlasBodyMain>
          <CardRow>
            <AtlasCaption>{pre}</AtlasCaption>
            <Highlight>{countString}</Highlight>
            <AtlasCaption>{post}</AtlasCaption>
            <Highlight>{location}</Highlight>
          </CardRow>
        </AtlasBodyMain>
      </CardRow>
      <CardRow>
        <CounterCardMolecule
          title={stockInString ?? count}
          subtitle={subtitle}
          overline={overline} />
      </CardRow>
    </>
  )
}
