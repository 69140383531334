import Axios, { type AxiosInstance } from 'axios'
import { type CommonHeaders, getCommonHeaders } from '../client/utilities.client-helper'
import { isProduction } from './environment'

let axios

function configureAxios () {
  if (isProduction()) {
    return Axios.create({
      baseURL: 'https://api.justo.cloud/'
    })
  }

  return Axios.create({
    baseURL: 'https://api.stag.justo.cloud/'
  })
}

function getAxiosInstance (): AxiosInstance {
  if (!axios) {
    axios = configureAxios()

    axios.interceptors.request.use((config) => {
      const headers: CommonHeaders = getCommonHeaders()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(headers).map(e => config.headers.set(e, headers[e]))
      return config
    })
  }

  return axios
}

export const tokenAxiosInstance = getAxiosInstance
