import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {useProduct} from '../../../hooks/product.hook'
import {useGoToInventoryProductList} from '../product-list/InventoryProductList.navigator'
import {toast} from 'react-toastify'
import {createInventoryProductClient, type CreateInventoryProductClientProp} from '../InventoryFix.client'
import {Analytics} from "../../../analytics/analytics.events";
import {useCurrentLocationAndContainer} from "../../../hooks/currentLocation.hook";

interface NewInventory {
  quantity: number
  lot: string
  expiration: string
}

function validateNewInventory ({ lot, quantity, expiration }: NewInventory) {
  if (quantity && !lot?.trim() && !expiration?.trim()) {
    return {}
  }

  return lot?.trim() &&
  quantity &&
  expiration?.trim()
    ? {}
    : { disabled: true } // TODO: Move this to a better place
}

export const useCreateProduct = () => {
  const navigate = useNavigate()
  const inventoryList = useGoToInventoryProductList()
  const product = useProduct()
  const locationAndContainer= useCurrentLocationAndContainer()
  const [newInventory, setNewInventory] = useState<NewInventory>({
    quantity: 0,
    lot: '',
    expiration: ''
  })
  const [loading, setLoading] = useState<boolean>(false)

  const canConfirm = validateNewInventory(newInventory)

  return {
    value: product,
    locationAndContainer,
    newInventory,
    canConfirm,
    actions: {
      confirm: async () => {
        setLoading(true)
        const newProductInventory: CreateInventoryProductClientProp = {
          containerId: locationAndContainer.container?.legacyId ?? undefined,
          containerName: locationAndContainer.container?.name ?? undefined,
          locationId: locationAndContainer.location?.legacyId as string,
          locationName: locationAndContainer.location?.name as string,
          productEan: product.ean,
          productId: product.legacyId,
          productSku: product.sku,
          quantity: newInventory.quantity
        }

        if (newInventory.expiration) {
          newProductInventory.expirationDate = newInventory.expiration
        }

        if (newInventory.lot) {
          newProductInventory.lot = newInventory.lot
        }

        try {
          await createInventoryProductClient(newProductInventory)

          Analytics.inventoryFix.createProduct({
            location: {
              locationName: locationAndContainer.location?.name as string,
              locationType: locationAndContainer.location?.type as string,
              containerId: locationAndContainer.container?.legacyId as string,
              containerName: locationAndContainer.container?.name as string
            },
            count: newInventory.quantity,
            ean: product.ean
          })

          toast.success('Se ha creado el SKU: ' + product.sku)
          setTimeout(() => {
            setLoading(false)
            inventoryList.go(locationAndContainer)
          }, 2000)
        } catch (e) {
          console.error('Error on create inventory', e)
          setLoading(false)
          toast.error('Error al crear nuevo inventario. ' + e?.message)
        }
      },
      goBack: () => {
        navigate(-1)
      },
      setQuantity: (quantity: number) => {
        setNewInventory(state => ({ ...state, quantity }))
      },
      setLot: (lot: string) => {
        setNewInventory(state => ({ ...state, lot }))
      },
      setExpiration: (expiration: string) => {
        setNewInventory(state => ({ ...state, expiration }))
      }
    },
    loading
  }
}
