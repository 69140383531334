import { useNavigate } from 'react-router-dom'

export const REVERSE_LOGISTICS_URL = '/reverse-logistics'

export const useReverseLogistics = () => {
  const navigate = useNavigate()
  return {
    go: () => {
      navigate(REVERSE_LOGISTICS_URL)
    }
  }
}
