import { useState } from 'react'

export const useWeightSystem = (initialWeight?: number | null) => {
  const [weights, setWeights] = useState<number[]>(initialWeight != null ? [initialWeight] : [])

  return {
    weights,
    total: weights.reduce((prev, acc) => prev + acc, 0),
    add: (weight: number): void => {
      setWeights([...weights, weight])
    },
    remove: (weight: number) => {
      const index = weights.indexOf(weight)
      if (index >= 0) {
        const temp = [...weights]
        temp.splice(index, 1)
        setWeights(temp)
      }
    }
  }
}
