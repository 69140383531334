import { useAtom } from 'jotai'
import {
  containerAdministrationContainerToLift,
  containerAdministrationLastDropState,
} from '../../ContainerAdministration.state'
import { useContainerAdministrationNavigator } from '../../ContainerAdministration.navigator'
import { toast } from 'react-toastify'
import { Analytics } from '../../../../analytics/analytics.events'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useScanner } from 'hooks/scanner.hook'
import { getLocationFromScan } from 'client/scanner/scanner.client'
import { type LocationInfo } from 'types/model'
import { useTranslation } from 'react-i18next'
import { liftContainer } from 'client/forklift/forklift.client'
import { validateLocationToLift } from './ConfirmLift.validator'

export const useConfirmLift = (onError: (str: string) => void) => {
  const [containerToLift] = useAtom(containerAdministrationContainerToLift)
  const [, setLastDropped] = useAtom(containerAdministrationLastDropState)
  const [suggestedDestination, setSuggestedDestination] = useState<LocationInfo>()
  const containerAdministration = useContainerAdministrationNavigator()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { keys, getScan } = useScanner()
  const { t } = useTranslation('global')
  
  const lift = async (destination) => {
    setLastDropped('')
    if (!containerToLift) return
    try {
      await liftContainer({
        origin: containerToLift,
        destination: {
          location: destination.name,
          locationType: destination.type,
        }
      })
      Analytics.forklift.upliftContainer({
        origin: {
          locationName: containerToLift.location,
          locationType: containerToLift.locationType,
          containerName: containerToLift.container
        },
        destination: {
          locationName: destination.name,
          locationType: destination.type,
        }
      })
      setTimeout(() => {
        toast.success(t(`confirm-lift.success`, { containerName: containerToLift.container, locationName: destination.name }))
        setIsLoading(false)
        containerAdministration.go()
      }, 3000)
    } catch (e) {
      console.error(e)
      onError('Hubo un error al subir contenedor: ' + e.message)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    //TODO: get suggested destination from backend
    // setSuggestedDestination({})
  }, [])

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setIsLoading(true)
      getLocationFromScan(scanned)
        .then(destination => validateLocationToLift(destination))
        .then(location => lift(location))
        .catch(e => {
          console.error(e);
          onError(e.message);
          setIsLoading(false)
        });
    }
  }, [keys])



  return {
    containerToLift,
    suggestedDestination,
    isLoading,
    actions: {
      goBack: () => {
        navigate(-1)
      }
    }
  }
}
