import { useNavigate } from 'react-router-dom'
import { type ScannedContainer, type ScannedLocation, ScanType } from '../../../../client/scanner/scanner.types'

export const ALERTS_SCAN_PRODUCT_URL = '/alerts/:source'

export const useAlertsScanProductNavigator = () => {
  const navigate = useNavigate()
  return {
    go: (scanned: ScannedLocation | ScannedContainer) => {
      const source = scanned.type === ScanType.Location ? scanned.location.name : scanned.container.name
      navigate(ALERTS_SCAN_PRODUCT_URL.replace(':source', source))
    }
  }
}
