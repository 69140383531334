import { type LocationOrContainer } from 'types/model'

export function validateOrigin (origin: LocationOrContainer, hasLooseProducts: boolean) {

  if (origin.location.name.includes('-RE-')) {
    throw Error('O-1: No se puede escanear una ubicación o contenedor en ubicación de recibo')
  }

  if (origin.location.type === 'Storage' && origin.totalContainers === 0 && !hasLooseProducts) {
    throw Error('O-3: No se puede escanear una ubicación de almacenamiento sin contenedor')
  }

  if (origin.location.type === 'Rack' && origin.totalContainers === 0 && !hasLooseProducts) {
    throw Error('O-5: No se puede escanear una ubicación de Rack sin contenedor')
  }

  if (origin.location.type === 'PreStorage' && origin.totalContainers === 0 && !hasLooseProducts) {
    throw Error('O-5: No se puede escanear una ubicación de pre-almacenamiento sin contenedor')
  }

  return origin
}
