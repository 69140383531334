import { SEARCH_PRODUCT_NAVIGATOR } from './search-product/SearchProduct.navigator'
import { SearchProductPage } from './search-product/SearchProduct.page'
import { PRODUCT_CONTAINER_LIST_NAVIGATOR } from './product-containers-list/ProductContainersList.navigator'
import { ProductContainersListPage } from './product-containers-list/ProductContainersList.page'

export const productRouter = [
  {
    id: '(product) Search containers for product',
    path: SEARCH_PRODUCT_NAVIGATOR,
    element: <SearchProductPage />
  }, {
    id: '(product) List containers for product',
    path: PRODUCT_CONTAINER_LIST_NAVIGATOR,
    element: <ProductContainersListPage />
  }
]
