
import { useScanner } from '../../../../hooks/scanner.hook'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { reverseLogisticsSelectedProductAtom, reverseLogisticsScannedSuppliesAtom } from '../reverse-logistics.state'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useReverseLogisticsNavigator } from '../ReverseLogistics.navigator'
import { useRequiredParams } from 'hooks/params.hook'
import { extractWeight } from 'utils/product'
import { getScannedInfo } from 'client/scanner/scanner.client'
import { registerUnpick } from '../reverse-logistics.client'
import { validateRegister } from './RegisterProductQuantity.validator'
import { getRulesFromProductClient } from 'client/utilities.client'
import { useReverseLogisticsScanProduct } from '../scan-product/ScanProduct.navigator'
import { isScannedLocation, isScannedProduct } from 'client/scanner/scanner.types'
import { Analytics } from 'analytics/analytics.events'
import { formatUnpickItemEvent } from './RegisterProductQuantity.helpers'
import { ReverseLogisticProductInfo, TareOrProduct } from 'types/model'
import { sortSuppliesByPendingQuantity, updateSupplyFromResponse } from '../reverse-logistics.helpers'

export const useRegister = () => {
  const {keys, getScan} = useScanner()
  const navigate = useNavigate()
  const { t } = useTranslation('global')
  const reverseLogistics = useReverseLogisticsNavigator()
  const scanProduct = useReverseLogisticsScanProduct()

  const [selectedProduct, setSelectedProduct] = useAtom(reverseLogisticsSelectedProductAtom)
  const [scannedSupplies, setScannedSupplies] = useAtom(reverseLogisticsScannedSuppliesAtom)
  
  const [loading, setLoading] = useState(false)
  const { ean } = useRequiredParams('ean')
  const firstWeight = extractWeight(ean)
  const [suggestedLocations, setSuggestedLocations] = useState<any>()
  const [weights, setWeights] = useState<number[]>(firstWeight != null ? [firstWeight] : [])
  const [quantity, setQuantity] = useState<number>(
    selectedProduct.isWeighable ? 
    firstWeight != null ? 
    firstWeight : 
    0 :
    selectedProduct.pendingQuantity === 1 ? 
    1 : 
    0
  )

  const totalWeights = weights.reduce((prev, it) => prev + it, 0)

  const addItem = (item: number) => {
    if (item > 0) {
      const newWeights = [...weights, item]
      const newTotalWeight = newWeights.reduce((acc, curr) => acc + curr, 0)
      if(newTotalWeight > selectedProduct.pendingQuantity) {
        toast.error(t('reverse-logistics.register.stock-error'));
      }
      else {
        setWeights(newWeights)
        setQuantity(newTotalWeight)
      }
    }
  }

  const deleteItem = (item: number) => {
    const index = weights.indexOf(item)
    if (index >= 0) {
      const temp = [...weights]
      temp.splice(index, 1)
      setWeights(temp)
      setQuantity(temp.reduce((acc, curr) => acc + curr, 0))
    }
  }

  const handleNavigation = (supplies: TareOrProduct[]) => {
    if (supplies[0].type === 'Product') {
      reverseLogistics.go();
    } else {
      const allProductsWithPendingQuantityZero = supplies.every(supply => 
        supply.products.every(product => product.pendingQuantity === 0)
      ); 
      if (allProductsWithPendingQuantityZero) {
        reverseLogistics.go();
      } else {
        scanProduct.go();
      }
    }
  };

  useEffect(() => {
    getRulesFromProductClient(selectedProduct.id as string)
    .then(e => setSuggestedLocations(e.data[0]?.locations?.map(e => e.locationName)))
    .catch(e => {
      console.error(e)
      toast.error('Error getting suggested locations:' + e.message as string)
    })
  }, [])

  useEffect(() => {
    const scanned = getScan();
  
    if (!scanned || loading) {
      return;
    }
  
    setLoading(true);
    getScannedInfo(scanned)
      .then((e) => {
        if (isScannedProduct(e)) {
          if (e.product.isWeighable) {
            if (selectedProduct.ean === e.product.ean) {
              const weight = extractWeight(scanned);
              if (weight) {
                addItem(weight);
              }
            } else {
              toast.error(t('reverse-logistics.register.different-product'));
            }
          }
        }
  
        if (isScannedLocation(e)) {
          try {
            validateRegister(scanned, quantity, selectedProduct, suggestedLocations, t);
            let remainingQuantity = quantity;

            // Filter supplies with selected product and sort by pending quantity
            const sortedSupplies = sortSuppliesByPendingQuantity(scannedSupplies,selectedProduct);
  
            // Accumulate the API calls in a list of promises
            const unpickPromises = sortedSupplies
              .filter((supply) => remainingQuantity > 0 && supply.products.find((p) => p.ean === selectedProduct.ean)) 
              .map((supply) => {
                if (remainingQuantity <= 0) return null;
                const product = supply.products.find((p) => p.ean === selectedProduct.ean);
                if (!product) return null;
    
                const pendingQuantity = product.pendingQuantity;
                const unpickQuantity = Math.min(remainingQuantity, pendingQuantity);
    
                remainingQuantity -= unpickQuantity;
    
                return registerUnpick({
                  id: supply.id,
                  ean: selectedProduct.ean,
                  location: scanned,
                  quantity: unpickQuantity,
                })
                  .then((res) => {
                    setSelectedProduct(res.products.find((p) => p.ean === selectedProduct.ean) as ReverseLogisticProductInfo);
    
                    const eventData = formatUnpickItemEvent(selectedProduct, supply, e.location, unpickQuantity);
                    Analytics.reverseLogistics.UnpickItem(eventData);

                    return res
                  })
                  .catch((error) => {
                    throw new Error(`Error registering unpick: ${error.message}`);
                  });
            });
  
            // Filter out null values and handle the promises
            Promise.all(unpickPromises.filter(Boolean))
              .then((responses) => {
                const updatedSupplies = scannedSupplies.map((supply) => {
                  const response = responses.find((res) => res.id === supply.id);
                  return response ? updateSupplyFromResponse(supply, response) : supply;
                });
                setScannedSupplies(updatedSupplies);
                handleNavigation(updatedSupplies);

                toast.success(
                  t('reverse-logistics.register.success', {
                    quantity,
                    location: scanned,
                    uom: selectedProduct.isWeighable ? 'g' : 'unidades',
                  })
                );
              })
              .catch((error) => {
                toast.error(error.message);
              })
              .finally(() => {
                setLoading(false);
              });
          } catch (error) {
            toast.error(error.message);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        toast.error('Error scanning ' + scanned + ': ' + error.message);
        console.error(error);
        setLoading(false);
      });
  }, [keys]);
  

  return {
    suggestedLocations,
    selectedProduct,
    loading,
    weights,
    totalWeights,
    setQuantity,
    actions: {
      goBack: () => {
        navigate(-1)
      },
      addItem,
      deleteItem
    }
  }
}
