import '../@types/custom.d.ts'

function _scanner (value: string) {
  return window.dispatchEvent(new MessageEvent('message', { data: String(value) }))
}

(window as any)._scanner = _scanner

const deviceWv = {
  openKeyboard: () => { WV.openKeyboard() },
  openMenu: () => { WV.openMenu() },
  setWebViewUrl: (url: string) => { WV.setWebViewUrl(url) },
  simulateKey: (key: string) => { WV.simulateKey(key) },
  testMessage: (msg: string) => { WV.testMessage(msg) },
  print: (document: string, transactionId: string) => { WV.print(document, transactionId) },
  _scanner
}

const browserWv = {
  openKeyboard: () => { console.log('WV.openKeyboard()') },
  openMenu: () => { console.log('WV.openMenu()') },
  setWebViewUrl: (url: string) => { console.log('WV.setWebViewUrl(url)', url) },
  simulateKey: (key: string) => { console.log('WV.simulateKey(key)', key) },
  testMessage: (msg: string) => { console.log('WV.testMessage(msg)', msg) },
  print: (document: string, transactionId: string) => { console.log('WV.print(document, transactionId)', document, transactionId) },
  _scanner
}

function getWV () {
  if ((window as any).WV) {
    return deviceWv
  }
  return browserWv
}

export const Global = {
  WV: getWV()
}
