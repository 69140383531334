import { AtlasBodyMain, CardComponent, Icon } from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'

interface CardInfoProps {
  icon: string
  description: string
}

export const CardInfoMolecule = (props: CardInfoProps) => {
  return (
    <CardComponent $variant="highlight">
      <FlexRowAtom $gap={8}>
        <Icon src={props.icon} size={15} />
        <AtlasBodyMain>{props.description}</AtlasBodyMain>
      </FlexRowAtom>
    </CardComponent>
  )
}
