import {useAtom} from "jotai/index";
import {useEffect, useState} from 'react'
import {toast} from "react-toastify";
import {useScanner} from '../../../hooks/scanner.hook'
import {validateDestination} from "./ScanDestination.validator";
import { useNavigate } from "react-router-dom";
import { registerWaste } from "../reverse-logistics.client";
import { reverseLogisticsSelectedItemAtom, reverseLogisticsSelectedProductAtom } from "../reverse-logistics.state";
import { useQueryParam } from "hooks/params.hook";
import { useReverseLogistics } from "../ReverseLogistics.navigator";
import { useTranslation } from "react-i18next";
import { Analytics } from 'analytics/analytics.events'
import { formatWasteOrMissingEvent } from "../register-product-quantity/RegisterProductQuantity.helpers";
import { ReverseLogisticProductInfo } from "types/model";
import { useReverseLogisticsScanProduct } from "../scan-product/ScanProduct.navigator";

export const useScanDestination = () => {
  const { keys, getScan } = useScanner()
  const [selectedProduct, setSelectedProduct] = useAtom(reverseLogisticsSelectedProductAtom)
  const [selectedItem, setSelectedItem] = useAtom(reverseLogisticsSelectedItemAtom)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const quantity = useQueryParam('quantity')
  const reverseLogistics = useReverseLogistics()
  const scanProduct = useReverseLogisticsScanProduct()
  const { t } = useTranslation('global')
  
  useEffect(() => {
    const scanned = getScan()
    if (scanned && !loading) {
      setLoading(true)
      if (validateDestination(scanned)) {
        registerWaste({
          id: selectedItem.id,
          ean: selectedProduct.ean,
          quantity: parseInt(quantity as string) ,
        })
        .then(res => {
          const product = res.products.find(p => p.ean === selectedProduct.ean)
          setSelectedProduct(product as ReverseLogisticProductInfo)
          setSelectedItem(res)
          const eventData = formatWasteOrMissingEvent(selectedProduct, selectedItem, quantity)
          Analytics.reverseLogistics.RegisterWaste(eventData)
          toast.success(t('reverse-logistics.register-waste-or-missing.success-waste', { 
            quantity, 
            unit: selectedProduct.isWeighable ? 'g' : parseInt(quantity as string) === 1 ? 'unidad' : 'unidades',
            product: selectedProduct.name
          }))
          if(res.status === 'Completed'){
            reverseLogistics.go()
          }
          else{
            scanProduct.go()
          } 
        })
        .catch((err) => {
            console.error('Error on create reverse logistic waste report', err)
            toast.error('Error al crear nuevo reporte de merma. ' + err?.response.data.detail)
        })
        .finally(() => {
          setLoading(false)
        })
      } else {
        toast.error(`No se puede escanear una ubicación que no sea de merma`)
        setLoading(false)
      }
    }
  }, [keys])

  return {
    actions: {
      goBack: () => { navigate(-1) },
    },
    loading
  }
}
