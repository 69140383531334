import {useLoaderData} from "react-router-dom";
import {isObject} from "../internal-types/IsObject";
import { ScanResponse } from "client/scanner/scanner.types";

type HasLocationAndContainer = {
  locationAndContainer: ScanResponse
}

const hasLocationAndContainer = (data: unknown): data is HasLocationAndContainer => {
  return isObject(data) && 'locationAndContainer' in data
}

export const useCurrentLocationAndContainer = () => {
  const data = useLoaderData()

  if (hasLocationAndContainer(data)) {
    return data.locationAndContainer
  }

  throw new Error('Expected location and container in loader data')
}