import {InventoryProductWithAlertInfo} from "../pages/fast-traslation/scan-product/ScanProduct.navigator";

export function alertComparator (a: InventoryProductWithAlertInfo, b: InventoryProductWithAlertInfo) {
  if (a.alert === b.alert) {
    return 0
  }

  if (a.alert === 'critical') {
    return -1
  }

  if (b.alert === 'critical') {
    return 1
  }

  if (a.alert === 'warning') {
    return -1
  }

  if (b.alert === 'warning') {
    return 1
  }

  return 0
}