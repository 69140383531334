import {useScanner} from '../../../hooks/scanner.hook'
import {useNavigate} from 'react-router-dom'
import {useGoToInventoryProductFix} from '../product-fix/InventoryProductFix.navigator'
import {useGoToCreateProduct} from '../create-product/CreateProduct.navigator'
import {useEffect} from 'react'
import {manageError} from '../../../utils/errors/error.handler'
import {useCurrentInventoryProducts} from "../../../hooks/inventoryProducts.hooks";
import {useCurrentLocationAndContainer} from "../../../hooks/currentLocation.hook";
import {isSameEan} from "../../../types/model";
import { getProductFromScan } from 'client/scanner/scanner.client'

export const useScanProductInContainer = () => {
  const inventoryProducts = useCurrentInventoryProducts()
  const locationAndContainer = useCurrentLocationAndContainer();
  const inventoryProductFix = useGoToInventoryProductFix()
  const createProduct = useGoToCreateProduct()
  const { keys, getScan } = useScanner()
  const navigate = useNavigate()

  useEffect(() => {
    const scanned = getScan()
    if (!scanned) return

    const scannedInventoryProduct = inventoryProducts.find(e => isSameEan(scanned, e.productEan))
    if (scannedInventoryProduct) {
      inventoryProductFix.go({
        locationAndContainer,
        ean: scannedInventoryProduct.productEan
      })
      return
    }

    getProductFromScan(scanned)
      .then(() => {
        createProduct.go({
          locationAndContainer,
          ean: scanned
        })
      })
      .catch(manageError('Producto no encontrado'))
  }, [keys])

  return {
    actions: {
      back: () => { navigate(-1) }
    }
  }
}
