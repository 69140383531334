import {AtlasBodySubtitle, AtlasColor, CardComponent, CardRow, Icon, Icons, StackAtom, Divider, AtlasBodyMain} from "@justomx/atlas-components";
import { ProductLocationDetail } from "client/products/products.types";
import ProductInfo from "./product-info.component";
import { InventoryProduct } from "types/model";
import { useTranslation } from "react-i18next";

type ProductWithStockProps = {
  product: InventoryProduct
}

export function ProductWithStock({product}: ProductWithStockProps) {
  const {t} = useTranslation('global')
  return <CardComponent>
    <StackAtom style={{flexDirection: 'column', alignItems: 'baseline'}} gap={12}>
      <ProductInfo product={{
        legacyId: product.productId,
        ean: product.productEan,
        sku: product.productSku,
        name: product.productName,
        image: product.productImage,
        isWeighable: product.byWeight,
        packagingLevels: []
      }}/>
      <Divider/>
      <CardRow style={{ width: '100%', justifyContent: 'space-between'}}>
        <AtlasBodyMain color={AtlasColor.Neutral40}>{t('universal-search.quantity')}</AtlasBodyMain>
        <AtlasBodyMain>{product.stock} {product.byWeight ? t('uom.grams') : t('uom.units')}</AtlasBodyMain>
      </CardRow>
    </StackAtom>
  </CardComponent>;
}

