import { useTranslation } from 'react-i18next'
import { type InventoryProduct } from '../../types/model'
import { InventoryProductPreviewMolecule } from '../molecules/InventoryProductPreview.molecule'
import { AtlasBodyTitle, StackAtom } from '@justomx/atlas-components'

interface MoveInventoryProductListOrganismProps {
  inventoryProducts: InventoryProduct[]
}

export const MoveInventoryProductListOrganism = ({ inventoryProducts }: MoveInventoryProductListOrganismProps) => {
  const { t } = useTranslation('global')
  return <StackAtom gap={16} style={{ overflow: 'auto' }}>
    <AtlasBodyTitle>{t('move-all.products-to-move')}</AtlasBodyTitle>
    <StackAtom gap={12}>
      {inventoryProducts.map(e =>
        <InventoryProductPreviewMolecule inventoryProduct={e} key={e.productEan + e.stock}/>
      )}
    </StackAtom>
  </StackAtom>
}
