import { useRequiredParams } from 'hooks/params.hook'
import { useNavigate } from 'react-router-dom'

export const useGoToScanContainer = () => {
  const navigate = useNavigate()
  const { receiptType } = useRequiredParams('receiptType')

  return {
    go: (receiptId: string, orderId: string, from?: string) => {
      let url = SCAN_CONTAINER_URL.replace(':receiptType', receiptType)
        .replace(':receiptId', receiptId)
        .replace(':orderId', orderId)
      if (from) {
        url += `?from=${from}`
      }
      navigate(url)
    }
  }
}

export const SCAN_CONTAINER_URL =
  '/receipt/:receiptType/:receiptId/order/:orderId/scan-container'
