import { useNavigate } from 'react-router-dom'
import { type InventoryProduct, type LocationAndContainer } from '../../../types/model'
import { useAtom } from 'jotai/index'
import { inventoryProductAtom } from '../../inventory-fix/InventoryFix.state'

export const MOVE_ONE_CONFIGURATION = '/traslation/:origin/:container/move-one-product-to/:destination'

const inventoryProductQueryParam = 'inventoryItem='

interface GoFromPicking {
  origin: LocationAndContainer
  destination: LocationAndContainer
  container: undefined | null
  inventoryProduct: InventoryProduct
}

interface GoFromLocationWithContainer {
  origin: LocationAndContainer
  destination: LocationAndContainer
  inventoryProduct: InventoryProduct
}

type Go = GoFromPicking | GoFromLocationWithContainer

export const useGoToMoveOneConfiguration = () => {
  const navigate = useNavigate()
  const [_, setInventoryProduct] = useAtom(inventoryProductAtom)

  return {
    go: ({ origin, destination, inventoryProduct }: Go) => {
      setInventoryProduct(inventoryProduct)
      const url = MOVE_ONE_CONFIGURATION
        .replace(':origin', origin.locationId)
        .replace(':container', origin.containerId || 'null')
        // .replace(':destination', destination.locationId)

      navigate(`${url}?${inventoryProductQueryParam}${inventoryProduct.productEan}`)
    }
  }
}
