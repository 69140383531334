import {useNavigate} from 'react-router-dom'
import { type ScanResponse } from 'client/scanner/scanner.types'

interface Go {
  locationAndContainer: ScanResponse
}

export const useGoToScanProductInContainer = () => {
  const navigate = useNavigate()

  return {
    go: ({ locationAndContainer }: Go) => {
      const uri = SCAN_PRODUCT_IN_CONTAINER
        .replace(':location', locationAndContainer.location?.name as string)
        .replace(':container', locationAndContainer.container?.name || 'null')
      navigate(uri)
    }
  }
}

export const SCAN_PRODUCT_IN_CONTAINER = '/inventory-fix/:location/:container/product/scan'
