import { useScanner } from '../../../hooks/scanner.hook'
import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { useReverseLogisticsRegister } from '../register-product-quantity/RegisterProductQuantity.navigator'
import { reverseLogisticsSelectedProductAtom, reverseLogisticsSelectedItemReader } from '../reverse-logistics.state'
import { getProductFromScan } from 'client/scanner/scanner.client'
import { ReverseLogisticProductInfo } from 'types/model'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { useReverseLogisticsRegisterWasteOrMissing } from '../register-waste-or-missing/RegisterWasteOrMissing.navigator'
import { useReverseLogistics } from '../ReverseLogistics.navigator'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const useScanProduct = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const navigate = useNavigate()
  const registerNavigator = useReverseLogisticsRegister()
  const [selectedProduct, setSelectedProduct] = useAtom(reverseLogisticsSelectedProductAtom) 
  const [selectedItem] = useAtom(reverseLogisticsSelectedItemReader)
  const [loading, setLoading] = useState(false)
  const {
    dialogRef,
    open: openWasteOrMissingDialog,
  } = useConfirmationDialog()
  const {
    dialogRef: typeProductDialogRef,
    open: openTypeProductDialog,
  } = useConfirmationDialog()
  const { go } = useReverseLogisticsRegisterWasteOrMissing()
  const reverseLogistics = useReverseLogistics()
  const { t } = useTranslation('global')

  const searchProduct = async (ean: string) => {
    setLoading(true)
      getProductFromScan(ean)
        .then(e => {
          const scannedProduct = selectedItem.products.find(p => p.ean === e.product.ean)
          if(scannedProduct) {
            setSelectedProduct({...scannedProduct, id: e.product.legacyId} as ReverseLogisticProductInfo)
            registerNavigator.go(ean)
          }
          else {
            toast.error(t('reverse-logistics.scan-product.error-not-found'))
          }
        })
        .catch(e => {
          console.error(e)
          toast.error('Error al obtener producto: ' + ean)
        })
        .finally(() => {
          setLoading(false)
        })
  }
  
  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      searchProduct(scanned)
    }
  }, [keys])

  return {
    tare: selectedItem.tara?.barcode as string,
    products: selectedItem.products.filter((item) => item.status !== 'Completed'),
    loading,
    dialogRef,
    typeProductDialogRef,
    selectedProduct,
    actions: {
      searchProduct,
      openWasteOrMissingDialog: (product) => {
        setSelectedProduct(product)
        openWasteOrMissingDialog()
      },
      openTypeProductDialog,
      goBack: () => {
        navigate(-1)
      },
      goToWasteOrMissingPage: go,
      goHome: () => {
        reverseLogistics.go()
      }
    }
  }
}
