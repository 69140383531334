import { atomWithStorage } from 'jotai/utils'
import { createReader } from '../../../utils/atomReader'
import { type AlertSuggestionProduct } from 'types/model'
import { type ProductWithStock } from './scan-product/ScanProduct.hook'

export const AlertsSelectedAtom = atomWithStorage<AlertSuggestionProduct | undefined>('RestockingAlertsSelected', undefined)
export const AlertsSelectedReader = createReader(AlertsSelectedAtom)

export const ProductSelectedAtom = atomWithStorage<ProductWithStock | undefined>('RestockingProductSelected', undefined)
export const ProductSelectedReader = createReader(AlertsSelectedAtom)
