import { useFeatureIsOn } from '@growthbook/growthbook-react'
import AlertsV2Page from './v2/Alerts.page'
import { AlertsV1Page } from './v1/Alerts.page'

const AlertsInitPage = () => {
  const isV2Enabled = useFeatureIsOn('op_utilities_front_new_alerts_ui')
  return isV2Enabled ? <AlertsV2Page /> : <AlertsV1Page />
}

export default AlertsInitPage
