import { useAtom } from 'jotai'
import { fastTraslationOrigin, fastTraslationProducts } from '../FastTraslation.state'
import { useRequiredParams } from '../../../hooks/params.hook'
import { useEffect, useState } from 'react'
import { useFastTraslationScanDestinationNavigator } from '../scan-destination/FastTraslationScanDestination.navigator'
import { getRulesFromProductClient, getTotalStockInWarehouse } from '../../../client/utilities.client'
import { isSameEan, normalizeEanForCapture } from 'types/model'
import { extractWeight } from 'utils/product'
import { useScanner } from 'hooks/scanner.hook'
import { useTranslation } from 'react-i18next'
import { formatString } from 'utils/string'
import { useNavigate } from 'react-router-dom'

export const useFastTransferWeight = (onError: (err: string) => void) => {
  const {
    keys,
    getScan
  } = useScanner()
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  const [inventoryProducts] = useAtom(fastTraslationProducts)
  const [currentLocation] = useAtom(fastTraslationOrigin)

  const scanDestination = useFastTraslationScanDestinationNavigator()

  const { ean } = useRequiredParams('ean')
  const normalizedEan = normalizeEanForCapture(ean)

  const firstWeight = extractWeight(ean)

  const [weights, setWeights] = useState<number[]>(firstWeight != null ? [firstWeight] : [])
  const weightToRestock = weights.reduce((prev, it) => prev + it, 0)

  const product = inventoryProducts.find(e => e.productEan === normalizedEan)
  const canConfirm = product && weightToRestock > 0 && weightToRestock <= product.stock

  const [totalStock, setTotalStock] = useState<number | null>(null)

  useEffect(() => {
    if (product && product.productId) {
      getRulesFromProductClient(product.productId)
        .catch(() => {
          console.error('Prefetching failed, just ignore')
        })
    }
    if (product && product.productEan) {
      getTotalStockInWarehouse(product.productEan)
        .then((data) => {
          setTotalStock(data.stock)
        })
        .catch(() => {
          setTotalStock(-1)
          console.error('Prefetching failed, just ignore')
        })
    }
  }, [product])

  useEffect(() => {
    const scanned = getScan()

    if (!scanned) {
      return
    }

    if (isSameEan(normalizedEan, scanned)) {
      const weight = extractWeight(scanned)

      if (weight == null) {
        onError(formatString(t('fast-set-weight.error-01'), scanned))
        return
      }

      setWeights([...weights, weight])
    } else {
      onError(formatString(t('fast-set-weight.error-02'), scanned))
    }
  }, [keys])

  const addItem = (item: number) => {
    if (item > 0) {
      setWeights([...weights, item])
    }
  }

  const deleteItem = (item: number) => {
    const index = weights.indexOf(item)
    if (index >= 0) {
      const temp = [...weights]
      temp.splice(index, 1)
      setWeights(temp)
    }
  }

  return {
    currentLocation,
    currentInventoryProduct: product,
    canConfirm,
    next: () => {
      scanDestination.go(normalizedEan, weightToRestock)
    },
    back: () => {
      navigate(-1)
    },
    weights,
    addItem,
    deleteItem,
    weightToRestock,
    totalStock
  }
}
