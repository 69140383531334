import styled from 'styled-components'

export const BadgeAtom = styled.div<{ $bg?: string }>`
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $bg }) => $bg};
`
