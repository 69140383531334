import { useNavigate } from 'react-router-dom'

export const useFastTraslation = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(FAST_TRASLATION_ROUTE)
    }
  }
}

export const FAST_TRASLATION_ROUTE = '/fast-traslation/'
