import { type TFunction } from 'i18next'
import { type Product } from 'types/model'
import * as Yup from 'yup'
import { validateStock } from './RegisterWaste.validator'

const isFruitOrVegetable = (product: Product) => product.productZone === 'Laboratorio'

export const wasteReasons = (product) => {
  if (!isFruitOrVegetable(product)) {
    return [
      'EXPIRATION',
      'QUALITY',
      'RETURN',
      'HARMFUL',
      'RECOVERY',
      'SINISTER'
    ]
  } else {
    return [
      'APPEARANCE',
      'WEIGHT',
      'DAMAGE',
      'DEFORMITY',
      'DEHYDRATION',
      'RETURN',
      'FUNGUS',
      'MATURATION',
      'OXIDATION',
      'DECAY'
    ]
  }
}

export const getValidationSchema = (
  t: TFunction<'global', undefined>,
  product
) => {
  const required = t('validations.required')
  const amount = t('validations.invalid-amount')
  const stockError = t('inventory-waste.register-waste.stock-error')
  return Yup.object({
    amount: Yup.number()
      .required(required)
      .positive(amount)
      .integer(amount)
      .test('validate-stock', stockError, (value) => validateStock(product, value)),
    reason: Yup.array().min(1, t('validations.single-select'))
  })
}
