import { useScanner } from '../../../../hooks/scanner.hook'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useRequiredParams } from 'hooks/params.hook'
import { extractWeight } from 'utils/product'
import { getLocationFromScan, getLocationOrContainerFromScan, getProductOrLocationFromScan } from 'client/scanner/scanner.client'
import { AlertsSelectedAtom, ProductSelectedAtom } from '../Alerts.state'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { restock } from 'client/alerts/alerts.client'
import { getLocationAssignedToProduct } from 'client/location-assignment/location-assignment.client'
import { type ProductLocation } from 'client/location-assignment/location-assignment.types'
import { getStock } from '../../../../client/inventory/inventory.client'
import { useNavigate } from 'react-router-dom'
import { waiting } from '../../../../utils/waiting'
import { useWeightSystem } from '../../../../hooks/weights.hook'
import { ScannedContainer, ScannedLocation, ScanType } from '../../../../client/scanner/scanner.types'
import { Analytics } from 'analytics/analytics.events'

export const useRegisterRestock = () => {
  const { t } = useTranslation('global')
  const { keys, getScan } = useScanner()
  const { ean } = useRequiredParams('ean')
  const { source } = useRequiredParams('source')
  const navigate = useNavigate()
  const initialWeight = extractWeight(ean)
  const weightSystem = useWeightSystem(initialWeight)

  const restockDialog = useConfirmationDialog<number>()
  const deleteWeightDialog = useConfirmationDialog<number>()

  const [alert] = useAtom(AlertsSelectedAtom)
  const [product] = useAtom(ProductSelectedAtom)

  const [loading, setLoading] = useState(false)
  const [suggestedLocations, setSuggestedLocations] = useState<ProductLocation[]>()
  const [destination, setDestination] = useState<ScannedLocation | null>(null)
  const [stock, setStock] = useState<number>(0)
  const [origin, setOrigin] = useState<ScannedLocation | ScannedContainer | null>(null)

  const [quantityToRestock, setQuantityToRestock] = useState<number>(0)

  /* On init: find source info for Analytics event  */
  useEffect(() => {
    getLocationOrContainerFromScan(source)
    .then(setOrigin)
    .catch(e => {
      console.error(e)
      toast.error('Error getting source location: ' + e.message)
    })
  }, [])


  /* On init: find location assignments for product  */
  useEffect(() => {
    if (product) {
      setLoading(true)
      Promise.all([
        getLocationAssignedToProduct(product.ean),
        getStock(product.ean, source)
      ]).then(([locations, stock]) => {
        setSuggestedLocations(locations.locations)
        setStock(stock)
      }).catch(e => {
        console.error(e)
        toast.error('Error getting suggested locations:' + e.message)
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [product])

  /* On scan */
  useEffect(() => {
    const textScanned = getScan()
    if (!textScanned || loading) {
      return
    }

    getProductOrLocationFromScan(textScanned)
      .then(scanned => {
        if (scanned.type === ScanType.Product) {
          const weight = extractWeight(textScanned)
          if (weight) {
            weightSystem.add(weight)
          }
        } else {
          if (suggestedLocations?.some(l => l.name === textScanned)) {
            setDestination(scanned)
          } else {
            toast.error(t('alerts.register.error-location-not-suggested'))
          }
        }
      })
      .catch(e => {
        toast.error('Error al escanear ' + textScanned + ': ' + e.message)
        console.error(e)
      })
  }, [keys])

  /* On change destination: restock product */
  useEffect(() => {
    if (!destination || loading) {
      return
    }

    const quantity = product?.isWeighable ? weightSystem.total : quantityToRestock
    if (quantity <= 0 || quantity > stock) {
      toast.error(t('alerts.register.quantity-error'))
      setDestination(null)
      return
    }

    if (alert && quantity < alert.expectedStock) {
      restockDialog.open()
    } else {
      registerRestock()
    }
  }, [destination])

  const registerRestock = () => {
    const quantity = quantityToRestock === 1 ? 1 : 
      product?.isWeighable ? weightSystem.total : quantityToRestock
    setLoading(true)
    restock({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ean: product!.ean,
      source,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      target: destination?.location.name!,
      quantity
    }).then(async () => {
      toast.success(t('alerts.register.success', {
        quantity,
        uom: product?.isWeighable ? 'g' : 'uds',
        product: product?.name ?? 'NN'
      }))
      Analytics.restock({
        count: quantity,
        ean: product?.ean as string,
        origin: {
          locationName: origin?.location.name as string,
          locationType: origin?.location.type as string,
          containerName: origin?.container?.name as string,
          containerId: origin?.container?.id as string
        },
        destination: {
          locationName: destination?.location.name as string,
          locationType: destination?.location.type as string
        },
        alertLevel: alert?.level as string
      })    
      await waiting(3_000)
      navigate(-1)
    }).catch((err: Error) => {
      toast.error('Error al registrar restock: ' + err.message)
      console.error(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    isLoading: loading,
    stock,
    expectedStock: product?.isWeighable
      ? Math.max(0, (alert?.expectedStock ?? 0) - weightSystem.total)
      : alert?.expectedStock ?? 0,
    suggestedLocations,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    product,
    alert,
    weights: weightSystem.weights,
    totalWeight: weightSystem.total,
    setQuantityToRestock,
    quantityToRestock,
    partialRestockDialogRef: restockDialog.dialogRef,
    deleteWeightDialogRef: deleteWeightDialog.dialogRef,
    weightToDelete: deleteWeightDialog.value ?? 0,
    actions: {
      goBack: () => {
        navigate(-1)
      },
      addItem: (item: number) => {
        weightSystem.add(item)
      },
      onRemoveItem: (item: number) => {
        deleteWeightDialog.open(item)
      },
      confirmRemoveItem: () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        weightSystem.remove(deleteWeightDialog.value!)
      },
      confirmRestock: () => {
        registerRestock()
      },
      clearDestination: () => {
        console.log('clearing destination...')
        setDestination(null)
      }
    }
  }
}
