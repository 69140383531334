import { useState } from 'react'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { useQueryParam } from 'hooks/params.hook'
import { reverseLogisticsSelectedItemAtom, reverseLogisticsSelectedProductAtom } from '../reverse-logistics.state'
import { registerMissing } from '../reverse-logistics.client'
import { useReverseLogistics } from '../ReverseLogistics.navigator'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useReverseLogisticsScanDestination } from '../scan-destination/ScanDestination.navigator'
import { formatWasteOrMissingEvent } from '../register-product-quantity/RegisterProductQuantity.helpers'
import { Analytics } from 'analytics/analytics.events'
import { useReverseLogisticsScanProduct } from '../scan-product/ScanProduct.navigator'
import { ReverseLogisticProductInfo } from 'types/model'

export const useRegisterWasteOrMissing = () => {
  const navigate = useNavigate()
  const flow = useQueryParam('flow')
  const [selectedProduct, setSelectedProduct] = useAtom(reverseLogisticsSelectedProductAtom)
  const [selectedItem, setSelectedItem] = useAtom(reverseLogisticsSelectedItemAtom)
  const [quantity, setQuantity] = useState<number | null>(selectedProduct.pendingQuantity === 1 ? 1 : null)
  const reverseLogistics = useReverseLogistics()
  const { t } = useTranslation('global')
  const scanDestination = useReverseLogisticsScanDestination()
  const scanProduct = useReverseLogisticsScanProduct()
  
  const register = async () => {
    if(flow === 'waste'){
      scanDestination.go(quantity)
    }
    else {
      if(quantity && quantity <= selectedProduct.pendingQuantity){
        registerMissing({
          id: selectedItem.id,
          ean: selectedProduct.ean,
          quantity: quantity as number
        })
        .then(res => {
          const product = res.products.find(p => p.ean === selectedProduct.ean)
          setSelectedProduct(product as ReverseLogisticProductInfo)
          setSelectedItem(res)
          const eventData = formatWasteOrMissingEvent(selectedProduct, selectedItem, quantity)
          Analytics.reverseLogistics.RegisterMissing(eventData)
          reverseLogistics.go()
          toast.success(t(`reverse-logistics.register-waste-or-missing.success-missing`, { 
            quantity, 
            unit: selectedProduct.isWeighable ? 'g' : quantity === 1 ? 'unidad' : 'unidades',
            product: selectedProduct.name
          }))
          if(res.status === 'Completed'){
            reverseLogistics.go()
          }
          else{
            scanProduct.go()
          }
        })
        .catch(e => {
          toast.error('Error al registrar: ' + e.message)
          console.error(e)
        })
      }
      else{
        toast.error(t('reverse-logistics.register-waste-or-missing.stock-error'))
      }
    }
  }

  return {
    flow,
    selectedProduct,
    quantity,
    setQuantity,
    actions: {
      register,
      goBack: () => {
        navigate(-1)
      },
      goHome: () => {
        reverseLogistics.go()
      }
    }
  }
}
