import React, { useEffect, useState } from 'react'

import { AtlasBodySubtitle, Icon, Icons } from '@justomx/atlas-components'
import { BadgeAtom } from 'components/atoms/Badge.atom'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { FlexSpaceBetweenAtom } from 'components/atoms/FlexSpaceBetween.atom'
import type { ProductLocation } from 'pages/inventory-transfer/types/InventoryTransfer.types'

interface ProductZoneProps {
  locations: ProductLocation[]
  zone?: string
}

interface LocationPart {
  letter: string
  num: string
}

export const ProductZoneMolecule = (props: ProductZoneProps) => {
  const { zone, locations } = props
  const [productZone, setProductZone] = useState<string | undefined>()

  useEffect(() => {
    if (!zone) {
      return
    }

    if (zone.toLowerCase().includes('laboratorio')) {
      setProductZone('laboratorio')
    } else {
      setProductZone(zone.toLowerCase())
    }
  }, [zone])

  const getLocationInfo = (location: string): LocationPart[] => {
    const start = location.lastIndexOf('P')
    const locationData = location.substring(start)
    const locationParts = locationData.split('-')
    return locationParts.map((part) => {
      const letter = part.substring(0, 1)
      const num = part.substring(1)
      return { letter, num }
    })
  }
  const locationParts = getLocationInfo(locations[0].locationName)

  const bgByZone = () => {
    if (!productZone) {
      return '#975DDF'
    }

    const bg = {
      abarrotes: '#FF6771',
      'alto valor': '#EA80FC',
      congelados: '#448AFF',
      granel: '#FAEE82',
      laboratorio: '#9CCC65',
      refrigerados: '#71E2F1',
      deli: '#FF9D6E'
    }[productZone]
    return bg || '#975DDF'
  }

  const iconByZone = () => {
    if (!productZone) {
      return Icons.icNoCategory
    }

    const icon = {
      abarrotes: Icons.icGroceries,
      'alto valor': Icons.icHighvalue,
      congelados: Icons.icFrozen,
      granel: Icons.IcBulk,
      laboratorio: Icons.icLaboratory,
      refrigerados: Icons.icRefrigerated,
      deli: Icons.IcBakery
    }[productZone]
    return icon || Icons.icNoCategory
  }

  return (
    <FlexSpaceBetweenAtom>
      <FlexRowAtom>
        {locationParts.map((part, idx) => (
          <React.Fragment key={idx}>
            <BadgeAtom $bg={bgByZone()}>
              <AtlasBodySubtitle color="#212121">
                {part.letter}
              </AtlasBodySubtitle>
            </BadgeAtom>
            <BadgeAtom>
              <AtlasBodySubtitle>{part.num}</AtlasBodySubtitle>
            </BadgeAtom>
          </React.Fragment>
        ))}
      </FlexRowAtom>

      <Icon src={iconByZone()} />
    </FlexSpaceBetweenAtom>
  )
}
