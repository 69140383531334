import type { GenericOption, ProductDetail } from 'types/model'

export const groupByStatus = (
  data: ProductDetail[]
): Record<string, ProductDetail[]> => {
  return data.reduce((acc, item) => {
    const { status } = item
    if (!acc[status]) {
      acc[status] = []
    }
    acc[status].push(item)
    return acc
  }, {})
}

export const tabs = ['pending', 'finished']
export const defaultActiveTab = 'pending'
export const missingOptions: GenericOption[] = [
  { id: 'fullMissing', label: 'Faltante completo' },
  { id: 'rejection', label: 'Rechazo' }
]
export const defaultMissingOption = 'fullMissing'
