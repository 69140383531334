import { LocationAndContainer, LocationOrContainer } from "types/model"
import { LocationAndContainerMolecule } from "./LocationAndContainer.molecule"

interface LocationAndContainerMoleculeProps {
  origin: LocationOrContainer
}

const LocationAndContainerMoleculeWrapper = ({origin}: LocationAndContainerMoleculeProps) => {
  const transformedOrigin: LocationAndContainer = {
    locationId: origin.location.id,
    locationName: origin.location.name,
    locationType: origin.location.type,
    containerId: origin.container?.id,
    containerName: origin.container?.name,
    containersInLocation: origin.totalContainers || 0,
  };
  return <LocationAndContainerMolecule location={transformedOrigin} />
}

export default LocationAndContainerMoleculeWrapper