import { toast } from 'react-toastify'
import { useTraslateScanOrigin } from '../scan-origin/ScanOrigin.navigator'
import { useInventoryProducts } from '../../../hooks/inventoryProducts.hooks'
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import { type InventoryProduct } from '../../../types/model'
import { useEffect, useState } from 'react'
import { isErrorResponse } from '../../../internal-types/HasError'
import {
  selectedInventoryProductsAtom,
  traslationDestinationInfoAtom,
  traslationOriginInfoReader
} from '../Traslation.state'
import { useAtom } from 'jotai/index'
import { useGoToMoveOneConfiguration } from '../move-one-configuration/MoveOneConfiguration.navigator'
import { MOVE_PRODUCT_LIST_URL } from './MoveInventoryProductList.navigator'
import { MOVE_PRODUCT } from '../traslation.flow'
import { useGoToScanDestination } from '../scan-destination/ScanDestination.navigator'

export const useMoveInventoryProductList = () => {
  const navigate = useNavigate()
  const data = useLoaderData() as any
  const traslateScanOrigin = useTraslateScanOrigin()
  const inventoryProducts = useInventoryProducts(data)
  const [selected, setSelected] = useState<InventoryProduct[]>([])
  const [origin] = useAtom(traslationOriginInfoReader)
  const [destination] = useAtom(traslationDestinationInfoAtom)
  const moveOneConfiguration = useGoToMoveOneConfiguration()
  const scanDestination = useGoToScanDestination()
  const [, setSelectedInventoryProducts] = useAtom(selectedInventoryProductsAtom)
  const [searchParams] = useSearchParams()
  const isLooseProducts = searchParams.get('looseProducts') === 'true'

  useEffect(() => {
    setSelectedInventoryProducts([])
  }, [])

  return {
    origin,
    destination,
    inventoryProducts,
    selectedInventoryProducts: selected,
    actions: {
      moveProducts: (inventoryProducts: InventoryProduct[]) => {
        setSelectedInventoryProducts(inventoryProducts)
        if (isLooseProducts) {
          scanDestination.go(origin, MOVE_PRODUCT_LIST_URL, MOVE_PRODUCT, undefined, 'true')
        } else {
          if (inventoryProducts.length === 1) {
            moveOneConfiguration.go({
              origin,
              destination,
              inventoryProduct: inventoryProducts[0]
            })
          } else {
            scanDestination.go(origin, MOVE_PRODUCT_LIST_URL, MOVE_PRODUCT)
          }
        }
      },
      cancel: () => {
        toast.success('Movimiento cancelado con éxito')
        traslateScanOrigin.go()
      },
      back: () => {
        navigate(-1)
      },
      toggleInventoryProduct: (inventoryProduct: InventoryProduct) => {
        if (selected.find(e => e.productSku === inventoryProduct.productSku)) {
          setSelected(s => s.filter(x => x.productSku !== inventoryProduct.productSku))
        } else {
          setSelected(s => s.concat(inventoryProduct))
        }
      },
      selectAllInventoryProducts: () => {
        if (!isErrorResponse(inventoryProducts)) {
          setSelected(inventoryProducts.allInventoryProducts)
        }
      },
      clearSelectedInventoryProducts: () => {
        setSelected([])
      }
    }
  }
}
