import { getLocationOrContainerFromScan } from "client/scanner/scanner.client";
import {getSuggestedLocationsInStorage} from "../Storage.client";

export const storageScanDestinationLoader = async ({params}) => {
  const containerName = String(params.containerName);
  const locationAndContainer = await getLocationOrContainerFromScan(containerName)

  if (!locationAndContainer.container?.id) {
    throw new Error('Container expected')
  }

  const suggestedLocations = await getSuggestedLocationsInStorage(locationAndContainer.container.legacyId)
  return {
    _from: 'storageScanDestinationLoader',
    locationAndContainer,
    suggestedLocations
  }
}