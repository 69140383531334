import { type LocationAndContainer } from '../../types/model'
import { LocationAndContainerMolecule } from '../molecules/LocationAndContainer.molecule'
import { AtlasCaption, AtlasColor, CardComponent, Icon, Icons } from '@justomx/atlas-components'

interface MoveOrganismProps {
  origin: LocationAndContainer
  originText?: string
  destination: LocationAndContainer | undefined
  destinationText?: string
}

export const MoveOrganism = ({ origin, destination, originText, destinationText }: MoveOrganismProps) => {
  return (
    <CardComponent $variant='highlight' style={{ backgroundColor: '#292929', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <CardComponent $variant='highlight' style={{gap: 8}}>
        <AtlasCaption color={AtlasColor.Neutral40}>{originText ?? 'Origen'}</AtlasCaption>
        <LocationAndContainerMolecule location={origin} />
      </CardComponent>
      {destination &&
        <>
        <Icon src={Icons.ArrowForward} size={18} />
        <CardComponent $variant='highlight' style={{ gap: 8 }}>
          <AtlasCaption color={AtlasColor.Neutral40}>{destinationText ?? 'Ubicación de destino'}</AtlasCaption>
          <LocationAndContainerMolecule location={destination} />
        </CardComponent>
        </>
      }
    </CardComponent>
  )
}
