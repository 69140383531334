import { useNavigate } from 'react-router-dom'

export const TRASLATION_SCAN_ORIGIN = '/traslation/scan-origin'

export const useTraslateScanOrigin = () => {
  const navigate = useNavigate()
  return {
    go: () => { navigate(TRASLATION_SCAN_ORIGIN) }
  }
}
