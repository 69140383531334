import type {
  AddLotEventParams,
  AddMissingParams,
  AddRejectionParams,
  MoveContainerParams,
  ProductEventParams,
  ProductReceivedParams,
  ReceiptEventParams,
  ScanContainerEventParams,
  SetFullMissingParams,
  SetTemperatureEventParams
} from './receipt-analytics.types'
import type { EventProperties } from '@segment/analytics-next'
import type { ProductDetail } from 'types/model'
import { analytics } from './analytics.utils'
import { MEASURE_UNITS } from 'pages/receipt/utils/constants'

const EVENT = 'Receipt'
const VERSION = 'v1.0.0'

const errorHandler = (e: any) => {
  console.error('Error calling analytic event', e)
}

const addCommonProps = (props: EventProperties) => {
  const metadata = (window as any)?.WVMetadata
  const warehouse = metadata?.['x-justo-warehouse']?.toUpperCase() || 'PP'
  return { ...props, timestamp: new Date(), warehouse, version: VERSION }
}

const trackEvent = (eventName: string, props: EventProperties) => {
  const data = addCommonProps(props)
  analytics.track(`${EVENT} - ${eventName}`, data).catch(errorHandler)
}

const getProductEventParams = (product: ProductDetail): ProductEventParams => {
  let productMeasureUnit = product?.measureUnit
  let productExpectedQuantity = product?.totalUnits
  if (productMeasureUnit === MEASURE_UNITS.kg && productExpectedQuantity) {
    productMeasureUnit = MEASURE_UNITS.gr
    productExpectedQuantity *= 1000
  }
  return {
    productBarcode: product?.barcode,
    productName: product?.description,
    productMeasureUnit,
    productExpectedQuantity
  }
}

export const syncDocument = (e: ReceiptEventParams) => {
  trackEvent('Initial Synchronization', e)
}

export const registerTemperature = (e: SetTemperatureEventParams) => {
  trackEvent('Temperature Control', e)
}

export const scanContainer = (
  e: ScanContainerEventParams,
  product: ProductDetail
) => {
  trackEvent('Container Scan', {
    ...e,
    ...getProductEventParams(product)
  })
}

export const addLots = (lots: AddLotEventParams[], product: ProductDetail) => {
  lots.forEach((e) => {
    trackEvent('Add Lot', { ...e, ...getProductEventParams(product) })
  })
}

export const updateProductStatus = (
  e: ProductReceivedParams,
  product: ProductDetail
) => {
  trackEvent('Update Product Status', {
    ...e,
    ...getProductEventParams(product)
  })
}

export const addRejection = (e: AddRejectionParams, product: ProductDetail) => {
  trackEvent('Add Rejection', {
    ...e,
    ...getProductEventParams(product)
  })
}

export const addPartialMissing = (
  e: AddMissingParams,
  product: ProductDetail
) => {
  trackEvent('Register Reported Missing', {
    ...e,
    ...getProductEventParams(product)
  })
}

export const setFullMissingStatus = (
  e: SetFullMissingParams,
  product: ProductDetail
) => {
  trackEvent('Set Full Missing Status', {
    ...e,
    ...getProductEventParams(product)
  })
}

export const moveContainer = (e: MoveContainerParams) => {
  trackEvent('Move Container', e)
}

export const finishReceipt = (e: ReceiptEventParams) => {
  trackEvent('Finish Receipt', e)
}
