import {useEffect, useState} from "react";
import {ProductDetailsResponse} from "../../../client/products/products.types";
import {AlertsWrapper} from "../../../client/alerts/alerts.types";
import {getProductDetailFromEan} from "../../../client/products/products.client";
import {getRulesFromProductClient} from "../../../client/utilities.client";
import { useAtom } from "jotai";
import { universalSearchProductAtomReader } from "../universal-search.state";
import { useNavigate } from "react-router-dom";

export function useUniversalSearchProduct() {
  const [product] = useAtom(universalSearchProductAtomReader)
  const [fulfilledProduct, setProduct] = useState<ProductDetailsResponse | null>(null)
  const [productRules, setProductRules] = useState<AlertsWrapper | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
      setLoading(true)
      const productPromise = getProductDetailFromEan(product.ean)
      .then(e => setProduct(e))


      const rulesPromise = getRulesFromProductClient(product.legacyId)
      .then(e => setProductRules(e))
      .catch(e => console.error(e))

      Promise.all([productPromise, rulesPromise]).finally(
        () => setLoading(false)
      )
    }, [product]
  )

  return {
    product,
    fulfilledProduct,
    productRules,
    loading,
    goBack: () => navigate(-1)
  }
}
