import { useTranslation } from 'react-i18next'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasColor,
  AtlasH1,
  CardComponent,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { type ProductWithStock, useScanProduct } from './ScanProduct.hook'
import LoaderAtom from 'components/atoms/Loader.atom'
import TabGroupMolecule from 'components/molecules/TabGroup.molecule'
import EmptyListComponent from '../../../../components/molecules/EmptyList.molecule'
import { ProductCard } from '../../../../components/molecules/v2/ProductCard.molecule'
import { type AlertSuggestionProduct } from '../../../../types/model'
import { TextfieldDialogOrganism } from '../../../../components/organisms/TextfieldDialog.organism'

interface TotalAlertsCardProps { qty: number }

const TotalAlertsCard = ({ qty }: TotalAlertsCardProps) => {
  const { t } = useTranslation('global')
  return (
    <CardComponent $variant="highlight" style={{
      marginBottom: '12px',
      textAlign: 'center'
    }}>
      <AtlasH1 color={AtlasColor.Neutral00}>
        {qty}
      </AtlasH1>
      <AtlasBodyMain>
        {t('alerts.scan-product.alerts-in-container')}
      </AtlasBodyMain>
    </CardComponent>
  )
}

function convertToProductCard (product: AlertSuggestionProduct) {
  return {
    ean: product.productEan,
    sku: product.productSku,
    name: product.productName,
    image: product.productImage,
    stock: product.expectedStock,
    isWeighable: product.isWeighable,
    zone: ''
  }
}

export const AlertsScanProductPage = () => {
  const {
    source,
    hasAlerts,
    totalAlerts,
    alerts,
    products,
    actions,
    isLoading,
    typeProductDialogRef
  } = useScanProduct()
  const { t } = useTranslation('global')

  if (isLoading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return <>
    <ToolbarMolecule
      title={source ?? 'Reabastece'}
      actionIcon={Icons.CloseIcon}
      onAction={actions.close}
      secondaryActionIcon={Icons.IcKeyboardIcon}
      onSecondaryAction={actions.openTypeProductDialog}
    />
    <PageContentAtom>
      {
        hasAlerts
          ? (
            <>
              <TotalAlertsCard qty={totalAlerts} />
              <AtlasBodySubtitle>{t('alerts.scan-product.scan')}</AtlasBodySubtitle>
              <TabGroupMolecule
                tabs={actions.getTabs()}
                onSelectTab={actions.onChangeTab}
                defaultActiveTab="Urgent"
                tabColors={['#D33641', '#FF8B47', '#FCE69C']}
              />
              {
                alerts.length > 0
                  ? alerts.map((alert) => {
                    return <ProductCard
                        key={alert.productEan}
                        product={convertToProductCard(alert)}
                        showQuantity={true}
                        quantityTitle={t('alerts.scan-product.card-quantity-title')}
                        openDialog={() => {}}
                      />
                  }
                  )
                  : <EmptyListComponent text={t('alerts.scan-product.empty-list')} />
              }
            </>
            )
          : (
            <>
              <AtlasBodySubtitle>{t('alerts.scan-product.scan')}</AtlasBodySubtitle>
              {
                products.length > 0
                  ? products.map((product) => {
                    return <ProductCard
                        key={product.ean}
                        product={{ ...product, zone: '' }}
                        showQuantity={true}
                        quantityTitle={t('alerts.scan-product.card-quantity-title')}
                        openDialog={() => {}}
                      />
                  }
                  )
                  : <EmptyListComponent text={t('alerts.scan-product.empty-list')} />
              }
            </>
            )
      }
      {/* <ConfirmationDialogMolecule
        dialogRef={dialogRef}
        confirmText="Continuar"
        cancelText="Cancelar"
        onConfirm={actions.deleteProduct}
      >
        <AtlasBodySubtitle>{t('alerts.scan-product.product-not-in-container.title')}</AtlasBodySubtitle>
        <AtlasBodyMain>{t('alerts.scan-product.product-not-in-container.body')}</AtlasBodyMain>
      </ConfirmationDialogMolecule> */}
      <TextfieldDialogOrganism
        dialogRef={typeProductDialogRef}
        title="Escribe el código del producto"
        label="Código"
        confirmText="Continuar"
        cancelText="Cancelar"
        confirmAction={actions.searchProduct}
      />
    </PageContentAtom>
  </>
}
