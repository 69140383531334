import {useStorageScanDestination} from "./StorageScanDestination.hook";
import {onErrorToast, onSucessToast} from "../../../onErrorToast.util";
import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from "@justomx/atlas-components";
import {Global} from "../../../utils/global";
import {useTranslation} from "react-i18next";
import {MoveSuggestionsOrganism} from "../../../components/organisms/MoveSuggestions.organism";

export const StorageScanDestinationPage = () => {
  const { suggestedLocations, origin } = useStorageScanDestination(onErrorToast, onSucessToast);
  const { t } = useTranslation('global')
  return <>
    <ToolbarMolecule title={t('storage-scan-destination.scan')}
      actionIcon={Icons.IcMenuIcon}
      onAction={Global.WV.openMenu}
    />
      <PageContentAtom $centered>
      <Filler flexGrow={1}/>
      <Icon src={Icons.ScanIcon}/>
      <AtlasBodyTitle>{t('storage-scan-destination.scan')}</AtlasBodyTitle>
      <AtlasH1 style={{ textAlign: 'center' }}>{t('storage-scan-destination.location')}</AtlasH1>
      <AtlasBodyTitle>{t('storage-scan-destination.use-your-scanner')}</AtlasBodyTitle>
      <Filler/>
      <MoveSuggestionsOrganism origin={origin} destinations={suggestedLocations} />
      </PageContentAtom>
  </>
}