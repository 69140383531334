import { useNavigate } from 'react-router-dom'
import { getProductContainers } from '../../../client/utilities.client'
import { productState } from '../product.state'
import { useAtom } from 'jotai'

export const useProductContainersListNavigator = (onError: (err: string) => void) => {
  const navigate = useNavigate()
  const [, setProducts] = useAtom(productState)

  return {
    go: async (ean: string) => {
      await getProductContainers(ean)
        .then(setProducts)
        .then(() => {
          navigate(PRODUCT_CONTAINER_LIST_NAVIGATOR.replace(':productId', ean))
        })
        .catch((err) => {
          console.error(err)
          onError(err.message as string)
        })
    }
  }
}

export const PRODUCT_CONTAINER_LIST_NAVIGATOR = '/product/:productId'
