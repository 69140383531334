import { atomWithStorage } from 'jotai/utils'
import { type LocationInfo } from '../../types/model'
import { atom } from 'jotai'

// Lower
export const containerAdministrationState = atomWithStorage<LocationInfo | null>('containerAdministrationState', null)
export const containerAdministrationDestination = atomWithStorage<LocationInfo | null>('containerAdministrationDestination', null)
export const containerAdministrationLastDropState = atom<string>('containerAdministrationLastDropState', '')

// Lift
export const containerAdministrationContainerToLift = atomWithStorage<LocationInfo | null>('containerAdministrationContainerToLift', null)
export const containerAdministrationDestinationToLift = atomWithStorage<LocationInfo | null>('containerAdministrationDestinationToLift', null)

