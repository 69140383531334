import { useState } from 'react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Analytics } from 'analytics/analytics.events'
import { useRequiredParams } from 'hooks/params.hook'
import { containerToMoveAtom } from '../Receipt.state'
import { useGoToMoveContainers } from '../move-containers/MoveContainers.navigator'
import { toastSuccess } from '../utils/toast'
import { moveContainer } from '../client/Receipt.client'

export const useScanLocationData = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('global')
  const [loading, setLoading] = useState(false)
  const container = useAtomValue(containerToMoveAtom)
  const moveContainers = useGoToMoveContainers()
  const { orderId, receiptId } = useRequiredParams('orderId', 'receiptId')

  const onScan = async (scanned: string) => {
    if (!container) {
      return
    }
    try {
      setLoading(true)
      await moveContainer(receiptId, container.containerName, scanned)
      Analytics.receipt.moveContainer({
        orderId,
        receiptType: 'providers',
        containerName: container?.containerName,
        locationName: scanned
      })

      toastSuccess(t('receipt-scan-location.scanned'))
      moveContainers.go(receiptId, orderId)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    container,
    loading,
    actions: {
      onScan,
      goBack: () => {
        navigate(-1)
      }
    }
  }
}
