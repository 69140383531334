import { type RouteObject } from 'react-router-dom'
import { ALERTS_SCAN_PRODUCT_URL } from './v2/scan-product/ScanProduct.navigator'
import { ALERTS_REGISTER_RESTOCK_URL } from './v2/register-restock/RegisterRestock.navigator'
import { ALERTS_HOME_PAGE } from './Alerts.navigator'
import { AlertsRegisterRestockPage } from './v2/register-restock/RegisterRestock.page'
import AlertsInitPage from './AlertsInit.page'
import { AlertsScanProductPage } from './v2/scan-product/ScanProduct.page'

export const alertsRoutes: RouteObject[] = [
  {
    id: '(alerts) Main Page - Container list',
    path: ALERTS_HOME_PAGE,
    element: <AlertsInitPage/>
  },
  {
    id: '(alerts) - Productos',
    path: ALERTS_SCAN_PRODUCT_URL,
    element: <AlertsScanProductPage/>
  },
  {
    id: '(alerts) - Reabastecer',
    path: ALERTS_REGISTER_RESTOCK_URL,
    element: <AlertsRegisterRestockPage/>
  }
]
