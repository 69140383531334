import { getLocationOrContainerFromScan, getProductFromScan } from 'client/scanner/scanner.client'
import { getInventoryProductsClient } from '../../client/utilities.client'
import { isSameProduct } from '../../types/model'
import { findProductsInfo } from '../../client/products/products.client'
import { UomKey } from '../../client/types'

function extractParams (params: Record<string, unknown>) {
  const receivedLocation = String(params.location)
  const receivedContainer = String(params.container)
  const ean = String(params.ean)
  const container = receivedContainer !== 'null' ? receivedContainer : null

  return {
    receivedLocation,
    receivedContainer,
    ean,
    container
  }
}

export const loaderLocationAndInventoryProducts = async (params: Record<string, unknown>) => {
  const parsedParams = extractParams(params)

  const locationAndContainer = await getLocationOrContainerFromScan(parsedParams.container || parsedParams.receivedLocation)

  const inventoryProducts = await getInventoryProductsClient(
    locationAndContainer.location.legacyId,
    locationAndContainer.container?.legacyId || null
  )

  const products = inventoryProducts.length ?
    await findProductsInfo(inventoryProducts.map((it) => it.productEan)) : []

  inventoryProducts.map((it) => {
    const product = products.find((p) => p.ean === it.productEan)
    if (product != null) {
      it.byWeight = product.packagingLevels.some((pl) => pl.uom === UomKey.Weight)
    }
    return it
  })

  return {
    inventoryProducts,
    inventoryProduct: parsedParams.ean && inventoryProducts.find(isSameProduct(parsedParams.ean)),
    locationAndContainer,
    ean: parsedParams.ean
  }
}

export const loaderLocationAndProduct = async (params: Record<string, unknown>) => {
  const parsed = extractParams(params)

  const locationAndContainer = await getLocationOrContainerFromScan(parsed.container || parsed.receivedLocation)

  const { product } = await getProductFromScan(parsed.ean)

  return {
    locationAndContainer,
    ean: parsed.ean,
    product
  }
}
