import { useNavigate } from 'react-router-dom'

export const useGoToOrderDetail = () => {
  const navigate = useNavigate()

  return {
    go: (receiptType: string, receiptId: string, orderId: string) => {
      navigate(
        ORDER_DETAIL_URL.replace(':receiptType', receiptType)
          .replace(':receiptId', receiptId)
          .replace(':orderId', orderId)
      )
    }
  }
}

export const ORDER_DETAIL_URL =
  '/receipt/:receiptType/:receiptId/order/:orderId'
