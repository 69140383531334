import styled from 'styled-components'
import {
  PrimaryButtonAtom,
  SecondaryButtonAtom
} from '@justomx/atlas-components'

const getComponentBase = (variant: string) => {
  switch (variant) {
    case 'primary':
      return PrimaryButtonAtom
    case 'secondary':
      return SecondaryButtonAtom
    default:
      return 'button'
  }
}

export const FabButtonAtom = styled.button.attrs<{
  $variant: string
  $size?: number
  $alignSelf?: string
}>(({ $variant }) => ({
  as: getComponentBase($variant)
}))`
  border-radius: 100px;
  width: ${({ $size }) => $size ?? 32}px;
  height: ${({ $size }) => $size ?? 32}px;
  align-self: ${({ $alignSelf }) => $alignSelf};
`
