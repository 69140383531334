import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  BottomGroupMolecule,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  SecondaryButtonAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useReceiptType } from './ReceiptType.hooks'

const ReceiptTypePage = () => {
  const { t, actions } = useReceiptType()

  return (
    <>
      <ToolbarMolecule
        title={t('receipt-type.title')}
        actionIcon={Icons.CloseIcon}
        onAction={actions.onClosePage}
      />

      <PageContentAtom $gap={16}>
        <AtlasBodySubtitle>{t('receipt-type.to-receive')}</AtlasBodySubtitle>
        <AtlasBodyMain>{t('receipt-type.help')}</AtlasBodyMain>

        <BottomGroupMolecule>
          <SecondaryButtonAtom
            type="button"
            onClick={() => {
              actions.onSelectType('transfers')
            }}
          >
            {t('receipt-type.transfers')}
          </SecondaryButtonAtom>
          <PrimaryButtonAtom
            type="button"
            onClick={() => {
              actions.onSelectType('providers')
            }}
          >
            {t('receipt-type.providers')}
          </PrimaryButtonAtom>
        </BottomGroupMolecule>
      </PageContentAtom>
    </>
  )
}

export default ReceiptTypePage
