import { useNavigate } from 'react-router-dom'

export const useGoToReceiptType = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(RECEIPT_TYPE_URL)
    }
  }
}

export const RECEIPT_TYPE_URL = '/receipt/receipt-type'
