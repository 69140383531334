import { AtlasColor, CardRow, PrimaryButtonAtom, TextBoxMolecule } from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ChangeWeightMoleculeProps {
  label: string
  onAdd: (value: number) => void
}

const ChangeWeightMolecule = ({
  label,
  onAdd
}: ChangeWeightMoleculeProps) => {
  const { t } = useTranslation('global')

  const [qty, setQty] = useState(0)

  const addWeightClick = (e) => {
    e.preventDefault()
    if (qty > 0) {
      onAdd(qty)
    }
    e.target.blur()
  }

  return (
    <CardRow>
      <FlexRowAtom style={{
        width: '100%',
        alignItems: 'stretch',
        gap: '1em'
      }}>
        <TextBoxMolecule type="number" label={label} onChange={(it) => {
          setQty(Number(it))
        }} />
        <PrimaryButtonAtom style={{
          height: 'auto',
          color: AtlasColor.Primary00,
          backgroundColor: `${AtlasColor.Primary00}22`
        }} onClick={addWeightClick}>
          {t('fast-set-weight.btn-add')}
        </PrimaryButtonAtom>
      </FlexRowAtom>
    </CardRow>
  )
}

export default ChangeWeightMolecule
