import styled from 'styled-components'

import { AtlasColor } from '../foundations/Colors.foundations'

export const PageLayoutAtom = styled.div`
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 8px;
  color: ${AtlasColor.Neutral00};
`
