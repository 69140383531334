export const REGEXPS = {
  alphanum: /^[0-9a-zA-Z]+$/,
  date: /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/
}

export const COLD_ZONES = ['Congelados', 'Refrigerados']

export const TEMP_RANGES: Record<string, { min: number, max: number }> = {
  Congelados: {
    min: -20,
    max: -12
  },
  Refrigerados: {
    min: 0,
    max: 7
  }
}

export const MISSING_TYPES: Record<string, string> = {
  fullMissing: 'fullMissing',
  rejection: 'rejection'
}

export const PRODUCT_STATUS: Record<string, string> = {
  pending: 'pending',
  received: 'finished'
}

export const MEASURE_UNITS: Record<string, string> = {
  kg: 'KG',
  piece: 'PIEZA',
  box: 'CAJA',
  gr: 'GRAMOS'
}

export const DOC_TYPES: Record<string, string> = {
  providers: 'purchase_orders',
  transfers: 'stock_transfers'
}

export const RECEIPT_TYPES: Record<string, string> = {
  purchase_orders: 'providers',
  stock_transfers: 'transfers'
}

export const RECEIPTS = {
  providers: 'providers',
  transfers: 'transfers'
}

export const COMMON_REJECT_REASONS = [
  'NEAR_EXPIRATION',
  'PRODUCT_DAMAGED',
  'WITHOUT_LABELING',
  'MOLD',
  'NO_FUMIGATION_CERT',
  'NO_APPOINTMENT',
  'OUT_OF_HOURS',
  'HARMFUL_FAUNA',
  'EXTRA_PRODUCT',
  'BAD_UPC',
  'PACKAGING_DAMAGE',
  'OVER_WEIGHT'
]

export const REJECT_REASONS_BY_ZONE = {
  Refrigerados: ['OUT_OF_RANGE_TEMP'],
  Congelados: ['OUT_OF_RANGE_TEMP'],
  Laboratorio: [
    'OUT_OF_RANGE_TEMP',
    'DEHYDRATED',
    'DECAY',
    'RIPENING',
    'MECHANICAL_DAMAGE',
    'OXIDATION',
    'SOGGINESS'
  ]
}

const MX_MEASURE_UNITS_MAP: Record<string, string> = {
  PIEZA: 'PIEZA',
  CAJA: 'CAJA',
  KG: 'KG'
}

const BR_MEASURE_UNITS_MAP: Record<string, string> = {
  UND: 'PIEZA',
  KG: 'KG',
  CX: 'CAJA',
  CX2: 'CAJA',
  CX3: 'CAJA',
  CX4: 'CAJA',
  CX5: 'CAJA',
  CX6: 'CAJA',
  CX7: 'CAJA',
  CX8: 'CAJA',
  CX9: 'CAJA',
  CX10: 'CAJA',
  CX12: 'CAJA',
  CX13: 'CAJA',
  CX14: 'CAJA',
  CX15: 'CAJA',
  CX16: 'CAJA',
  CX17: 'CAJA',
  CX18: 'CAJA',
  CX20: 'CAJA',
  CX21: 'CAJA',
  CX22: 'CAJA',
  CX23: 'CAJA',
  CX24: 'CAJA',
  CX25: 'CAJA',
  CX26: 'CAJA',
  CX27: 'CAJA',
  CX28: 'CAJA',
  CX30: 'CAJA',
  CX31: 'CAJA',
  CX32: 'CAJA',
  CX33: 'CAJA',
  CX34: 'CAJA',
  CX35: 'CAJA',
  CX36: 'CAJA',
  CX37: 'CAJA',
  CX38: 'CAJA',
  CX40: 'CAJA',
  CX42: 'CAJA',
  CX44: 'CAJA',
  CX45: 'CAJA',
  CX48: 'CAJA',
  CX49: 'CAJA',
  CX50: 'CAJA',
  CX52: 'CAJA',
  CX54: 'CAJA',
  CX55: 'CAJA',
  CX56: 'CAJA',
  CX58: 'CAJA',
  CX60: 'CAJA',
  CX62: 'CAJA',
  CX63: 'CAJA',
  CX64: 'CAJA',
  CX68: 'CAJA',
  CX70: 'CAJA',
  CX72: 'CAJA',
  CX76: 'CAJA',
  CX80: 'CAJA',
  CX84: 'CAJA',
  CX90: 'CAJA',
  CX92: 'CAJA',
  CX96: 'CAJA',
  CX100: 'CAJA',
  CX108: 'CAJA',
  CX112: 'CAJA',
  CX120: 'CAJA',
  CX128: 'CAJA',
  CX144: 'CAJA',
  CX160: 'CAJA',
  CX180: 'CAJA',
  CX192: 'CAJA',
  CX216: 'CAJA',
  CX240: 'CAJA',
  CX249: 'CAJA',
  CX264: 'CAJA',
  'KG1,38': 'KG',
  CX272: 'CAJA',
  CX288: 'CAJA',
  CX336: 'CAJA',
  CX384: 'CAJA',
  CX480: 'CAJA',
  CX500: 'CAJA',
  CX640: 'CAJA',
  CX720: 'CAJA',
  DISP8: 'CAJA',
  DISP12: 'CAJA',
  DISP128: 'CAJA',
  FRD1: 'CAJA',
  FRD2: 'CAJA',
  FRD3: 'CAJA',
  FRD4: 'CAJA',
  FRD5: 'CAJA',
  FRD6: 'CAJA',
  FRD8: 'CAJA',
  FRD9: 'CAJA',
  FRD10: 'CAJA',
  FRD12: 'CAJA',
  FRD14: 'CAJA',
  FRD15: 'CAJA',
  FRD16: 'CAJA',
  FRD18: 'CAJA',
  FRD20: 'CAJA',
  FRD22: 'CAJA',
  FRD24: 'CAJA',
  FRD30: 'CAJA',
  FRD140: 'CAJA',
  PCT4: 'CAJA',
  PCT6: 'CAJA',
  PCT12: 'CAJA',
  CX140: 'CAJA',
  PCT18: 'CAJA',
  GR: 'KG',
  GR150: 'KG',
  GR125: 'KG',
  GR600: 'KG',
  GR1080: 'KG',
  GR300: 'KG',
  'KG1,56': 'KG',
  GR115: 'KG',
  GR120: 'KG',
  'KG2,4': 'KG',
  C12: 'CAJA',
  GR200: 'KG',
  KG6: 'KG',
  GR500: 'KG',
  CX270: 'CAJA',
  'KG1,05': 'KG',
  'KG1,2': 'KG',
  GR140: 'KG',
  'KG1,35': 'KG',
  'KG1,6': 'KG',
  GR840: 'KG',
  KG02: 'KG',
  GR170: 'KG',
  KG2: 'KG',
  KG7: 'KG',
  KG3: 'KG',
  PCT20: 'CAJA',
  PCT10: 'CAJA',
  PCT30: 'CAJA',
  CX11: 'CAJA',
  CX576: 'CAJA',
  CX150: 'CAJA',
  PCT16: 'CAJA',
  GR400: 'KG',
  GR380: 'KG',
  GR220: 'KG',
  GR350: 'KG',
  GR390: 'KG',
  GR370: 'KG',
  'KG3,5': 'KG',
  PCT24: 'CAJA',
  PCT: 'CAJA',
  GR190: 'KG',
  GR280: 'KG',
  PCT27: 'CAJA',
  UND20: 'PIEZA',
  CX672: 'CAJA',
  CX630: 'CAJA',
  CX83: 'CAJA',
  CX19: 'CAJA',
  CX143: 'CAJA',
  CX66: 'CAJA',
  CX360: 'CAJA',
  KG8: 'KG',
  KG08: 'KG',
  KG6747: 'KG',
  KG10: 'KG',
  KG05: 'KG',
  'KG1,25': 'KG',
  'KG9,8': 'KG',
  KG20: 'KG',
  KG24: 'KG',
  KG008: 'KG',
  KG010: 'KG',
  KG07: 'KG',
  KG005: 'KG',
  KG007: 'KG',
  KG12: 'KG',
  UND2: 'PIEZA',
  UND4: 'PIEZA',
  UND6: 'PIEZA',
  CX125: 'CAJA',
  CX200: 'CAJA',
  UND50: 'PIEZA',
  CX999: 'CAJA',
  CX352: 'CAJA',
  CX250: 'CAJA',
  CX10753: 'CAJA',
  KG10658: 'KG',
  CX10924: 'CAJA',
  'KG5-10658': 'KG',
  'KG1-10659': 'KG',
  'KG5-10661': 'KG',
  'KG5-10662': 'KG',
  'KG5-10663': 'KG',
  'KG1-10664': 'KG',
  'KG1-10665': 'KG',
  'KG1-10666': 'KG',
  'KG1-10667': 'KG',
  'KG1-10668': 'KG',
  'KG1-10670': 'KG',
  'KG1-10671': 'KG',
  'KG1-10680': 'KG',
  CX111: 'CAJA',
  'CX30-10753': 'CAJA',
  'CX60-10754': 'CAJA',
  'KG5-10680': 'KG',
  'KG3-10664': 'KG',
  'KG3-10665': 'KG',
  'KG3-10666': 'KG',
  'KG3-10667': 'KG',
  'KG2-10668': 'KG',
  'KG-11588': 'KG',
  'KG5-12145': 'KG',
  'KG-12146': 'KG',
  'KG-12147': 'KG',
  'KG-12148': 'KG',
  PCT9: 'CAJA',
  CX540: 'CAJA',
  CX65: 'CAJA',
  CX39: 'CAJA',
  CX43: 'CAJA',
  UND8: 'PIEZA',
  'KG0,5': 'KG',
  'KG0,8': 'KG',
  CX81: 'CAJA',
  CX276: 'CAJA',
  'KG0,7': 'KG',
  CX138: 'CAJA'
}

const PE_MEASEURE_UNITS_MAP: Record<string, string> = {
  UND: 'PIEZA',
  PAC: 'PIEZA',
  CAJ: 'CAJA',
  KG: 'KG',
  BAN: 'PIEZA',
  ATA: 'PIEZA'
}

export const MEASEURE_UNITS_MAP: Record<string, string> = {
  ...BR_MEASURE_UNITS_MAP,
  ...PE_MEASEURE_UNITS_MAP,
  ...MX_MEASURE_UNITS_MAP
}

export const RECEIPT_URLS = {
  staging: 'https://staging.op-receipt-web.pages.dev/receipt',
  prod: 'https://op-receipt-web.pages.dev/receipt'
}
