import {useTranslation} from "react-i18next";
import {AtlasBodySubtitle, Icons, PageContentAtom, ToolbarMolecule} from "@justomx/atlas-components";
import {useUniversalSearchLocation} from "./universal-search-location.hook";
import LoaderAtom from "../../../components/atoms/Loader.atom";
import ScannedLocationOrContainer from "../components/scanned-location-or-container.component";
import { ProductWithStock } from "../components/product-with-stock.component";


export const UniversalSearchLocationPage = () => {
  const {location, totalContainers, fulfilledLocation, loading, goBack} = useUniversalSearchLocation()
  const {t} = useTranslation('global')
  
  const hasMultipleContainers = totalContainers && totalContainers > 1

  const locationTypesMap = {
    Storage: t('universal-search.storage'),
    PreStorage: t('universal-search.prestorage'),
    Picking: t('universal-search.picking'),
  }

  return <>
    {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
    <ToolbarMolecule 
      title={t('universal-search.location.toolbar-title', {type: locationTypesMap[location?.type as string]})}
      actionIcon={Icons.ArrowBack}
      onAction={goBack}
    />
    <PageContentAtom>
    <AtlasBodySubtitle>{t('universal-search.location.scanned-location')}</AtlasBodySubtitle>
      <ScannedLocationOrContainer item={location} type="location"/>
      <AtlasBodySubtitle>{hasMultipleContainers ? t('universal-search.location.containers-in-location') : t('universal-search.location.products-in-location')}</AtlasBodySubtitle>
      {fulfilledLocation.inventoryProducts.map(e => <ProductWithStock key={e.productId} product={e}/>)}
    </PageContentAtom>
  </>
}
