import { AtlasBodyMain, AtlasBodySubtitle, AtlasColor, CardRow, ImageThumbnailComponent, StackAtom } from "@justomx/atlas-components";
import { ProductResponse } from "client/scanner/scanner.types";
import { FlexRowAtom } from "components/atoms/FlexRow.atom";

interface ProductInfoProps {
  product: ProductResponse
}

const ProductInfo = ({product}: ProductInfoProps) => {
  return(<CardRow style={{alignItems: 'flex-start'}}>
    <ImageThumbnailComponent 
      size={60}
      src={product.image}
      style={{ alignSelf: 'self-start' }}
    />
    <StackAtom gap={12}>
      <AtlasBodySubtitle>{product.name}</AtlasBodySubtitle>
      <FlexRowAtom style={{alignItems: 'center', gap:4, color: AtlasColor.Neutral40}}>
        <AtlasBodyMain>UPC: {product.ean}</AtlasBodyMain>
        <AtlasBodyMain>|</AtlasBodyMain>
        <AtlasBodyMain>SKU: {product.sku}</AtlasBodyMain>
      </FlexRowAtom>
    </StackAtom>
  </CardRow>
  )
}

export default ProductInfo;