import { useNavigate } from 'react-router-dom'

export const REVERSE_LOGISTICS_REGISTER_WASTE_OR_MISSING_URL = '/reverse-logistics/register-waste-or-missing'

export const useReverseLogisticsRegisterWasteOrMissing = () => {
  const navigate = useNavigate()
  return {
    go: (flow) => {
      navigate(`${REVERSE_LOGISTICS_REGISTER_WASTE_OR_MISSING_URL}?flow=${flow}`)
    }
  }
}
