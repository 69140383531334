import {useLoaderData} from "react-router-dom";
import {isCointainerDetail} from "./ContainerDetail.loader";
import {useTranslation} from "react-i18next";
import {useGoToStorageScanDestinationDetail} from "../scan-destination/StorageScanDestination.navigator";
import { useState } from "react";
import { InventoryProduct } from "types/model";
import { isErrorResponse } from "internal-types/HasError";
import { selectedInventoryProductsAtom } from "pages/traslation/Traslation.state";
import { useAtom } from "jotai";

export const useContainerDetail = (onError: (err: string) => void) => {
  const { t } = useTranslation('global')
  const scanDestination = useGoToStorageScanDestinationDetail();
  const data = useLoaderData();
  const [selected, setSelected] = useState<InventoryProduct[]>([])
  const [, setSelectedInventoryProducts] = useAtom(selectedInventoryProductsAtom)

  if (!isCointainerDetail(data)) {
    onError(t('storage-container-detail.bad-request'))
    throw new Error(t('storage-container-detail.bad-request'))
  }

  return {
    data,
    selectedInventoryProducts: selected,
    actions: {
      continue: (inventoryProducts) => {
        setSelectedInventoryProducts(inventoryProducts)
        scanDestination.go(data.locationAndContainer.container.name)
      },
      toggleInventoryProduct: (inventoryProduct: InventoryProduct) => {
        if (selected.find(e => e.productSku === inventoryProduct.productSku)) {
          setSelected(s => s.filter(x => x.productSku !== inventoryProduct.productSku))
        } else {
          setSelected(s => s.concat(inventoryProduct))
        }
      },
      selectAllInventoryProducts: () => {
        if (!isErrorResponse(data)) {
          setSelected(data.inventoryProductsWithAlertInfo)
        }
      },
      clearSelectedInventoryProducts: () => {
        setSelected([])
      }
    }
  };
}