import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useScanContainerToLift } from './ScanContainerToLift.hook'
import { onErrorToast } from '../../../../onErrorToast.util'
import { useTranslation } from 'react-i18next'

export const ScanContainerToLiftPage = () => {
  const {actions} = useScanContainerToLift(onErrorToast)
  const { t } = useTranslation('global')
  return (
    <>
      <ToolbarMolecule title={t('scan-to-lift.toolbar-title')}
                      actionIcon={Icons.ArrowBack}
                      onAction={actions.goBack} />
      <PageContentAtom $centered>
        <Filler flexGrow={1}/>
        <AtlasBodyTitle>{t('scan-to-lift.scan')}</AtlasBodyTitle>
        <AtlasH1 style={{ textAlign: 'center' }}>{t('scan-to-lift.container')}</AtlasH1>
        <Filler/>
        <Filler/>
      </PageContentAtom>
    </>
  )
}
