import { useNavigate } from 'react-router-dom'
import { type LocationOrContainer } from '../../../types/model'
import { type TraslationFlow } from '../traslation.flow'

export const useGoToScanDestination = () => {
  const navigate = useNavigate()

  return {
    go: ({ location: {legacyId: locationId}, container }: LocationOrContainer, callbackUrl: string, flow: TraslationFlow, quantity?: string, looseProducts?: string) => {

      const containerId = container?.legacyId || 'undefined';

      const scanUrl = SCAN_DESTINATION_URL
        .replace(':origin', locationId)
        .replace(':container', containerId)

      const finalCallbackUrl = callbackUrl
        .replace(':origin', locationId)
        .replace(':container', containerId)

      navigate(`${scanUrl}?flow=${flow}&callbackUrl=${encodeURIComponent(finalCallbackUrl)}&looseProducts=${looseProducts}${quantity ? `&quantity=${quantity}` : ''}`)
    }
  }
}

export const SCAN_DESTINATION_URL = '/traslation/:origin/:container/scan-destination'
