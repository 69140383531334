import styled from 'styled-components'

export const PageContentAtom = styled.div<{
  $centered?: boolean
  $gap?: number
}>`
  flex-grow: 1;
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: ${({ $gap }) => $gap ?? 16}px;
  overflow: auto;
  ${({ $centered }) => {
    if ($centered) {
      return `
                justify-content: center;
                align-items: center;
            `
    }
    return ''
  }}
`
