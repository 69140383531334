import { useNavigate } from 'react-router-dom'
import { useRequiredParams } from 'hooks/params.hook'

export const useGoToSummary = () => {
  const navigate = useNavigate()
  const { receiptType } = useRequiredParams('receiptType')

  return {
    go: (
      receiptId: string,
      orderId: string,
      productId: string,
      readonly = false
    ) => {
      navigate(
        `${SUMMARY_URL.replace(':receiptType', receiptType)
          .replace(':receiptId', receiptId)
          .replace(':orderId', orderId)
          .replace(':productId', productId)}?readonly=${readonly}`
      )
    }
  }
}

export const SUMMARY_URL =
  '/receipt/:receiptType/:receiptId/order/:orderId/lots/:productId/summary'
