import { useEffect, useState } from 'react'
import { getAlertsSuggestions } from '../../../client/alerts/alerts.client'
import { type AlertSuggestion, type PalletItem } from '../../../client/alerts/alerts.types'
import { type IsLoadable, LoadedObject, NoneObject } from '../../../utils/async-types'
import _ from 'lodash'
import { useFastTraslation } from '../../fast-traslation/FastTraslation.navigator'
import { useScanProductNavigator } from '../../fast-traslation/scan-product/ScanProduct.navigator'
import { useScanner } from '../../../hooks/scanner.hook'
import { useSearchProductNavigator } from '../../product/search-product/SearchProduct.navigator'
import { isEAN } from '../../../types/model'
import { useProductContainersListNavigator } from '../../product/product-containers-list/ProductContainersList.navigator'
import { validateOrigin } from './Alerts.validator'
import { useTranslation } from 'react-i18next'
import { getScannedInfo } from '../../../client/scanner/scanner.client'
import { toCommonLocationInfoFromScanResponse } from '../../../utils/commonLocationInfo'

export const useAlerts = (onError: (str: string) => void) => {
  const fastTraslation = useFastTraslation()
  const scanProductNavigator = useScanProductNavigator(onError)
  const [isLoadingPage, setLoading] = useState<boolean>(false)
  const { keys, getScan } = useScanner()
  const searchProduct = useSearchProductNavigator()
  const productContainerList = useProductContainersListNavigator(onError)
  const { t } = useTranslation('global')

  const [alerts, setAlerts] =
    useState<IsLoadable<Array<Required<AlertSuggestion>>>>(NoneObject)

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setLoading(true)
      if (isEAN(scanned)) {
        productContainerList.go(scanned)
          .catch(e => {
            console.error(e)
            onError(t('alerts.scan-product-error') + e.message)
            setLoading(false)
          })
          .then(() => { setLoading(false) })
          .catch(err => { console.error(err) })
        return
      }

      getScannedInfo(scanned)
        .then(validateOrigin)
        .then(origin => {
          const commonLocationInfo = toCommonLocationInfoFromScanResponse(origin)
          scanProductNavigator.goFromPalletOrLPN(commonLocationInfo)
        })
        .catch(e => {
          console.error(e)
          onError(t('alerts.scan-container-error') + e.message)
          setLoading(false)
        })
        .then(() => { setLoading(false) })
        .catch(err => { console.error(err) })
    }
  }, [keys])

  useEffect(() => {
    getAlertsSuggestions()
      .then(e => LoadedObject(e))
      .then(setAlerts)
      .catch(e => {
        console.error(e)
        onError(e.message as string)
      })
  }, [])

  return {
    alerts,
    isLoadingPage,
    actions: {
      goSearchProduct: () => {
        searchProduct.go()
      },
      goPalletDetail: async (pallet: PalletItem) => {
        try {
          setLoading(true)
          await scanProductNavigator.goFromPalletOrLPN(pallet)
        } catch (e) {
          console.error('goPalletDetail', e)
          onError(e.message as string)
        }
        setLoading(false)
      },
      goFastTraslation: () => {
        setLoading(true)
        fastTraslation.go()
      }
    }
  }
}
