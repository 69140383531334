import { defer } from 'react-router-dom'
import type { OrderSummary } from 'types/model'
import { getPurchaseOrders } from './client/Receipt.client'
import { RECEIPT_URLS } from './utils/constants'
import { isProduction } from 'utils/environment'

export const isArrayOfOrders = (data: unknown): data is OrderSummary[] => {
  if (!Array.isArray(data)) {
    return false
  }

  return data.every((item) => {
    return (
      item &&
      typeof item === 'object' &&
      'receiptId' in item &&
      'orderId' in item &&
      'provider' in item &&
      'numberSkus' in item &&
      'frozenOrRefrigerated' in item &&
      'documentType' in item
    )
  })
}

export const getPurchaseOrdersLoader = async () => {
  const url = isProduction() ? RECEIPT_URLS.prod : RECEIPT_URLS.staging
  window.location.href = url
  const getOrdersPromise = getPurchaseOrders()
  return defer({ orders: getOrdersPromise })
}
