import { useEffect, useState } from 'react'
import { fastTraslationOrigin, fastTraslationProducts } from '../FastTraslation.state'
import { useAtom } from 'jotai'
import { useFastTraslationQuantityNavigator } from '../set-quantity/FastTraslationQuantity.navigator'
import { useScanner } from '../../../hooks/scanner.hook'
import { isSameProduct } from '../../../types/model'
import { useFastTransferWeightNavigator } from '../set-weight/FastTraslationWeight.navigator'
import { getProductFromScan } from '../../../client/scanner/scanner.client'
import { useNavigate } from 'react-router-dom'

export const useFastTraslationScanProduct = (onError: (err: string) => void) => {
  const {
    keys,
    getScan
  } = useScanner()
  const [inventoryProducts] = useAtom(fastTraslationProducts)
  const [origin] = useAtom(fastTraslationOrigin)
  const fastTraslationQuantity = useFastTraslationQuantityNavigator()
  const fastTransferWeight = useFastTransferWeightNavigator()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  
  useEffect(() => {
    const scanned = getScan()
    if (scanned && origin) {
      if (!inventoryProducts.some(isSameProduct(scanned))) {
        onError('El producto ' + scanned + ' no existe en el contenedor: ' + origin.containerName)
        return
      }
      setIsLoading(true)
      getProductFromScan(scanned)
        .then(e => {
          if (e.product.isWeighable) {
            fastTransferWeight.go(scanned)
          } else {
            fastTraslationQuantity.go(scanned)
          }
        })
        .catch(e => {
          console.error(e)
          onError('Error al obtener producto: ' + scanned)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [keys])

  return {
    inventoryProducts,
    origin,
    isLoading,
    back: () => { navigate(-1) }
  }
}
