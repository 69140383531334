import styled from 'styled-components'

import {
  CardComponent,
  AtlasColor,
  AtlasBodySubtitle,
  AtlasBodyMain,
  Icon,
  Divider,
  AtlasCaption
} from '@justomx/atlas-components'

import type { ProductLocation } from 'pages/inventory-transfer/types/InventoryTransfer.types'
import { FabButtonAtom } from 'components/atoms/FabButton.atom'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { FlexSpaceBetweenAtom } from 'components/atoms/FlexSpaceBetween.atom'
import { FlexColumnAtom } from 'components/atoms/FlexColumn.atom'
import { ProductZoneMolecule } from './ProductZone.molecule'

interface FooterLine {
  label: string
  value: string
}

interface DataCardProps {
  imageUrl: string
  title: string
  subtitle: string
  footerLines: FooterLine[]
  showErrorState?: boolean
  actionIcon?: string
  action?: () => void
  zone?: string
  locations?: ProductLocation[]
}

const ProductImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 8px;
`

const ProductDetailContainer = styled(FlexColumnAtom)`
  justify-content: center;
`

export const DataCardMolecule = (props: DataCardProps) => {
  const {
    imageUrl,
    title,
    subtitle,
    footerLines,
    showErrorState,
    actionIcon,
    zone,
    locations,
    action
  } = props

  return (
    <CardComponent
      style={{
        border: `1px solid ${
          showErrorState ? AtlasColor.Error : AtlasColor.PrimarySurface
        }`
      }}
    >
      <FlexSpaceBetweenAtom $gap={10}>
        <FlexRowAtom $gap={16}>
          <ProductImage src={imageUrl} alt="product" />
          <ProductDetailContainer $gap={16}>
            <AtlasBodySubtitle>{title}</AtlasBodySubtitle>
            <AtlasCaption>{subtitle}</AtlasCaption>
          </ProductDetailContainer>
        </FlexRowAtom>

        {action && actionIcon && (
          <FabButtonAtom
            type="button"
            $variant="secondary"
            $alignSelf="self-start"
            onClick={action}
          >
            <Icon src={actionIcon} />
          </FabButtonAtom>
        )}
      </FlexSpaceBetweenAtom>

      <Divider />

      {footerLines.map((line, index) => (
        <FlexSpaceBetweenAtom key={index}>
          <AtlasBodyMain>{line.label}</AtlasBodyMain>
          <AtlasBodyMain>{line.value}</AtlasBodyMain>
        </FlexSpaceBetweenAtom>
      ))}
      {locations?.length && (
        <ProductZoneMolecule locations={locations} zone={zone} />
      )}
    </CardComponent>
  )
}
