import { type InventoryProductWithAlertInfo } from '../../pages/fast-traslation/scan-product/ScanProduct.navigator'
import { AtlasBodySubtitle } from '@justomx/atlas-components'
import { InventoryProductCardMolecule } from 'components/molecules/InventoryProductCard.molecule'
import { type InventoryProduct } from 'types/model'

interface GroupOfProductProps {
  title: string
  variant?: 'critical' | 'warning'
  products: InventoryProductWithAlertInfo[]
  selectable?: boolean
  onSelect?: (p: InventoryProduct) => void
  selectedInventoryProducts?: InventoryProduct[]
}

function formatStock (stock: number, isWeighable: boolean): string {
  if (isWeighable) {
    return `${stock} g`
  } else if (stock === 1) {
    return `${stock} pieza`
  } else {
    return `${stock} piezas`
  }
}

export const GroupOfProduct = ({
  title,
  products,
  selectable,
  onSelect,
  selectedInventoryProducts,
  variant
}: GroupOfProductProps) => {
  if (products == null || products.length === 0) {
    return null
  }
  return (
    <>
      <AtlasBodySubtitle>{title}</AtlasBodySubtitle>
      {
        products.map((p) => (
          <InventoryProductCardMolecule
            selectable={selectable}
            key={p.productEan + p.stock}
            inventoryProduct={p}
            onClick={() => onSelect && onSelect(p)}
            selected={selectedInventoryProducts?.some(s => s.productSku === p.productSku)}
            subtitle={`Cantidad total: ${formatStock(p.stock, p.byWeight)}`}
            variant={variant}
          />
        ))
      }
    </>
  )
}
