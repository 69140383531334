import { type InventoryProduct } from '../types/model'
import { type Alerts } from '../client/alerts/alerts.types'
import _ from 'lodash'
import { type InventoryProductWithAlertInfo } from '../pages/fast-traslation/scan-product/ScanProduct.navigator'
import { getCurrentCountry } from '../client/utilities.client-helper'

export function isEanWeighable (ean: string): boolean {
  return ean.startsWith('2')
}

export function formatWeight (
  weight: number,
  decimalPlaces: number = 1,
  useKg: boolean = false
): string {
  if (!useKg) { return `${formatNumber(weight)} g` }
  return weight <= 1000 ? `${formatNumber(weight, decimalPlaces)} g` : `${formatNumber((weight / 1000), decimalPlaces)} kg`
}

export function formatNumber (value: number, decimalPlaces: number = 0): string {
  return value.toLocaleString('es-MX', {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces
  })
}

// 2012341005325
export function extractWeight (ean: string): number | null {
  if (ean == null || ean.length !== 13 || !/^\d+$/.test(ean) || !isEanWeighable(ean)) {
    return null
  }
  return parseInt(ean.substring(7, 12))
}

export function buildInventoryProductsWithAlertInfo (inventoryProducts: InventoryProduct[], alerts: Alerts[]): InventoryProductWithAlertInfo[] {
  const productsWithCriticalAlert = alerts.filter(product => product.state === 'critical')
  const productsWithWarningAlert = alerts.filter(product => product.state === 'warning')

  return inventoryProducts.map((product: InventoryProduct) => {
    const productAlertResult: InventoryProductWithAlertInfo = {
      ...product,
      alert: 'none',
      locations: [],
      expectedStock: 0,
      currentStock: 0
    }

    let alerts: Alerts[] = []

    const criticalAlerts = productsWithCriticalAlert.filter(e => e.productId === product.productId)

    if (criticalAlerts.length) {
      productAlertResult.alert = 'critical'
      alerts = criticalAlerts
    } else {
      const warningAlerts = productsWithWarningAlert.filter(e => e.productId === product.productId)
      if (warningAlerts.length) {
        productAlertResult.alert = 'warning'
        alerts = warningAlerts
      }
    }

    productAlertResult.locations = _.flatMap(
      alerts,
      alert => alert.locations.map(e => e.name)
    )

    productAlertResult.expectedStock = alerts.length ? Math.max(...alerts.map(e => e.expectedStock)) : 0

    // Preciso stock
    productAlertResult.currentStock = alerts.length ? Math.max(...alerts.map(e => e.stock)) : 0

    return productAlertResult
  })
}
