import { useTranslation } from 'react-i18next'
import { FieldArray, Form, FormikProvider } from 'formik'
import {
  BottomGroupMolecule,
  CounterCard,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  SecondaryButtonAtom,
  SpacerAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'

import { CardImageMolecule } from 'components/molecules/CardImage.molecule'
import { LotFormOrganism } from 'components/organisms/LotForm.organism'
import { useProductLotsData } from './ProductLots.hooks'
import { MissingDialogOrganism } from 'components/organisms/MissingDialog.organism'
import LoaderAtom from 'components/atoms/Loader.atom'
import { labelsByMeasureUnit } from './ProductLots.helpers'
import styled from 'styled-components'

const FormContent = styled(PageContentAtom)`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const ProductLotsPage = () => {
  const { t } = useTranslation('global')
  const {
    product,
    formConfig,
    boxesCounter,
    missingDialogRef,
    loading,
    container,
    missingCounter,
    expectedQuantity,
    receiptType,
    actions
  } = useProductLotsData()

  if (!product) {
    return null
  }

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}

      <ToolbarMolecule
        title={product.barcode}
        actionIcon={Icons.CloseIcon}
        onAction={actions.onClosePage}
        secondaryActionIcon={Icons.IcAddIcon}
        onSecondaryAction={actions.onAddNewContainer}
        secondaryLabel={t(
          product.measureUnit === 'KG'
            ? 'receipt-product-lots.counter-kg'
            : 'receipt-product-lots.counter',
          { counter: boxesCounter, total: expectedQuantity }
        )}
      />
      <FormContent>
        <CounterCard
          subtitle={t(
            `receipt-product-lots.${labelsByMeasureUnit[product.measureUnit]}`
          )}
          title={t(
            product.measureUnit === 'KG'
              ? 'receipt-product-lots.counter-kg'
              : 'receipt-product-lots.counter',
            { counter: boxesCounter, total: expectedQuantity }
          )}
        ></CounterCard>

        <CardImageMolecule
          imageURL={product.imgUrl}
          textLines={[
            t('receipt-order-detail.upc', { upc: product.barcode }),
            product.description,
            t('receipt-product-lots.measure', {
              measure: product.unitsOfMeasurment
            })
          ]}
        />

        <FormikProvider value={formConfig}>
          <Form onSubmit={formConfig.handleSubmit}>
            <FieldArray name="forms">
              {({ push, remove }) => (
                <>
                  <LotFormOrganism
                    formConfig={formConfig}
                    containerName={container}
                    product={product}
                    remove={remove}
                  ></LotFormOrganism>
                  <SpacerAtom height={200}></SpacerAtom>
                  <BottomGroupMolecule>
                    <SecondaryButtonAtom
                      type="button"
                      onClick={async () => {
                        await actions.onAddLot(push)
                      }}
                    >
                      {t('receipt-product-lots.add-lot')}
                    </SecondaryButtonAtom>
                    <PrimaryButtonAtom
                      type="submit"
                      disabled={
                        !formConfig.isValid ||
                        boxesCounter > product.upperTotalLimit
                      }
                    >
                      {t('receipt-product-lots.end')}
                    </PrimaryButtonAtom>
                  </BottomGroupMolecule>
                </>
              )}
            </FieldArray>
          </Form>
        </FormikProvider>
      </FormContent>

      <MissingDialogOrganism
        receiptType={receiptType}
        dialogRef={missingDialogRef}
        missingCount={missingCounter}
        product={product}
        onMissingReasonChange={actions.onMissingReasonChange}
        coninueAction={actions.continueToMissing}
      />
    </>
  )
}

export default ProductLotsPage
