import {ToolbarMolecule} from "../molecules";
import {AtlasBodySubtitle, Filler, Icons, ImageThumbnailComponent, PageContentAtom} from "../atoms";
import React, {useEffect} from "react";
import styled from 'styled-components'
import ErrorDinosaur from '../foundations/images/ErrorDinosaur.svg'
import { AnalyticsBrowser } from '@segment/analytics-next'
import {AtlasColor} from "../foundations/Colors.foundations";

export type ErrorPageProps = {
  title: string,
  reason: string,
  action: () => void
}

const Image = styled.img`
  width: 218px;
  height: 251px;
`

export const ErrorPage = (props: ErrorPageProps) => {
  return <>
    <ToolbarMolecule title={props.title} actionIcon={Icons.ArrowBack} onAction={props.action}/>
    <PageContentAtom $centered={true}>
      <Filler />
      <Image src={ErrorDinosaur} />
      <AtlasBodySubtitle color={AtlasColor.Neutral40}>{props.reason}</AtlasBodySubtitle>
      <Filler />
    </PageContentAtom>
  </>
}
