import { useState } from 'react'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { useQueryParam } from 'hooks/params.hook'
import { reverseLogisticsScannedSuppliesAtom, reverseLogisticsSelectedProductAtom } from '../reverse-logistics.state'
import { registerMissing } from '../reverse-logistics.client'
import { useReverseLogisticsNavigator } from '../ReverseLogistics.navigator'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useReverseLogisticsScanDestination } from '../scan-destination/ScanDestination.navigator'
import { formatWasteOrMissingEvent } from '../register-product-quantity/RegisterProductQuantity.helpers'
import { Analytics } from 'analytics/analytics.events'
import { useReverseLogisticsScanProduct } from '../scan-product/ScanProduct.navigator'
import { ReverseLogisticProductInfo, TareOrProduct } from 'types/model'
import { sortSuppliesByPendingQuantity, updateSupplyFromResponse } from '../reverse-logistics.helpers'

export const useRegisterWasteOrMissing = () => {
  const navigate = useNavigate()
  const flow = useQueryParam('flow')
  const [selectedProduct, setSelectedProduct] = useAtom(reverseLogisticsSelectedProductAtom)
  const [scannedSupplies, setScannedSupplies] = useAtom(reverseLogisticsScannedSuppliesAtom)
  const [quantity, setQuantity] = useState<number | null>(selectedProduct.pendingQuantity === 1 ? 1 : null)
  const [loading, setLoading] = useState(false)
  const reverseLogistics = useReverseLogisticsNavigator()
  const { t } = useTranslation('global')
  const scanDestination = useReverseLogisticsScanDestination()
  const scanProduct = useReverseLogisticsScanProduct()
  
  const handleNavigation = (supplies: TareOrProduct[]) => {
    if (supplies[0].type === 'Product') {
      reverseLogistics.go();
    } else {
      const allProductsWithPendingQuantityZero = supplies.every(supply => 
        supply.products.every(product => product.pendingQuantity === 0)
      ); 
      if (allProductsWithPendingQuantityZero) {
        reverseLogistics.go();
      } else {
        scanProduct.go();
      }
    }
  };

  const register = async () => {
    if (flow === 'waste') {
      scanDestination.go(quantity);
    } else {
      if (quantity && quantity <= selectedProduct.pendingQuantity) {
        try{
          let remainingQuantity = quantity;
    
          // Filter supplies with selected product and sort by pending quantity
          const sortedSupplies = sortSuppliesByPendingQuantity(scannedSupplies,selectedProduct);
          
         // Accumulate the API calls in a list of promises
          const promises = sortedSupplies
          .filter((supply) => remainingQuantity > 0 && supply.products.find((p) => p.ean === selectedProduct.ean))   
          .map((supply) => {
            if (remainingQuantity <= 0) return null;
    
            const product = supply.products.find(p => p.ean === selectedProduct.ean);
            if (!product) return null;
    
            const pendingQuantity = product.pendingQuantity;
            const missingQuantity = Math.min(remainingQuantity, pendingQuantity);

            remainingQuantity -= missingQuantity;

            return registerMissing({
              id: supply.id,
              ean: selectedProduct.ean,
              quantity: missingQuantity
            })
            .then(res => {
              setSelectedProduct(res.products.find(p => p.ean === selectedProduct.ean) as ReverseLogisticProductInfo);
    
              const eventData = formatWasteOrMissingEvent(selectedProduct, supply, quantity);
              Analytics.reverseLogistics.RegisterMissing(eventData);

              return res
            })
            .catch((error) => {
              throw new Error(`Error registering missing: ${error.message}`);
            });
          });
    
          // Filter out null values and handle the promises
          Promise.all(promises.filter(Boolean))
          .then((responses) => {
            const updatedSupplies = scannedSupplies.map((supply) => {
              const response = responses.find((res) => res.id === supply.id);
              return response ? updateSupplyFromResponse(supply, response) : supply;
            });
            setScannedSupplies(updatedSupplies);
            handleNavigation(updatedSupplies);

            toast.success(t(`reverse-logistics.register-waste-or-missing.success-missing`, { 
              quantity, 
              unit: selectedProduct.isWeighable ? 'g' : quantity === 1 ? 'unidad' : 'unidades',
              product: selectedProduct.name
            }));
          })
          .catch((error) => {
            toast.error(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
        } catch (error) {
          toast.error(error.message);
          setLoading(false);
        }  
      } else {
        toast.error(t('reverse-logistics.register-waste-or-missing.stock-error'));
      }
    }
  };
  

  return {
    flow,
    selectedProduct,
    quantity,
    loading,
    setQuantity,
    actions: {
      register,
      goBack: () => {
        navigate(-1)
      },
      goHome: () => {
        reverseLogistics.go()
      }
    }
  }
}
