import {RouteObject} from "react-router-dom";
import { REVERSE_LOGISTICS_URL } from "./ReverseLogistics.navigator";
import { REVERSE_LOGISTICS_SCAN_PRODUCT_URL } from "./scan-product/ScanProduct.navigator";
import { REVERSE_LOGISTICS_REGISTER_URL } from "./register-product-quantity/RegisterProductQuantity.navigator";
import { REVERSE_LOGISTICS_REGISTER_WASTE_OR_MISSING_URL } from "./register-waste-or-missing/RegisterWasteOrMissing.navigator";
import { REVERSE_LOGISTICS_SCAN_DESTINATION_URL } from "./scan-destination/ScanDestination.navigator";
import ReverseLogisticWrapper from "./ReverseLogistics.wrapper";
import { REVERSE_LOGISTICS_SCAN_SUPPLIES_URL } from "./v2/scan-supplies/ScanSupplies.navigator";
import { ScanSuppliesPage } from "./v2/scan-supplies/ScanSupplies.page";
import ScanProductWrapper from "./ScanProduct.wrapper";
import RegisterProductQuantityWrapper from "./RegisterProductQuantity.wrapper";
import RegisterWasteOrMissingWrapper from "./RegisterWasteOrMissing.wrapper";
import ScanDestinationWrapper from "./ScanDestination.wrapper";

export const reverseLogisticsRoutes: RouteObject[] = [
  {
    id: '(Logistica Inversa) - Scan',
    path: REVERSE_LOGISTICS_URL,
    element: <ReverseLogisticWrapper/>
  },
  {
    id: '(Logistica Inversa) - Taras',
    path: REVERSE_LOGISTICS_SCAN_SUPPLIES_URL,
    element: <ScanSuppliesPage/>
  },
  {
    id: '(Logistica Inversa) - Productos',
    path: REVERSE_LOGISTICS_SCAN_PRODUCT_URL,
    element: <ScanProductWrapper/>
  },
  {
    id: '(Logistica Inversa) - Registrar',
    path: REVERSE_LOGISTICS_REGISTER_URL,
    element: <RegisterProductQuantityWrapper/>
  },
  {
    id: '(Logistica Inversa) - Registrar Merma o Faltante',
    path: REVERSE_LOGISTICS_REGISTER_WASTE_OR_MISSING_URL,
    element: <RegisterWasteOrMissingWrapper/>
  },
  {
    id: '(Logistica Inversa) - Escanear destino',
    path: REVERSE_LOGISTICS_SCAN_DESTINATION_URL,
    element: <ScanDestinationWrapper/>
  },
]
