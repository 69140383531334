import React, { useEffect, useRef, useCallback, ReactNode } from 'react';
import styled from 'styled-components';
import { AtlasColor } from '../foundations/Colors.foundations';

const SidebarContainer = styled.div<{ $isOpen: boolean }>`
  width: ${(props) => (props.$isOpen ? '75%' : '0')};
  transition: width 0.3s ease;
  overflow: hidden;
  height: 100%;
  background-color: ${AtlasColor.PrimarySurface};
  padding: ${(props) => (props.$isOpen ? '32px 24px' : '0')};
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  > * {
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s ease,
      visibility 0s linear ${(props) => (props.$isOpen ? '0.2s' : '0s')};
  }

  ${(props) =>
    props.$isOpen &&
    `
    > * {
      opacity: 1;
      visibility: visible;
    }
  `}
`;

interface SidebarAtomProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children: ReactNode;
}

export const SidebarAtom = ({ isOpen, setIsOpen, children, ...props }: SidebarAtomProps) => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  return (
    <SidebarContainer $isOpen={isOpen} ref={sidebarRef} {...props}>
      {children}
    </SidebarContainer>
  );
};
