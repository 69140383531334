import type { TFunction } from 'i18next'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import type {
  FormikRejectValues,
  ProductDetail,
  RejectionData,
  RejectionSummary,
  UpsertRejectionData
} from 'types/model'
import {
  COMMON_REJECT_REASONS,
  MEASURE_UNITS,
  REGEXPS,
  REJECT_REASONS_BY_ZONE
} from '../utils/constants'

export const maxPhotos = 6

export const getRejectReasons = (zone: string) => {
  const additionalReasons: string[] = REJECT_REASONS_BY_ZONE[zone]
  if (additionalReasons?.length) {
    return COMMON_REJECT_REASONS.concat(additionalReasons)
  }
  return COMMON_REJECT_REASONS
}

export const getRejectionData = (
  receiptId: string,
  product: ProductDetail,
  values: FormikRejectValues,
  photos: string[],
  rejectionId?: string
) => {
  const upsertData: UpsertRejectionData = {
    receiptId,
    productId: product.productId,
    product: {
      id: product.productId,
      barcode: product.barcode,
      expectedQuantity: product.totalUnits
    },
    rejected: {
      id: rejectionId,
      photos,
      quantity: +values.amount,
      lot: values.lot,
      rejectedReasons: values.reason,
      boxes: values.boxes ? +values.boxes : undefined
    }
  }
  return upsertData
}

export const getValidationSchema = (
  t: TFunction<'global', undefined>,
  maxAmount: number,
  maxBoxes: number,
  measureUnit: string,
  validateRanges: boolean
) => {
  const required = t('validations.required')
  const amount = t('validations.invalid-amount')

  const boxesValidation = Yup.number().positive(amount).integer(amount)
  const amountValidation = Yup.number()
    .required(required)
    .positive(amount)
    .integer(amount)
  return Yup.object({
    amount: Yup.number().concat(
      validateRanges
        ? amountValidation.max(maxAmount, t('validations.greater-total-units'))
        : amountValidation
    ),
    lot: Yup.string()
      .required(required)
      .matches(REGEXPS.alphanum, t('validations.alphanum')),
    reason: Yup.array().min(1, t('validations.multi-select')),
    boxes: Yup.number().concat(
      measureUnit === MEASURE_UNITS.box
        ? boxesValidation
          .required(required)
          .concat(
            validateRanges
              ? Yup.number().max(
                maxBoxes,
                t('validations.greater-total-units')
              )
              : Yup.number()
          )
        : boxesValidation.notRequired()
    )
  })
}

export const generateFileName = (originalName: string, orderId: string) => {
  const timestamp = new Date().getTime()
  const extension = originalName.split('.').pop()
  return `order_${orderId}_${timestamp}_${uuidv4()}.${extension}`
}

export const renameFile = (originalFile: File, newFileName: string): File => {
  const renamedFile = new File([originalFile], newFileName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified
  })
  return renamedFile
}

export const getRejectLimit = (
  product: ProductDetail | undefined,
  totals: RejectionSummary
) => {
  if (!product) {
    return { units: 0, boxes: 0 }
  }
  const currentRejected = totals.rejection?.quantity || 0
  const totalGrams =
    product.measureUnit === 'KG'
      ? product.totalUnits * 1000
      : product.totalUnits
  const total =
    totalGrams +
    currentRejected -
    (totals.totalMissing + totals.totalReceived + totals.totalRejected)

  const currentRejBoxes = totals.rejection?.boxes || 0
  const boxes =
    product.quantity +
    currentRejBoxes -
    (totals.totalMissingBoxes +
      totals.totalReceivedBoxes +
      totals.totalRejectedBoxes)

  return { units: total, boxes }
}

export const mapRejFormValues = (rej?: RejectionData): FormikRejectValues => {
  if (rej) {
    return {
      amount: rej.quantity.toString(),
      lot: rej.lot,
      reason: rej.rejectedReasons,
      boxes: rej.boxes?.toString()
    }
  }
  return { amount: '', lot: '', reason: [], boxes: '' }
}
