import { useScanner } from '../../hooks/scanner.hook'
import { useEffect } from 'react'
import { getInventoryProductsClient } from '../../client/utilities.client'
import { manageError } from '../../utils/errors/error.handler'
import { useScanProductNavigator } from './scan-product/ScanProduct.navigator'
import { getScannedInfo } from 'client/scanner/scanner.client'
import { type ScannedLocation, ScanType } from 'client/scanner/scanner.types'
import { toCommonLocationInfoFromScanResponse } from 'utils/commonLocationInfo'

export const useFastTraslation = (onError: (err: string) => void) => {
  const {
    keys,
    getScan
  } = useScanner()
  const scanProductNavigator = useScanProductNavigator(onError)

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      getScannedInfo(scanned)
        .then((info) => {
          if (info.type === ScanType.Location) {
            if ((info as ScannedLocation).totalContainers > 1) {
              onError(
                `Se escaneó una ubicación (${scanned}) con multiples contenedores`
              )
              return
            } else if ((info as ScannedLocation).totalContainers === 0) {
              onError(`Se escaneó una ubicación (${scanned}) sin contenedor`)
              return
            }
          } else if (info.type === ScanType.Product) {
            onError(
              'En esta pantalla no se puede escanear un EAN/UPC de un producto.'
            )
            return
          }
          return info
        })
        .then(async (origin) => {
          if (
            origin?.location?.legacyId == null ||
            origin?.container?.legacyId == null
          ) {
            return
          }
          const products = await getInventoryProductsClient(
            origin.location.legacyId,
            origin.container.legacyId
          )

          if (products.length === 0) {
            onError(`El contenedor ${origin.container.name} no tiene productos`)
            return
          }
          const commonLocationInfo = toCommonLocationInfoFromScanResponse(origin)
          await scanProductNavigator.go(commonLocationInfo, products, false)
        })
        .catch(manageError(`FastTraslation.hooks.ts#useEffect ${scanned}`))
    }
  }, [keys])
}
