import type { InventoryProduct, Product } from 'types/model'

export function validateProduct (product: Product, inventoryProducts: InventoryProduct[]) {
  const selectedProduct = inventoryProducts.find((p) => p.productId === product.productId)

  if (selectedProduct === undefined) {
    throw new Error('El producto no está registrado en la ubicación, actualiza la información.')
  }

  if (selectedProduct?.stock === 0) {
    throw new Error('El producto no tiene stock en la ubicación o contenedor seleccionado.')
  }

  return {
    ...selectedProduct,
    productZone: product.productZone,
    byWeight: product.isWeighable
  }
}
