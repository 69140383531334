import { AtlasBodyMain, AtlasColor, CardComponent, Icon, Icons } from "@justomx/atlas-components"
import { FlexRowAtom } from "components/atoms/FlexRow.atom"

export const InfoMessage = ({message}: {message: string}) => {
  return (
    <CardComponent $variant='highlight' style={{ marginBottom: '12px'}}>
      <FlexRowAtom style={{ alignItems: 'center', gap: 8}}>
        <Icon src={Icons.IcInformationIcon} size={20}/>
        <AtlasBodyMain color={AtlasColor.Neutral00}>
          {message}
        </AtlasBodyMain>
      </FlexRowAtom>
    </CardComponent>
  )
}