import styled from 'styled-components'
import React from "react";

const ProgressBarContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 7px; 
  background-color: transparent; 
  top: 2.5rem; 
  z-index: 2;
`

const ProgressBarLine = styled.progress`
  width: 100%;
  height: 100%; 
  color: #ffd700; 
  background-color: #cccccc;

  &::-webkit-progress-bar {
    background-color: #212121;
  }
  &::-webkit-progress-value {
    background-color: #ffd700; 
  }
`

type ProgressBarProps = {
  progress: number;
  "data-test"?: string;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const {progress} = props;
  return (
    <ProgressBarContainer>
      <ProgressBarLine
        value={progress}
        max={100}
        aria-valuemin={0}
        aria-valuemax={100}
        data-test={props['data-test']}
      />
    </ProgressBarContainer>
  )
}
