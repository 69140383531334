import { useTranslation } from 'react-i18next'
import { useScanOrigin } from './ScanOrigin.hook'
import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon, Icons,
  PageContentAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'

export const ScanOriginPage = () => {
  const { actions } = useScanOrigin()
  const { t } = useTranslation('global')
  
  return <>
    <ToolbarMolecule title={t('scan-origin.toolbar-title')}
                     actionIcon={Icons.IcMenuIcon}
                     onAction={actions.openMenu}/>
    <PageContentAtom $centered>
      <Filler/>
      <Icon src={Icons.ScanIcon}/>
      <AtlasBodyTitle>{t('scan-origin.scan')}</AtlasBodyTitle>
      <AtlasH1 style={{ textAlign: 'center' }}>{t('scan-origin.location')} <br />o <br />{t('scan-origin.container')}</AtlasH1>
      <AtlasBodyTitle>{t('scan-origin.use-your-scanner')}</AtlasBodyTitle>
      <Filler/>
      <TextBoxMolecule label={t('scan-origin.insert-last-four-digits')}
                       onNewValue={actions.goToContainerByLastNumbers}/>
    </PageContentAtom>
  </>
}
