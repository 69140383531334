import React from 'react'
import { Await } from 'react-router-dom'

import {
  ToolbarMolecule,
  Icons,
  PageContentAtom,
  CardComponent,
  AtlasBodyMain,
  BottomGroupMolecule,
  SecondaryButtonAtom,
  AtlasBodySubtitle
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'

import { MyFiller } from 'pages/fast-traslation/scan-product/ScanProduct.page'
import type { OrderSummary } from 'types/model'
import LoaderAtom from 'components/atoms/Loader.atom'
import { Global } from 'utils/global'
import { useReceiptData } from './Receipt.hooks'

const Receipt = () => {
  const { t } = useTranslation('global')
  const { data, actions } = useReceiptData()

  return (
    <React.Suspense fallback={<LoaderAtom>{t('loader.message')}</LoaderAtom>}>
      <Await
        resolve={(data as any).orders}
        errorElement={<p>Error loading orders data!</p>}
      >
        {(orders: OrderSummary[]) => (
          <>
            <ToolbarMolecule
              title={t('receipt.title')}
              actionIcon={Icons.HamburgerIcon}
              onAction={Global.WV.openMenu}
              secondaryActionIcon={Icons.IcQuickReorder}
              onSecondaryAction={actions.goToInventoryTransfer}
            />

            <PageContentAtom>
              {orders.map((order, idx) => (
                <CardComponent
                  key={`order${idx}`}
                  onClick={() => {
                    actions.onSelectOrder(order)
                  }}
                >
                  <AtlasBodySubtitle>
                    {t('receipt.order-id', { orderId: order.orderId })}
                  </AtlasBodySubtitle>
                  <AtlasBodyMain>
                    {t('receipt.provider', { provider: order.provider })}
                  </AtlasBodyMain>
                  <AtlasBodyMain>
                    {t('receipt.total-sku', { totalSKU: order.numberSkus })}
                  </AtlasBodyMain>
                </CardComponent>
              ))}

              {!orders.length
                ? <>
                    <AtlasBodySubtitle>{t('receipt.no-data-title')}</AtlasBodySubtitle>
                    <AtlasBodyMain>{t('receipt.no-data-help')}</AtlasBodyMain>
                  </>
                : null}

              <MyFiller />
              <BottomGroupMolecule>
                <SecondaryButtonAtom onClick={actions.onSelectSync}>
                  {t('receipt.sync')}
                </SecondaryButtonAtom>
              </BottomGroupMolecule>
            </PageContentAtom>
          </>
        )}
      </Await>
    </React.Suspense>
  )
}

export default Receipt
