import { useNavigate, useSearchParams } from 'react-router-dom'
import { useScanner } from '../../../hooks/scanner.hook'
import { useEffect } from 'react'
import { getLocationOrContainerByLastNumbers } from '../../../client/utilities.client'
import { selectedInventoryProductsReader, traslationDestinationInfoAtom, traslationOriginInfoReader } from '../Traslation.state'
import { useAtom } from 'jotai/index'
import { type LocationOrContainer } from '../../../types/model'
import { manageError } from '../../../utils/errors/error.handler'
import { validateMovement } from './ScanDestination.validator'
import { MOVE_CONTAINER, isTraslationFlow } from '../traslation.flow'
import { useGoToMoveInventoryItems } from '../move-all/MoveAll.navigator'
import { getLocationOrContainerFromScan } from 'client/scanner/scanner.client'

export const useScanDestination = () => {
  const navigate = useNavigate()
  const { keys, getScan } = useScanner()
  const [searchParams] = useSearchParams()
  const callbackUrl = searchParams.get('callbackUrl')
  const flow = searchParams.get('flow')
  const quantity = searchParams.get('quantity')
  const hasLooseProducts = searchParams.get('looseProducts')
  const [, setDestinationContainer] = useAtom(traslationDestinationInfoAtom)
  const [origin] = useAtom(traslationOriginInfoReader)
  const moveInventoryProducts = useGoToMoveInventoryItems()
  const [inventoryProducts] = useAtom(selectedInventoryProductsReader)

  if (callbackUrl === null) {
    throw new Error('Callback URL is null')
  }

  if (!isTraslationFlow(flow)) {
    throw new Error(`Unexpected traslation flowURL:${flow}`)
  }

  const goToCallback = (scanned: string) => (e: LocationOrContainer) => {
    validateMovement({
      origin,
      destination: e,
      flow,
      valueScanned: scanned
    })
    
    setDestinationContainer(e)

    if(hasLooseProducts === 'true'){
      moveInventoryProducts.go({
        origin,
        destination: e as LocationOrContainer,
        inventoryProducts,
        moveContainer: flow === MOVE_CONTAINER ? 'true' : 'false',
        looseProducts: hasLooseProducts
      })
    }
    else{
      if(inventoryProducts.length === 1){ 
        moveInventoryProducts.goOneProduct({
          origin,
          destination: e as LocationOrContainer,
          inventoryProduct: inventoryProducts[0],
          quantity: Number(quantity)
        })
      }
      else{
        moveInventoryProducts.go({
          origin,
          destination: e as LocationOrContainer,
          inventoryProducts,
          moveContainer: flow === MOVE_CONTAINER ? 'true' : 'false',
          looseProducts: hasLooseProducts as string
        })
      }
    }
  }

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      getLocationOrContainerFromScan(scanned)
        .then(goToCallback(scanned))
        .catch(manageError(`ScanDestination.hooks.ts#useEffect ${scanned}`))
    }
  }, [keys, goToCallback, flow])

  return {
    flow,
    origin,
    actions: {
      back: () => {
        navigate(-1)
      },
      goToContainerByLastNumbers: (lastNumbers: string) => {
        getLocationOrContainerByLastNumbers(lastNumbers)
          .then(goToCallback(lastNumbers))
          .catch(manageError(`ScanDestination.hooks.ts#actions.goToContainerByLastNumbers ${lastNumbers}`))
      }
    }
  }
}
