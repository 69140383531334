import { type ScannedContainer, type ScannedLocation, ScanType } from 'client/scanner/scanner.types'
import { LocationType, LocationZone } from '../../../client/types'

export function validateScan (scan: ScannedContainer | ScannedLocation) {
  if (scan.location.type === LocationType.Receipt) {
    throw Error('E-1: No se puede escanear una ubicación y/o contenedor en ubicación de recibo')
  }

  if (scan.type === ScanType.Location) {
    if (scan.totalContainers > 1) {
      throw Error('E-2: No se puede escanear una ubicación con multiples contenedores')
    }

    if (
      (scan.location.type === LocationType.PreStorage ||
        (scan.location.type === LocationType.Storage && scan.location.zone === LocationZone.Groceries)) &&
      scan.totalContainers === 0
    ) {
      throw Error(`E-5: No se puede escanear una ubicación de ${
        scan.location.type === LocationType.Storage ? 'almacenamiento' : 'pre-almacenamiento'
      } que no tenga un contenedor.`)
    }
  }

  return scan
}
