import { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSetAtom } from 'jotai'

import { Analytics } from 'analytics/analytics.events'
import { isValidLPNScan } from 'pages/fast-traslation/isValidLpn.util'
import { useRequiredParams } from 'hooks/params.hook'
import { isContainerStatus } from './MoveContainers.loader'
import { defaultActiveTab, groupByStatus, tabs } from './MoveContainers.helpers'
import { useGoToScanLocation } from '../scan-location/ScanLocation.navigator'
import { useGoToOrderDetail } from '../order-detail/OrderDetail.navigator'
import { useGoToReceipt } from '../Receipt.navigator'
import { containerToMoveAtom } from '../Receipt.state'
import { toastError, toastSuccess } from '../utils/toast'
import { finishReceipt } from '../client/Receipt.client'

export const useMoveContainersData = () => {
  const { t } = useTranslation('global')
  const loaderData = useLoaderData()
  const [activeTab, setActiveTab] = useState(defaultActiveTab)
  const [loading, setLoading] = useState(false)
  const { orderId, receiptId, receiptType } = useRequiredParams(
    'orderId',
    'receiptId',
    'receiptType'
  )
  const scanLocation = useGoToScanLocation()
  const orderDetail = useGoToOrderDetail()
  const receipt = useGoToReceipt()
  const setContainerToMove = useSetAtom(containerToMoveAtom)

  if (!isContainerStatus(loaderData)) {
    throw new Error(t('receipt-containers.type-error'))
  }
  const containers = groupByStatus(loaderData.containers)

  const onChangeTab = (selectedTab: string) => {
    setActiveTab(selectedTab)
  }

  const getTabs = () => {
    return tabs.map((id) => ({
      id,
      label: t(`receipt-containers.${id}`, {
        counter: containers[id]?.length || 0
      })
    }))
  }

  const onScan = (scanned: string) => {
    if (!isValidLPNScan(scanned)) {
      toastError(t('receipt-scan-container.invalid-input', { lpn: scanned }))
      return
    }
    const container = containers.toMove.find(
      (container) => container.containerName === scanned
    )
    if (!container) {
      toastError(t('receipt-containers.invalid-container'))
      return
    }
    setContainerToMove(container)
    scanLocation.go(receiptId, orderId)
  }

  const onFinishOrder = async () => {
    try {
      setLoading(true)
      await finishReceipt(receiptId)
      Analytics.receipt.finishReceipt({ orderId, receiptType: 'providers' })

      toastSuccess(t('receipt-containers.finish-success'))
      receipt.go()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    containers,
    activeTab,
    enableFinish: loaderData.allProductsReceived,
    loading,
    actions: {
      onChangeTab,
      getTabs,
      onScan,
      onFinishOrder,
      goToOrderDetail: () => {
        orderDetail.go(receiptType, receiptId, orderId)
      }
    }
  }
}
