import type { ChangeEvent } from 'react'
import styled from 'styled-components'
import { AtlasBodyMain, AtlasColor } from '@justomx/atlas-components'

interface CheckboxProps {
  label: string
  name: string
  value: string
  checked: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const Container = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  height: 24px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .checkmark {
    background-color: ${AtlasColor.Primary00};
    border: 2px solid ${AtlasColor.Primary00};
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 2px solid ${AtlasColor.Neutral60};
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 0;
    width: 5px;
    height: 14px;
    border: solid ${AtlasColor.Background};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export const CheckboxMolecule = (props: CheckboxProps) => {
  const { label, name, value, checked, onChange } = props
  return (
    <Container>
      <AtlasBodyMain>{label}</AtlasBodyMain>
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <Checkmark className="checkmark" />
    </Container>
  )
}
