import { useNavigate } from 'react-router-dom'

export const REVERSE_LOGISTICS_SCAN_DESTINATION_URL = '/reverse-logistics/scan-destination'

export const useReverseLogisticsScanDestination = () => {
  const navigate = useNavigate()
  return {
    go: (quantity) => { navigate(`${REVERSE_LOGISTICS_SCAN_DESTINATION_URL}?quantity=${quantity}`) }
  }
}
