import { useTranslation } from 'react-i18next'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasColor,
  AtlasH1,
  AtlasListAtom,
  CardComponent,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  SpacerAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useRegister } from './RegisterProductQuantity.hook'
import { ProductCard } from '../components/product-card.component'
import ChangeWeightMolecule from 'components/molecules/ChangeWeight.molecule'
import ListOfWeights from 'components/molecules/ListOfWeights.molecule'
import { formatWeight } from 'utils/product'
import { formatString } from 'utils/string'
import { FlexColumnAtom } from 'components/atoms/FlexColumn.atom'
import { ConfirmationDialogMolecule, useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import LoaderAtom from 'components/atoms/Loader.atom'

export const ReverseLogisticsRegisterPage = () => {
  const { suggestedLocations, selectedProduct, loading, weights, totalWeights, setQuantity, actions } = useRegister()
  const { t } = useTranslation('global')
  const {
    dialogRef: deleteConfirmationRef,
    open: openDeleteDialog,
    value: valueToDelete
  } = useConfirmationDialog<number>()

  if(loading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }
  
  const isWeighable = selectedProduct?.isWeighable

  const quantityToShow = isWeighable ? selectedProduct.pendingQuantity - totalWeights : selectedProduct.pendingQuantity

  return <>
    <ToolbarMolecule
      title={t('reverse-logistics.register.toolbar-title')}
      actionIcon={Icons.ArrowBack}
      onAction={actions.goBack}
    />
    <PageContentAtom>
      <AtlasBodySubtitle>{t("reverse-logistics.register.instructions")}</AtlasBodySubtitle>
      <AtlasListAtom>
        <li><AtlasBodyMain display='contents'>{t(`reverse-logistics.register.set-quantity${isWeighable ? '-weighable' : ''}`)}</AtlasBodyMain></li>
        <li><AtlasBodyMain display='contents'>{t("reverse-logistics.register.scan-destination")}</AtlasBodyMain></li>
      </AtlasListAtom>
      <CardComponent $variant='highlight' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <CardComponent style={{backgroundColor: '#686868', textAlign: 'center'}}>
          <AtlasBodyMain style={{color: AtlasColor.Neutral20}}>Despickea</AtlasBodyMain>
          <AtlasH1>{quantityToShow} {isWeighable ? 'g' : 'uds'}</AtlasH1>
        </CardComponent>
        <Icon src={Icons.ArrowForward} color='white' size={16}/>
        <CardComponent style={{backgroundColor: '#686868', textAlign: 'center', gap: '12px'}}>
        <AtlasBodyMain color={AtlasColor.Neutral40}>{t('reverse-logistics.register.suggested-location')}</AtlasBodyMain>
        {suggestedLocations?.map((location, index) =>
            <AtlasBodySubtitle key={`${index}-${location}`}>{location}</AtlasBodySubtitle>
          )}
        </CardComponent>
        <SpacerAtom height={20} />
        <FlexColumnAtom style={{display: quantityToShow === 1 ? "none" : "block", width: '100%'}}>
        {
          !isWeighable ?
            <TextBoxMolecule 
              type="number"
              label={t('inventory-waste.register-waste.units')}
              onChange={(value)=>setQuantity(parseInt(value))}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  const target = e.target as HTMLInputElement; 
                  target.blur();
                }
              }}
            />
            : 
            <ChangeWeightMolecule
              onAdd={(value) => {
                actions.addItem(value)
              }}
              label={t('inventory-waste.register-waste.grams')}
            />
        }
        </FlexColumnAtom>
      </CardComponent>
      {isWeighable && <ListOfWeights
        detail={formatString(t('inventory-waste.register-waste.total-qty'), formatWeight(totalWeights, 2))}
        items={weights}
        onDelete={openDeleteDialog}
      />}
      <Filler/>
      <ProductCard product={selectedProduct} showTitle={true}/>
      <ConfirmationDialogMolecule 
        dialogRef={deleteConfirmationRef}
        value={valueToDelete}
        confirmText={t('fast-set-weight.dialog-delete.btn-confirm')}
        onConfirm={actions.deleteItem}
        cancelText={t('fast-set-weight.dialog-delete.btn-cancel')}>
        {
          valueToDelete
            ? formatString(t('fast-set-weight.dialog-delete.msg'), formatWeight(valueToDelete))
            : ''
        }
      </ConfirmationDialogMolecule>
    </PageContentAtom>
  </>
}
