import { useTranslation } from 'react-i18next'
import { type InventoryProduct } from '../../types/model'
import {
  AtlasBodyMain,
  AtlasColor,
  CardComponent,
  CardRow,
  ImageThumbnailComponent,
  StackAtom
} from '@justomx/atlas-components'

interface InventoryProductPreviewMoleculeProps {
  inventoryProduct: InventoryProduct
}

export const InventoryProductPreviewMolecule = ({ inventoryProduct }: InventoryProductPreviewMoleculeProps) => {
  const { t } = useTranslation('global')
  return <CardComponent style={{ padding: 8 }}>
    <CardRow $gap={12}>
      <ImageThumbnailComponent size={40}
                               src={inventoryProduct.productImage}
                               style={{ alignSelf: 'self-start' }}/>
      <StackAtom>
        <AtlasBodyMain color={AtlasColor.Neutral40}>{inventoryProduct.productName}</AtlasBodyMain>
        <AtlasBodyMain color={AtlasColor.Neutral40}>{`${t('move-all.product-qty')} ${inventoryProduct.stock}`}</AtlasBodyMain>
      </StackAtom>
    </CardRow>
  </CardComponent>
}
