import { ScanOriginPage } from './scan-origin/ScanOrigin.page'
import { ScanDestinationPage } from './scan-destination/ScanDestination.page'
import { ScanProductPage } from './scan-product/ScanProduct.page'
import { type RouteObject } from 'react-router-dom'
import { MoveOptionsPage } from './move-options/MoveOptions.page'
import { MOVE_OPTIONS_URL } from './move-options/MoveOptions.navigator'
import { MOVE_INVENTORY_ITEMS } from './move-all/MoveAll.navigator'
import { MoveAllPage } from './move-all/MoveAll.page'
import { SCAN_DESTINATION_URL } from './scan-destination/ScanDestination.navigator'
import { moveAllLoader } from './move-all/MoveAll.loader'
import { MOVE_PRODUCT_LIST_URL } from './move-product-list/MoveInventoryProductList.navigator'
import { moveInventoryProductListLoader } from './move-product-list/MoveInventoryProductList.loader'
import { MoveInventoryProductListPage } from './move-product-list/MoveInventoryProductList.page'
import { TRASLATION_SCAN_ORIGIN } from './scan-origin/ScanOrigin.navigator'
import { MOVE_ONE_CONFIGURATION } from './move-one-configuration/MoveOneConfiguration.navigator'
import { MoveOneConfigurationPage } from './move-one-configuration/MoveOneConfiguration.page'
import { CHOOSE_CONTAINER_URL } from './choose-container/ChooseContainer.navigator'
import { ContainersPage } from './choose-container/ChooseContainer.page'
import { chooseContainerLooseProductsListLoader } from './choose-container/ChooseContainer.loader'
import { moveOptionsLooseProductsListLoader } from './move-options/MoveOptions.loader'

const simulateSlowness = (response) => async () =>
  await new Promise(resolve => setTimeout(resolve, 1000))
    .then(() => response)

export const traslationRoutes: RouteObject[] = [
  {
    id: '(traslate) Scan origin',
    path: TRASLATION_SCAN_ORIGIN,
    element: <ScanOriginPage/>
  },
  {
    id: '(translate) Option for scan',
    path: MOVE_OPTIONS_URL,
    element: <MoveOptionsPage />,
    loader: moveOptionsLooseProductsListLoader
  },
  {
    id: '(traslate) Scan destination',
    path: SCAN_DESTINATION_URL,
    element: <ScanDestinationPage/>
  },
  {
    id: '(traslate) Move all products to location',
    path: MOVE_INVENTORY_ITEMS,
    element: <MoveAllPage />,
    loader: moveAllLoader
  }, {
    id: '(traslate) Move some products to location',
    path: MOVE_PRODUCT_LIST_URL,
    element: <MoveInventoryProductListPage />,
    loader: moveInventoryProductListLoader
  },
  {
    id: '(traslate) Scan product',
    path: '/traslation/scan-product',
    element: <ScanProductPage/>,
    loader: simulateSlowness('respuesta: scan-product-page')
  }, {
    id: '(traslate) Configure movement of one product to location',
    path: MOVE_ONE_CONFIGURATION,
    element: <MoveOneConfigurationPage />
  },
  {
    id: '(traslate) Choose Container',
    path: CHOOSE_CONTAINER_URL,
    element: <ContainersPage/>,
    loader: chooseContainerLooseProductsListLoader
  },
]
