import { Form, FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  AtlasBodySubtitle,
  BottomGroupMolecule,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { usePartialMissingData } from './PartialMissing.hooks'
import { FormikTextFieldOrganism } from 'components/organisms/FormikTextField.organism'
import LoaderAtom from 'components/atoms/Loader.atom'
import { MEASURE_UNITS } from '../utils/constants'

const PartialMissingPage = () => {
  const { t } = useTranslation('global')
  const { formConfig, loading, product, actions } = usePartialMissingData()

  if (!product) {
    return
  }

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}

      <ToolbarMolecule
        title={t('receipt-partial-missing.title')}
        actionIcon={Icons.ArrowBack}
        onAction={actions.goBack}
      />

      <PageContentAtom $gap={32}>
        <AtlasBodySubtitle>
          {t('receipt-partial-missing.subtitle')}
        </AtlasBodySubtitle>

        <FormikProvider value={formConfig}>
          <Form
            onSubmit={formConfig.handleSubmit}
            style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          >
            <FormikTextFieldOrganism
              type="number"
              name="totalMissing"
              label={t(
                product.measureUnit === 'KG'
                  ? 'receipt-partial-missing.total-kg'
                  : 'receipt-partial-missing.total-missing'
              )}
              formConfig={formConfig}
            />

            {product.measureUnit === MEASURE_UNITS.box && (
              <FormikTextFieldOrganism
                type="number"
                name="boxes"
                label={t('receipt-partial-missing.missing-boxes')}
                formConfig={formConfig}
              />
            )}

            <BottomGroupMolecule>
              <PrimaryButtonAtom type="submit" disabled={!formConfig.isValid}>
                {t('receipt-partial-missing.register')}
              </PrimaryButtonAtom>
            </BottomGroupMolecule>
          </Form>
        </FormikProvider>
      </PageContentAtom>
    </>
  )
}

export default PartialMissingPage
