import { AlertIndicatorAtom } from 'components/atoms/AlertIndicator.atom'
import { LocationOrContainer, type LocationAndContainer } from '../../types/model'
import {AtlasBodySubtitle, AtlasBodyTitle, AtlasColor, CardRow, Icon, Icons} from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'

interface LocationAndContainerMoleculeProp {
  location: Omit<LocationAndContainer, 'containersInLocation'>
  focus?: 'location' | 'container'
}

export const LocationAndContainerMolecule = ({ location, focus='container' }: LocationAndContainerMoleculeProp) => {
  const isLocationFocus = focus === 'location';
  const title = isLocationFocus ? location.locationName : location.containerName;
  const subtitle = isLocationFocus ? location.containerName : location.locationName;
  const titleIcon = isLocationFocus ? Icons.IcUbicacionIcon : Icons.IcPallet;
  const subtitleIcon = isLocationFocus ? Icons.IcPallet : Icons.IcUbicacionIcon;
  return (
    <>
      <CardRow style={{justifyContent: 'space-between'}}>
        <FlexRowAtom style={{ gap: 8}}>
          <Icon src={titleIcon} size={18}/>
          <AtlasBodyTitle 
            color={AtlasColor.Neutral00}
            data-location={title}
          >
            {title}
          </AtlasBodyTitle>
        </FlexRowAtom>
        {location.alerts && 
          <FlexRowAtom style={{ gap: 8}}>
            <AlertIndicatorAtom type='urgent'>{location.alerts.urgent}</AlertIndicatorAtom>
            <AlertIndicatorAtom type='critical'>{location.alerts.critical}</AlertIndicatorAtom>
            <AlertIndicatorAtom type='warning'>{location.alerts.warning}</AlertIndicatorAtom>
          </FlexRowAtom>
        }
      </CardRow>
      {subtitle &&
        <CardRow >
          <Icon src={subtitleIcon} size={18}/>
          <AtlasBodySubtitle 
            color={AtlasColor.Neutral20}
            data-container={subtitle}
            >
            {subtitle}
          </AtlasBodySubtitle>
        </CardRow>
      }
    </>
  )
}
