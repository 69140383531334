export const formatUnpickItemEvent = (selectedProduct, selectedItem, location, quantity) =>{
  return {
    ean: selectedProduct.ean,
    destinationLocationName: location.name,
    destinationLocationType: location.type,
    quantity,
    warehouse: selectedItem.warehouse,
    zone: location.zone as string,
    orderId: selectedItem.tara?.orderNumber as string,
    productName: selectedProduct.name,
    sku: selectedProduct.sku,
    supply: selectedItem.tara?.barcode as string,
    priorityLevel: String(selectedProduct.priority)
  } 
}

export const formatWasteOrMissingEvent = (selectedProduct, selectedItem, quantity) =>{
  return {
    ean: selectedProduct.ean,
    quantity,
    warehouse: selectedItem.warehouse,
    zone: selectedProduct.zone,
    orderId: selectedItem.tara?.orderNumber as string,
    productName: selectedProduct.name,
    sku: selectedProduct.sku,
    supply: selectedItem.tara?.barcode as string,
    priorityLevel: String(selectedProduct.priority)
  } 
}