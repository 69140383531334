export enum LocationType {
  Receipt = 'Receipt',
  PreStorage = 'PreStorage',
  Storage = 'Storage',
  Picking = 'Picking',
  Rack = 'Rack',
}

export enum LocationZone {
  HighValue = 'HighValue',
  Groceries = 'Groceries',
  Laboratory = 'Laboratory',
  Frozen = 'Frozen',
  Refrigerated = 'Refrigerated',
  Bulk = 'Bulk',
  Deli = 'Deli',
  Pharmacy = 'Pharmacy',
  PickFloor = 'PickFloor',
  Supplies = 'Supplies',
}

export enum UomKey {
  Piece = 'pieces-uom',
  Weight = 'weight-uom',
  Box = 'box-uom',
}

export interface CommonLocationInfo {
  locationId: string;
  locationName: string;
  locationType: string;
  containerId?: string;
  containerName?: string;
  containersInLocation: number;
}

