import { useNavigate } from 'react-router-dom'

export const useGoToUniversalSearch = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(UNIVERSAL_SEARCH_URL)
    }
  }
}

export const UNIVERSAL_SEARCH_URL = '/universal-search/'
