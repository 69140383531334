import React from 'react'
import { ImageThumbnailComponent } from '@justomx/atlas-components'

interface ImageProps {
  src: string
  size: number
}

const LazyImageThumbnailAtom: React.FC<ImageProps> = React.memo(
  (props: ImageProps) => {
    const { src, size } = props
    return <ImageThumbnailComponent src={src} size={size} loading="lazy" />
  }
)
LazyImageThumbnailAtom.displayName = 'LazyImageThumbnailAtom'

export default LazyImageThumbnailAtom
