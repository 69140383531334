import { AtlasCaption, AtlasH1 } from './Typography/Typography.atom'
import { CardComponent } from './Card/Card.atom'
import React from "react";

interface CounterCardProps {
  title: string | number
  subtitle: string | number
  'data-test'?: string
}

export const CounterCard = (params: CounterCardProps) => {
  const { title, subtitle } = params;
  return <CardComponent
    $variant={'highlight'}
    $centered
    data-test={params["data-test"]}
  >
    <AtlasH1 data-test={params["data-test"]+'-title'}>{title}</AtlasH1>
    <AtlasCaption data-test={params["data-test"]+'-caption'}>{subtitle}</AtlasCaption>
  </CardComponent>
}
