import { useNavigate } from 'react-router-dom'

export const useLiftContainerNavigator = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(SCAN_CONTAINER_TO_LIFT_NAVIGATOR)
    }
  }
}

export const SCAN_CONTAINER_TO_LIFT_NAVIGATOR = '/forklift/lift-container'
