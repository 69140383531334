import { defer } from 'react-router-dom'
import { getOutputOrders } from './client/InventoryTransfer.client'
import type { OutputOrderData } from './types/InventoryTransfer.types'
import { isProduction } from 'utils/environment'

const INV_TRANSFER_URLS = {
  staging: 'https://staging.op-receipt-web.pages.dev/inventory-transfer',
  prod: 'https://op-receipt-web.pages.dev/inventory-transfer'
}

export const isArrayOfOutputOrders = (
  data: unknown
): data is OutputOrderData[] => {
  if (!Array.isArray(data)) {
    return false
  }

  return data.every((order) => {
    return (
      order &&
      typeof order === 'object' &&
      'order' in order &&
      'status' in order &&
      'updatedAt' in order &&
      'products' in order
    )
  })
}

export const inventoryTransferLoader = async () => {
  const url = isProduction()
    ? INV_TRANSFER_URLS.prod
    : INV_TRANSFER_URLS.staging
  window.location.href = url
  const getOrdersPromise = getOutputOrders()
  return defer({ orders: getOrdersPromise })
}
