import { AnalyticsBrowser } from '@segment/analytics-next'
import { getCommonHeaders } from "../client/utilities.client-helper";
import {getUserInfo} from "../client/user/user.client";



export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.SEGMENT_TOKEN || ''
})


const extractMail = () => {
  return new Promise<string>( (resolve) => {
    const handler = setInterval(async () => {
      const a = getCommonHeaders();
      if (a["x-hardcoded"]) return;
      clearInterval(handler);

      try {
        const payload = JSON.parse(atob(a['x-authorization-core'].split(' ')[1].split('.')[1]));
        const user = await getUserInfo();
        resolve(user?.username? 'email:'+user.username : 'id:'+payload.id)
      } catch (e) {
        resolve("error:parse_error@justo.mx");
      }
    }, 200)
  })

}

const setUser = () => {
  extractMail()
    .then(id =>
      analytics.identify(id)
    )
    .catch(e => console.error("Error identifying user", e));
}

setUser();
