import { useGoToScanDestination } from '../scan-destination/ScanDestination.navigator'
import { MOVE_INVENTORY_ITEMS } from '../move-all/MoveAll.navigator'
import { MOVE_PRODUCT_LIST_URL } from '../move-product-list/MoveInventoryProductList.navigator'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { type MoveType } from '../../../components/molecules/MoveProductsSelector.molecule'
import { traslationOriginInfoAtom } from '../Traslation.state'
import { useAtom } from 'jotai/index'
import { MOVE_CONTAINER } from '../traslation.flow'
import { useInventoryProducts } from 'hooks/inventoryProducts.hooks'
import { LocationOrContainer } from 'types/model'

export const useMoveOptions = () => {
  const navigate = useNavigate()
  const [origin, setLocationState] = useAtom(traslationOriginInfoAtom)
  const scanDestination = useGoToScanDestination()
  const data = useLoaderData() as any

  const looseProducts = useInventoryProducts(data)

  return {
    origin,
    looseProducts,
    actions: {
      back: () => { navigate(-1) },
      onClickGo: (value: MoveType) => () => {
        if (value === 'container') {
          scanDestination.go(origin as LocationOrContainer, MOVE_INVENTORY_ITEMS + '?moveContainer=true', MOVE_CONTAINER)
        }
        if (value === 'product') {
          navigate(MOVE_PRODUCT_LIST_URL.replace(':origin', origin?.location.legacyId as string).replace(':container', origin?.container?.legacyId as string))
        }
        if (value === 'loose-product') {
          setLocationState(origin)
          navigate((MOVE_PRODUCT_LIST_URL + '/?looseProducts=true').replace(':origin', origin?.location.legacyId as string).replace(':container', origin?.container?.legacyId as string))
        }
      }
    }
  }
}
