import Axios, { type AxiosRequestConfig, type AxiosInstance } from 'axios'
import {
  type CommonHeaders,
  getCommonHeaders
} from 'client/utilities.client-helper'
import { isProduction } from 'utils/environment'
import { handleError } from './Receipt.error'

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  skipErrorAlert?: boolean
}

let axios: AxiosInstance

const configureAxios = () => {
  let baseURL = 'https://stag.justo.cloud/receipt-api/'
  if (isProduction()) {
    baseURL = 'https://api.justo.cloud/receipt-api/'
  }

  return Axios.create({ baseURL })
}

const getAxiosInstance = (): AxiosInstance => {
  if (!axios) {
    axios = configureAxios()

    axios.interceptors.request.use((config) => {
      const headers: CommonHeaders = getCommonHeaders()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(headers).map((e) => config.headers.set(e, headers[e]))
      return config
    })
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const config = error.config as CustomAxiosRequestConfig
        if (config?.skipErrorAlert) {
          return await Promise.reject(error)
        }

        handleError(error?.response?.data)
        return await Promise.reject(error)
      }
    )
  }

  return axios
}

export const receiptAxiosInstance = getAxiosInstance
