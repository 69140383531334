import { useNavigate, useSearchParams } from 'react-router-dom'

export const useFastTraslationScanDestinationNavigator = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return {
    go: (ean: string, quantity: number) => {
      const isFromAlertParam = searchParams.get('isFromAlert') === 'true' ? '&isFromAlert=true' : ''

      navigate(`${FAST_TRASLATION_SCAN_DESTINATION
          .replace(':ean', ean)
          .replace(':quantity', quantity.toString())}?${isFromAlertParam}`
      )
    }
  }
}

export const FAST_TRASLATION_SCAN_DESTINATION = '/fast-traslation/:ean/:quantity/scan-destination'
