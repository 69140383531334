import { type ProductInfo } from '../../types/model'
import { productGatewayAxiosInstance } from '../../utils/op-product-gateway.axios.instance'
import { type ProductDetailsResponse } from './products.types'
import { axiosInstance } from '../../utils/axios.instance'

export const findProductInfo = async (ean: string): Promise<ProductInfo> => {
  return await productGatewayAxiosInstance().get<ProductInfo>(`/v1/products/${ean}`)
    .then((res) => res.data)
}

export const findProductsInfo = async (eans: string[]): Promise<ProductInfo[]> => {
  return await productGatewayAxiosInstance()
    .get<ProductInfo[]>('/v1/products/multiple/eans', { params: { n: eans.join(',') } })
    .then((res) => res.data)
}

// TODO: move to productGatewayAxiosInstance
export const getProductDetailFromEan = async (ean: string) => {
  const axios = axiosInstance()
  return await axios.get<ProductDetailsResponse>(`/products/${ean}/details`)
    .then(e => e.data)
}
