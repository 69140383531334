import { ReverseLogisticProductInfo, TareOrProduct } from "types/model";

export const sortSuppliesByPendingQuantity = (supplies: TareOrProduct[], product: ReverseLogisticProductInfo) => {
  return supplies
    .filter((s) => s.products.some((p) => p.ean === product.ean))
    .sort((a, b) => {
      const pendingA = a.products.find((p) => p.ean === product.ean)?.pendingQuantity || 0;
      const pendingB = b.products.find((p) => p.ean === product.ean)?.pendingQuantity || 0;
      return pendingB - pendingA;
    });
}

export const updateSupplyFromResponse = (supply: TareOrProduct, res: TareOrProduct) => {
  return {
    ...res,
    products: res.products.map((product) => {
      const existingProduct = supply.products.find((p) => p.ean === product.ean);
      return {
        ...product,
        image: existingProduct ? existingProduct.image : undefined,
        isWeighable: existingProduct ? existingProduct.isWeighable : false,
      };
    }),
  };
}