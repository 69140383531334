import { useTranslation } from 'react-i18next'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasColor,
  CardComponent,
  Icons,
  ImageThumbnailComponent,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useScanProduct } from './ScanProduct.hook'
import { ProductCard } from '../components/product-card.component'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import TareIcon from '../../../assets/images/Utilities/Illustration_tare.svg'
import LoaderAtom from 'components/atoms/Loader.atom'
import { OptionsDialogOrganism } from 'components/organisms/OptionsDialog.organism'
import { defaultWasteOrMissingOption, wasteOrMissingOptions } from '../ReverseLogistics.page'
import { TextfieldDialogOrganism } from 'components/organisms/TextfieldDialog.organism'

type ScannedTareProps = {
  id: string
}

const ScannedTare = ({id}: ScannedTareProps) => {
  const { t } = useTranslation('global')
  return (
    <CardComponent $variant='highlight' style={{ marginBottom: '12px'}}>
      <AtlasBodyMain>
        {t("reverse-logistics.scan-product.scanned-tare")}
      </AtlasBodyMain>
      <FlexRowAtom style={{ alignItems: 'center', gap: 8}}>
        <ImageThumbnailComponent 
          size={20}
          src={TareIcon}
          style={{ alignSelf: 'self-start' }}
        />
        <AtlasBodySubtitle color={AtlasColor.Neutral00}>
          {id}
        </AtlasBodySubtitle>
      </FlexRowAtom>
    </CardComponent>
  )
}

export const ReverseLogisticsScanProductPage = () => {
  const { tare, products, actions, loading, selectedProduct, dialogRef, typeProductDialogRef } = useScanProduct()
  const { t } = useTranslation('global')

  if(loading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return <>
    <ToolbarMolecule
      title={t('reverse-logistics.scan-product.toolbar-title')}
      actionIcon={Icons.CloseIcon}
      onAction={actions.goHome}
      secondaryActionIcon={Icons.IcKeyboardIcon}
      onSecondaryAction={actions.openTypeProductDialog}
    />
    <PageContentAtom>
      <ScannedTare id={tare}/>
      <AtlasBodySubtitle>{t("reverse-logistics.scan-product.scan")}</AtlasBodySubtitle>
      {products.map((product) => 
        <ProductCard
          key={product.ean}
          product={product}
          showPriority={true}
          showStock={true}
          showWasteIcon={true}
          openDialog={()=>actions.openWasteOrMissingDialog(product)}
        />
      )}
       <OptionsDialogOrganism
          dialogRef={dialogRef}
          title={`Selecciona el tipo de reporte para ${selectedProduct.name}`}
          options={wasteOrMissingOptions}
          defaultOption={defaultWasteOrMissingOption}
          confirmAction={actions.goToWasteOrMissingPage}
        />
        <TextfieldDialogOrganism
          dialogRef={typeProductDialogRef}
          title='Escribe el código del producto'
          label='Código'
          confirmText='Continuar'
          cancelText='Cancelar'
          confirmAction={actions.searchProduct}
        />
    </PageContentAtom>
  </>
}
