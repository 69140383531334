import styled from 'styled-components'
import React, { type ReactNode } from 'react'
import { PageContentAtom } from '../atoms/PageContent.atom'
import { AtlasColor } from '../foundations/Colors.foundations'

const BottomGroupContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${AtlasColor.Background}
`

interface BottoGroupProps {
  children: ReactNode
}

export const BottomGroupMolecule = ({ children }: BottoGroupProps) => {
  return <BottomGroupContainer>
    <PageContentAtom>
      {children}
    </PageContentAtom>
  </BottomGroupContainer>
}
