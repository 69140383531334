import {
  AtlasBodySubtitle,
  CardComponent,
  Filler,
  Icons,
  PageContentAtom,
  StackAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { LocationAndContainerMolecule } from '../../../components/molecules/v2/LocationAndContainer.molecule'
import { onErrorToast } from '../../../onErrorToast.util'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { useAlerts } from './Alerts.hooks'
import { Global } from '../../../utils/global'
import { useTranslation } from 'react-i18next'
import { ConfirmationDialogMolecule } from 'components/molecules/ConfirmationDialog.molecule'
import { type LocationInfo } from '../../../types/model'
import { type AlertSuggestionV2 } from '../../../client/alerts/alerts.types'

interface ContainerCardProps {
  container: LocationInfo
}

export const ContainerCard = ({ container }: ContainerCardProps) => {
  return (
    <CardComponent>
      <StackAtom>
        <LocationAndContainerMolecule data={container} />
      </StackAtom>
    </CardComponent>
  )
}

const AlertsV2Page = () => {
  const {
    alerts: suggestions,
    actions,
    isLoading,
    dialogRef
  } = useAlerts(onErrorToast)
  const { t } = useTranslation('global')

  if (isLoading) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return <>
    <ToolbarMolecule title={t('alerts.v2.toolbar-title')}
                     actionIcon={Icons.IcMenuIcon}
                     onAction={Global.WV.openMenu} />
    <PageContentAtom>
      <AtlasBodySubtitle>{t('alerts.v2.scan')}</AtlasBodySubtitle>
      {suggestions.map((it) => {
        return <ContainerCard key={`${it.location.name}-${it.container?.name}`} container={convert(it)} />
      })}
      <Filler />
    </PageContentAtom>
    <ConfirmationDialogMolecule
      dialogRef={dialogRef}
      confirmText="Continuar"
      cancelText="Cancelar"
      onConfirm={actions.goToScanProductPage}
    >
      <AtlasBodySubtitle>{t('alerts.container-not-in-list')}</AtlasBodySubtitle>
    </ConfirmationDialogMolecule>
  </>
}

export default AlertsV2Page

function convert (it: AlertSuggestionV2): LocationInfo {
  return {
    location: it.location.name,
    locationType: it.location.type,
    container: it.container?.name || undefined,
    alerts: {
      critical: it.critical,
      warning: it.warning,
      urgent: it.urgent
    }
  }
}
