import { useNavigate } from 'react-router-dom'

export const INVENTORY_WASTE_REGISTER = '/inventory-waste/register-waste/:ean'

export const useInventoryWasteRegisterNavigator = () => {
  const navigate = useNavigate()
  return {
    go: (ean: string) => {
      navigate(`${INVENTORY_WASTE_REGISTER.replace(':ean', ean)}`)
    }
  }
}
