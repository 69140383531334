import { type LocationAndContainer } from 'types/model'
import { AtlasBodyMain, AtlasBodyTitle, AtlasColor, AtlasListAtom, BottomGroupMolecule, CardComponent, Filler, Icon, Icons, PageContentAtom, SnackbarInformationPanel, StackAtom, ToolbarMolecule } from '@justomx/atlas-components'
import { LocationAndContainerMolecule } from 'components/molecules/LocationAndContainer.molecule'
import { isError, isLoading, isNone } from 'utils/async-types'
import LoaderAtom from 'components/atoms/Loader.atom'
import { useTranslation } from 'react-i18next'
import { onErrorToast } from '../../../onErrorToast.util'
import { useContainers } from './ChooseContainer.hooks'
import ImageSingleProduct from '../../../assets/images/Utilities/Illustration_single_product.svg'

interface ContainerCardProps {
  container: Required<LocationAndContainer>
  onClick: () => void
}

export const ContainerCard = ({ container, onClick }: ContainerCardProps) => {
  return <CardComponent onClick={onClick}>
    <StackAtom> 
      <LocationAndContainerMolecule location={container}/>
    </StackAtom>
  </CardComponent>
}

export const ContainersPage = () => {
  const { origin, containers, looseProducts, actions, isLoadingPage } = useContainers(onErrorToast)
  const { t } = useTranslation('global')

  if (isLoadingPage || isLoading(containers) || isNone(containers)) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  if (isError(containers)) {
    return (<p>{t('alerts.default-error')}</p>)
  }

  return <>
    <ToolbarMolecule title={t('choose-container.toolbar-title')}
                    actionIcon={Icons.ArrowBack}
                    onAction={actions.back}
                    />
    <PageContentAtom>  
      {/* TODO: Show message when new page to show products in container is implemented */}
      {/* <AtlasBodyMain>{t('choose-container.scan-container')}</AtlasBodyMain> */}
      {origin?.locationType !== "Picking" && looseProducts.inventoryProducts.length > 0 && (
        <>
          <AtlasBodyTitle>{t('choose-container.location-with-loose-product')}</AtlasBodyTitle>
          <CardComponent onClick={actions.goToMoveProducts}>
            <img src={ImageSingleProduct} alt='Image showing how to move a loose product to a location'/>
            <AtlasBodyTitle>{t('choose-container.loose-product.title')}</AtlasBodyTitle>
            <AtlasListAtom>
              {t('choose-container.loose-product.items', { returnObjects: true }).map(e => <li key={e}>
                <AtlasBodyMain display='contents'>{e}</AtlasBodyMain>
              </li>)}
            </AtlasListAtom>
          </CardComponent>
        </>
      )}
      <AtlasBodyTitle>{t('choose-container.containers-in-location')}</AtlasBodyTitle>
      {
        containers.value.map(e => (
          <ContainerCard key={e.containerId} container={e} onClick={async () => { 
            // TODO: Implement new page to show products in container
            //await actions.goShowProducts(e)
          }}/>
        ))
      }
    </PageContentAtom>
      <SnackbarInformationPanel style={{
        border: '1px solid',
        backgroundColor: AtlasColor.Primary10,
        borderColor: AtlasColor.Important
      }}>
        <Icon src={Icons.ScanIcon}/>
        {t('choose-container.scan-container')}
      </SnackbarInformationPanel>
  </>
}
