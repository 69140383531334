import { type LocationAndContainer } from '../../../types/model'
import { CardComponent, Filler, Icons, PageContentAtom, StackAtom, ToolbarMolecule } from '@justomx/atlas-components'
import { LocationAndContainerMolecule } from '../../../components/molecules/LocationAndContainer.molecule'
import { onErrorToast } from '../../../onErrorToast.util'
import { isError, isLoading, isNone } from '../../../utils/async-types'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { useAlerts } from './Alerts.hooks'
import { Global } from '../../../utils/global'
import { useTranslation } from 'react-i18next'

interface ContainerCardProps {
  container: Required<LocationAndContainer>
  onClick: () => void
}

export const ContainerCard = ({ container, onClick }: ContainerCardProps) => {
  return <CardComponent onClick={onClick}>
    <StackAtom>
      <LocationAndContainerMolecule location={container} focus='location'/>
    </StackAtom>
  </CardComponent>
}

export const AlertsV1Page = () => {
  const { alerts, actions, isLoadingPage } = useAlerts(onErrorToast)
  const { t } = useTranslation('global')

  if (isLoadingPage || isLoading(alerts) || isNone(alerts)) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  if (isError(alerts)) {
    return (<p>{t('alerts.default-error')}</p>)
  }

  const suggestions = alerts.value

  return <>
    <ToolbarMolecule title={t('alerts.toolbar-title')}
                     actionIcon={Icons.IcMenuIcon}
                     onAction={Global.WV.openMenu}
                     secondaryActionIcon={Icons.IcSearch24dpIcon}
                     onSecondaryAction={actions.goSearchProduct}/>
    <PageContentAtom>
      {
        suggestions.map(e => (
          <ContainerCard
            key={`${e.locationId}-${e.containerId}`}
            container={e}
            onClick={async () => { await actions.goPalletDetail(e) }}/>
        ))
      }
      <Filler/>
    </PageContentAtom>
  </>
}
