import { useEffect, useState } from 'react'
import { getAlertsSuggestionsV2 } from '../../../client/alerts/alerts.client'
import { type AlertSuggestionV2 } from '../../../client/alerts/alerts.types'
import { useScanner } from '../../../hooks/scanner.hook'
import { useTranslation } from 'react-i18next'
import { getLocationOrContainerFromScan } from 'client/scanner/scanner.client'
import { useAlertsScanProductNavigator } from './scan-product/ScanProduct.navigator'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { type ScannedContainer, type ScannedLocation, ScanType } from '../../../client/scanner/scanner.types'

export const useAlerts = (onError: (str: string) => void) => {
  const { t } = useTranslation('global')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [alerts, setAlerts] = useState<AlertSuggestionV2[]>([])
  const [scanned, setScanned] = useState<ScannedLocation | ScannedContainer | null>(null)

  const scanProductNavigator = useAlertsScanProductNavigator()
  const { keys, getScan } = useScanner()
  const { dialogRef, open } = useConfirmationDialog()

  const goToScanProductPage = () => {
    if (scanned) {
      scanProductNavigator.go(scanned)
    }
  }

  const isInSuggestions = () => {
    if (scanned) {
      return alerts.some((a) => {
        if (scanned.type === ScanType.Location) {
          return scanned.location.name === a.location.name
        } else {
          return scanned.container.name === a.container?.name
        }
      })
    }
  }

  /* On init */
  useEffect(() => {
    setIsLoading(true)
    getAlertsSuggestionsV2()
      .then((data) => { setAlerts(data) })
      .catch((err: Error) => {
        console.error(err)
        onError(err.message)
      })
      .finally(() => { setIsLoading(false) })
  }, [])

  /* On scan */
  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setIsLoading(true)
      getLocationOrContainerFromScan(scanned)
        .then(res => {
          setScanned(res)
        })
        .catch((err: Error) => {
          console.error(err)
          onError(t('container-management.error-reading-container'))
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [keys])

  /* On after scanned */
  useEffect(() => {
    if (!isInSuggestions()) {
      open()
    } else {
      goToScanProductPage()
    }
  }, [scanned])

  return {
    alerts,
    isLoading,
    dialogRef,
    actions: {
      goToScanProductPage
    }
  }
}
