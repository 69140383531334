import { useProductContainersList } from './ProductsContainersList.hook'
import {
  AtlasBodySubtitle,
  AtlasCaption,
  CardComponent,
  Filler,
  Icons,
  PageContentAtom,
  StackAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { Global } from '../../../utils/global'
import { LocationAndContainerMolecule } from '../../../components/molecules/LocationAndContainer.molecule'
import { type ProductContainersWrapper } from '../../../client/products/products.types'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { ProductCardMolecule } from '../../../components/molecules/ProductCard.molecule'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

interface ProductContainersCardProps {
  containers: ProductContainersWrapper['containers']
}

export const ProductContainersCard = ({ containers }: ProductContainersCardProps) => {
  return containers.map(container => <CardComponent key={container.containerId}>
    <StackAtom>
      <LocationAndContainerMolecule location={container}/>
      <AtlasCaption>Stock: {container.stock}</AtlasCaption>
    </StackAtom>
  </CardComponent>)
}

export const ProductContainersListPage = () => {
  const { product } = useProductContainersList(/* onErrorToast */)
  const { t } = useTranslation('global')

  if (product === null) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  const byLocationType = _.groupBy(product.containers, e => e.locationType)

  return (
    <>
      <ToolbarMolecule title={t('product-containers.toolbar-title')}
        actionIcon={Icons.IcMenuIcon}
        onAction={Global.WV.openMenu}/>

        <PageContentAtom>
          {product &&
              <ProductCardMolecule product={product} />
          }

          {Object.keys(byLocationType).map(e => {
            return (
              <>
                <AtlasBodySubtitle>{t(tSubtitle[e])}</AtlasBodySubtitle>
                <ProductContainersCard containers={byLocationType[e]} />
              </>
            )
          })}

          {!product?.containers.length
            ? <AtlasBodySubtitle>{t('product-containers.no-containers')}</AtlasBodySubtitle>
            : null
          }

          {
            product != null && product.locations?.length > 0
              ? <>
                  <AtlasBodySubtitle>{t('product-containers.in-picking')}</AtlasBodySubtitle>
                  <ProductContainersCard containers={product.locations} />
                </>
              : <AtlasBodySubtitle>
                  {t('product-containers.no-picking')}
                </AtlasBodySubtitle>
          }

          <Filler/>
        </PageContentAtom>
      </>
  )
}

const tSubtitle: Record<string, string> = {
  Storage: 'product-containers.in-storage',
  PreStorage: 'product-containers.in-pre-storage',
  Rack: 'product-containers.in-rack'
}
