import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  Icon,
  Icons,
  ImageThumbnailComponent,
  StackAtom
} from '@justomx/atlas-components'
import { type Product } from '../../types/model'
import { type InventoryProductWithAlertInfo } from '../../pages/fast-traslation/scan-product/ScanProduct.navigator'
import { useTranslation } from 'react-i18next'
import { formatWeight } from 'utils/product'
import { formatString } from 'utils/string'

interface ProductInfoProps {
  product: Product
  selected?: boolean
  selectable?: boolean
  variant?: 'common' | 'restocking'
  'data-test'?: string
  showStock?: boolean
}

const ProductInfoCommonBody = (props: Omit<ProductInfoProps, 'variant'>) => {
  const { product, selectable, selected, showStock } = props;
  return <>
    <StackAtom>
      <AtlasBodySubtitle
        data-test={props["data-test"] + '-name'}
        data-product-name={product.productName}>{product.productName}</AtlasBodySubtitle>
      <AtlasBodyMain
        data-test={props["data-test"] + '-sku'}
        data-product-sku={product.productSku}>SKU: {product.productSku}</AtlasBodyMain>
      <AtlasBodyMain
        data-test={props["data-test"] + '-ean'}
        data-product-ean={product.productEan}>UPC: {product.productEan}</AtlasBodyMain>
      {showStock && <AtlasBodyMain>Cantidad en ubicación: {product.stock}</AtlasBodyMain>}
    </StackAtom>
    {selectable && selected ? <Icon src={Icons.CheckboxChecked} size={24} /> : null}
    {selectable && !selected ? <Icon src={Icons.CheckboxUnchecked} size={24} /> : null}
  </>
}

export const ProductInfoAtom = (props: ProductInfoProps) => {
  const {
    product,
    selectable = false,
    selected = false,
    showStock = false
  } = props
  return <>
    <ImageThumbnailComponent 
      size={60}
      src={product.productImage}
      style={{ alignSelf: 'self-start' }}
    />
    <ProductInfoCommonBody 
      product={product}
      selected={selected}
      selectable={selectable}
      showStock={showStock}
      data-test={props['data-test']}
    />
  </>
}

interface ProductInfoRestockingMoleculeProps {
  product: InventoryProductWithAlertInfo
}

const ProductInfoRestockingBody = ({ product }: ProductInfoRestockingMoleculeProps) => {
  const restockingAmount = Math.min(product.expectedStock - product.currentStock, product.stock)

  const { t } = useTranslation('global')

  const templateOriginQty = t('product-info-restocking.total-in-origin')
  const templateToRestock = t('product-info-restocking.to-restock')
  const templateUnits = t('product-info-restocking.units')

  /* TODO: add this line when the inventory is properly fixed
            <AtlasCaption>Stock actual en picking: {product.currentStock} de {product.expectedStock} unidades</AtlasCaption>
   */
  return <>
    <StackAtom>
      <AtlasBodySubtitle>{product.productName}</AtlasBodySubtitle>
      <AtlasBodyMain>UPC: {product.productEan}</AtlasBodyMain>
      {restockingAmount > 0
        ? <>
            <AtlasBodyMain>
              {
                formatString(templateOriginQty, !product.byWeight
                  ? product.stock
                  : formatWeight(product.stock))
              }
            </AtlasBodyMain>
            <AtlasBodyMain>
              {
                formatString(templateToRestock, !product.byWeight
                  ? `${restockingAmount} ${templateUnits}`
                  : formatWeight(restockingAmount))
              }
            </AtlasBodyMain>
          </>
        : null
      }
    </StackAtom>
  </>
}

export const ProductInfoRestockingMolecule = ({
  product
}: ProductInfoRestockingMoleculeProps) => {
  return <>
    <ImageThumbnailComponent size={40}
                             src={product.productImage}
                             style={{ alignSelf: 'self-start' }}/>
    <ProductInfoRestockingBody product={product} />
  </>
}
