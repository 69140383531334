import { useCallback, useRef } from 'react'
import styled from 'styled-components'

import { Icon, Icons } from '@justomx/atlas-components'
import { FabButtonAtom } from 'components/atoms/FabButton.atom'

interface UploadFileProps {
  onNewPhoto: (file: File) => void
  disabled?: boolean
}

const HiddenInput = styled.input`
  display: none;
`

export const UploadFileMolecule = (props: UploadFileProps) => {
  const { disabled, onNewPhoto } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const onAddFile = useCallback(() => {
    if (!disabled && inputRef.current) {
      inputRef.current.click()
    }
  }, [disabled])

  const onSelectFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files }
      } = event
      console.info({ files })
      if (files?.length) {
        const file = files[0]
        onNewPhoto(file)
      }

      if (inputRef.current) {
        inputRef.current.value = ''
      }
    },
    [onNewPhoto]
  )

  return (
    <>
      <HiddenInput
        type="file"
        multiple={false}
        ref={inputRef}
        onChange={onSelectFile}
        accept="image/*"
        capture="environment"
      />
      <FabButtonAtom
        $variant="secondary"
        $size={40}
        type="button"
        disabled={disabled}
        onClick={onAddFile}
      >
        <Icon src={disabled ? Icons.IcAddPhotoDark : Icons.IcAddPhoto}></Icon>
      </FabButtonAtom>
    </>
  )
}
