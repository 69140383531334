import { useNavigate } from 'react-router-dom'

export const useContainerAdministrationNavigator = () => {
  const navigate = useNavigate()

  return {
    go: () => { navigate(CONTAINER_ADMINISTRATION) }
  }
}

export const CONTAINER_ADMINISTRATION = '/forklift'
