import { type LocationInfo } from '../../types/model'
import { useLowerContainerNavigator } from './lower-container/LowerContainer.navigator'
import { useLiftContainerNavigator } from './lift-container/scan-to-lift/ScanContainerToLift.navigator'
import { useEffect, useState } from 'react'
import { buildErrorObject, type IsLoadable, LoadedObject, NoneObject } from '../../utils/async-types'
import _ from 'lodash'
import { useScanner } from '../../hooks/scanner.hook'
import { useNavigate } from 'react-router-dom'
import { getContainerFromScan } from 'client/scanner/scanner.client'
import { toCommonLocationInfoFromScanResponse } from 'utils/commonLocationInfo'
import { useTranslation } from 'react-i18next'
import { Pallets } from 'client/forklift/forklift.types'
import { getContainersWithAlerts } from 'client/forklift/forklift.client'

export const useContainerAdministration = (onError: (err: string) => void) => {
  const lowerContainerPage = useLowerContainerNavigator(onError)
  const liftContainerPage = useLiftContainerNavigator()
  const [containersInStorage, setCriticalContainers] = useState<IsLoadable<Array<Required<Pallets>>>>(NoneObject)
  const [showLoading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const { t } = useTranslation('global')
  const { keys, getScan } = useScanner()

  useEffect(() => {
    getContainersWithAlerts()
      .then(e => LoadedObject(e))
      .then(setCriticalContainers)
      .catch(e => buildErrorObject(e.message as string))
      .then(() => { setLoading(false) })
      .catch(err => { console.error(err) })
  }, [setLoading])

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setLoading(true)
      getContainerFromScan(scanned)
        .then(res => {         
          const formattedContainer: LocationInfo = {
            location: res.location.name,
            locationType: res.location.type,
            container: res.container.name
          }
          lowerContainerPage.go(formattedContainer)
        })
        .catch(e => {
          console.error(e)
          onError(t('container-management.error-reading-container'))
        })
        .finally(() => { setLoading(false) })
    }
  }, [keys])

  return {
    showLoading,
    containersInStorage,
    actions: {
      lower: async (info: Required<LocationInfo>) => {
        setLoading(true)
        await lowerContainerPage.go(info)
      },
      lift: () => {
        setLoading(true)
        liftContainerPage.go()
      },
      goBack: () => {
        navigate(-1)
      }
    }
  }
}
