import { useNavigate } from 'react-router-dom'
import { type LocationOrContainer } from '../../../types/model'

export const useGoToChooseContainer = () => {
  const navigate = useNavigate()

  return {
    go: (origin: LocationOrContainer) => {
      navigate(CHOOSE_CONTAINER_URL
        .replace(':origin', origin.location.legacyId)
      )
    }
  }
}

export const CHOOSE_CONTAINER_URL = '/traslation/:origin/choose-container'
