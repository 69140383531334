import * as Yup from 'yup'
import type { TFunction } from 'i18next'

import type { ProductDetail, RejectionSummary } from 'types/model'
import { MEASURE_UNITS } from '../utils/constants'

export const getMissingLimit = (
  product: ProductDetail | undefined,
  totals: RejectionSummary
) => {
  if (!product) {
    return { totalUnits: 0, boxes: 0 }
  }
  let totalUnits = product.totalUnits
  if (product.measureUnit === 'KG') {
    totalUnits *= 1000
  }
  totalUnits -= totals.totalReceived + totals.totalRejected
  const boxes =
    product.quantity - (totals.totalReceivedBoxes + totals.totalRejectedBoxes)

  return { totalUnits, boxes }
}

export const getValidationSchema = (
  t: TFunction<'global', undefined>,
  maxAmount: number,
  maxBoxes: number,
  measureUnit: string
) => {
  const amount = t('validations.invalid-amount')
  const required = t('validations.required')
  const limit = t('validations.greater-total-units')

  const boxesValidation = Yup.number().positive(amount).integer(amount)
  return Yup.object({
    totalMissing: Yup.number()
      .required(required)
      .positive(amount)
      .integer(amount)
      .max(maxAmount, limit),
    boxes: Yup.number().concat(
      measureUnit === MEASURE_UNITS.box
        ? boxesValidation.required(required).max(maxBoxes, limit)
        : boxesValidation.notRequired()
    )
  })
}
