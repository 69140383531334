import { Icon, Icons, PrimaryButtonAtom, SecondaryButtonAtom } from "@justomx/atlas-components"

interface SelectAllAtomProps {
  hasSelected: boolean
  onAll: () => void
  onNone: () => void
}
export const SelectAllAtom = ({ hasSelected, onAll, onNone }: SelectAllAtomProps) => {
  if (!hasSelected) {
    return <PrimaryButtonAtom onClick={onAll}>Seleccionar todos</PrimaryButtonAtom>
  }

  return <SecondaryButtonAtom onClick={onNone}>
    <Icon src={Icons.IcCancelIcon} size={24}/>
    Quitar seleccion
  </SecondaryButtonAtom>
}