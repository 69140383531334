export enum LoadableStatus {
  LOADING = 'loading',
  ERROR = 'error',
  LOADED = 'loaded',
  NONE = 'none',
}

type WasLoadedApplyType<T> = (fn: (value: T) => T) => WasLoaded<T>

export interface WasLoaded<T> {
  status: LoadableStatus.LOADED
  value: T
  apply: WasLoadedApplyType<T> // I don't know how to call it
}

export interface HasError {
  status: LoadableStatus.ERROR
  error?: string
}

export interface IsLoading {
  status: LoadableStatus.LOADING
}

export interface IsNone {
  status: LoadableStatus.NONE
}

export type IsLoadable<T> = WasLoaded<T> | HasError | IsLoading | IsNone

export const LoadingObject: IsLoading = { status: LoadableStatus.LOADING }

export const ErrorObject: HasError = { status: LoadableStatus.ERROR }
export const buildErrorObject = (error: string): HasError => ({
  status: LoadableStatus.ERROR,
  error
})

export const NoneObject: IsNone = { status: LoadableStatus.NONE }

export const LoadedObject = <T>(object: T): WasLoaded<T> => ({
  status: LoadableStatus.LOADED,
  value: object,
  apply<T>(fn: (value: T) => T): WasLoaded<T> {
    // TODO: pending to resolve later
    return LoadedObject(fn(this.value))
  }
})

export const isLoaded = <T>(obj: IsLoadable<T>): obj is WasLoaded<T> => {
  return 'status' in obj && obj.status === LoadableStatus.LOADED
}

export const isLoading = <T>(obj: IsLoadable<T>): obj is IsLoading => {
  return 'status' in obj && obj.status === LoadableStatus.LOADING
}

export const isError = <T>(obj: IsLoadable<T>): obj is HasError => {
  return 'status' in obj && obj.status === LoadableStatus.ERROR
}

export const isNone = <T>(obj: IsLoadable<T>): obj is IsNone => {
  return 'status' in obj && obj.status === LoadableStatus.NONE
}
