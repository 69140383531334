import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { Global } from '../../utils/global'
import { useFastTraslation } from './FastTraslation.hook'
import { onErrorToast } from '../../onErrorToast.util'
import { useTranslation } from 'react-i18next'

export const FastTraslationPage = () => {
  const { t } = useTranslation('global')

  useFastTraslation((errorText) => {
    onErrorToast(t(errorText))
  })

  return (
    <>
      <ToolbarMolecule
        title={t('fast-traslation.toolbar-title')}
        actionIcon={Icons.IcMenuIcon}
        onAction={Global.WV.openMenu}
      />
      <PageContentAtom $centered>
        <Filler flexGrow={1} />
        <Icon src={Icons.ScanIcon} />
        <AtlasBodyTitle>{t('fast-traslation.scan')}</AtlasBodyTitle>
        <AtlasH1 style={{ textAlign: 'center' }}>LPN</AtlasH1>
        <AtlasBodyTitle>{t('fast-traslation.use-your-scanner')}</AtlasBodyTitle>
        <Filler />
      </PageContentAtom>
    </>
  )
}
