import { AtlasBodyMain, AtlasBodySubtitle } from '@justomx/atlas-components'
import styled from 'styled-components'

interface CardImageProps {
  imageURL: string
  textLines: [string, string, string]
}

const MainContainer = styled.div`
  width: 100%;
  height: 250px;
  min-height: 250px;
  position: relative;
  overflow: auto;
`

const ImageContainer = styled.div<{ $imageURL: string }>`
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: ${({ $imageURL }) =>
    `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url(${$imageURL})`};
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  overflow: auto;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  gap: 16px;
`

export const CardImageMolecule = (props: CardImageProps) => {
  const { imageURL, textLines } = props

  return (
    <MainContainer>
      <ImageContainer $imageURL={imageURL} />
      <TextContainer>
        <AtlasBodySubtitle>{textLines[0]}</AtlasBodySubtitle>
        <AtlasBodySubtitle>{textLines[1]}</AtlasBodySubtitle>
        <AtlasBodyMain>{textLines[2]}</AtlasBodyMain>
      </TextContainer>
    </MainContainer>
  )
}
