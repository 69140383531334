export const zonesMap: Record<string, string> = {
  'Groceries': 'Abarrotes',
  'HighValue': 'Alto Valor',
  'Frozen': 'Congelados',
  'Deli': 'Deli',
  'Pharmacy': 'Farmacia',
  'Bulk': 'Granel',
  'Laboratory': 'Laboratorio',
  'Supplies': 'Suministros',
  'Refrigerated': 'Refrigerados',
}