import styled from 'styled-components'
import { type Property } from 'csstype'

interface HasDisplay { display?: Property.Display }
// https://www.figma.com/file/CPcYevO1033ut5qWFIXw67/Style-Guide-Atlas?node-id=3313%3A9&mode=dev

export const AtlasH1 = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px; /* 100% */
  letter-spacing: 0.051px;
  color: ${({ color }) => color ?? 'inherit'};
`

export const AtlasH2 = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
  letter-spacing: 0.036px;
  color: ${({ color }) => color ?? 'inherit'};
`

export const AtlasH3 = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.027px;
  color: ${({ color }) => color ?? 'inherit'};
`

export const AtlasBodyTitle = styled.h2`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 100% */
  letter-spacing: 0.72px;
  color: ${({ color }) => color ?? 'inherit'};
`

export const AtlasBodySubtitle = styled.h3<{ color?: string }>`
  /* Atlas/Atom/Typography/Body_Subtitle */
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.32px;
  color: ${({ color }) => color ?? 'inherit'};
`

export const AtlasBodyMain = styled.h2<HasDisplay>`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.021px;
  color: ${({ color }) => color ?? 'inherit'};
  display: ${({ display }) => display ?? 'block'}
`

export const AtlasCaption = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.12px;
  color: ${({ color }) => color ?? 'inherit'};
`

export const AtlasTypographyButton = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  color: ${({ color }) => color ?? 'inherit'};
`
