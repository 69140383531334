import { useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { toastSuccess } from 'pages/receipt/utils/toast'
import { handleError } from 'pages/receipt/client/Receipt.error'
import { useQueryParam, useRequiredParams } from 'hooks/params.hook'
import type { CreatePickData } from '../types/InventoryTransfer.types'
import { useGoToPickingList } from '../PickingList/PickingList.navigator'
import { useGoToScanContainer } from '../ScanContainer/ScanContainer.navigator'
import {
  pendingPickAtom,
  selectedProductAtom
} from '../InventoryTransfer.state'
import { getValidationSchema } from './PickProduct.helpers'
import { createPick } from '../client/InventoryTransfer.client'

export const usePickProduct = () => {
  const { t } = useTranslation('global')
  const { orderId, docId } = useRequiredParams('orderId', 'docId')
  const grams = useQueryParam('grams')
  const pickingList = useGoToPickingList()
  const scanContainer = useGoToScanContainer()
  const product = useAtomValue(selectedProductAtom)
  const setPendingPick = useSetAtom(pendingPickAtom)
  const [loading, setLoading] = useState(false)

  const formConfig = useFormik<{ quantity: string, location: string }>({
    initialValues: { quantity: grams || '', location: '' },
    validationSchema: getValidationSchema(t),
    onSubmit: async (values) => {
      if (!product || !values.location || loading) {
        return
      }

      const pick: CreatePickData = {
        locationName: values.location,
        barcode: product.barcode,
        fulfilledQuantity: +values.quantity
      }
      try {
        setLoading(true)
        await createPick(orderId, pick)
        toastSuccess(t('inventory-transfer.pick.success'))
        pickingList.go(orderId, docId)
      } catch (error) {
        console.error(error)
        if (error?.response?.data?.includes('more than one LPN')) {
          setPendingPick(pick)
          scanContainer.go(orderId, docId)
        } else {
          handleError(error?.response?.data)
        }
      } finally {
        setLoading(false)
      }
    }
  })

  const onGoBack = () => {
    pickingList.go(orderId, docId)
  }

  const onScanLocation = async (scanned: string) => {
    await formConfig.setTouched({ quantity: true })
    if (formConfig.isValid) {
      formConfig.values.location = scanned
      await formConfig.submitForm()
    }
  }

  return {
    orderId,
    product,
    formConfig,
    loading,
    actions: { onGoBack, onScanLocation }
  }
}
