import { useNavigate } from 'react-router-dom'
import {
  AtlasBodySubtitle,
  AtlasBodyTitle, AtlasColor,
  AtlasH1, CardComponent, CardRow,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'

export const ScanProductPage = () => {
  const navigate = useNavigate()

  return <>
        <ToolbarMolecule title='Traslado de productos'
                         actionIcon={Icons.ArrowBack}
                         onAction={() => { navigate(-1) }}/>
        <PageContentAtom $centered>
            <Filler/>
            <Icon src={Icons.ScanIcon}/>
            <AtlasBodyTitle>Escanea</AtlasBodyTitle>
            <AtlasH1>Código de barras</AtlasH1>
            <AtlasBodyTitle>Usa tu escáner</AtlasBodyTitle>
            <Filler/>
            <CardComponent>
                <CardRow>
                    <AtlasBodySubtitle color={AtlasColor.Neutral40}>Origen: </AtlasBodySubtitle>
                    <AtlasBodySubtitle>CYN-ABA-P6-R3-N3-U6</AtlasBodySubtitle>
                </CardRow>
                <CardRow>
                    <AtlasBodySubtitle color={AtlasColor.Neutral40}>Destino: </AtlasBodySubtitle>
                    <AtlasBodySubtitle>CYN-ABA-P6-R3-N3-U2</AtlasBodySubtitle>
                </CardRow>
            </CardComponent>
        </PageContentAtom>
    </>
}
