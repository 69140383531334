import * as Yup from 'yup'
import type { TFunction } from 'i18next'

import type {
  CreateLotData,
  GetProductLotsResponse,
  LotFormValues,
  ProductDetail,
  UpsertProductLotsData
} from 'types/model'
import { MEASURE_UNITS, REGEXPS } from '../utils/constants'
import { emptyForm } from './ProductLots.hooks'

export const labelsByMeasureUnit = {
  KG: 'requested-quantity',
  CAJA: 'requested-boxes',
  PIEZA: 'requested-units'
}

export const getUpsertData = (
  receiptId: string,
  container: string,
  product: ProductDetail,
  lotValues: LotFormValues[]
): UpsertProductLotsData => {
  return {
    receiptId,
    product: {
      id: product.productId,
      barcode: product.barcode,
      expectedQuantity: product.totalUnits
    },
    lots: lotValues.map((data) => ({
      lotCode: data.lot,
      expirationDate: data.expirationDate,
      units: +data.numberOfUnits,
      boxes: +data.amountOfBoxes,
      containerName: data.containerName || container
    }))
  }
}

export const getCurrentTotal = (
  values: LotFormValues[],
  measureUnit: string,
  totals: GetProductLotsResponse
) => {
  let total = 0
  if (measureUnit === MEASURE_UNITS.piece) {
    total = totals.totalRejected + totals.totalMissing
    values.forEach((value) => (total += +value.numberOfUnits || 0))
  } else if (measureUnit === MEASURE_UNITS.box) {
    total = totals.totalRejectedBoxes + totals.totalMissingBoxes
    values.forEach((value) => (total += +value.amountOfBoxes || 0))
  } else if (measureUnit === 'KG') {
    total = totals.totalRejected + totals.totalMissing
    values.forEach((value) => (total += +value.numberOfUnits || 0))
    total /= 1000
    total = +total.toFixed(2)
  }
  return total
}

const getMaxTotalValidator = (
  product: ProductDetail | undefined,
  t: TFunction<'global', undefined>,
  totals: GetProductLotsResponse,
  measureUnit: string
) => {
  return (_: number, context: Yup.TestContext<Yup.AnyObject>) => {
    if (!product) {
      return
    }
    const { path, options, createError } = context

    const formValues = (options.context?.forms || []) as LotFormValues[]
    let total = getCurrentTotal(formValues, measureUnit, totals)
    total /= product.measureUnit === MEASURE_UNITS.kg ? 1000 : 1
    let limit = product.upperTotalLimit
    if (measureUnit === MEASURE_UNITS.box) {
      limit = product.quantity
    }
    if (total > limit) {
      return createError({
        path,
        message: t('validations.greater-total-units')
      })
    }
    return true
  }
}

export const getValidationSchema = (
  t: TFunction<'global', undefined>,
  product: ProductDetail | undefined,
  totals: GetProductLotsResponse
) => {
  const required = t('validations.required')
  const amount = t('validations.invalid-amount')
  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 1)

  let numberOfUnits = Yup.number().required(required).positive(amount)
  if (product?.measureUnit !== MEASURE_UNITS.kg) {
    numberOfUnits = numberOfUnits.integer()
  }
  const boxesValidation = Yup.number()
    .required(required)
    .positive(amount)
    .integer(amount)

  return Yup.object({
    forms: Yup.array().of(
      Yup.object({
        lot: Yup.string()
          .required(required)
          .matches(REGEXPS.alphanum, t('validations.alphanum')),
        expirationDate: Yup.date()
          .transform((_, originalValue: Date | string) => {
            if (
              typeof originalValue === 'string' &&
              !originalValue.match(REGEXPS.date)
            ) {
              return new Error('')
            }

            const stringValue = originalValue.toString()
            const parsedDate = originalValue
              ? new Date(stringValue.split('/').reverse().join('/'))
              : null
            return parsedDate
          })
          .typeError(t('validations.invalid-date'))
          .required(required)
          .min(minDate, t('validations.expired-date')),
        amountOfBoxes: Yup.number()
          .concat(
            product?.measureUnit === MEASURE_UNITS.box
              ? boxesValidation.test('maxUnits', getMaxTotalValidator(product, t, totals, MEASURE_UNITS.box))
              : boxesValidation
          ),
        numberOfUnits: numberOfUnits.test(
          'maxUnits',
          getMaxTotalValidator(product, t, totals, MEASURE_UNITS.piece)
        )
      })
    )
  })
}

export const mapLotFormValues = (lots: CreateLotData[]): LotFormValues[] => {
  if (!lots.length) {
    return [emptyForm]
  }

  return lots.map((lot) => ({
    lot: lot.lotCode,
    expirationDate: lot.expirationDate,
    numberOfUnits: lot.units.toString(),
    amountOfBoxes: lot.boxes.toString(),
    containerName: lot.containerName
  }))
}
