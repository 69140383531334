import { msRestockingAxiosInstance } from 'utils/ms-restocking.axios.instance '
import { bffRestockingAxiosInstance } from '../../utils/bff-restocking.axios.instance'
import { type AlertSuggestion, type AlertSuggestionV2, type AlertsWrapper } from './alerts.types'
import { type AlertSuggestionProduct } from 'types/model'
import { findProductsInfo } from 'client/products/products.client'

export async function getAlertedContainersClient (): Promise<AlertsWrapper> {
  return await bffRestockingAxiosInstance()
    .get<AlertsWrapper>('/atlas/uploaded-containers')
    .then(({ data }) => data)
}

export async function getAlertsSuggestions (): Promise<AlertSuggestion[]> {
  return await bffRestockingAxiosInstance()
    .get<AlertSuggestion[]>('/atlas/restocking-group-suggestions')
    .then(({ data }) => data)
}

export async function getAlertsSuggestionsV2 (): Promise<AlertSuggestionV2[]> {
  return await msRestockingAxiosInstance()
    .get<AlertSuggestionV2[]>('/v2/suggestions')
    .then(({ data }) => data)
}

export async function getActiveProductsFromSuggestion (suggestion: string): Promise<AlertSuggestionProduct[]> {
  return await msRestockingAxiosInstance().get<AlertSuggestionProduct[]>(`/restocking/active/${suggestion}`)
    .then(async ({ data }) => {
      if (data.length > 0) {
        const products = await findProductsInfo(data.map((product) => product.productEan))
        return data.map((it) => {
          const product = products.find((p) => p.ean === it.productEan)
          return {
            ...it,
            productImage: product?.image ?? 'https://media.justo.mx/fallback.jpg',
            isWeighable: product?.isWeighable ?? false
          }
        })
      }
      return data
    })
}

export async function getNumberOfAlerts (): Promise<any> {
  return await msRestockingAxiosInstance()
    .get<AlertSuggestion[]>('/stats/active')
    .then(({ data }) => data)
}

interface restockProps {
  ean: string
  source: string
  target: string
  quantity: number
}

export const restock = async (props: restockProps) => {
  return await msRestockingAxiosInstance().post('/restock', props)
}
