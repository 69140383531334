import { AtlasBodyMain, AtlasColor, PageContentAtom } from '@justomx/atlas-components';
import EmptyListImg from '../../../assets/images/Utilities/Empty_list.svg';

const EmptyListComponent = () => {
  return(
    <PageContentAtom $centered> 
      <img src={EmptyListImg} alt="empty-list" />
      <AtlasBodyMain color={AtlasColor.Neutral10}>Sin taras o productos a despickear</AtlasBodyMain>
    </PageContentAtom>
  )

}

export default EmptyListComponent;