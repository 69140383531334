import {useEffect, useState} from "react";
import { LocationAndContainerUniversalInfo } from "../universal-search.types";
import {getInventoryProductsClient} from "../../../client/utilities.client";
import {onErrorToast} from "../../../onErrorToast.util";
import { useAtom } from "jotai";
import { universalSearchScanAtomReader } from "../universal-search.state";
import { useNavigate } from "react-router-dom";
import { findProductsInfo } from "client/products/products.client";
import { UomKey } from "client/types";

export const useUniversalSearchLocation = () => {
  const [scan] = useAtom(universalSearchScanAtomReader)
  const [fulfilledLocation, setFulfilledLocation] = useState<LocationAndContainerUniversalInfo>({
    inventoryProducts: [],
    loading: false,
    error: null
  })
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const location = scan.location
  const totalContainers = scan.totalContainers

  useEffect(() => {
    if(!location) return
    setLoading(true)
    const inventoryProductsPromise = getInventoryProductsClient(location.legacyId, null, false)

    inventoryProductsPromise
    .then(async (products) => {
      const tempProducts = products.length > 0 ? await findProductsInfo(products.map((it) => it.productEan)) : []
      return products.map((it) => {
        const product = tempProducts.find((p) => p.ean === it.productEan)
        if (product != null) {
          it.byWeight = product.packagingLevels.some((pl) => pl.uom === UomKey.Weight)
        }
        return it
      })
    })
    .then(e => setFulfilledLocation(x => ({...x, inventoryProducts: e})))
    .catch(e => {
      onErrorToast("Error al obtener productos de inventario en ubicación: " + location.name + ": " + e.message)
      setFulfilledLocation(x => ({...x, error: e}))
    })
    .finally(() => {
      setFulfilledLocation(x => ({...x, loading: false}))
      setLoading(false)
    })
  }, [])

  return {
    location,
    totalContainers,
    fulfilledLocation,
    loading,
    goBack: () => navigate(-1)
  };
}
