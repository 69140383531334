import { AtlasBodyMain, AtlasBodySubtitle, AtlasColor, CardRow, Icon, Icons, ImageThumbnailComponent, StackAtom } from "@justomx/atlas-components";
import { ProductResponse } from "client/scanner/scanner.types";
import { FlexRowAtom } from "components/atoms/FlexRow.atom";
import AlertIcon from '../../../assets/images/Utilities/alert_icon.svg';
import PriorityIcon from '../../../assets/images/Utilities/priority_icon.svg';
import { type  ReverseLogisticProductInfo } from "types/model";

interface ProductInfoProps {
  product: ReverseLogisticProductInfo
  showPriority?: boolean
  showWasteIcon?: boolean
  openDialog?: () => void
}

const ProductInfo = ({product, showPriority, showWasteIcon, openDialog}: ProductInfoProps) => {
  return(<CardRow style={{alignItems: 'flex-start', width: '100%'}}>
    <div style={{position: 'relative'}}>
      <ImageThumbnailComponent 
        size={60}
        src={product.image}
        style={{ alignSelf: 'self-start' }}
      />
      {showPriority && product.priority !== 4 &&
        <ImageThumbnailComponent 
          size={25}
          src={PriorityIcon}
          style={{ position: 'absolute', top: -4, left: -4 }}
        />
      }
    </div>
    <StackAtom gap={12}>
      <FlexRowAtom style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <AtlasBodySubtitle>{product.name}</AtlasBodySubtitle>
        {showWasteIcon && 
          <img 
            src={AlertIcon}
            alt='alert-icon'
            onClick={openDialog}
            style={{cursor: 'pointer'}}
          />
        }
      </FlexRowAtom>
      <FlexRowAtom style={{alignItems: 'center', gap:4, color: AtlasColor.Neutral40}}>
        <AtlasBodyMain>UPC: {product.ean}</AtlasBodyMain>
        <AtlasBodyMain>|</AtlasBodyMain>
        <AtlasBodyMain>SKU: {product.sku}</AtlasBodyMain>
      </FlexRowAtom>
    </StackAtom>
  </CardRow>
  )
}

export default ProductInfo;