import { useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSetAtom } from 'jotai'

import type { ProductDetail } from 'types/model'
import { Analytics } from 'analytics/analytics.events'
import { selectedProductAtom } from '../Receipt.state'
import { useRequiredParams } from 'hooks/params.hook'
import { isAnOrder } from './OrderDetail.loader'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { useGoToScanContainer } from '../scan-container/ScanContainer.navigator'
import { useGoToMoveContainers } from '../move-containers/MoveContainers.navigator'
import { useGoToSummary } from '../summary/Summary.navigator'
import { useGoToReceipt } from '../Receipt.navigator'
import { useGoToRejection } from '../rejection/Rejection.navigator'
import { toastError } from '../utils/toast'
import { defaultActiveTab, groupByStatus, tabs } from './OrderDetail.helpers'
import { updateMissings } from '../client/Receipt.client'
import { MISSING_TYPES, PRODUCT_STATUS, RECEIPTS } from '../utils/constants'

export const useOrderDetailData = () => {
  const data = useLoaderData()
  const { orderId, receiptId, receiptType } = useRequiredParams(
    'orderId',
    'receiptId',
    'receiptType'
  )
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  const [tab, setTab] = useState(defaultActiveTab)
  const [loading, setLoading] = useState(false)
  const [isFullMissing, setIsFullMissing] = useState(false)
  const setSelectedProduct = useSetAtom(selectedProductAtom)
  const scanContainer = useGoToScanContainer()
  const moveContainers = useGoToMoveContainers()
  const summary = useGoToSummary()
  const receipt = useGoToReceipt()
  const rejection = useGoToRejection()
  const {
    dialogRef: missingsDialogRef,
    open: openMissingDialog,
    value: missingsDialogRefValue
  } = useConfirmationDialog()
  const {
    dialogRef: fullMissingDialogRef,
    open: openFullMissingDialog,
    value: missingDialogRefValue
  } = useConfirmationDialog()

  if (!isAnOrder(data)) {
    toastError(t('receipt-order-detail.type-error'))
    throw new Error(t('receipt-order-detail.type-error'))
  }
  const products = groupByStatus(data.products)

  const onSelectTab = (selectedTab: string) => {
    setTab(selectedTab)
  }

  const getTabs = () => {
    return tabs.map((id) => ({
      id,
      label: t(`receipt-order-detail.${id}`, {
        counter: products[id]?.length || 0
      })
    }))
  }

  const scanProduct = (upc: string) => {
    if (isNaN(+upc)) {
      toastError(t('receipt-order-detail.invalid-upc', { upc }))
      return
    }

    const scannedProduct = data.products.find(
      (product) => product.barcode === upc
    )
    if (!scannedProduct) {
      toastError(t('receipt-order-detail.nonexistent-upc', { upc }))
      return
    } else if (scannedProduct.status !== PRODUCT_STATUS.pending) {
      toastError(t('receipt-order-detail.registered-upc', { upc }))
      return
    }

    setSelectedProduct(scannedProduct)
    scanContainer.go(receiptId, orderId)
  }

  const onSelectProduct = (product: ProductDetail) => {
    setIsFullMissing(!!product.isFullMissing)
    if (product.status === PRODUCT_STATUS.pending) {
      if (receiptType === RECEIPTS.transfers) {
        openFullMissingDialog(product)
      } else {
        openMissingDialog(product)
      }
    } else {
      if (product.isFullMissing) {
        openFullMissingDialog(product)
      } else {
        setSelectedProduct(product)
        summary.go(receiptId, orderId, product.productId)
      }
    }
  }

  const onSelectMissingType = async (type: string) => {
    const selectedProduct = missingsDialogRefValue as ProductDetail
    if (type === MISSING_TYPES.fullMissing) {
      await onConfirmFullMissing(selectedProduct)
    } else {
      setSelectedProduct(selectedProduct)
      rejection.go(receiptId, orderId, selectedProduct.productId)
    }
  }

  const goToContainersPage = () => {
    moveContainers.go(receiptId, orderId)
  }

  const onConfirmFullMissing = async (product: ProductDetail) => {
    try {
      setLoading(true)
      const {
        productId: id,
        barcode,
        totalUnits: expectedQuantity,
        isFullMissing = false
      } = product
      const newIsFullMissing = !isFullMissing
      await updateMissings({
        product: { id, barcode, expectedQuantity },
        receiptId,
        isFullMissing: newIsFullMissing
      })
      Analytics.receipt.setFullMissingStatus(
        { orderId, receiptType: 'providers', isFullMissing: newIsFullMissing },
        product
      )

      if (newIsFullMissing && products.pending?.length === 1) {
        goToContainersPage()
      } else {
        navigate(0)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    orderDetail: data,
    orderId,
    products,
    tab,
    missingsDialogRef,
    fullMissingDialogRef,
    loading,
    isFullMissing,
    missingDialogRefValue,
    actions: {
      scanProduct,
      onSelectTab,
      getTabs,
      onSelectProduct,
      onConfirmFullMissing,
      onSelectMissingType,
      goToContainersPage,
      goToReceipt: () => {
        receipt.go()
      }
    }
  }
}
