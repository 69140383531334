
import { useScanner } from '../../../hooks/scanner.hook'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { reverseLogisticsSelectedProductAtom, reverseLogisticsSelectedItemAtom } from '../reverse-logistics.state'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useReverseLogistics } from '../ReverseLogistics.navigator'
import { useRequiredParams } from 'hooks/params.hook'
import { extractWeight } from 'utils/product'
import { getScannedInfo } from 'client/scanner/scanner.client'
import { registerUnpick } from '../reverse-logistics.client'
import { validateRegister } from './RegisterProductQuantity.validator'
import { getRulesFromProductClient } from 'client/utilities.client'
import { useReverseLogisticsScanProduct } from '../scan-product/ScanProduct.navigator'
import { isScannedLocation, isScannedProduct } from 'client/scanner/scanner.types'
import { Analytics } from 'analytics/analytics.events'
import { formatUnpickItemEvent } from './RegisterProductQuantity.helpers'
import { ReverseLogisticProductInfo } from 'types/model'

export const useRegister = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const navigate = useNavigate()
  const [selectedProduct, setSelectedProduct] = useAtom(reverseLogisticsSelectedProductAtom)
  const [selectedItem, setSelectedItem] = useAtom(reverseLogisticsSelectedItemAtom)
  const { t } = useTranslation('global')
  const [loading, setLoading] = useState(false)
  const reverseLogistics = useReverseLogistics()
  const scanProduct = useReverseLogisticsScanProduct()
  const { ean } = useRequiredParams('ean')
  const firstWeight = extractWeight(ean)
  const [weights, setWeights] = useState<number[]>(firstWeight != null ? [firstWeight] : [])
  const totalWeights = weights.reduce((prev, it) => prev + it, 0)
  const [quantity, setQuantity] = useState<number>(
    selectedProduct.isWeighable ? 
      firstWeight != null ? 
        firstWeight : 
        0 :
          selectedProduct.pendingQuantity === 1 ? 
          1 : 
          0
    )
  const [suggestedLocations, setSuggestedLocations] = useState<any>()

  const addItem = (item: number) => {
    if (item > 0) {
      const newWeights = [...weights, item]
      const newTotalWeight = newWeights.reduce((acc, curr) => acc + curr, 0)
      if(newTotalWeight > selectedProduct.pendingQuantity) {
        toast.error(t('reverse-logistics.register.stock-error'));
      }
      else {
        setWeights(newWeights)
        setQuantity(newTotalWeight)
      }
    }
  }

  const deleteItem = (item: number) => {
    const index = weights.indexOf(item)
    if (index >= 0) {
      const temp = [...weights]
      temp.splice(index, 1)
      setWeights(temp)
      setQuantity(temp.reduce((acc, curr) => acc + curr, 0))
    }
  }

  useEffect(() => {
    getRulesFromProductClient(selectedProduct.id as string)
    .then(e => setSuggestedLocations(e.data[0]?.locations?.map(e => e.locationName)))
    .catch(e => {
      console.error(e)
      toast.error('Error getting suggested locations:' + e.message as string)
    })
  }, [])

  useEffect(() => {
    const scanned = getScan()
    
    if (!scanned || loading) {
      return
    }
    
    setLoading(true)
    getScannedInfo(scanned)
    .then(e => {
      if(isScannedProduct(e)) {
        if(e.product.isWeighable){
          if(selectedProduct.ean === e.product.ean){
            const weight = extractWeight(scanned)
            if(weight){
              addItem(weight)
            }
          }
          else{
            toast.error(t('reverse-logistics.register.different-product'))
          }
        }
      }
      if(isScannedLocation(e)) {
        try{
          validateRegister(scanned,quantity,selectedProduct,suggestedLocations,t)
          registerUnpick({
            id: selectedItem.id,
            ean: selectedProduct.ean,
            location: scanned,
            quantity
          })
          .then(res => {
            const updatedProductsWithImage = res.products.map((product) => {
              const existingProduct = selectedItem.products.find(p => p.ean === product.ean);
              return {
                ...product,
                image: existingProduct ? existingProduct.image : undefined,
                isWeighable: existingProduct ? existingProduct.isWeighable : false,
              };
            });
            const updatedItem = {
              ...res,
              products: updatedProductsWithImage,
            };
            setSelectedProduct(updatedProductsWithImage.find(p => p.ean === selectedProduct.ean) as ReverseLogisticProductInfo)
            setSelectedItem(updatedItem)
            const eventData = formatUnpickItemEvent(selectedProduct, selectedItem, e.location, quantity)
            Analytics.reverseLogistics.UnpickItem(eventData)
            toast.success(t('reverse-logistics.register.success', { quantity, location: scanned, uom: selectedProduct.isWeighable ? 'g' : 'unidades' }))
            if(selectedItem.type === 'Product'){
              reverseLogistics.go()
            }
            else{
              if(res.status === 'Completed'){
                reverseLogistics.go()
              }
              else{
                scanProduct.go()
              }
            }
          })
          .catch(e => {
            toast.error('Error al registrar: ' + e.message)
            console.error(e)
          })
        }
        catch(e){
          toast.error(e.message)
          setLoading(false)
        }
      }
    })
    .catch(e => {
      toast.error('Error al escanear ' + scanned + ": " + e.message)
      console.error(e)
      setLoading(false)
    })

  }, [keys])


  return {
    suggestedLocations,
    selectedProduct,
    loading,
    weights,
    totalWeights,
    setQuantity,
    actions: {
      goBack: () => {
        navigate(-1)
      },
      addItem,
      deleteItem
    }
  }
}
