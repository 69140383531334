export const isObject = (o: unknown): o is object => {
  return o !== null && typeof o === 'object'
}

export const hasStringProperty = (o: object, property: string) => {
  return property in o && typeof o[property] === 'string'
}

export const hasNumberProperty = (o: object, property: string) => {
  return property in o && typeof o[property] === 'number'
}
