import { AtlasBodyMain, AtlasColor } from '@justomx/atlas-components'
import { useState } from 'react'
import styled from 'styled-components'

interface TabData {
  id: string
  label: string
}

interface TabGroupMoleculeProps {
  tabs: TabData[]
  defaultActiveTab?: string
  onSelectTab?: (selectedTab: string) => void
  tabColors? : string[]
}

const TabPane = styled.div`
  width: 100%;
  height: 42px;
  background-color: ${AtlasColor.Background};
  display: flex;
`

const TabButton = styled.button<{ $active?: boolean, $underlineColor: string }>`
  height: 42px;
  border: none;
  flex: 1;
  background-color: transparent;
  color: ${({ $active }) =>
    $active ? AtlasColor.Neutral00 : AtlasColor.Neutral40};
  border-bottom: ${({ $active, $underlineColor }) =>
    $active ? `4px solid ${$underlineColor}` : null};
`

const TabGroupMolecule = (props: TabGroupMoleculeProps) => {
  const { tabs, defaultActiveTab, onSelectTab, tabColors } = props
  const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs[0].id)

  const selectTab = (selectedTab: string) => {
    setActiveTab(selectedTab)

    if (onSelectTab) {
      onSelectTab(selectedTab)
    }
  }

  return (
    <>
      <TabPane>
        {tabs.map((tab, idx) => (
          <TabButton
            $active={activeTab === tab.id}
            key={`tab${idx}`}
            onClick={() => {
              selectTab(tab.id)
            }}
            $underlineColor={tabColors?.[idx] || AtlasColor.Primary00} 
          >
            <AtlasBodyMain>{tab.label}</AtlasBodyMain>
          </TabButton>
        ))}
      </TabPane>
    </>
  )
}

export default TabGroupMolecule
