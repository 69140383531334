import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useScanner } from 'hooks/scanner.hook'
import { Global } from 'utils/global'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { useReverseLogisticsRegisterWasteOrMissing } from './register-waste-or-missing/RegisterWasteOrMissing.navigator'
import { getPendingDetails, getPendings } from './reverse-logistics.client'
import { ReverseLogisticProductInfo, TareOrProduct } from 'types/model'
import { reverseLogisticsSelectedProductAtom, reverseLogisticsSelectedItemAtom } from './reverse-logistics.state'
import { useReverseLogisticsScanProduct } from './scan-product/ScanProduct.navigator'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { getProductOrSupplyFromScan } from 'client/scanner/scanner.client'
import { useReverseLogisticsRegister } from './register-product-quantity/RegisterProductQuantity.navigator'

export const useReverseLogistics = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const {
    dialogRef,
    open: openWasteOrMissingDialog,
  } = useConfirmationDialog()
  const { go } = useReverseLogisticsRegisterWasteOrMissing()
  const [data, setData] = useState<TareOrProduct[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedProduct, setSelectedProduct] = useAtom(reverseLogisticsSelectedProductAtom)
  const [, setSelectedItem] = useAtom(reverseLogisticsSelectedItemAtom)
  const reverseLogisticsScanProduct = useReverseLogisticsScanProduct()
  const { t } = useTranslation('global')
  const registerNavigator = useReverseLogisticsRegister()

  useEffect(() => {
    setLoading(true)
    getPendings()
      .then((data) => {
        setData(data.filter((item) => item.status !== 'Completed'))
      })
      .catch((error) => {
        console.log(error)
        toast.error('Error al obtener listado de taras y productos')
      })
      .finally(() => { 
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    const scanned = getScan()
    if(scanned){
      setLoading(true)
      getProductOrSupplyFromScan(scanned)
      .then((res) => {
        if(res.type === 'Supply'){
          const selected = data.find((item) => item.tara?.barcode === scanned)
          if(selected){
            getPendingDetails(selected.id)
            .then((res) => {
              setSelectedItem({...selected, products: res.products})
              reverseLogisticsScanProduct.go()
            })
            .catch((error) => {
              console.log(error)
              toast.error('Error al obtener detalles de tara')
            })
          }
          else{
            toast.error(t('reverse-logistics.tare-not-found'))
          }
        }
        else{
          const products = data.filter((item) => item.type === 'Product')
          const selected = products.find((item) => item.products[0].ean === res.product.ean)
          if(selected){
            setSelectedItem(selected)
            setSelectedProduct({...selected?.products[0], id: res.product.legacyId} as ReverseLogisticProductInfo)
            registerNavigator.go(scanned)
          }
          else{
            toast.error(t('reverse-logistics.product-not-found'))
          }
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(t('reverse-logistics.tare-not-valid'))
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }, [keys])

  return {
    data,
    loading,
    selectedProduct,
    actions: {
      openMenu: () => {
        Global.WV.openMenu()
      },
      openWasteOrMissingDialog: (product) => {
        setSelectedProduct(product)
        openWasteOrMissingDialog()
      },
      goToWasteOrMissingPage: go,
    },
    dialogRef
  }
}
