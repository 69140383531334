import { useNavigate } from 'react-router-dom'
import { type InventoryProduct, type LocationOrContainer } from '../../../types/model'

interface Go {
  origin: LocationOrContainer
  destination: LocationOrContainer
  inventoryProducts: InventoryProduct[]
  moveContainer: string
  looseProducts: string
}

interface GoOneProduct {
  origin: LocationOrContainer
  destination: LocationOrContainer
  inventoryProduct: InventoryProduct
  quantity: number
}

const inventoryProductQueryParam = 'inventoryItem='

export const MOVE_INVENTORY_ITEMS = '/traslation/:origin/:container/move-products-to/:destination'

export const useGoToMoveInventoryItems = () => {
  const navigate = useNavigate()

  return {
    go: ({ origin, destination, inventoryProducts, moveContainer, looseProducts }: Go) => {
      const url = MOVE_INVENTORY_ITEMS 
        .replace(':origin', origin.location.legacyId)
        .replace(':container', origin.container?.legacyId || 'null')
        .replace(':destination', destination.location.legacyId)

      const props = inventoryProducts
        .map(e => e.productEan)
        .join('&' + inventoryProductQueryParam)

      navigate(`${url}?${inventoryProductQueryParam}${props}&moveContainer=${moveContainer}&looseProducts=${looseProducts}`)
    },
    goOneProduct: ({ origin, destination, inventoryProduct, quantity }: GoOneProduct) => {
      const url = MOVE_INVENTORY_ITEMS
        .replace(':origin', origin.location.legacyId)
        .replace(':container', origin.container?.legacyId || 'null')
        .replace(':destination', destination.location.legacyId)

      navigate(`${url}?${inventoryProductQueryParam}${inventoryProduct.productEan}&quantity=${quantity}`)
    }
  }
}
