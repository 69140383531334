import { useScanner } from '../../../hooks/scanner.hook'
import { useEffect } from 'react'
import { useGoToInventoryProductList } from '../product-list/InventoryProductList.navigator'
import { getLocationOrContainerByLastNumbers } from '../../../client/utilities.client'
import { manageError } from '../../../utils/errors/error.handler'
import { validateScan } from './ScanContainerLocation.validator'
import { getContainerFromScan, getLocationOrContainerFromScan } from 'client/scanner/scanner.client'

export const useScanContainerLocation = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const inventoryProductList = useGoToInventoryProductList()

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      getLocationOrContainerFromScan(scanned)
        .then(validateScan)
        .then(inventoryProductList.go)
        .catch(manageError(`ScanContainerLocation.hooks.ts#useEffect ${scanned}`))
    }
  }, [keys])

  return {
    actions: {
      goToContainerByLastNumbers: (lastNumbers: string) => {
        getLocationOrContainerByLastNumbers(lastNumbers)
          .then(async (info) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return await getContainerFromScan(info.containerName!)
          })
          .then(inventoryProductList.go)
          .catch(manageError(`ScanContainerLocation.hooks.ts#actions.goToContainerByLastNumbers ${lastNumbers}`))
      }
    }
  }
}
