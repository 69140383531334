import { AtlasColor } from '../foundations/Colors.foundations'
import styled from 'styled-components'

export const TextButton = styled.button`
  color: ${AtlasColor.Important};
  text-align: center;
  /* Atlas/ Button */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  color: ${({ color }) => color ?? '#FACE39)'};

  &:disabled {
    color: ${AtlasColor.Neutral60};
  }
`
