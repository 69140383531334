import { atomWithStorage } from 'jotai/utils'
import { createReader } from '../../../utils/atomReader'
import { ReverseLogisticProductInfo, TareOrProduct } from 'types/model'

export const reverseLogisticsSelectedISupplyAtom = atomWithStorage<TareOrProduct>('reverseLogisticsSelectedSupply', {} as TareOrProduct)
export const reverseLogisticsSelectedSupplyReader = createReader(reverseLogisticsSelectedISupplyAtom)

export const reverseLogisticsScannedSuppliesAtom = atomWithStorage<TareOrProduct[]>('reverseLogisticsScannedSupplies', [])
export const reverseLogisticsScannedSuppliesReader = createReader(reverseLogisticsScannedSuppliesAtom)

export const reverseLogisticsSelectedProductAtom = atomWithStorage<ReverseLogisticProductInfo>('reverseLogisticsSelectedProduct', {} as ReverseLogisticProductInfo)
export const reverseLogisticsSelectedProductReader = createReader(reverseLogisticsSelectedProductAtom)