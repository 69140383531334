import {
  AtlasBodyMain,
  AtlasBodyTitle,
  AtlasColor, BottomGroupMolecule,
  CardComponent, Icon,
  Icons,
  PageContentAtom, SnackbarInformationPanel,
  StackAtom,
  ToolbarMolecule
} from "@justomx/atlas-components";
import {Global} from "../../utils/global";
import {useTranslation} from "react-i18next";
import React from "react";
import {LocationAndContainerMolecule} from "../../components/molecules/LocationAndContainer.molecule";
import {LocationAndContainer} from "../../types/model";
import {useStorageHomepage} from "./Storage.hooks";
import {onErrorToast} from "../../onErrorToast.util";
import { isError } from "utils/async-types";

const ContainerCard = (
  params: {container: LocationAndContainer, "data-test"?: string}
) => {
  const {container} = params;

  return <CardComponent
    data-test={params["data-test"] || 'container-card'}
  >
    <StackAtom gap={16}>
      <LocationAndContainerMolecule location={container}/>
    </StackAtom>
  </CardComponent>
}

/**
 * Storage home page
 *
 * @constructor
 * @figma https://www.figma.com/file/2YZBZUPe8XySSzhRBUw9hH/Almacenamiento?type=design&node-id=10376-4327&mode=design&t=viOwxdKEiDD9voRg-0
 */
export const StoragePage = () => {
  const { t } = useTranslation('global')
  const locationAndContainers = useStorageHomepage(onErrorToast);

  if (isError(locationAndContainers)) {
    return (<p>{t('to-storage.default-error')}</p>)
  }

  return <>
    <ToolbarMolecule title={t('to-storage.title')}
                     actionIcon={Icons.IcMenuIcon}
                     onAction={Global.WV.openMenu}
    />
    <PageContentAtom>
      {locationAndContainers.value.length > 0 ? locationAndContainers.value.map(e => <ContainerCard
          key={e.locationName + '-' + e.containerName}
          container={e}
        />) 
        : <AtlasBodyMain>{t('to-storage.no-containers')}</AtlasBodyMain>
      }
    </PageContentAtom>
    <BottomGroupMolecule>
      <SnackbarInformationPanel style={{
        border: '1px solid',
        borderRadius: '12px',
        backgroundColor: AtlasColor.Primary10,
        borderColor: AtlasColor.Important
      }}>
        <Icon src={Icons.ScanIcon} size={40}/>
        <AtlasBodyTitle>{t('to-storage.description')}</AtlasBodyTitle>
      </SnackbarInformationPanel>
    </BottomGroupMolecule>
  </>
};