import {useNavigate} from "react-router-dom";

export const useGoToStorageScanDestinationDetail = () => {
  const navigate = useNavigate()

  return {
    go: (containerName: string) => {
      navigate(
        STORAGE_SCAN_DESTINATION_URL
        .replace(':containerName', containerName)
      );
    }
  }
}

export const STORAGE_SCAN_DESTINATION_URL = '/to-storage/:containerName/send'