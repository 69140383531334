import { FormikProvider, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import {
  ToolbarMolecule,
  PageContentAtom,
  BottomGroupMolecule,
  Icons
} from '@justomx/atlas-components'

import LoaderAtom from 'components/atoms/Loader.atom'
import { DataCardMolecule } from 'components/molecules/DataCard.molecule'
import { ScannerMolecule } from 'components/molecules/Scanner.molecule'
import { FormikTextFieldOrganism } from 'components/organisms/FormikTextField.organism'
import { usePickProduct } from './PickProduct.hooks'

const PickProductPage = () => {
  const { t } = useTranslation('global')
  const { actions, orderId, product, formConfig, loading } = usePickProduct()

  if (!product) {
    return
  }

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
      <ToolbarMolecule
        title={`#${orderId}`}
        actionIcon={Icons.CloseIcon}
        onAction={actions.onGoBack}
      />

      <PageContentAtom>
        <DataCardMolecule
          imageUrl={product.productImage}
          title={product.description}
          subtitle={t('inventory-transfer.detail.upc-sku', {
            barcode: product.barcode,
            sku: product.sku
          })}
          footerLines={[
            {
              label: t('inventory-transfer.detail.requested'),
              value: product.isWeighted
                ? `${product.quantity}g`
                : `${product.quantity}`
            }
          ]}
        />

        <FormikProvider value={formConfig}>
          <Form onSubmit={formConfig.handleSubmit}>
            <FormikTextFieldOrganism
              type="number"
              name="quantity"
              label={t(
                product.isWeighted
                  ? 'inventory-transfer.pick.quantity-gr'
                  : 'inventory-transfer.pick.quantity'
              )}
              formConfig={formConfig}
            />
          </Form>
        </FormikProvider>

        <div style={{ minHeight: '150px' }}></div>
        <BottomGroupMolecule>
          <ScannerMolecule
            cardMode={true}
            cardHeight={50}
            label={t('inventory-transfer.pick.scan')}
            onScan={actions.onScanLocation}
          ></ScannerMolecule>
        </BottomGroupMolecule>
      </PageContentAtom>
    </>
  )
}

export default PickProductPage
