import { ScanOriginPage } from './scan-origin/ScanOrigin.page'
import { ScanDestinationPage } from './scan-destination/ScanDestination.page'
import { type RouteObject } from 'react-router-dom'
import { INVENTORY_WASTE_SCAN_ORIGIN } from './scan-origin/ScanOrigin.navigator'
import { ScanProductPage } from './scan-product/ScanProduct.page'
import { INVENTORY_WASTE_SCAN_PRODUCT } from './scan-product/ScanProduct.navigator'
import { INVENTORY_WASTE_REGISTER } from './register-waste/RegisterWaste.navigator'
import { RegisterWastePage } from './register-waste/RegisterWaste.page'
import { INVENTORY_WASTE_SCAN_DESTINATION } from './scan-destination/ScanDestination.navigator'

const simulateSlowness = (response) => async () =>
  await new Promise(resolve => setTimeout(resolve, 1000))
    .then(() => response)

export const inventoryWasteRoutes: RouteObject[] = [
  {
    id: '(inventory waste) Scan origin',
    path: INVENTORY_WASTE_SCAN_ORIGIN,
    element: <ScanOriginPage/>
  },
  {
    id: '(inventory waste) Scan product',
    path: INVENTORY_WASTE_SCAN_PRODUCT,
    element: <ScanProductPage/>,
    loader: simulateSlowness('respuesta: scan-product-page')
  },
  {
    id: '(inventory waste) Register waste',
    path: INVENTORY_WASTE_REGISTER,
    element: <RegisterWastePage/>
    // loader: simulateSlowness('respuesta: scan-product-page')
  },
  {
    id: '(inventory waste) Scan destination',
    path: INVENTORY_WASTE_SCAN_DESTINATION,
    element: <ScanDestinationPage/>
  },
]
