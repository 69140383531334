import { useState, type MutableRefObject } from 'react'
import { AtlasBodySubtitle, TextBoxMolecule } from '@justomx/atlas-components'
import { ConfirmationDialogMolecule } from 'components/molecules/ConfirmationDialog.molecule'
import styled from 'styled-components'

interface TextfieldDialogOrganismProps {
  dialogRef: MutableRefObject<HTMLDialogElement | null>
  title: string
  label: string
  confirmText: string
  cancelText: string
  confirmAction: (value: string) => void
}

const DialogBody = styled.div`
  margin-top: 24px;
`

export const TextfieldDialogOrganism = (props: TextfieldDialogOrganismProps) => {
  const { dialogRef, title, label, confirmText, cancelText, confirmAction } = props
  const [value, setValue] = useState('')

  const onChange = (value: string) => {
    setValue(value)
  }

  return (
    <ConfirmationDialogMolecule
      dialogRef={dialogRef}
      confirmText={confirmText}
      onConfirm={confirmAction}
      cancelText={cancelText}
      value={value}
    >
      <AtlasBodySubtitle>{title}</AtlasBodySubtitle>

      <DialogBody>
        <TextBoxMolecule
          type='text'
          label={label}
          onChange={onChange}
        />
      </DialogBody>
    </ConfirmationDialogMolecule>
  )
}
