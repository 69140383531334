import { type InventoryProduct } from '../../types/model'
import { ProductInfoAtom } from '../atoms/ProductInfo.atom'
import { AtlasBodySubtitle, AtlasColor, CardComponent, CardDivider, CardRow } from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'

interface ProductCardComponentProps {
  onClick?: (p: InventoryProduct) => void
  inventoryProduct: InventoryProduct
  selected?: boolean
  selectable?: boolean
  subtitle?: string
  'data-test'?: string
  variant?: 'critical' | 'warning'
}

export const InventoryProductCardMolecule = (props: ProductCardComponentProps) => {
  const {
    onClick,
    inventoryProduct,
    selected = false,
    selectable = false,
    subtitle,
    variant
  } = props

  const { t } = useTranslation('global')

  const variantTheme = {
    critical: '2px solid #FF6771',
    warning: '2px solid #FF9E67'
  }

  return (
    <CardComponent
      color={AtlasColor.Neutral40}
      onClick={onClick}
      data-test={props['data-test']}
      style={{
        backgroundColor: '#171717',
        border: variant != null ? variantTheme[variant] : '1px solid #404040'
      }}
    >
      <CardRow $gap={16}>
        <ProductInfoAtom
          product={inventoryProduct}
          selectable={selectable}
          selected={selected}
          data-test={props['data-test'] + '-info'}
        />
      </CardRow>
      <CardDivider />
      <CardRow>
        <AtlasBodySubtitle
          data-stock={inventoryProduct.stock}>{subtitle ?? t('inventory-product-card.count', { value: inventoryProduct.stock })}</AtlasBodySubtitle>
      </CardRow>
    </CardComponent>
  )
}
