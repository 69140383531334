import { getInventoryProductsClient } from '../../../client/utilities.client'

export const moveOptionsLooseProductsListLoader = async ({ params }) => {
  let {
    origin,
    container
  } = params
  
  if (container === 'null') {
    container = undefined
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const products = await getInventoryProductsClient(origin, container,true)
  return { inventoryProducts: products }
}
