import { useTranslation } from 'react-i18next'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasBodyTitle,
  BottomGroupMolecule,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'

import { DataCardMolecule } from 'components/molecules/DataCard.molecule'
import { usePickingList } from './PickingList.hooks'
import { ScannerMolecule } from 'components/molecules/Scanner.molecule'
import LoaderAtom from 'components/atoms/Loader.atom'
import { ConfirmationDialogMolecule } from 'components/molecules/ConfirmationDialog.molecule'

const PickingListPage = () => {
  const { t } = useTranslation('global')
  const {
    actions,
    orderId,
    productsToPick,
    pickedProducts,
    loading,
    dialogRef,
    selectedProduct
  } = usePickingList()

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
      <ToolbarMolecule
        title={`#${orderId}`}
        actionIcon={Icons.CloseIcon}
        onAction={actions.onClosePage}
      />

      <PageContentAtom>
        <AtlasBodyTitle>
          {t('inventory-transfer.detail.to-pick')}
        </AtlasBodyTitle>
        {productsToPick.map((product) => (
          <DataCardMolecule
            key={product.barcode}
            imageUrl={product.productImage}
            title={product.description}
            subtitle={t('inventory-transfer.detail.upc-sku', {
              barcode: product.barcode,
              sku: product.sku
            })}
            footerLines={[
              {
                label: t('inventory-transfer.detail.requested'),
                value: product.isWeighted
                  ? `${product.quantity}g`
                  : `${product.quantity}`
              }
            ]}
            zone={product.zone}
            locations={product.locations}
            actionIcon={Icons.IcReportPrimary}
            action={() => {
              actions.openMissingDialog(product)
            }}
          />
        ))}
        {!productsToPick.length && (
          <AtlasBodyMain>
            {t('inventory-transfer.detail.not-to-pick')}
          </AtlasBodyMain>
        )}

        <AtlasBodyTitle>{t('inventory-transfer.detail.picked')}</AtlasBodyTitle>
        {pickedProducts.map((product) => (
          <DataCardMolecule
            key={product.barcode}
            imageUrl={product.productImage}
            title={product.description}
            subtitle={t('inventory-transfer.detail.upc-sku', {
              barcode: product.barcode,
              sku: product.sku
            })}
            footerLines={actions.getFooterLines(product)}
            actionIcon={product.isFullMissing ? undefined : Icons.IcDelete}
            action={async () => {
              await actions.onDeletePick(product)
            }}
            zone={product.zone}
            locations={product.locations}
          />
        ))}
        {!pickedProducts.length && (
          <AtlasBodyMain>
            {t('inventory-transfer.detail.not-picked')}
          </AtlasBodyMain>
        )}

        <div style={{ minHeight: '150px' }}></div>
        <BottomGroupMolecule>
          <PrimaryButtonAtom
            type="button"
            onClick={actions.onFinishPicking}
            disabled={!!productsToPick.length}
          >
            {t('commons.finish')}
          </PrimaryButtonAtom>
          <ScannerMolecule
            cardMode={true}
            cardHeight={50}
            label={t('inventory-transfer.detail.scan-product')}
            onScan={actions.onScanProduct}
          ></ScannerMolecule>
        </BottomGroupMolecule>

        <ConfirmationDialogMolecule
          dialogRef={dialogRef}
          confirmText={t('inventory-transfer.detail.missing')}
          onConfirm={actions.onConfirmFullMissing}
          cancelText={t('commons.cancel')}
        >
          <AtlasBodySubtitle>
            {t('inventory-transfer.detail.missing-alert')}
          </AtlasBodySubtitle>

          {selectedProduct && (
            <AtlasBodyMain style={{ paddingTop: '24px' }}>
              {selectedProduct.description}
            </AtlasBodyMain>
          )}
        </ConfirmationDialogMolecule>
      </PageContentAtom>
    </>
  )
}

export default PickingListPage
