import { type InventoryProduct, isInventoryProductsResponse } from '../types/model'
import { useState } from 'react'
import { localeIncludes } from '../utils/string'
import { useLoaderData } from 'react-router-dom'

export interface UseHasInventoryProducts {
  allInventoryProducts: InventoryProduct[]
  inventoryProducts: InventoryProduct[]
  filter: {
    setFilterValue: (value: string) => void
    hasFilter: boolean
  }
}

export type UseInventoryProductsResponse = UseHasInventoryProducts

const filterInventoryProduct = (filter: string | null) => (o: InventoryProduct) => {
  if (!filter) {
    return true
  }
  const normalizedFilter = localeIncludes(filter)

  return normalizedFilter(o.productSku) ||
    normalizedFilter(o.productEan) ||
    normalizedFilter(o.productName) ||
    normalizedFilter(o.stock.toString())
}

/**
 * @deprecated
 * @param data
 */
export function useInventoryProducts (data: any): UseInventoryProductsResponse {
  const [filterValue, setFilterValue] = useState<null | string>(null)

  if (isInventoryProductsResponse(data)) {
    const filteredInventoryProducts = data.inventoryProducts.filter(filterInventoryProduct(filterValue))

    return {
      allInventoryProducts: data.inventoryProducts,
      inventoryProducts: filteredInventoryProducts,
      filter: {
        setFilterValue,
        hasFilter: Boolean(filterValue)
      }
    }
  }

  throw new Error('Inventory product response expected in useData')
}

export function useCurrentInventoryProducts (): InventoryProduct[] {
  const data = useLoaderData()

  if (isInventoryProductsResponse(data)) {
    return data.inventoryProducts
  }

  throw new Error('Expected inventoryData from useLoaderData')
}
