import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  BottomGroupMolecule,
  CardComponent,
  Filler,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { ScannerMolecule } from 'components/molecules/Scanner.molecule'
import { useScanContainer } from './ScanContainer.hooks'

const ProductContainer = styled.div`
  display: flex;
  gap: 8px;
`

const ProductDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`

const ProductImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 8px;
`

const ScanContainerPage = () => {
  const { t } = useTranslation('global')
  const { actions, product } = useScanContainer()

  return (
    <>
      <ToolbarMolecule
        title={t('receipt-scan-container.title')}
        actionIcon={Icons.ArrowBack}
        onAction={actions.goBack}
      />

      <Filler flexGrow={2} />
      <PageContentAtom>
        <ScannerMolecule
          label={t('receipt-scan-container.scan-label')}
          onScan={actions.validateContainer}
        />
      </PageContentAtom>
      <Filler />

      <BottomGroupMolecule>
        <AtlasBodySubtitle>
          {t('receipt-scan-container.product')}
        </AtlasBodySubtitle>
        <CardComponent>
          <ProductContainer>
            <ProductImage src={product.imgUrl} alt="product" />

            <ProductDetail>
              <AtlasBodySubtitle>
                {t('receipt-order-detail.upc', { upc: product.barcode })}
              </AtlasBodySubtitle>
              <AtlasBodyMain>{product.description}</AtlasBodyMain>
            </ProductDetail>
          </ProductContainer>
        </CardComponent>
      </BottomGroupMolecule>
    </>
  )
}

export default ScanContainerPage
