import styled from 'styled-components'

import { AtlasColor } from '../foundations/Colors.foundations'

export const Divider = styled.div`
  height: 1px;
  border-radius: 1px;
  background-color: ${AtlasColor.Neutral60};
  width:100%;
`
