import { type ToastOptions, toast } from 'react-toastify'
import { AtlasColor } from '@justomx/atlas-components'

const defaultOptions: ToastOptions = {
  hideProgressBar: true,
  theme: 'colored',
  icon: false,
  closeButton: true
}

const defaultStyle = {
  borderRadius: '4px',
  margin: '0 8px 8px 8px'
}

export const toastSuccess = (msg: string) => {
  toast.success(msg, {
    ...defaultOptions,
    style: {
      ...defaultStyle,
      backgroundColor: AtlasColor.StateGreen
    }
  })
}

export const toastError = (msg: string) => {
  toast.success(msg, {
    ...defaultOptions,
    style: {
      ...defaultStyle,
      backgroundColor: AtlasColor.Error
    }
  })
}

export const toastWarn = (msg: string) => {
  toast.warn(msg, {
    ...defaultOptions,
    style: {
      ...defaultStyle,
      backgroundColor: AtlasColor.Important
    }
  })
}
