import { type LocationAndContainer } from 'types/model'
import { type ScannedResponse, ScanType } from '../../../client/scanner/scanner.types'
import { LocationType, LocationZone } from '../../../client/types'

export function validateOrigin (origin: LocationAndContainer) {
  if (origin.locationName.includes('-RE-')) {
    throw Error('O-1: No se puede escanear una ubicación o contenedor en ubicación de recibo')
  }

  if (origin.locationType === 'PreStorage') {
    if (origin.containerId == null && origin.containersInLocation > 1) {
      throw Error('E-2: No se puede escanear una ubicación con multiples contenedores')
    }
    if (origin.containersInLocation === 0) {
      throw Error('E-5: No se puede escanear una ubicación de pre-almacenamiento que no tenga contenedores')
    }
  }

  if (origin.locationType === 'Rack') {
    if (origin.containersInLocation === 0) {
      throw Error('E-5: No se puede escanear una ubicación de Rack que no tenga contenedores')
    }
    if (origin.containerId == null && origin.containersInLocation > 1) {
      throw Error('E-7: No se puede escanear una ubicación con multiples contenedores')
    }
  }

  return origin
}

export function validate (origin: ScannedResponse) {
  if (origin.type === ScanType.Product) {
    throw Error('No se puede escanear un producto')
  }

  if (origin.location.type === LocationType.Receipt) {
    throw Error('No se puede escanear una ubicación o contenedor en ubicación de recibo')
  }

  if (origin.type === ScanType.Location) {
    if (origin.totalContainers > 1) {
      throw Error('No se puede escanear una ubicación con multiples contenedores')
    }
    if (
      (origin.location.type === LocationType.PreStorage ||
      (origin.location.type === LocationType.Storage && origin.location.zone === LocationZone.Groceries)) &&
      origin.totalContainers === 0
    ) {
      throw Error('No se puede escanear una ubicación sin contenedor')
    }
  }
  return origin
}
