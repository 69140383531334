import { useNavigate } from 'react-router-dom'
import { useRequiredParams } from 'hooks/params.hook'

export const useGoToRejection = () => {
  const navigate = useNavigate()
  const { receiptType } = useRequiredParams('receiptType')

  return {
    go: (
      receiptId: string,
      orderId: string,
      productId: string,
      rejectionId?: string
    ) => {
      let url = REJECTION_URL.replace(':receiptType', receiptType)
        .replace(':receiptId', receiptId)
        .replace(':orderId', orderId)
        .replace(':productId', productId)
      if (rejectionId) {
        url += `?rejectionId=${rejectionId}`
      }
      navigate(url)
    }
  }
}

export const REJECTION_URL =
  '/receipt/:receiptType/:receiptId/order/:orderId/lots/:productId/rejection'
