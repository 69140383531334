import {useTranslation} from "react-i18next";
import {useLoaderData} from "react-router-dom";
import {InventoryProduct, Location, LocationAndContainer} from "../../../types/model";
import {isObject} from "../../../internal-types/IsObject";
import {moveContainerClient, moveProducts} from "../../../client/move.client";
import {getInventoryProductsClient} from "../../../client/utilities.client";
import {useEffect, useState} from "react";
import {useScanner} from "../../../hooks/scanner.hook";
import {useGoToStorage} from "../Storage.navigator";
import {Analytics} from "../../../analytics/analytics.events";
import { validateMovement } from "./ScanDestination.validator";
import { useAtom } from "jotai";
import { selectedInventoryProductsReader } from "pages/traslation/Traslation.state";
import { getLocationOrContainerFromScan } from "client/scanner/scanner.client";
import { ScannedLocation } from "client/scanner/scanner.types";
import { toCommonLocationInfoFromScanResponse } from "utils/commonLocationInfo";

export type StorageScanDestination = {
  suggestedLocations: Location[]
  locationAndContainer: Required<ScannedLocation>
}

export const isCointainerDetail = (o: unknown): o is StorageScanDestination => {
  return isObject(o) && '_from' in o && o._from === 'storageScanDestinationLoader';
}

const isGroceries = (locationName: string) => {
  return locationName.split("-")[1] === 'ALM'
}

async function makeStorageMovement(destinationLocation: ScannedLocation, hasOrigin: StorageScanDestination, selectedInventoryProducts: InventoryProduct[], t: any) {
  validateMovement(destinationLocation)
  const products = await getInventoryProductsClient(hasOrigin.locationAndContainer.location.legacyId, hasOrigin.locationAndContainer.container.legacyId)
  const tmpOrigin = toCommonLocationInfoFromScanResponse(hasOrigin.locationAndContainer)
  const tmpDestination = toCommonLocationInfoFromScanResponse(destinationLocation)
  if (isGroceries(destinationLocation.location.name)) {
    if(selectedInventoryProducts.length === products.length){
      await moveContainerClient({
        origin: tmpOrigin,
        destination: tmpDestination
      })
    }
    else{
      throw new Error(t('storage-scan-destination.destination-groceries-validation'))
    }
  } else {
    await moveProducts({
      origin: tmpOrigin,
      destination: {
        locationName: destinationLocation.location.name,
        locationId: destinationLocation.location.legacyId,
      },
      inventoryProducts: selectedInventoryProducts,
      hasLooseProducts: false
    })
  }
}

export const useStorageScanDestination = (onError: (err: string) => void, onSuccess: (err: string) => void) => {
  const {t} = useTranslation('global')
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const {keys, getScan} = useScanner()
  const storagePage = useGoToStorage()
  const data = useLoaderData()
  const [inventoryProducts] = useAtom(selectedInventoryProductsReader)
  
  async function move(destinationLocationName: string) {
    if (!isCointainerDetail(data)) return { error: 'NO_CONTAINER_DETAIL'};

    const destinationLocation = await getLocationOrContainerFromScan(destinationLocationName);

    if (destinationLocation.location.type !== 'Storage') {
      throw new Error(t('storage-scan-destination.destination-no-storage', {destination: destinationLocation.location.name}))
    }
    
    await makeStorageMovement(destinationLocation as ScannedLocation, data, inventoryProducts, t).catch(e => {
      throw new Error(t(`${e.message}`))
    })

    return {
      destination: destinationLocation,
      origin: data.locationAndContainer
    }
  }

  useEffect(() => {
    const scanned = getScan()
    if (scanned && isCointainerDetail(data) && !showLoader) {
      setShowLoader(true)
      move(scanned)
      .then(e => {
        if (e.origin && e.destination) {
          onSuccess(t('storage-scan-destination.ok', {
            container: data.locationAndContainer.container.name,
            origin: data.locationAndContainer.location.name,
            destination: scanned,
          }))

          Analytics.storage({
            origin: {
              locationName: e.origin.location.name,
              locationType: e.origin.location.type,
              containerId: e.origin.container?.id,
              containerName: e.origin.container?.name,
            },
            destination: {
              locationName: e.destination.location.name,
              locationType: e.destination.location.type,
              containerId: e.destination.container?.id,
              containerName: e.destination.container?.name,
            }
          })

          setTimeout(() => {
            setShowLoader(false)
            storagePage.go()
          }, 2000)
          return
        }

        onError(t('storage-scan-destination.bad-request'));
        Analytics.error({
          event: 'storage',
          origin,
          scanned,
          error: e.error
        })
      })
      .catch(e => {
        onError(e.message)
        setShowLoader(true)
      })
      .finally(() => {
        setShowLoader(false)
      })
    }
  }, [keys, getScan])

  if (!isCointainerDetail(data)) {
    onError(t('storage-scan-destination.bad-request'))
    throw new Error(t('storage-scan-destination.bad-request'))
  }

  return {
    suggestedLocations: data.suggestedLocations,
    origin: data.locationAndContainer
  };
}