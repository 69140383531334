import { useEffect, useState } from 'react'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useNavigate } from 'react-router-dom'

import { useRequiredParams } from 'hooks/params.hook'
import { useGoToInventoryTransfer } from '../InventoryTransfer.navigator'
import { useGoToPickProduct } from '../PickProduct/PickProduct.navigator'
import { selectedProductAtom } from '../InventoryTransfer.state'
import type { OutputOrderProduct } from '../types/InventoryTransfer.types'
import { toastError, toastSuccess } from 'pages/receipt/utils/toast'
import { isOutputOrder } from './PickingList.loader'
import {
  deletePick,
  updateMissing,
  updateOrderStatus
} from '../client/InventoryTransfer.client'
import { normalizeEanForCapture } from 'types/model'
import { extractWeight } from 'utils/product'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'

export const usePickingList = () => {
  const { t } = useTranslation('global')
  const setProduct = useSetAtom(selectedProductAtom)
  const order = useLoaderData()
  const navigate = useNavigate()
  const inventoryTransfer = useGoToInventoryTransfer()
  const pickProduct = useGoToPickProduct()
  const [productsToPick, setProductsToPick] = useState<OutputOrderProduct[]>([])
  const [pickedProducts, setPickedProducts] = useState<OutputOrderProduct[]>([])
  const [loading, setLoading] = useState(false)
  const { orderId, docId } = useRequiredParams('orderId', 'docId')

  if (!isOutputOrder(order)) {
    throw new Error(t('inventory-transfer.detail.type-error'))
  }

  useEffect(() => {
    if (order) {
      const picked: OutputOrderProduct[] = []
      const toPick: OutputOrderProduct[] = []
      order.products.forEach((product) => {
        if (product.picks.length || product.isFullMissing) {
          picked.push(product)
        } else {
          toPick.push(product)
        }
      })
      setProductsToPick(toPick)
      setPickedProducts(picked)
    }
  }, [order])

  const { dialogRef, open: openDialog, value } = useConfirmationDialog()

  const onClosePage = () => {
    inventoryTransfer.go()
  }

  const onScanProduct = (upc: string) => {
    if (isNaN(+upc)) {
      toastError(t('receipt-order-detail.invalid-upc', { upc }))
      return
    }

    const barcode = normalizeEanForCapture(upc)
    const grams = extractWeight(upc)

    const picked = pickedProducts.find((prod) => prod.barcode === barcode)
    if (picked) {
      toastError(
        t('inventory-transfer.detail.already-picked', { upc: barcode })
      )
      return
    }
    const toPick = productsToPick.find((prod) => prod.barcode === barcode)
    if (!toPick) {
      toastError(
        t('inventory-transfer.detail.not-existing-upc', { upc: barcode })
      )
      return
    }

    setProduct(toPick)
    if (grams) {
      pickProduct.go(orderId, docId, grams)
    } else {
      pickProduct.go(orderId, docId)
    }
  }

  const onFinishPicking = async () => {
    try {
      setLoading(true)
      await updateOrderStatus(orderId, 'completed')
      toastSuccess('Se finalizó la transferencia correctamente.')
      inventoryTransfer.go()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onDeletePick = async (product: OutputOrderProduct) => {
    const pickId = product.picks[0]?.pickId
    if (!pickId) {
      return
    }
    try {
      setLoading(true)
      await deletePick(orderId, product.barcode, pickId)
      navigate(0)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const openMissingDialog = (product: OutputOrderProduct) => {
    openDialog(product)
  }

  const onConfirmFullMissing = async () => {
    const prod = value as OutputOrderProduct
    try {
      setLoading(true)
      await updateMissing(orderId, prod.barcode)
      navigate(0)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const getFooterLines = (product: OutputOrderProduct) => {
    const footerLines = [
      {
        label: t('inventory-transfer.detail.picked-quantity'),
        value: product.isWeighted
          ? `${product.picks[0]?.quantity || 0}g`
          : `${product.picks[0]?.quantity || 0}`
      }
    ]
    if (product.picks[0]) {
      footerLines.push({
        label: t('inventory-transfer.detail.location'),
        value: product.picks[0].locationName
      })
    }
    return footerLines
  }

  return {
    orderId,
    pickedProducts,
    productsToPick,
    loading,
    dialogRef,
    selectedProduct: value as OutputOrderProduct,
    actions: {
      onClosePage,
      onScanProduct,
      onFinishPicking,
      onDeletePick,
      onConfirmFullMissing,
      openMissingDialog,
      getFooterLines
    }
  }
}
