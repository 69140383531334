import { useState, type MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AtlasBodySubtitle } from '@justomx/atlas-components'
import { ConfirmationDialogMolecule } from 'components/molecules/ConfirmationDialog.molecule'
import { RadioButton } from 'components/molecules/RadioButton.molecule'
import type { GenericOption } from 'types/model'

interface OptionsDialogProps {
  dialogRef: MutableRefObject<HTMLDialogElement | null>
  title: string
  options: GenericOption[]
  defaultOption: string
  confirmAction: (value: string) => void
}

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
`

export const OptionsDialogOrganism = (props: OptionsDialogProps) => {
  const { dialogRef, title, options, defaultOption, confirmAction } = props
  const { t } = useTranslation('global')
  const [selectedOption, setSelectedOption] = useState(defaultOption)

  const onChangeOption = (value: string) => {
    setSelectedOption(value)
  }

  return (
    <ConfirmationDialogMolecule
      dialogRef={dialogRef}
      confirmText={t('receipt-missing-dialog.continue')}
      onConfirm={confirmAction}
      cancelText={t('receipt-missing-dialog.back')}
      value={selectedOption}
    >
      <AtlasBodySubtitle>{title}</AtlasBodySubtitle>

      <DialogBody>
        {options.map((option) => (
          <RadioButton
            name="missingType"
            key={option.id}
            id={option.id}
            value={option.id}
            label={option.label}
            defaultChecked={selectedOption === option.id}
            onChange={onChangeOption}
          />
        ))}
      </DialogBody>
    </ConfirmationDialogMolecule>
  )
}
